@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.title,
.companyName {
  @apply --EditListingDescriptionForm_formMargins;
}

.description {
  @apply --EditListingDescriptionForm_formMargins;
}

.customField {
  @apply --EditListingDescriptionForm_formMargins;
}

.category {
  @apply --EditListingDescriptionForm_formMargins;
}

.subCategory {
  @apply --EditListingDescriptionForm_formMargins;
}

.providerName {
  @apply --EditListingDescriptionForm_formMargins;
}

.addCompanyNameNote {
  @apply --EditListingDescriptionForm_formMargins;
  color: var(--matterColorAnti);
}

.companyListing {
  @apply --EditListingDescriptionForm_formMargins;
}

.bankDetails {
  @apply --EditListingDescriptionForm_formMargins;
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButtonContainer .submitButton {
  min-width: 30%;
  width: auto;
  margin: 20px auto;
  border-radius: 5px;
  padding: 0.5rem 1rem;

  @media (--viewportMobile) {
    min-width: 50%;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  background-color: var(--matterColorBlue) !important;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
}

.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}

.selectedActivity .editListingSelectCardText {
  color: var(--matterColorLight) !important;
  background-color: var(--matterColorBlue);
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 136px; */
  padding: 10px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.levelButtonWrapper {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  /* height: 136px; */
  /* padding: 8px; */
  /* background: var(--matterColorLight); */
  /* border: 1px solid #d7d7d7; */
  /* border-radius: 14px; */
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 9px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.buttonContent {
  display: block;
  justify-content: center;
}
.levelContent {
  display: flex;
  justify-content: center;
}
.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
      line-height: 23px;
      margin-left: 0px;
    }
  }
}
.editListingTypeText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  display: block;
  color: #000000;
}
.levelwrapper {
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #ffb81d;
}
.editListingTypeWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 30px;
}

.editListingTypeWrap .editListingTypeHead {
  display: flex;
  align-items: center;
  gap: 15px;
}
.editListingTypeHead .editListingTypeIcon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #d9d9d9;
}
.editListingLabel {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}
.editListingCardWrap {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (--viewportTabletDown) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.editListingFormWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
  @media (--viewportTabletDown) {
    grid-template-columns: 1fr;
  }
}
.seatsField {
  margin-top: 30px;
}

.editListingSelect {
  margin-top: 30px;
}
.bookingCurrencyWrapper {
  position: relative;
  margin-top: 30px;
}

.seatsField [class*='textarea'] {
  width: 97%;
  @media (--viewportTabletDown) {
    width: 94%;
  }
}
.bookingCurrencyWrapper label {
  background: var(--matterColorLight);
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
}
.bookingCurrencyWrapper input {
  &:focus {
    border-color: var(--matterColorBlue);
  }
}

.editListingSelect .editListingSelectText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
  margin-bottom: 20px;
}
.editListingSelectWrap {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
.editListingSelectCard {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
}
.editListingSelectWrap .editListingSelectCard .editListingSelectCardText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(86, 81, 81, 0.5);
  border: 1px solid rgba(86, 81, 81, 0.5);
  border-radius: 8px;
  padding: 8px 15px;
}

.requestTypeWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 40px;
}

.companyNameWrapper {
  margin-top: 30px;
}

.bankDetailWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.inputRoot {
  border-color: red;
}
.subCategoriesButton {
  min-height: 59px;
  margin: 0;
  color: var(--matterColorBlue);
  text-align: center;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  border: 0;
}
.editHasSubscription {
  margin-top: 30px;
  display: flex;
}
.editHasSubscription .editHasSubscriptionText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.editHasSubscriptionWrap {
  margin-left: 30px;
}

.buttonLevelWrapper {
  display: flex;
  justify-content: space-between;
  width: 165px;
  height: 60px;
  padding: 10px;
  background: var(--matterColorLight);
  border: 1px solid #005aff;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.buttonMain.selected .buttonLevelWrapper {
  /* border: 1px solid black; */
  background: #005aff;
  color: #fff;
}
.editListingTypeLevelText {
  font-weight: 500;
  font-size: 14px;
  /* line-height: 23px; */
  display: block;
  /* color: #000000; */
}
