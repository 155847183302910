@import '../../marketplace.css';

.mainWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.thankyouWrapper {
  width: 786px;
  padding: 100px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thankyouWrapper img {
  max-width: 100%;
  height: auto;
}
.thankyouWrapper h1 {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.02em;
  /* margin: 40px 0 70px; */
  text-align: center;
}
.btnDone {
  background: var(--matterColorBlue);
  border-radius: 7px;
  width: 222px;
  padding: 0;
  line-height: 60px;
  font-weight: 500;
  font-size: 18px;
  color: #eff5ff;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .thankyouWrapper {
    width: 100%;
    padding: 20px;
  }
  .mainWrapper {
    padding: 24px;
  }
  .btnDone {
    width: 186px;
    padding: 0 30px;
    line-height: 50px;
    cursor: pointer;
  }
  .thankyouWrapper h1 {
    font-size: 20px;
    /* margin: 30px 0 70px; */
  }

  .thankyouWrapper img {
    max-width: 100%;
    height: 128px;
    width: 128px;
  }
}
