@import '../../marketplace.css';

.footerItem {
  height: 24px;
}

.footerItem svg {
  fill: transparent;
  width: 20px !important;
  height: auto !important;
}

.activeFooterItem svg path,
.activeFooterItem svg rect,
.activeFooterItem svg circle {
  stroke: #0058fa;
}
.activeFooterItemEdu svg path,
.activeFooterItemEdu svg rect,
.activeFooterItemEdu svg circle {
  stroke: #f75959;
}
.activeFooterItem [class*='bike'] path {
  fill: #0058fa;
  stroke: none;
}

.activeFooterItemEdu [class*='bike'] path {
  fill: #f75959;
  stroke: none;
}

.footer {
  background: #ffffff;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 8%);
  border-radius: 12px 12px 0px 0px;
}

.logoContainer {
  margin-bottom: 1.2rem;
  margin-left: 0rem;

  & img {
    height: 2rem;
  }
}

.createBottomMenu {
  height: 100%;
}

.activitySearch {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.activitySearchWrap {
  /* height: calc(100vh - 140px); */
  flex: 1;
  /* overflow: auto; */
  padding: 20px 40px;
  position: relative;
}

.footerItemWrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 70px;
  z-index: 999;
  background-color: #ffffff;
  transition: 500ms;
}

.hideFooter {
  height: 0;
}

.closeBtn {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 20px;
  @media (--viewportMobile) {
    padding-top: 7px;
  }
}

.requestWrap {
  color: #000000;
  background: #ffffff;
  border: 1px solid #d7d7d7;
  border-radius: 7px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  justify-content: space-between;
  padding: 25px;
  position: relative;
}

.selectedRequest {
  border: 1px solid #0058fa;
}

.requestWrap .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
}

.requestWrap.selectedRequest .checkMark {
  display: flex;
}

.btnWrapper {
  padding: 35px 22px 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* justify-content: center; */
  gap: 25px;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.nextBtn {
  background: #0058fa;
  border-radius: 6px;
  border: none;
  width: 186px;
  height: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #eff5ff;
}
.createImage {
  width: 100%;
}

.suggestionContainer {
  display: flex;
  align-items: center;
}
.suggestionImg {
  border-radius: 50%;
  margin-right: 15px;

  @media (--viewportTabletDown) {
    width: 38px !important;
    height: 38px !important;
    object-fit: cover;
  }
}
.suggestionDetailsContainer {
  display: block;
  font-family: 'Int Circular PTT';
}

.suggestionTitle {
  font-style: normal;
  line-height: 21px;
  color: #000000;
  font-size: 16.7044px;

  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 22px;
  }
}
.suggestionDetailsText {
  font-style: normal;
  font-weight: 400;
  font-size: 12.5283px;
  line-height: 16px;
  color: #a8a8a8;

  @media (--viewportTabletDown) {
    font-size: 15px;
    line-height: 18px;
  }
}
.searchQuery {
  flex: 1 1;
  /*min-width: 276px;*/
  font-family: 'Int Circular PTT';
  transition: border-color 0.2s ease 0s;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50px;
  width: 100%;
  /*max-width: 214px;*/
  height: 100%;
  display: flex;
  border: 1px solid #c5c5c5;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1b;

  @media (--viewportLaptopDown) {
    transform: scale(1.03);
  }

  @media (--viewportMobile) {
    width: 100%;
    min-width: 100%;
  }

  @media (--viewportTabletDown) {
    margin: 0 0 10px 0;
    height: 40px;
    width: 100%;
  }

  & input {
    height: 100%;
    font-family: 'Int Circular PTT';
    font-weight: normal;
    border: none;
    font-size: 16px;
    padding: 0 0 0 0.2rem;
    line-height: 21px;
    color: #617189;
    width: calc(100% - 30px);
    margin-left: auto;

    &::placeholder {
      color: #aeadad;
      font-style: normal;
    }

    @media (--viewportMobile) {
      padding: 0 0 0 50px;
      margin-bottom: 2px;
    }

    @media (--viewportTabletDown) {
      font-size: 12.9841px;
      line-height: 16px;
      width: 100%;
    }
  }

  &:not(.active-search-query):hover {
    border-color: #dddddd;
  }

  & :global(.react-autosuggest__container) {
    height: 48px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    @media (--viewportTabletDown) {
      height: 100%;
    }
  }
}
.searchQuery :global(.react-autosuggest__suggestions-container--open) {
  background-color: #ffffff;
  z-index: calc(50 + 1);
  width: 100%;
  top: 45px;
  overflow-y: auto;
  box-shadow: none;
  margin: 0;
  overflow: auto;
  height: calc(100vh - 282px);
  width: calc(100% - 3px);
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 35%);
  background-color: transparent;
  display: none;
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 35%);
  display: none;
}

/* .searchQuery :global(.react-autosuggest__section-container--first) {
  margin-bottom: 50px;
} */

.searchQuery :global(.react-autosuggest__suggestion),
.searchPageInputWrapper :global(.react-autosuggest__suggestion) {
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  border: 1px solid transparent;
  padding: 10px 0;
}

.searchQuery :global(.react-autosuggest__suggestion):hover,
.searchPageInputWrapper :global(.react-autosuggest__suggestion):hover {
  border-radius: 8px;
  font-weight: 700;
}

.searchQuery :global(.react-autosuggest__suggestion):hover .suggetionDotWrap,
.searchPageInputWrapper :global(.react-autosuggest__suggestion):hover .suggetionDotWrap {
  font-weight: 700;
}

.searchQuery :global(.react-autosuggest__suggestions-list) {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (--viewportTabletDown) {
    grid-template-columns: 1fr;
  }
}

.singleActivityContainer .searchQuery :global(.react-autosuggest__suggestions-list) {
  display: grid;
  grid-template-columns: 1fr;
}

.singleActivityContainer .searchQuery :global(.react-autosuggest__suggestions-container--open) {
  @media (--viewportTabletDown) {
    height: 28vh;
  }
}
.activeSearchIcon {
  position: absolute;
  top: 16px;
  left: 30px;

  @media (--viewportTabletDown) {
    top: 12px;
    left: 24px;
    width: 14px;
  }
}
.mobileInputRoot {
  width: 100%;

  @media (--viewportLaptopDown) {
    background: #ffffff;
    box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
    border-radius: 15px;
    min-width: 346px;
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 29px;
  }
}
.ratingTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (--viewportTabletDown) {
    justify-content: space-between;
  }
}

.whichActivity {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #1b1b1b;
  margin-bottom: 10px;
}
.seeResult {
  display: flex;
  /* position: absolute; */
  font-size: 16px;
  bottom: -4px;
  /* left: 35px;
  padding-top: 10px; */
  gap: 15px;
  cursor: pointer;
  width: 100%;
  /* padding: 0 40px; */
  z-index: 999;
  background: #ffffff;
  /* left: 0; */

  & img {
    width: 20px;
    height: 20px;
  }
}
.footerImg {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.bottomMenuItemWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottomMenuItemText {
  font-weight: 500;
  font-size: 10px;
  color: var(--matterColorParagraphText);
}
.activeBottomMenuItemText {
  color: var(--matterColorBlue);
}
.activeBottomMenuItemTextEdu {
  color: #f75959;
}
