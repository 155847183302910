@import '../../marketplace.css';

.root {
}

.bookingInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateSection {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* color: #aba7a7; */
  @media (--viewportTabletDown) {
    font-size: 13px;
    line-height: 15px;
  }
}
