.layoutTopbar {
  z-index: 99;
}
.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}
.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}
.overlayMain {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 9;
}
.testScroll {
  overflow: hidden;
}
.main {
  position: relative;
  background: #f5f9ff;
  padding-left: 305px;
  overflow: hidden;
  /* height: calc(100vh - 271px); */
  /* overflow: auto; */
  @media (--viewportTabletDown) {
    padding-left: 0;
    overflow-x: hidden;
    /* height: calc(100vh - 202px); */
  }

  @media (--viewportLaptopDown) {
    padding-left: 0;
  }
}
.main [class*='defaultMapLayout'] {
  width: 100%;
  height: calc(100vh - 140px);
  overflow: hidden;
}
.openMainHeaderMain {
  height: calc(100vh - 340px);

  @media (--viewportTabletDown) {
    height: calc(100vh - 268px);
  }
}
.disableScroll {
  overflow-y: hidden;

  @media (--viewportLaptopDown) {
    height: calc(100vh - 225px);
    /* height: calc(100vh - 282px) */
    /* display: none; */
  }

  @media (--viewportMobile) {
    height: calc(100vh - 292px);
  }
}
.disableFilterScroll {
  overflow-y: hidden;
  height: calc(100vh - 225px);
}
.openModalDisableScrolling {
  overflow: hidden;
}

.switchContainer {
  display: flex;
  z-index: 1;
  overflow-x: auto;
  display: grid;
  gap: 5px;
  row-gap: 20px;
  /* padding: 20px; */
  padding: 20px 40px;
  margin-bottom: 10px;
  grid-template-columns: repeat(4, 1fr);
  background: linear-gradient(180deg, rgba(168, 168, 168, 0.17) 99.99%, rgba(1, 7, 15, 0.19) 100%);
  @media (max-width: 1024px) {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    /* margin-left: 30px; */
  }
  @media (--viewportTabletDown) {
    display: grid;
    padding: 20px;
    gap: 5px;
    grid-template-columns: repeat(4, 1fr);
    /* margin-left: 30px; */
  }
  @media (--viewportMobile) {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 5px;
  }
}
.switch {
  display: flex;
  z-index: 1;
  align-items: center;
  column-gap: 6px;
  height: 80px;
  margin: 0 8px;
  background: #a8a8a852;
  border: 1px solid #ffffff87;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0px 0px 5px #0005;
  @media (--viewportMobile) {
    column-gap: 5px;
    margin: unset;
    /* justify-content: space-between; */
    /* width: 46vw; */
    height: 60px;
  }
}
.switch h4 {
  font-weight: 600;
  font-size: 14px;
  width: 50%;
  text-align: center;
  line-height: 20px;
  color: #000000;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 12px;
    font-weight: 500;
  }
}

/* .switch [class*='react-switch-bg'] {
  width: 37px !important;
  height: 16px !important;
}
.switch [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  top: -2px !important;
}
.switchUnchecked [class*='react-switch-handle'] {
  left: -1px !important;
  background: #b5b5b5 !important;
}
.switchUnchecked [class*='react-switch-bg'] {
  background: #e2e1e1 !important;
}
.switchChecked [class*='react-switch-handle'] {
  left: 16px !important;
  background: #0058fa !important;
}
.switchChecked [class*='react-switch-bg'] {
  background: rgba(0, 88, 250, 0.2) !important;
} */
.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
  height: 100vh;
  overflow: auto;
}

.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: fill;
  padding: 25px 42px;
  @media (--viewportMobile) {
    width: 100%;
    height: unset;
    padding: 0 0 16px;
  }
}

.cityHeading {
  margin: 0 80px;
  @media (--viewportTabletDown) {
    margin: 0 40px;
  }
  @media (--viewportMobile) {
    margin: 0 8px;
  }
}

/* listing mode */

.listingCardWrap {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (--viewportTabletDown) {
    flex-wrap: nowrap;
    overflow-x: auto;
    column-gap: 10px;
    row-gap: 10px;
    padding-top: 10px;
    justify-content: center;
  }
}

.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}
.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
  @media (--viewportMobile) {
    width: 16px;
    height: 16px;
    border-radius: 6px 0px 2px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
  background-color: #0058fa;
}

.buttonMain.selected .buttonWrapper {
  border: 1px solid #0058fa;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 136px; */
  height: 52px;
  padding: 10px 25px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 10px 16px;
  }
}
.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
}
.listingModeText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}
.listingModeWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 80px;
  @media (--viewportTabletDown) {
    padding: 0 40px;
    width: 100%;
  }
  @media (--viewportMobile) {
    padding: 8px;
  }
}
.switchBtnWrap {
  display: grid;
  gap: 24px;
  justify-content: space-between;
  align-items: center;
  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
  @media (--viewportMobile) {
    display: block;
  }
}

.whatsappLinkHome a img {
  max-width: 90px;
  height: auto;
  border-radius: 30px;
  position: fixed;
  z-index: 100;
  top: 14px;
  right: 30px;
  @media (--viewportMobile) {
    right: 0;
    top: 80px;
  }
}
.switchimage {
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: 10% 25%;
  filter: brightness(100%);
  border-radius: 5px 0px 0px 5px;
  @media (--viewportMobile) {
    display: block;
    height: 100%;
    border-radius: 5px;
    flex-shrink: 0;
  }
}
.switchActive {
  background: #fff;
  box-shadow: 3px 5px 10px #0005;
}
.switchActive h4 {
  color: #ffb81d;
}
