.becomeSellerWrap {
  display: inline;
  align-items: center;
  position: relative;
  width: 100%;
}
.becomeSellerImg img {
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
  height: 22rem;
  border-radius: 20px;

  @media (--viewportMobile) {
    height: 200px;
    width: 100%;
  }
}
.becomeSellerText {
  background: #00000080;
  border-radius: 20px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (--viewportTabletDown) {
    border-radius: 0;
  }
}
.becomeSellerHead {
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  text-align: center;
  color: #ffffff;
  margin: 0 0 10px;
  @media (--viewportMiniDesktopDown) {
    font-size: 32px;
    line-height: 42px;
  }
  @media (--viewportTabletDown) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 13px;
  }
}
.becomeSellerDesc {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  width: 460px;
  margin: 0 0 40px;
  @media (--viewportMiniDesktopDown) {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 20px;
  }
  @media (--viewportTabletDown) {
    font-size: 10px;
    line-height: 17px;
    margin: 0 0 13px;
    width: 260px;
  }
}
.becomeSellerButton {
  background: #ffffff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #000000;
  width: 230px;
  padding: 15px 20px;
  cursor: pointer;
  border: 0;
  @media (--viewportMiniDesktopDown) {
    font-size: 16px;
  }
  @media (--viewportTabletDown) {
    font-size: 10px;
    line-height: 13px;
    padding: 7px 20px;
    width: 130px;
  }
}
