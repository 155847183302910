@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;
}

/* Dummy Topbar */

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;
  width: auto;
  height: 27px;
  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  background-color: #f5f5f5;
  padding: 1rem 0;

  align-items: center;

  @media (--viewportLaptop) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1rem 48px 40px;
  }

  @media (--viewportMobile) {
    flex-direction: column;
    gap: 20px;
  }
}

.bookListingContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  padding: 1.5rem;
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border-radius: 10px;

  @media (--viewportLaptop) {
    flex-grow: 0;
    flex-basis: 700px;
    margin-right: 2rem;
  }
  @media (--viewportMobile) {
    padding: 23px;
    width: 85%;
  }
}
.modifiedBookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;

  @media (--viewportLarge) {
    flex-grow: 0;
    flex-basis: 750px;
    /* margin-top: 29px; */
    margin-right: 50px;
  }
  @media (--viewportMobile) {
    margin-bottom: 90px;
    padding: 0px;
    width: 90%;
  }
}

.aspectWrapper {
  position: relative;
  background-color: var(--matterColorNegative); /* Loading BG color */
  display: none;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px 12px 0 0;
  /* @media (--viewportLarge) {
    
  } */
}

.avatarWrapper {
  /* Position (over the listing image)*/
  /* margin-left: 24px;
  margin-top: -31px; */

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;
  & div {
    width: 54px;
    height: 54px;
    background: #c4c4c4;
    & span {
      text-transform: uppercase;
    }
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 29px;
  margin-bottom: 34px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 30px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 54px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 420px;

    /* Position */
    padding: 20px 20px 15px 20px;

    /* Coloring */
    border-radius: 2px;
  }
  @media (--viewportLaptopDown) {
    margin: unset;
    padding: 0 20px;
    border-radius: 12px 12px 0 0;
  }
  @media (--viewportMobile) {
    margin-bottom: 90px;
    /* display: none; */
    padding: 0 23px;
    width: 100%;
  }
}

/* Details column is visible only on desktop */
.withoutPaymentDetailsContainerDesktop {
  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 369px;

    /* Position */
    padding: 20px 20px 15px 20px;

    /* Coloring */
    border-radius: 2px;
  }
  @media (--viewportLaptopDown) {
    margin-bottom: 90px;
    padding: 0 20px;
    border-radius: 12px 12px 0 0;
  }
  @media (--viewportMobile) {
    margin-bottom: 30px;
    padding: 0 23px;
    width: 100%;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 65.6667%;
  border-radius: 12px 12px 0 0;
}

.detailsHeadings {
  /* display: none; */
  padding-left: 20px;

  @media (--viewportLarge) {
    display: block;
    /* margin: 0 48px; */
  }
}

.detailsTitle {
  margin-bottom: 10px;
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: var(--marketplaceColorDarkest);

  @media (--viewportLarge) {
    /* margin-top: 17px; */
    margin-bottom: 5px;
  }
}

.detailsSubtitle {
  /* @apply --marketplaceH5FontStyles; */

  /* Reset margins from font styles */
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #0058fa;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px;
  }
  @media (--viewportMobile) {
    padding: 0 20px;
  }
}

.remainingSessions {
  color: var(--matterColorPink);
}

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: auto;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;
  padding: 27px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.options {
  display: grid;
  /* grid-template-columns: 250px; */
  grid-row: auto;
  grid-column-gap: 45px;
  grid-row-gap: 34px;
  justify-content: center;
  @media (--viewportLarge) {
    grid-template-columns: 100%;
  }
  @media (--viewportMobile) {
    grid-template-columns: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.optionItem {
  position: relative;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: var(--marketplaceColorDarkest);

  width: 100%;
  /* width: 244px;
  height: 193px; */
  margin-bottom: 18px;
  padding: 1rem;
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    box-shadow: var(--boxShadowButton);
  }
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    width: auto;
    font-size: 12px;
    line-height: 18px;
    padding: 0.5rem 1rem;
    margin: 0;
  }
}

.selectedPaymentOptionItem {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  width: 245px;
  height: 193px;
  background-color: var(--marketplaceColorBackground);
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 15px;
  cursor: pointer;
  & div {
    color: white;
    & svg {
      & path {
        width: 100px;
        height: 100px;
        fill: white;
      }
    }
  }
  &:hover {
    box-shadow: var(--boxShadowButton);
  }
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    width: auto;
    height: 152px;
    font-size: 12px;
    line-height: 18px;
  }
}

.Summary {
  border: 1px solid var(--matterColorNegative);
  background-color: white;
  padding: 20px 17px 44px;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  & div {
    margin: 0;
  }
}

.SummaryHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 18px;
}

.icons {
  display: flex;
  gap: 0.5rem;
  margin: 0;
}

.icons li img {
  @media (--viewportMobile) {
    width: 25px;
    height: 25px;
  }
}

.icon {
  padding: 0.5rem;
  /* width: 100px;
  margin: 0 auto 10px; */
  & > svg {
    width: 55px;
    height: 36px;
    fill: var(--marketplaceColorDark);
    @media (--viewportMobile) {
      width: 100%;
      height: 36px;
    }
  }
}

.label {
  text-align: start;
  padding: 0.4rem 0;
  & p {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    @media (--viewportMobile) {
      font-size: 12px;
    }
  }
}

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}
.button {
  margin-top: 48px;
  width: 50%;
  @media (--viewportMobile) {
    margin-bottom: 60px;
    margin-top: 0;
  }
}
.buttonContainer {
  display: flex;
  justify-content: center;
}

.done {
  cursor: pointer;
}

.mobileFooter {
  display: none;
  @media (--viewportMobile) {
    display: block;
  }
}

.paymentOptionsSection {
  padding: 20px 0;
  & img {
    margin: 0 16px 0 0;
    @media (--viewportMobile) {
      margin: 0 10px 0 0;
    }
  }
}

.roundedCheckIcon {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 35px;
  @media (--viewportMobile) {
    top: 8px;
    right: 8px;
    height: 30px;
    width: 30px;
  }
}

.paymentSelectHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 21.3306px;
  line-height: 27px;
  color: #3c3c3c;
}

.infoSpan {
  font-size: 16px;
  color: var(--matterColorDimmedText);
}

.ContainerLine {
  width: 100%;
  height: 8px;
  background: var(--marketplaceColorBackground);
}

.priceForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0 22px 14px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }
  & button {
    height: 59px;
    min-height: 59px;
    width: 200px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    background: #0058fa !important;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  @media (--viewportMobile) {
    padding: 0;
    margin-right: 0;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: #0058fa;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.contactUsButton {
  @apply --marketplaceButton2StylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  @media (--viewportMobile) {
    min-width: 66px;
  }
}
