@import '../../marketplace.css';

.autorListingWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportTabletDown) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.content {
  background: var(--matterColorLight);
  overflow: unset;
  @media (--viewportMobile) {
    overflow-x: hidden;
  }
}

.section {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  min-width: 100%;
  margin: 0px auto 60px auto;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 3.25rem;
    margin: 0;
    padding: 0;
  }

  @media (min-width: 1600px) {
    max-width: 100%;
    margin: 0px auto 60px auto;
  }
}

body {
  overflow-x: hidden;
}

/*have*/

.topHeadContainer {
  height: calc(70vh - 56px);
  width: 100%;
  /* background: var(--marketplaceColorBackground); */
  display: flex;
  position: relative;
  color: var(--matterColorLight);
  padding: 100px 30px;
  gap: 50px;
  @media (max-width: 991px) {
    flex-flow: column-reverse;
    margin-top: 50px;
    height: fit-content;
    padding: 30px 20px;
  }
}
/* Top Header Quotes CSS starts */
.quoteWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  @media (--viewportLaptopDown) {
    margin-bottom: 0px;
  }
  @media (--viewportMobile) {
    padding: 0 15px;
  }
}
.quoteWrapper img {
  @media (--viewportMobile) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    max-width: 100px;
    height: auto;
  }
}

.quoteWrapper p {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  position: relative;
  color: #345592;

  @media (--viewportLaptopDown) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 15px;
  }
}

.quoteWrapper .floating img {
  @media (--viewportMobile) {
    display: none;
  }
}
.quoteWrapper .inputWrapper img {
  max-width: 130px;
  height: auto;
  position: absolute;
  right: 0;
  top: 5px;
  @media (--viewportMobile) {
  }
}
.quoteWrapper .inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapperInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.wrapperInput.floatingInput {
  position: fixed;
  flex-direction: column;
  background: linear-gradient(180deg, #0058fa 26.39%, rgba(45, 104, 213, 0.43) 100%);
  border-radius: 25px;
  right: 0;
  height: 170px;
  padding: 25px;
  @media (max-width: 991px) {
    height: 140px;
    padding: 15px;
    gap: 5px;
  }
  @media (--viewportMiniDesktopDown) {
    height: 150px;
    padding: 15px;
  }
  @media (--viewportMobile) {
    left: 50%;
    transform: translatex(-50%);
    bottom: 70px;
    width: 95%;
    height: 120px;
    /* box-shadow: 0 -2px 5px 2px #0000002b; */
  }
}

.inputWrap {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0 0;
  @media (--viewportMiniDesktopDown) {
    width: 200px;
  }
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.inputWrap .inputLabel {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding: 0;
  @media (--viewportMiniDesktopDown) {
    font-size: 18px;
  }
  @media (--viewportTabletDown) {
    display: none;
  }
}

.quoteWrapper .inputWrapper input[type='text'] {
  border: 1px solid rgba(0, 0, 0);
  height: 40px;
  padding: 0 25px;
  width: 420px;
  color: #000;
  font-size: 20px;
  @media (--viewportMobile) {
    padding: 0 15px;
    font-size: 14px;
    width: 100%;
  }
}
.quoteWrapper .inputWrapper .btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  padding: 0 24px;
  width: 185px;
  line-height: 40px;
  border-radius: 6px;
  @media (--viewportMobile) {
    padding: 10px 2px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  }
}
.quoteWrapper .inputWrapper .btnQuote:active {
  background: transparent;
  border: 1px solid #0058fa;
  color: #0058fa;
}

.quoteWrapper h4 {
  font-weight: 500;
  font-size: 46px;
  line-height: 45px;
  color: #000000;
  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }
}
.heroImage img {
  border-radius: 27px;
  position: relative;
  z-index: 1;
}
.heroImage .heroVideo {
  position: relative;
  left: 50%;
  transform: translateX(-55%);
  top: 0;
  opacity: 1;
  /* width: 100%; */
  height: 100%;
  border-radius: 27px;
  position: relative;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  box-shadow: #00000066 0px 2px 4px, #0000004d 0px 7px 13px -3px, #00000033 0px -3px 0px inset;

  @media screen and (max-width: 991px) {
    width: 100%;
    transform: translateX(-50%);
  }
}
.heroImage .overlayBg {
  background: var(--matterColorShades);
  border-radius: 27.5877px;
  width: 520px;
  height: calc(100% + 60px);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-55%, -50%);
  @media (max-width: 1699px) {
    width: 450px;
  }
  @media (--viewportMobile) {
    width: 80%;
    left: 55%;
  }
}

.heroImage video::-webkit-media-controls-panel {
  display: flex;
  -webkit-appearance: none;
  opacity: 1;
  height: 40px;
}

.heroImage video::-webkit-media-controls-mute-button,
.heroImage video::-webkit-media-controls-volume-slider {
  display: block;
  visibility: visible;
  width: 40px;
}

.heroImage video::-webkit-media-controls-timeline,
.heroImage video::-webkit-media-controls-play-button,
.heroImage video::-webkit-media-controls-fullscreen-button,
.heroImage video::-webkit-media-controls-time-remaining-display,
.heroImage video::-webkit-media-controls-current-time-display {
  display: none;
  -webkit-appearance: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
/* Top Header Quotes CSS ends */
.heroImage {
  flex: 1.5;
  width: 100%;
  height: 100%;
  position: relative;

  @media (--viewportMobile) {
    height: 255px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* location area css start */
.locationArea {
  background-color: var(--matterColorBlue);
  margin: 5px 0px;
  padding: 5px 0;
}
.locationHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  width: 85%;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.positionStatic {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  height: fit-content;
  @media (--viewportMobile) {
    height: 50px;
  }
}
.locationTitle {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: start;
  color: #fff;
  margin: 20px 0 0;
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 28px;
    width: 85%;
    margin: 15px auto 0 auto;
  }
}
.locationTextWrap {
  color: #fff;
}
.locationHeader {
  padding: 35px 0;
  background-color: transparent;
  @media (--viewportTabletDown) {
    padding: 20px 24px;
  }
}

.locationListContainer {
  /* height: 147px; */
  display: flex;
  align-items: center;
  /* left: 100px; */
  position: relative;
  width: 85%;
  margin: auto;
  /* padding: 0 40px; */
  /* justify-content: center; */
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 0;
    margin: unset;
  }
}

.locationListContainer [class*='scrollContainer'] {
  /* padding: 0 30px; */
  padding-bottom: 10px;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar {
  display: block;
  height: 3px;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar-track {
  background: transparent;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 50px;
}

.locationListContainer [class*='left'] {
  left: -40px;
}

.locationListContainer [class*='right'] {
  right: -50px;
}

.categoryListContainerWithoutArrow {
  width: 100%;
  margin: 0 80px;

  @media (--viewportMobile) {
    margin: 0;
  }
}

.locationWrapperTop {
  display: flex !important;
  flex-direction: row;
  column-gap: 30px;
  align-items: center;
  flex: none;
  cursor: pointer;
  @media (--viewportLaptopDown) {
    column-gap: 15px;
  }
  &:hover {
    text-decoration: none;
  }
}
.locationImgWrap {
  width: 75px;
  height: 75px;
  @media (--viewportLaptopDown) {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}
.locationImgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.locationTextWrap {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  @media (--viewportLaptopDown) {
    font-size: 17px;
    line-height: 21px;
  }
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 15px;
  }
}
.selected {
  color: #ffb81d;
}
/* location area css end */

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.shareFormModal [class*='container'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    padding: 15px;
    min-height: unset;
    width: 95%;
    height: 100%;
    flex-grow: 0;
    top: 50px;
    border-radius: 10px;
    background: rgb(247, 247, 247);
  }
}
.shareFormModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.shareFormModal [class*='close'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    left: 5px;
    background: none;
    width: 15px;
    height: 15px;
    fill: #000000;
  }
}
/* Quotes Modal Design End */

/* /////////////////////////////////////////////////////////////////////// */

/* //////////////////// JobsBoard-card-css ////////////////////*/
.JobsBoardCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
}

.jobCardMain {
  flex: 2;
  position: relative;
  width: 100%;
  margin: 40px 20px;
  @media (--viewportTabletDown) {
    margin-right: 0;
    padding-right: 20px;
  }
}
.JobFilled {
  position: absolute;
  width: 100%;
  text-align: center;
  height: 100%;
  /* align-items: center; */
  z-index: 5;
  /* top: 40%; */
  @media (--viewportMobile) {
    top: 15%;
  }
}
.JobFilled img {
  height: 100%;
  @media (--viewportLaptopDown) {
    width: 100%;
  }
  @media (--viewportTabletDown) {
    width: auto;
  }
  @media (--viewportMobile) {
    height: auto;
    width: 100%;
  }
}
.jobCardBG {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 90%;
  height: 70%;
  background: #0058fa;
  border-radius: 10px;
  z-index: 0;
  @media (--viewportMobile) {
    top: -15px;
    left: -15px;
  }
}
.jobCardWrap {
  border: 2px solid #ffb534;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */
  box-shadow: #015ef44d 6px 2px 16px 0px;
  background-color: #e9e9e9;
  @media (--viewportTabletDown) {
    height: 100%;
  }
}
.jobCardWrapBox {
  padding: 15px 25px;
  border-radius: 10px;
  background: #fff;
  @media (--viewportTabletDown) {
    padding: 15px 25px;
  }
}
.jobCardImg {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-top: 15px; */
}
.jobCardHeadWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.cardName {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  margin: 0;
}
.cardNameWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.jobCardHead {
  display: flex;
  align-items: flex-start;
  gap: 35px;
  @media (--viewportTabletDown) {
    gap: 20px;
  }
}
.jobCardDetail {
  display: flex;
  flex-direction: column;
  margin: 15px 0 0;
}
.detailHead {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  width: fit-content;
  margin: 0;
  color: #0058fa;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
}
.JobsBoardBage {
  color: #fff;
  font-size: medium;
  padding: 6px 10px;
  border-radius: 10px;
  text-wrap: nowrap;
  width: fit-content;
  @media (--viewportMobile) {
    display: block;
    margin: 5px 0px;
  }
  /* mar */
}

.jobCardDetail .pointer:hover {
  border-bottom: 2px solid;
}
.detailDesc {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  /* color: #a8a8a8; */
  margin: 0;
  width: 100%;
}
.detailDescHide {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  background: #fff;
}

.jobCardButtons {
  display: flex;
  align-items: center;
  gap: 25px;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }
}
.btn {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 250px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}
.BtnBlue {
  background-color: #005aff;
  color: #fff;
  /* font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  border-radius: 5px;
  width: 250px;
  @media (--viewportMiniDesktopDown) {
    width: 100%;
    padding: 10px 25px;
  } */
}
.BtnOrange {
  background-color: #fe6436;
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  /* font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  border-radius: 5px;
  width: 250px;
  @media (--viewportMiniDesktopDown) {
    width: 100%;
    padding: 10px 25px;
  } */
}
.locationIcon {
  padding: 0 7px;
  max-height: 18px;
  position: relative;
  bottom: 3px;
}
.toggellocation {
  width: 250px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }
}
.fullloaction {
  background-color: #fff;
  position: absolute;
  top: 47px;
  box-shadow: 10px 8px 12px 2px #0005;
  color: #000;
  padding: 10px;
  text-align: center;
  width: 250px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 20px;
  }
}
.hide {
  display: none;
}
.jobCardBoday {
  margin-top: 25px;
}
.jobCardText {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #000000ce;
  background: #005ef40d;
  border-radius: 10px;
  padding: 5px;
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}
.jobCardText span {
  color: #0058faad;
}
.JobsBoardCardLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.jobsBoardReply {
  min-width: 100%;
  display: flex;
  border-radius: 0 0 10px 10px;
  justify-content: space-between;
  align-items: center;
  /* margin: 10px 20px; */
  padding: 10px 20px;
}
.BtnReply {
  background-color: #005aff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  line-height: 26px;
  font-weight: 500;
  border: 0;
  padding: 8px 25px;

  border-radius: 5px;
}
/* // CSS For JobsBoardForm // */

.enquiryCardWrapper {
  margin: 30px;
  background: var(--matterColorLight);

  @media (--viewportTabletDown) {
    margin: 20px 0;
  }
}

.enquiryCard {
  padding: 0 40px 40px;
  display: flex;
  /* display: grid;
  grid-template-columns: 20vw 1fr; */
  column-gap: 30px;

  @media (--viewportTabletDown) {
    padding: 0 15px 30px;
    flex-direction: column;
    /* grid-template-columns: 1fr; */
  }
}
/* @media (--viewportTabletDown) {
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0 20px;
} */
.enquiryCardTopBar {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  @media (--viewportTabletDown) {
    flex-direction: column;
  }
}
.enquiryCardTitle {
  padding: 40px;
  margin-right: 20px;
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    padding: 15px;
    font-size: 22px;
    line-height: 25px;
  }
}
.aciveBtn {
  display: flex;
  gap: 20px;
}
.jobBoardBtnGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (--viewportTabletDown) {
    justify-content: space-around;
    margin: 10px 0;
  }
  @media (--viewportMobile) {
    flex-direction: column;
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  height: 52px;
  margin: 0 8px;
  padding: 0 8px;
  background: rgba(255, 255, 255, 0.53);
  border: 1px solid rgba(255, 255, 255, 0.53);
  border-radius: 7px;
  white-space: nowrap;
  @media (--viewportMobile) {
    column-gap: 5px;
    margin: unset;
  }
}
.switch label {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.switch [class*='react-switch-bg'] {
  width: 37px !important;
  height: 16px !important;
}
.switch [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  top: -2px !important;
}
.switchChecked [class*='react-switch-handle'] {
  left: -12px !important;
  background: #0058fa !important;
}
.switchChecked [class*='react-switch-bg'] {
  background: rgba(0, 88, 250, 0.2) !important;
}
.switchUnchecked [class*='react-switch-handle'] {
  left: -1px !important;
  background: #b5b5b5 !important;
}
.switchUnchecked [class*='react-switch-bg'] {
  background: #e2e1e1 !important;
}
.enquiryCardRoot {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.formatWrapper {
  /* flex: 1; */
  /* display: flex; */
  align-self: flex-start;
  position: sticky;
  top: 20px;
  /* padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px; */
  @media (--viewportTabletDown) {
    width: 100%;
    position: unset;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid #000000;
  padding-bottom: 20px;
  @media (--viewportTabletDown) {
    border-bottom: none;
  }
}

.searchWrapper {
  flex: 1;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: sticky;
  top: 150px;
  background: #ffffff;
  width: 300px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 15px;
    position: inherit;
  }
}

.formatTitle {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    padding: 10px;
  }
}
.formatTitleCategory {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    border: 1px solid #000000;
    box-shadow: 5px 5px 10px #0005;
    border-radius: 10px;
    padding: 14px;
    z-index: 4;
  }
}
.formatTitleOpen {
  @media (--viewportTabletDown) {
    border-radius: 10px 10px 0 0;
  }
}
.formatBody {
  border-color: red;
}
.filterInputBody {
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.filterInputBody:focus {
  border-color: rgba(0, 0, 0, 0.1);
}

.locationAddress {
  position: relative;
  flex-shrink: 0;
}

.rootClass {
  position: unset;
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border-bottom: 1px solid #000000;
  padding-bottom: 15px 0;
  max-height: 300px;
  @media (--viewportTabletDown) {
    border-bottom: none;
    border-radius: 10px 10px 0 0;
    row-gap: 0;
    position: relative;
  }
}

.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;
}

.predictionsRoot li {
  color: var(--matterColorDark) !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 10px 29px !important;

  @media (--viewportxldesktop) {
    font-size: 14px;
  }
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 30px 0 35px;
}
.categoryBody {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: auto;
}
.categoryDDShrink {
  display: none;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s;
  overflow: hidden;
}
.categoryBodyDDFull {
  height: auto;
  display: unset;
  overflow: auto;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  border: 1px solid #000000;
  border-top: none;
  box-shadow: 5px 5px 10px #0005;
  border-radius: 0 0 10px 10px;
  position: absolute;
  width: 100%;
  padding: 10px 0;
  top: 54px;
  z-index: 3;
  max-height: 300px;
  background-color: #ffffff;
}
.selectedCategory {
  border-radius: 4px;
  background-color: rgb(218, 237, 254);
}

.styledCheckbox {
  position: absolute;
  opacity: 0;
}

.labelText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.styledCheckbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 5px;
}

.styledCheckbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.styledCheckbox:checked + label:before {
  background: #0058fa;
  border-radius: 3px;
}

.styledCheckbox:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  border: 0;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}
.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.addStripeAccountModal {
  width: 600px;
  background: #ffffff;
  padding: 65px;
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
    padding: 40px;
  }
}
.customModal {
  padding: 0px !important;
  border-radius: 15px !important;
  overflow: hidden;
  @media (--viewportMobile) {
    border-radius: unset !important;
  }
}
.JobsCardReplyModel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
}
.JobsCardMessagediv {
  width: 95%;
}
.radioWrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.selected {
  color: #ffb81d;
}
.JobsBoardUsername {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 18px;
  font-weight: 600px;
}
.JobsReplyMessagetext {
  padding: 10px;
  max-width: 100%;
}
.error {
  color: red;
}
.jobsBoardTime {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  text-transform: capitalize;
}
.pointer {
  cursor: pointer;
}
.thankYouWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 60px;
}
.soloJobsBoardCard {
  width: 80%;
  padding: 20px;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 96%;
    margin: auto 10px;
  }
  @media (--viewportMobile) {
    width: 100%;
    padding: 0px;
    padding-right: 10px;
    margin: auto;
  }
}
.JobsBoardBackBtn {
  padding: 30px 0 0 64px;
  padding-bottom: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.backbtn {
  border-radius: 10px;
  background: #ffffff;
  padding: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 10px #0005;
  cursor: pointer;
}
.floatingTopBar {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 9999;
  @media (--viewportTabletDown) {
    display: none;
  }
}
.JobsLeadText {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportLaptopDown) {
    max-width: 50%;
  }
  @media (--viewportMobile) {
    max-width: 100%;
  }
}
.JobsLeadText p {
  font-size: 18px;
  color: #ffb81d;
  font-weight: 500;
  @media (--viewportLaptopDown) {
    /* font-size: 16px; */
    margin-bottom: 0;
  }
}
.btnGroup {
  display: flex;
  flex-direction: row;
  gap: 0px;
  @media (--viewportLaptopDown) {
    gap: 20px;
    flex-direction: column;
    width: 100%;
  }
  @media (--viewportMobile) {
    margin-top: 10px;
  }
}
.Leadbtn {
  padding: 0px 10px;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  margin: 0px 5px;
  height: 55px;
  cursor: pointer;
  @media (--viewportLaptopDown) {
    width: 100%;
  }
  @media (--viewportMobile) {
    margin: 0px;
    width: fit-content;
  }
}
.Leadbtn:active {
  background-color: #fff;
  border: 2px solid #005aff;
  color: #005aff;
}
.card {
  text-align: center;
  width: 280px;
}
.card .title {
  font-size: 2em;
  margin: 15px;
}
.card .form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.form label {
  cursor: pointer;
}
.card .form .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 15px;
  height: 15px;
  margin-right: 7px;

  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.card .form label {
  display: flex;
  margin: 10px 15px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}
.input-radio.blueCheck:checked {
  box-shadow: 0px 0px 0px 4px #005ef4;
  background-color: #005ef4;
  width: 15px;
  height: 15px;
}
.input-radio.orgCheck:checked {
  box-shadow: 0px 0px 0px 4px #ec614f;
  background-color: #ec614f;
  width: 15px;
  height: 15px;
}
.isActive {
  background: #8b8b8b;
}
.progressBarMain {
  width: 100%;
  height: 7px;
  border-radius: 10px;
  background: #e3e3e3;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.progressBarMain .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #0058fa;
  width: 25%;
  transition: 0.3s ease;
}
.banner {
  width: 100%;
  margin-bottom: 20px;
}
.banner .bannerText {
  padding: 10px 25px;
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  align-items: center;
}
.banner .bannerText h3 {
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0;
  @media (--viewportMobile) {
    font-size: 24px;
    font-weight: 500;
  }
}
.banner .bannerText p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  text-align: justify;
  margin: 0 0;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.banner .bannerText button {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 20px;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.banner .bannerImg {
  width: inherit;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @media (--viewportMobile) {
    border-radius: unset;
    height: 35%;
    object-fit: cover;
    object-position: left;
  }
}
.btnBlue {
  border-radius: 6px;
  padding: 8px 14px;
  font-weight: 500;
  /* font-size: 16px; */
  font-size: 18px;
  line-height: 16px;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  background: #0058fa;
  color: #ffffff;
  border: 0;
}
.btnBlue:active {
  background: #fff;
  color: #0058fa;
  border: 0;
  border: 2px solid #0058fa;
}
.btnBack {
  border-radius: 6px;
  padding: 8px 14px;
  font-weight: 500;
  /* font-size: 16px; */
  font-size: 18px;
  line-height: 16px;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  border: 1px solid #0058fa;
  color: #0058fa;
  background: transparent;
}
.btnBack:active {
  background: #0058fa;
  color: #fff;
  border: 0;
  border: 1px solid #0058fa;
}

/* viewportLaptopDown 1023 */
/* viewportTabletDown 768 */
/* viewportMobile 550 */
.Blue {
  background-color: #0058fa;
}
.Org {
  background-color: #ec614f;
}
.Yellow {
  background-color: #ffb81d;
}
