@import '../../marketplace.css';

.header {
  padding: 16px 7%;
  background-color: var(--marketplaceColorDarker);
  color: var(--matterColorLight);
  font-size: 25px;
  font-weight: 600;
  @media (--viewportMobile) {
    padding: 16px 15px;
  }
}

.title {
  margin: 0px 25px;
}

.tabsContainer {
  @media (--viewportTabletDown) {
    padding: 50px 24px 0;
  }
}

.move {
  color: var(--matterColorLightBlue);
}

.searchContainer {
  padding: 26px 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (--viewportMobile) {
    padding: 0;
  }
}

.searchTabs {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  & > * {
    margin-right: 34px;
    @media (--viewportMobile) {
      display: block;
      margin-right: 10px;
    }
  }
  & > *:last-child {
    margin-right: 0;
  }
  @media (--viewportMobile) {
    justify-content: flex-start;
    display: none;
  }
}

.tabs {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  color: #223046;
  font-size: 20px;
  @media (--viewportMobile) {
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
}

.activeTab {
  border-bottom: 6px solid #2c3e50;
  font-weight: bold;
  font-size: 21px;
  line-height: 60px;
  text-transform: capitalize;
  color: var(--matterColorDarkBlue);
  /* width: 111px; */
  @media (--viewportMobile) {
    font-weight: bold;
    border-bottom: 6px solid var(--marketplaceColor);
    font-size: 23px;
    line-height: 60px;
    text-transform: capitalize;
    color: var(--marketplaceColor);
  }
}

.inactiveTab {
  border-bottom: 6px solid transparent;
  font-weight: normal;
  font-size: 21px;
  line-height: 60px;
  text-transform: capitalize;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 23px;
    line-height: 60px;
    text-transform: capitalize;
    color: #243144;
  }
}

.borderWrapper {
  position: absolute;
  display: flex;
  width: 100%;
}

.borderLight {
  background-color: var(--matterColorLightBlue);
  height: 3px;
  width: 70%;
  float: left;
  margin-top: 4px;
}

.borderDark {
  background-color: var(--marketplaceColorBackground);
  width: 30%;
  height: 3px;
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

.providerHeading {
  width: 170px;
  @media (--viewportMobile) {
    width: 125px;
  }
}

.customersHeadingBorderDark {
  @media (--viewportMobile) {
    width: 37px;
  }
}

.customersHeadingBorderLight {
  @media (--viewportMobile) {
    width: 95px;
  }
}

.providerHeadingBorderLight {
  width: 97px;
  @media (--viewportMobile) {
    width: 70px;
  }
}
.providerHeadingBorderDark {
  width: 43px;
  @media (--viewportMobile) {
    width: 37px;
  }
}

.search {
  margin-bottom: 40px;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 615px;
  color: var(--matterColor);

  padding-bottom: 25px;

  @media (--viewportMobile) {
    display: flex;
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}
.searchQuery {
  flex: 1;
  position: relative;
  border: 1px solid #f1f1f1;
  border-radius: 8px 0 0 8px;
  background-color: var(--matterColorLight);
  transition: border-color 0.2s ease 0s;
  cursor: pointer;
  @media (--viewportMobile) {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }
  & input {
    height: 100%;
    border: none !important;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #617189;
    @media (--viewportMobile) {
      padding: 0;
    }
  }
  &:not(.active-search-query):hover {
    border-color: #dddddd;
  }
  & :global(.react-autosuggest__container) {
    height: 54px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.searchButton {
  @media (--viewportMobile) {
  }

  & button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    text-align: center;
    text-decoration: none;
    width: 84px;
    height: 55px;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    border: none;
    border-radius: 0 8px 8px 0;
    outline: none;
    padding: 14px 20px;
    -webkit-transition: box-shadow 0.2s ease, -webkit-transform 0.1s ease, transform 0.1s ease;
    -moz-transition: box-shadow 0.2s ease, transform 0.1s ease;
    transition: box-shadow 0.2s ease, -ms-transform 0.1s ease, -webkit-transform 0.1s ease,
      transform 0.1s ease;
    background: var(--marketplaceColor);
    color: var(--matterColorLight);
    white-space: nowrap;

    & svg {
      stroke: var(--matterColorLight);
      transform: scaleX(-1);
      width: 29px;
      height: 29px;
    }

    @media (--viewportMobile) {
      margin: 10px 0px 10px 0px;
      width: 60px;
      height: 60px;
    }
  }
}

.border {
  -ms-grid-row-align: center;
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  border-right: 1px solid #dddddd;
  -webkit-flex: 0 1 0px;
  -ms-flex: 0 1 0px;
  flex: 0 1 0px;
  height: 44px;

  @media (--viewportMobile) {
    display: none;
  }
}
.dateRangeLengthFilter button {
  border: none;
  font-size: 13px !important;
  font-weight: normal !important;
  color: var(--matterColorAnti) !important;
  padding: 4px 0 10px 0;
  background-color: var(--matterColorLight) !important;
  @media (--viewportMobile) {
    font-size: 16px !important;
  }
}

.dateRangeLengthFilter button:hover {
  background-color: var(--matterColorBright) !important;
  box-shadow: none !important;
  border: none !important;
}

.dateRangeLengthFilter button:focus {
  background-color: var(--matterColorBright) !important;
  box-shadow: none !important;
  border: none !important;
}

.dateRangeLengthFilter > div:first-of-type {
  @media (--viewportMobile) {
    position: absolute;
    left: 0px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.addListingButton {
  @apply --marketplaceButtonStyles;

  animation-delay: 0.8s;
  min-height: 55px;
  padding: 15px 0 0;
  background-color: #34b7ba;
  width: 190px;
  border-radius: 8px;
}

.findCustomers {
  margin: 0px;
  padding-bottom: 32px;
  border-bottom: 1px solid #eaeaea;
  & p {
    margin: 0 0 16px 0;
    font-size: 14px;
    line-height: 16px;
    @media (--viewportLaptop) {
      margin: 0 0 20px 0;
      font-size: 18px;
      line-height: 22px;
    }
  }
  & .findProvidersTitle {
    margin-bottom: 12px;
  }
}

.findCustomersTitle {
  margin-bottom: 24px;
  font-size: 21px;
  line-height: 26px;
  color: var(--matterColorDarkBlue);
  @media (--viewportLarge) {
    margin-bottom: 16px;
    margin-top: 16px;
    font-size: 32px;
    line-height: 42px;
  }
}

.findProvidersTitle {
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 33.2px;
  line-height: 46px;
  color: var(--matterColorDarkBlue);
  margin: 43px 0 0;
  & span {
    color: var(--marketplaceColor);
  }
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    margin: 0 0 0;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    font-size: 28px;
  }
  @media screen and (min-width: 1441px) and (max-width: 1490px) {
    font-size: 32px;
  }
  @media screen and (min-width: 1491px) and (max-width: 1580px) {
    font-size: 33px;
  }
}

.findProvidersInfo {
  font-weight: 600;
  font-size: 23px;
  line-height: 41px;
  /* color: var(--matterColorDimmedText); */
  color: #6e6f71;
  margin: 0 0 48px;
  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0 26px;
  }
}

.findProvidersSubTitle {
  margin-top: 0;
  font-size: 90%;
  color: var(--matterColorLight);
  @media (--viewportLarge) {
    font-size: 140%;
    line-height: 56px;
  }
}

.findCustomersDescription,
.findProvidersDescription {
  font-size: 16px;
}
.suggestionSectionTitle {
  color: var(--matterColorLightBlue);
  text-align: left;
  padding: 10px 36px;
}

.popularRequests {
  @media (--viewportMobile) {
    display: none;
  }
}

.searchAddEnquiry {
  display: flex;
  justify-content: space-between;
  padding: 24px 0 0 24px;
  background: #7c8a8b;
  color: var(--matterColorLight);
  border-radius: 8px;
  @media (--viewportLaptop) {
    position: relative;
    padding: 24px;
  }

  & .searchAddEnquiryHeader {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 23px;
    font-weight: bold;
    line-height: 23px;
    color: var(--marketplaceColorLightWhite);
    @media (--viewportMobile) {
      font-size: 4vw;
      line-height: 4vw;
    }
  }

  & .searchAddEnquiryHighlighted {
    margin-top: 5px;
    font-weight: 900;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    color: #c8d9da;
    @media (--viewportMobile) {
      font-size: 3.5vw;
      line-height: 4vw;
    }
  }

  & .searchAddEnquiryContent {
    flex: 1 1;
    max-width: 345px;
    & p {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 17px;
      @media (--viewportMobile) {
        margin-bottom: 3vw;
        font-size: 2.5vw;
        line-height: 3vw;
      }
    }
    @media (--viewportLaptop) {
    }
  }

  & .searchAddEnquiryButton {
    padding: 8px 24px;
    color: #223147;
    font-size: 14px;
    background: var(--matterColorLight);
    border-radius: 100px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
    & svg {
      margin-left: 12px;
    }
    @media (--viewportMobile) {
      padding: 1.9vw 5.7vw;
      font-size: 3.4vw;
      border-radius: 24vw;
      white-space: nowrap;
      & svg {
        margin-left: 2.9vw;
        width: 3.6vw;
        height: 3.3vw;
      }
    }
  }
}

.searchEnquiryFormContainer {
  @media (--viewportMobile) {
    padding: 0 24px;
  }
}

.ImageSection {
  display: flex;
  width: 876px;
  height: 259px;
  margin: 30px 0 -56px;
  z-index: -1;
  /* birthday */
  position: relative;
  justify-content: center;
  @media (--viewportXLarge) {
    margin: 30px 0 -26px;
  }
  & .leftSvg {
    position: absolute;
    top: -16px;
    left: -34px;
    z-index: -1;
    @media (--viewportTabletDown) {
      display: none;
    }
  }
  & .rightSvg {
    position: absolute;
    top: -50px;
    right: -22px;
    z-index: -1;
    @media (--viewportTabletDown) {
      display: none;
    }
  }
  @media (--viewportLargeWithPaddings) {
    width: 100%;
  }
  & div {
    & img {
      width: 100%;
      height: auto;
      border-radius: 50%;
      @media (--viewportMedium) {
        width: auto;
        height: 220px;
      }
    }
  }
  @media (--viewportTabletDown) {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
  }
}
