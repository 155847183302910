@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}



.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.connectPros{
  position: sticky;
  bottom: 0;
  background: #F1F1F1;
  box-sizing: border-box;
  padding: 9px 19px 12px;
  width: 100%;
  @media (--viewportTabletDown) {
    display: none;
  }

  & .connectProsTitle{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    color: var(--matterColorDarkBlue);
    margin: 0 0 14px;
    & svg{
      margin-right: 4px;
      width: 13px;
      height: 22px;
    }
  }

  & .startRequestFormButton{
    background: var(--matterColorLight);
    width: 100%;
    max-width: 236px;
    margin: 0 auto;
    min-height: 40px;
    border-radius: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    color: var(--marketplaceColor);
  }
}