@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}
.layoutTopbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  height: fit-content;
  @media (--viewportMobile) {
    height: 50px;
  }
}
.layoutFooter {
  width: 100%;
  position: relative;
}
