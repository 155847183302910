.myRewardMain {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.myRewardMain .myRewardWrap {
  display: flex;
  border: 1px solid #dce5f2;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  gap: 10px;
  position: relative;
  @media screen and (--viewportTabletDown) {
    flex-direction: column;
    padding-bottom: 30px;
  }
}
.myRewardWrap .myRewardDetail {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}
.myRewardDetail .matchCardImg {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  @media screen and (--viewportTabletDown) {
    width: 50px;
    height: 50px;
  }
}
.myRewardDetail .myRewardDetailWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media screen and (--viewportTabletDown) {
    gap: 0;
  }
}
.myRewardDetailWrap .myRewardHead {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #0b1333;
  margin: 0;
  @media screen and (--viewportTabletDown) {
    font-size: 16px;
    line-height: 26px;
  }
}
.myRewardDetailWrap .myRewardSubHead {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0b1333;
  text-transform: capitalize;
  margin: 0;
  @media screen and (--viewportTabletDown) {
    font-size: 14px;
    line-height: 24px;
  }
}
.myRewardWrap .myRewardTransactions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  flex: 1;
  @media screen and (--viewportTabletDown) {
    gap: 0;
  }
}
.myRewardTransactions .myRewardText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}
.myRewardTransactions .myRewardValue {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #0b1333;
  margin: 0;
  @media screen and (--viewportTabletDown) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
  }
}
.myRewardTransactions .myRewardValue span {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
.myRewardWrap .myRewardDetailButton {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  gap: 10px;
  @media screen and (--viewportTabletDown) {
    align-items: center;
  }
}
.myRewardDetailButton .detailLocation {
  position: absolute;
  top: 3px;
  right: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  color: #0058fa;
  margin: 0;
  text-align: end;

  @media screen and (--viewportTabletDown) {
    top: unset;
    bottom: 3px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}
.myRewardDetailButton .myRewardRequest {
  background: #ffb81d;
  border-radius: 6px;
  min-width: 105px;
  max-width: 105px;
  height: 44px;
  min-height: unset;
  @media screen and (--viewportTabletDown) {
    min-width: 95px;
    max-width: 95px;
    height: 40px;
    font-size: 16px;
  }
}
.myRewardDetailButton .myRewardAccept {
  background: #0058fa;
  border-radius: 6px;
  min-width: 105px;
  max-width: 105px;
  height: 44px;
  min-height: unset;
  @media screen and (--viewportTabletDown) {
    min-width: 95px;
    max-width: 95px;
    height: 40px;
    font-size: 16px;
  }
}
