@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  row-gap: 14px;
}

.quotesFormWrapper {
  display: flex;
  flex-direction: column;
}
.quotesFormWrapper .textField textarea {
  box-sizing: border-box;
  height: 250px;
}
.quotesFormWrapper h4 {
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}
.btnWrap {
  display: flex;
  justify-content: flex-end;
}
.emailfield {
  padding-bottom: 20px;
}

.quotesFormWrapper .textField {
  margin-bottom: 40px;
}
.btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  padding: 0 18px;
  width: auto;
  line-height: 40px;
  border-radius: 7px;
  @media (--viewportMobile) {
    padding: 10px 14px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  }
}
