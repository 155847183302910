@import '../../marketplace.css';

.stateBtn {
  margin-right: 10px;
  padding: 5px 10px;
  /* color: var(--marketplaceColorDark); */
  /* border: 1px solid var(--marketplaceColorDark); */
  /* border-radius: 4px; */
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s linear, color 0.2s linear;
  height: 50px;
  border-radius: 25px;
  background-color: #f7f7f7;
  font-size: 15px;
  color: #949494;
  font-weight: 400;
  &:hover {
    box-shadow: var(--boxShadowButton);
    text-decoration: none;
  }
  @media (--viewportMobile) {
    margin-bottom: 10px;
  }
}
.stateBtnActive {
  background-color: #0058fa !important;
  color: #ffffff !important;
  filter: drop-shadow(0px 10px 8.5px rgba(33, 227, 248, 0.11));

  & span {
    color: #ffffff !important;
  }
}

.isScheduleBtn {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  background-color: transparent;
  border-radius: unset;
}

.isScheduleBtnActive {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  background: transparent !important;
  border-bottom: 2px solid #0058fa;
  border-radius: unset;
  & span {
    color: #0058fa !important;
  }
}
