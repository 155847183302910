@import '../../marketplace.css';

.upcomingCalendarContainer {
  background: #f7f7f7;
  padding: 20px 15px;
  flex: 1;

  & .subHeader {
    margin: 12px 0;
    text-align: center;
    overflow: hidden;

    & span {
      position: relative;
      font-size: 14px;
      color: var(--matterColorGrey);

      &:after,
      &:before {
        content: '';
        position: absolute;
        height: 1px;
        background-color: var(--matterColorNegative);
        top: 50%;
        width: 100vh;
      }

      &:before {
        right: 100%;
        margin-right: 15px;
      }

      &:after {
        left: 100%;
        margin-left: 15px;
      }
    }
  }

  @media (--viewportMobile) {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }
}

.month {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0 20px;
  @media (--viewportMobile) {
    padding: 15px 0;
  }
}

.calendarContainer {
  width: 556px;
  margin: 0 auto;

  @media (--viewportTabletDown) {
    width: calc(100% - 32px);
    max-width: 375px;
    margin: 0 auto;
  }
}

.upcomingCalendar {
  margin: 0 48px;
  gap: 18px;
  @media (--viewportTabletDown) {
    margin: 0 45px;
    gap: 6px;
  }
  & .date {
    padding: 9px 11px;
    min-width: 47px;
    @media (--viewportTabletDown) {
      min-width: 31px;
      height: 44px;
      padding: 0;
    }
    & .weekDate {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #5c5b5c;
      margin-bottom: 8px;
      @media (--viewportTabletDown) {
        font-size: 13px;
        line-height: 16px;
        margin: 0;
        flex: 1;
        display: flex;
        align-items: center;
      }
    }

    & .numberDate {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: var(--matterColorDark);
      @media (--viewportTabletDown) {
        font-size: 13px;
        line-height: 16px;
        margin: 0;
        flex: 1;
        display: flex;
        align-items: center;
      }
    }

    &.selected {
      background: rgba(0, 88, 250, 0.2);
      border-radius: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      margin: 0 10px;
      color: var(--matterColorBlue);
      @media (--viewportTabletDown) {
        margin: 0;
      }
      @media (--viewportMobile) {
        background: rgba(0, 88, 250, 0.2);
        margin: 0;
        border-radius: 4.97827px;
      }

      & .weekDate {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--matterColorBlue);
        /*  @media (--viewportMobile) {
          font-size: 16px;
          line-height: 20px;
          color: var(--matterColorLight);
        } */
      }

      & .numberDate {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: var(--matterColorBlue);
        /*  @media (--viewportMobile) {
          font-size: 16px;
          line-height: 20px;
          color: var(--matterColorLight);
        } */
      }
    }
  }
}

.scrollArrow {
  display: block;
  & > div {
    background-image: url('../../assets/blue_right-arrow.png') !important;
    left: 0;
    right: 0;
    margin: 0;
    @media (--viewportMobile) {
      height: 30px !important;
      width: 30px !important;
      background-image: url('../../assets/blue_right-arrow.png') !important;
    }
  }
}

.dateLabelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportTabletDown) {
    height: 100%;
  }
}

.scheduleText {
  margin: 0 0 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkest);
  position: relative;

  & span {
    background: var(--matterColorLight);
    padding: 0 13px 0 0;
    z-index: 1;
    width: fit-content;
  }

  @media (--viewportMobile) {
    font-size: 30px;
  }
}

.scheduleText::after {
  content: '';
  height: 1.5px;
  width: 98%;
  background: var(--matterColorNegative);
  /* top: 50%; */
  bottom: 10px;
  left: 0;
  position: absolute;
  z-index: -1;
}

.textSeaction {
  padding-left: 75px;

  @media (--viewportTabletDown) {
    padding-left: 22px;
  }
}

.desktopData {
  @media (--viewportMobile) {
    display: none;
  }
}

.classFoundTextSeaction {
  padding-left: 75px;

  @media (--viewportTabletDown) {
    padding-left: 22px;
  }
}

.mobileTextSeaction {
  display: none;

  @media (--viewportMobile) {
    display: block;
    max-width: 86%;
    margin: 0 auto 27px;
  }
}

.selectedDateText {
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkest);
  position: relative;

  & span {
    background: var(--matterColorLight);
    padding: 0 14px 0 24px;
    z-index: 1;
    width: fit-content;

    @media (--viewportMobile) {
      padding: 0 10px 0 13px;
    }
  }

  & .selectedDate {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--marketplaceColorDarkest);

    @media (--viewportMobile) {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
  }

  & .classesFound {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #6e6f71;

    @media (--viewportMobile) {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      color: #c4c4c4;
    }
  }

  @media (--viewportMobile) {
    font-size: 30px;
  }
}

.selectedDateText::after {
  content: '';
  height: 1.5px;
  width: 98%;
  background: var(--marketplaceColorDarkest);
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
  @media (--viewportMobile) {
    top: 75%;
    background: #c4c4c4;
  }
}
