@import '../../marketplace.css';

body {
  overflow-x: hidden;
}

.topSection {
  position: relative;
  height: 100vh;
  width: 100vw;
  max-height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .topHeadContainer {
  height: 100vh;
  width: 100vw;
  max-height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.topSection .topSectionImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .topHeadContainer img {
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  object-fit: cover;
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
  top: 0;
  left: 0;
} */

.webPageContent {
  height: 50%;
  width: 1050px;
  z-index: 99;
  background: #ffffff;
  box-shadow: 0px 4px 144px rgb(0 0 0 / 15%);
  border-radius: 43px;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 100vh;
  display: flex;

  @media (--viewportMobile) {
    width: 100%;
    border-radius: 20px;
    margin: 0 15px;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 10px;
    align-items: center;
    padding: 25px 20px;
    /* Don't Change the height */
    height: 650px;
  }
}

.webPageContentChrome {
  height: 60vh;
}

.webAppContentWrap {
  display: flex;
  width: 345px;
  padding: 25px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  position: relative;

  @media (--viewportMobile) {
    padding: 0;
    width: 235px;
    height: auto;
    justify-content: unset;
    gap: 10px;
  }
}

.contentDivider {
  width: 3px;
  background: #c9c8c8;
  margin: 40px 0;

  @media (--viewportMobile) {
    height: 3px;
    width: 100%;
    margin: 0;
  }
}
.webAppHeading {
  font-weight: 450;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #000000;

  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.4px;
  }
}
.webAppImageWrap {
  overflow: hidden;
  border-radius: 20px;
  height: 330px;
  width: 100%;
  @media (--viewportTabletDown) {
    height: 100px;
  }
}
.webAppImageWrap .webAppImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.educationBtnWrap {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
  @media (--viewportMobile) {
    bottom: 10px;
  }
}
.btn {
  border-radius: 12px;
  width: 70%;
  height: 70px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  letter-spacing: 0.3px;
  padding: 10px 20px;

  @media (--viewportMobile) {
    /* width: 100%; */
    font-size: 15px;
    height: 40px;
    border-radius: 5px;
  }
}
.btn:hover {
  text-decoration: none;
}
.educationBtn {
  background: #4889ff;  
}
.sportsBtn {
  background: #ff6d34;
}
.tennisBtn {
  background: #ffb91d;
}
.pageRootClass {
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  max-height: -webkit-fill-available;
}
.webAppMainHeading {
  width: 308px;
  height: 54px;
  flex-shrink: 0;
  color: #0058fa;
  text-align: center;
  font-family: Int Circular PTT;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (--viewportMobile) {
    height: 40px;
    font-size: 22px;
  }
}
.logo {
  width: 232.699px;
  height: 58px;
  flex-shrink: 0;
  @media (--viewportMobile) {
    display: none;
  }
}
