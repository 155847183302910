@import '../../marketplace.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.panelNavWrapper {
  width: 100%;
  /* border: 1px solid #d7d7d7;
  border-radius: 10px;
  overflow: hidden; */
}
.panelExpectNavWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
}
.panelBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  @media (--viewportTabletDown) {
    margin-bottom: 40px;
  }
}
.panelBtn {
  background: #0058fa;
  width: 222px;
  height: 60px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #eff5ff;
  border: 0;
  cursor: pointer;
}
.tabsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  padding: 20px;
  width: 100%;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
  @media (--viewportTabletDown) {
    padding: 0;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 30px;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  overflow-x: scroll;
  height: 104px;
  align-items: center;
  border: 1px solid #d7d7d7;
  border-radius: 10px;

  @media (--viewportLarge) {
    max-width: 290px;
    width: 290px;
    padding: 45px;
    /* margin: 15px 20px 0px 30px; */
    /* padding: 128px 0 82px 36px; */
    flex-direction: column;
    overflow-x: auto;
    /* align-items: flex-start; */
    background-color: var(--matterColorLight);
    box-shadow: none;
    border-top: none;
    position: sticky;
    top: 15px;
    height: 100%;
    overflow: hidden;
  }
}

.tabsContainer [class*='tabContents'] {
  @media (--viewportLaptopDown) {
    flex-direction: row;
    gap: 20px;
  }
}

.tab {
  margin-right: 8px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    /* padding-left: 24px; */
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    /* padding-right: 24px; */
  }

  @media (--viewportLarge) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.tab [class*='link'] {
  text-transform: capitalize;
}

.panel {
  flex-grow: 1;
  padding: 30px 40px;
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  overflow: hidden;

  @media (--viewportLarge) {
    /* padding: 88px 36px 82px 59px; */
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }
  @media (--viewportTabletDown) {
    padding: 30px 20px;
  }

  @media (--viewportLargeWithPaddings) {
    /* padding: 88px calc((100% - 1056px) / 2) 82px 59px; */
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.requestDescriptionContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 53px;
  margin: 112px 0 64px 285px;
  @media (--viewportMobile) {
    margin: 34px 0 -5px;
    flex-direction: column-reverse;
  }
}

.requestInfoWrapper {
  max-width: 374px;
  @media (--viewportMobile) {
    max-width: 100%;
    padding: 22px;
    margin-top: 25px;
    background-color: var(--marketplaceColorBackground);
  }
  & .requsetTitleBlue {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: var(--marketplaceColor);
  }
  & .requsetTitle {
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    color: var(--matterColorDarkBlue);
    @media (--viewportMobile) {
      color: var(--matterColorLight);
    }
  }
  & p {
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: var(--matterColorDarkBlue);
    margin-top: 22px;
    @media (--viewportMobile) {
      color: var(--matterColorLight);
    }
  }
}

.postRequestGifContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 454px;
  @media (--viewportMobile) {
    width: 100%;
    padding: 0 22px;
  }
}

.postRequestGif {
  width: 315px;
  height: 315px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 23px;
  z-index: 2;
  @media (--viewportMobile) {
    height: auto;
    width: 80%;
  }
}

.imagePostRequestGifBackground {
  width: 100%;
  max-width: 392px;
  max-height: 241px;
  position: absolute;
  @media (--viewportMobile) {
    width: 90%;
    height: auto;
  }
}

.payoutModalInfoNote {
  display: inline-table;
  padding: 10px;
  font-weight: normal;
  font-size: 16px;
  height: 18px;
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}

.noteFont {
  font-weight: bold;
  font-size: 16px;
  height: 18px;
}
.editWizardSvg {
  width: 20px;
  height: 20px;
}
.expectationsRight {
  background: #0d60fa;
  border-radius: 10px;
  flex: 1;
  height: 100%;
  padding: 35px 30px;
}
.expectationsRightHead {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
}
.expectationsRightCard {
  background: rgba(255, 255, 255, 0.14);
  border-radius: 19px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.expectationsCardText {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.expectationsCardDelete {
  background: #0d60fa;
  border-radius: 4px;
  padding: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
