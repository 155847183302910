@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 26px;
  margin-top: -2px;
  margin-bottom: 2px;

  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: #000000;
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  width: 70%;
  border-color: 1px solid rgba(0, 0, 0, 0.1) !important;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.submitContainer {
  margin-top: auto;
  margin-bottom: 50px;
  @media (--viewportLarge) {
    margin-top: 0;
  }

  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: start;
  padding: 0 42px;
  margin: 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.uploadPaymentProofBtn {
  @apply --marketplaceButtonStylesSecondary;
  margin-top: 22px;
  padding: 0px;
}

.paymentProofSsContainer {
  display: flex;
  flex-wrap: wrap;
}

.paymentProofSs {
  width: 150px;
  height: 150px;
  border-color: rgb(189, 189, 189);
  border-width: 1px;
  margin-right: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  overflow: hidden;
}

.paymentProofSs p {
  font-size: 14px;
  line-height: 20px;
  color: rgb(189, 189, 189);
  text-align: center;
  margin: 0;
  overflow-wrap: anywhere;
}

.paymentProofSs img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.removeItem {
  position: absolute;
  right: 22px;
  top: 6px;
  padding: 0 4px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  cursor: pointer;
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  padding: 20px;
  border-color: rgb(238, 238, 238);
  border-width: 2px;
  border-style: dashed;
  border-radius: 2px;
  color: rgb(189, 189, 189);
  outline: none;
  transition: border 0.24s ease-in-out 0s;
}

.dropzoneContainer p {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.shareReceiptContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  /* background: #ffffff; */
  border: 1px solid #0058fa;
  border-radius: 10px;
  @media (--viewportMobile) {
    width: 100%;
    padding: 1rem;
  }
  & .shareReceiptWrapper {
    max-width: 100%;
  }
  & h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 10px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }
  & .vendorRequiredInfo {
    display: inline-flex;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #a8a8a8;
    margin: 0 0 20px;

    @media (--viewportMobile) {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
  & .receiptInfo {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--marketplaceColor);
    margin: 18px 0 0;

    @media (--viewportMobile) {
      font-size: 16px;
      margin: 10px 0 0;
    }
  }
}

.shareReceiptBtnWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e7fbff;
  border: 1.5px solid #e7fbff;
  box-sizing: border-box;
  border-radius: 10px;
}

.shareReceiptBtn {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #ffffff;
  background: #0058fa;
  border-radius: 5px;

}
