@import '../../marketplace.css';

.packageItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 10px;
  padding: 10px;
  box-shadow: var(--boxShadowBreakdown);
  border-radius: var(--borderRadius);
  & p {
    margin: 0;
  }
}

.session {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
}

.desc {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: var(--matterColorParagraphText);
}

.price {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorBlue);
}

.section {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.pricingSection {
  display: flex;
  flex-direction: column;
}
