@import '../../marketplace.css';

.loadingWrapper {
  background: rgba(217, 217, 217, 0.17);
  border-radius: 32.0543px;
  position: relative;
  height: calc(100vh - 450px);
  width: 100%;
  top: 0;
  left: 0;
  @media (--viewportLaptopDown) {
    height: calc(100vh - 400px);
  }
 
}
.loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.loadingContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.probuddy {
  display: flex;
  & p {
    font-size: 40px;
    margin: 0;
  }
}
.buddy {
  font-weight: 350;
}
