@import '../../marketplace.css';

.cardListingBox {
  width: 300px;
  display: flex;
  background: #fff;
  padding: 10px;
  gap: 10px;
  flex-direction: column;
  @media (--viewportTabletDown) {
    flex-direction: row;
    width: 100%;
  }
}

.imageWrap {
  width: 100%;
  border-radius: 15px;
  margin: auto;
}
.imageWrap img {
  border-radius: 15px;
  height: 150px;
  @media (--viewportTabletDown) {
    height: unset;
  }
}
.informationWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-evenly;
}

.cardTitle {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #000;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.description {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #a8a8a8;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.price {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #28a74b;
}

.cardLocation {
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 8px;
    height: 10px;
  }
}

.cardLocation .cardAddress {
  font-weight: 400;
  font-size: 12.5283px;
  line-height: 16px;
  color: #a8a8a8;
  padding-left: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardLocation svg {
  @media (--viewportTabletDown) {
    width: 30px;
  }
}
