@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingPhotosForm_imageWidthDesktop: calc(50% - 12px);
}

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: var(--EditListingPhotosForm_imageWidthDesktop);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: dimgrey;
  font-size: 22px;
  font-weight: var(--fontWeightSemiBold);
  padding-bottom: 5% !important;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: red;
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.submitButtonContainer .submitButton {
  min-width: 30%;
  width: auto;
}
.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
