@import '../../marketplace.css';

.root {
}

.select {
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;
  border-radius: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%0d1624" fill="%0d1624" fill-rule="evenodd"/></svg>');
  background-size: 14px 14px;
  background-position: center right;

  & option {
    color: var(--matterColor);
  }

  & optgroup {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor) !important;
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: transparent;
  }
}

.support {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 70%;
}

.support .supportText {
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  background-color: var(--matterColorDark);
  color: var(--matterColorLight);
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  margin: 0 21px;
  position: absolute;
  top: 0px;
  right: 9px;
  z-index: 1;
}

.hideSupportText {
  visibility: hidden;
}

.limitError {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--failColor);
  margin-top: 6px;
  margin-bottom: 10px;
}

.tabsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-height: 145px;
  overflow-y: auto;
  margin: 20px 0 0;
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }
}

.menuContentClass {
  margin-top: 7px;
  padding: 13px 10px 5px;
  min-width: 300px !important;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 4px;
  background: var(--matterColorLight);

  @media (--viewportMobile) {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.subCategoryItem {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;
  display: flex;
  align-items: center;
}

.selectedSubCategoryItem {
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  cursor: pointer;
  background: transparent;
  transition: background-size 0.2s;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
}

.extraLabel {
  font-size: 14px;
  color: grey;
  line-height: 18px;
}
