@import '../../marketplace.css';

.companyLink{
  &:hover {
    text-decoration: none;
  }
}

.companyMain{
  max-width: 900px;
}
.companyCard {
    display: flex;
    flex-direction: column;
    width: 100%;
  @media (--viewportMobile) {
    max-width: 100%;
  }
}
.card {
    border-top: 1px solid #E5E5E5;
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    box-sizing: border-box;

    @media (--viewportMobile) {
      border-radius: 0px;
      border-top: 0;
      flex-direction: column;
      padding: 20px 22px 0;
    }

    & .ArrowLeft {
      display: none !important;
    }
    & .ArrowRight{
      display: none !important;
    }
    & :global(.slick-dots){
      display: none;
      @media (--viewportMobile) {
        display: block;
        bottom: 0;
        & li button:before{
          width: 6px;
          height: 6px;
          color: var(--matterColorLight);
        }
      }
    }
    &:hover{
      & .ArrowLeft {
        display: block !important;
      }
      & .ArrowRight{
        display: block !important;
      }
      & :global(.slick-dots) li{
        width: 6px;
      }
      & :global(.slick-dots){
        display: block;
        bottom: 0;
      }
      & :global(.slick-dots)  li button:before{
        width: 6px;
        height: 6px;
        color: var(--matterColorLight);
      }
    }
}

.cardImage {
  position: relative;
  width: 352px !important;
  height: 220px;
  object-fit: cover;
  display: block;
  @media (--viewportMobile) {
    width: 100% !important;
    height: 223px;
  }
}
.cardImage img {
    width: 100%;
    height: 100%;
    display: block; 
    border-radius: 22px;
}

.popularImage {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.cardRight {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    width: 76%;
    @media (--viewportMobile) {
      padding: 5px 5px 0px 10px;
    }
}
.cardInfoWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 10px 33px;
  @media (--viewportMobile) {
    padding: 13px 0 0;
  }
}
.companyName {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: var(--matterColorDarkBlue);
    width: 95%;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (--viewportMobile) {
      font-size: 16px;
      line-height: 20px;
      padding-top: 8px;
    }
}


.companyCardWrapper{
  display: flex;
  width: 100%;
  @media (--viewportMobile) {
    flex-direction: column;
  }
}

.listingTypeWrapper {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 10px;
  @media (--viewportMobile) {
    margin-top: 0px;
    column-gap: 10px;
    justify-content: space-between;
  }
}

.address {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: var(--marketplaceColor);
  margin-top: 7px;
  @media (--viewportMobile) {
    width: 100%;
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
  }
}
.address svg {
  margin-right: 5px;
}

.starWrapper {
  display: flex;
  align-items: center;
  height: 18px;
  @media (--viewportMobile) {
    display: none;
  }
}
.mobileStarWrapper {
  display: none;
  align-items: center;
  height: 18px;
  @media (--viewportMobile) {
    display: flex;
  }
}

  .rating {
    color: #e82953;
    font-size: 10px;
    font-weight: 550;
  
    @media (--viewportLarge) {
      font-size: 11px;
    }
  }
  .reviewCount {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    margin: 0 8px;
    color: #C4C4C4;
    @media (--viewportMobile) {
      font-size: 11px;
      line-height: 14px;
    }
  }
  .companyInfo {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #6E6F71;
    margin: 16px 0 27px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 44px;
    @media (--viewportMobile) {
      margin: 15px 0 10px;
      min-height: auto;
      font-size: 15px;
      line-height: 22px;
    }
  }
  .listingTypeCount {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    color: var(--matterColorDarkBlue); 
    padding: 0 20px 0 0;
    @media (--viewportMobile) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
    }
  }

.aspectWrapper {
  widows: 100%;
  height: 100%;
  object-fit: cover;
}

.sliderWrapper {
  width: 352px;
  height: 220px;
  @media (--viewportMobile) {
    width: 100%;
    height: 223px;
  }
}

.ArrowLeft {
  width: 25px;
  height: 25px;
  position: absolute;
    top: 45%;
    left: 7px;
    @media (--viewportMobile) {
      top: auto;
      right: auto;
      bottom: -20px;
      left: 0px;
      z-index: 0 !important;
    }
}

.ArrowRight {
width: 25px;
height: 25px;
position: absolute;
transform: scaleX(-1);
  top: 45%;
  right: 12px;
  display: none;
  @media (--viewportMobile) {
    top: auto;
    bottom: -20px;
    right: 5px;
    z-index: 0 !important;
  }
}