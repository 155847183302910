@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bookingPeriodSection {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.dayLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorParagraphText);
}

.dayInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  display: inline;
  font-size: 14px;
  flex: 1;
}

.priceLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorParagraphText);
}

.itemValue {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: var(--marketplaceColorDarkerI);
  min-width: 90px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 20px 0;

  border: none;
  background-color: var(--matterColorNegative);
}

.totalLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorParagraphText);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  color: #0058fa;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.voucherAmount {
  color: #0058fa;
}
