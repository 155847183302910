@import '../../marketplace.css';
.packageSection {
  margin-top: 36px;
  margin-bottom: 24px;
  @media (--viewportMobile) {
    margin-top: 10px;
    /* margin-bottom: 90px; */
  }

  & .standoutInlinePackage {
    /* min-width: 225px;
    width: 225px; */
    @media (--viewportMobile) {
      /* min-width: 155px;
      width: 155px; */
      margin-right: 0;
    }
  }
}
.packagesContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 12px;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
  }
}

.inlinePackagesContainer {
  display: flex;
  /* justify-content: center; */
  color: var(--matterColorDarkBlue);
  flex-direction: row;
  align-items: center;
}
.package {
  width: 238px;
  border-radius: 7px;
  background-color: var(--matterColorLight);
  box-shadow: 0 0 15px 5px rgba(174, 177, 183, 0.3);
  text-align: center;
  margin: -5px;

  @media (--viewportLarge) {
    margin: 20px 0px;
  }
}

.standoutPackage {
  z-index: 2;
  & .package {
    margin-top: -25px;
    @media (--viewportLarge) {
      margin-top: 20px;
    }
    & .content {
      height: 290px;
      @media (--viewportLarge) {
        height: 250px;
      }
    }
  }
}

.standoutInlinePackage {
  height: auto;
  flex: 1;
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  /* @media (--viewportTabletDown) {
    min-width: 190px;
  } */
  @media (--viewportLarge) {
    flex: 1;
    height: auto;
  }
}
.standoutInlinePackage:nth-of-type(1) .svgWrap svg circle {
  fill: #604ae5;
}
.standoutInlinePackage:nth-of-type(1) .productBtn button {
  background: #604ae5;
}
.standoutInlinePackage:nth-of-type(2) .svgWrap svg circle {
  fill: #e5634a;
}
.standoutInlinePackage:nth-of-type(2) .productBtn button {
  background: #e5634a;
}
.standoutInlinePackage:nth-of-type(3) .svgWrap svg circle {
  fill: #0058fa;
}
.standoutInlinePackage:nth-of-type(3) .productBtn button {
  background: #0058fa;
}

.header {
  background-color: #aeb1b7;
  width: 100%;
  height: 54px;
  border-radius: 7px 7px 0 0;
  padding: 15px;
  font-size: 20px;
  color: var(--matterColorLight);
}

.modalContent {
  @media (--viewportMobile) {
    overflow: unset;
  }
}

.packageContainer {
  @media (--viewportMobile) {
    padding: 24px;
  }
}

.content {
  width: 100%;
  height: 250px;
  margin-top: 30px;
  word-break: break-word;
}

.price,
.currency {
  font-weight: 700;
  text-align: center;
  padding-top: 10px;
  font-size: 42px;
  line-height: 55px;
}

.price {
  margin-right: 10px;
}

.description {
  font-weight: 400;
  color: #0b150a;
  font-size: 18px;
  line-height: 33px;
  text-align: left;
  white-space: break-spaces;
  text-overflow: ellipsis;
  height: 66px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 45px;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 24px;
    height: auto;
  }
}

.footer {
  width: 100%;
  height: 100px;
  font-size: 12px;
  font-weight: 700;
  color: var(--matterColorDark);
  padding: 15px 36px;
}

.footer a {
  @apply --marketplaceButtonStylesPrimary;
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}

.uploadPaymentProofBtn {
  @apply --marketplaceButtonStylesSecondary;
  padding: 5px;
}

.paymentProofsContainer {
  margin-top: 30px;
  & h2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
  }
}

.paymentProofSsContainer {
  display: flex;
  flex-wrap: wrap;
}

.paymentProofSs {
  width: 150px;
  height: 150px;
  border-color: rgb(189, 189, 189);
  border-width: 1px;
  margin-right: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  overflow: hidden;
}

.paymentProofSs p {
  font-size: 14px;
  line-height: 20px;
  color: rgb(189, 189, 189);
  text-align: center;
  margin: 0;
  overflow-wrap: anywhere;
}

.paymentProofSs img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.removeItem {
  position: absolute;
  right: 22px;
  top: 6px;
  padding: 0 4px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  cursor: pointer;
}
.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.bankDetails {
  white-space: pre;
  font-size: 18px;
  line-height: 22px;
}

.creditFeeText {
  text-align: center;
  font-style: italic;
  font-size: 16px;
}

.membershipCard {
  margin: 24px 0px;

  &:focus {
    border-color: #0058fa;
    outline: none;
  }
}

.or {
  text-align: center;
  margin: 24px 0px;
}

.paymentTypeContainer {
  margin-bottom: 50px;
  @media (--viewportLarge) {
    margin: 30px auto 30px;
    width: 920px;
  }
}

.backBtn {
  display: inline-block !important;
  width: auto !important;
  padding: 5px 15px !important;
}

.listingPackages {
  & .package {
    width: 185px;
    margin: 0 10px 0 0;
  }
  & .standoutPackage {
    z-index: 0;
    & .package {
      margin-top: 0;
      @media (--viewportMobile) {
        margin-top: 0;
      }
      & .content {
        height: 250px;
        @media (--viewportMobile) {
          height: 250px;
        }
      }
    }
  }

  & .price,
  & .currency {
    font-size: 28px;
  }

  @media (min-width: 320px) and (max-width: 1920px) {
    /* overflow: auto;
    overflow-y: hidden; */
    display: flex;
    flex-direction: row;
    align-items: initial;
    justify-content: flex-start;
    & .package {
      margin-left: 10px;
    }
  }
}

.listingPackages::-webkit-scrollbar {
  width: 0;
}

.shareReceiptContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: #ffffff;
  border: 1px solid #0058fa;
  border-radius: 10px;
  @media (--viewportMobile) {
    width: 100%;
    padding: 1rem;
  }
  & .shareReceiptWrapper {
    max-width: 100%;
  }
  & h4 {
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin: 0 0 10px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }
  & .vendorRequiredInfo {
    display: inline-flex;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #a8a8a8;
    margin: 0 0 26px;

    @media (--viewportMobile) {
      font-size: 16px;
      margin: 0 0 10px;
    }
  }
  & .receiptInfo {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: var(--marketplaceColor);
    margin: 18px 0 0;

    @media (--viewportMobile) {
      font-size: 16px;
      margin: 10px 0 0;
    }
  }
}

.shareReceiptBtnWrapper {
  width: 100%;
  height: 146.25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 50px;
}

.shareReceiptBtn {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #ffffff;
  background: #0058fa;
  border-radius: 5px;
}

.icons {
  display: flex;
  gap: 0.5rem;
  margin: 0;
}

.icons li img {
  @media (--viewportMobile) {
    width: 25px;
    height: 25px;
  }
}

.icon {
  & > svg {
    width: 55px;
    height: 36px;
    fill: var(--marketplaceColorDark);
    @media (--viewportMobile) {
      width: 100%;
      height: 36px;
    }
  }
}

.button {
  margin-top: 48px;
  width: 50%;
  @media (--viewportMobile) {
    margin-bottom: 60px;
    margin-top: 0;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.buttonContinue {
  @apply --marketplaceButton2StylesPrimary;
  width: 40%;
}

.buttonSkip {
  padding: 16px 106px;
  background: var(--marketplaceColorBackground);
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: var(--matterColorLight);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 69px;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.label {
  text-align: center;
  & p {
    margin: 0;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    @media (--viewportMobile) {
      font-size: 12px;
    }
  }
}

.options {
  display: grid;
  grid-template-columns: 250px;
  grid-row: auto;
  grid-column-gap: 45px;
  grid-row-gap: 20px;
  justify-content: center;
  @media (--viewportLarge) {
    grid-template-columns: 500px;
  }
  @media (min-width: 320px) and (max-width: 1920px) {
    grid-template-columns: 100%;
    padding: 0px;
    margin-bottom: 20px;
  }
}

.optionItem {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: var(--marketplaceColorDarkest);
  width: 100%;
  margin-bottom: 18px;
  padding: 1rem;
  border: 1px solid var(--matterColorNegative);
  background-color: var(--matterColorLight);
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    box-shadow: var(--boxShadowButton);
  }
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    width: auto;
    font-size: 12px;
    line-height: 18px;
    padding: 1rem;
    margin: 0;
  }
}

.selectedPaymentOptionItem {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  width: 245px;
  height: 193px;
  background-color: var(--marketplaceColorBackground);
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid var(--matterColorNegative);
  border-radius: 15px;
  cursor: pointer;
  & div {
    color: white;
    & svg {
      & path {
        width: 100px;
        height: 100px;
        fill: white;
      }
    }
  }
  &:hover {
    box-shadow: var(--boxShadowButton);
  }
  @media (--viewportLarge) {
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    width: auto;
    height: 152px;
    font-size: 12px;
    line-height: 18px;
  }
}

.stepContainer {
  align-self: center;
}

.FormContainer {
  display: flex;
  justify-content: center;
  @media (min-width: 320px) and (max-width: 1920px) {
    justify-content: center;
  }
}

.packageBookingPanelContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
  margin: -180px 0 0;
  @media (--viewportMedium) {
    max-width: 1040px;
    flex-direction: row;
    margin: 0 auto;
    column-gap: 30px;
  }
}

.packageBookingPanelInfo {
  max-width: 492px;
  @media (--viewportMobile) {
    margin-bottom: 90px;
  }
  & h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    color: var(--matterColorDark);
  }
  & p {
    font-weight: normal;
    font-size: 19px;
    line-height: 24px;
    padding-bottom: 30px;
    color: var(--marketplaceColorDarkest);
  }
}

.packageBookingPanel {
  padding: 25px 25px 21px 25px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px var(--matterColorAnti);
  display: block;
  width: 404px;
  justify-content: flex-end;
  background: var(--marketplaceColorLightWhite);
  @media (--viewportLaptopDown) {
    padding: 20px;
    width: 100%;
  }
  @media (--viewportMobile) {
    background: #ffffff;
    border: 0.832774px solid #c4c4c4;
    box-sizing: border-box;
    box-shadow: 0px 8.32774px 19.9866px 2.49832px rgba(0, 0, 0, 0.05);
    border-radius: 8.32774px;
    margin: 0 0 90px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
}

.bookingFormContainer {
  width: 500px;
  margin: 0 auto;
  @media (--viewportMobile) {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 60px;
    & h3 {
      padding: 0;
    }
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 94px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportMobile) {
    padding: 0 14px 21px;
    margin: 0;
  }
}

.stepTitle {
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;

  color: #3c3c3c;
  color: var(--matterColorGrey);
  @media (--viewportMobile) {
    padding-left: 20px;
  }
}

.stepTitleChoosePackage {
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  color: var(--marketplaceColorDarkest);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 21px;
    line-height: 27px;
    color: #3c3c3c;
    margin: 15px;
  }
  @media (--viewportTabletDown) {
    margin: 15px;
  }
}

.card {
  height: auto;
  width: 100%;
  /* padding: 16px 19px 12px; */
  /* background: var(--matterColorLight); */
  /* box-shadow: 0 10px 24px 3px rgba(0, 0, 0, 0.05); */
  /* border-radius: 10px; */
  display: block;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background-color: var(--matterColorLight);
}

.productWrap {
  display: grid;
  grid-template-columns: 1fr;
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 37px 40px;
  @media (--viewportMobile) {
    padding: 20px;
    height: 220px;
    width: 260px;
  }
  @media (--viewportTabletDown) {
    padding: 20px;
    height: 240px;
    width: 260px;
  }
}
.card .product .sub {
  font-style: normal;
  font-weight: normal;
  color: var(--matterColorAnti);
  margin: 8px 0 0 0;
  font-size: 13px;
  line-height: 17px;
  @media (--viewportMobile) {
    font-size: 7px;
    line-height: 10px;
    margin: 3px 0 0 0;
  }
}
.card .product .mainPrice {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColor);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 8px;
    line-height: 11px;
  }
}

.card .product .main {
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorDark);
  color: var(--matterColorLight);
  margin: 0;
  list-style-type: disc;
  @media (--viewportMobile) {
    &::marker {
      font-size: 12px;
      margin-top: 12px;
    }
  }
}

.card .product .mainHour {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorDark);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 8px;
    line-height: 11px;
  }
}
.card .productBtn {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  & button {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #fffdfd;
    text-align: center;
    /* border: 1.5px solid var(--matterColor); */
    box-sizing: border-box;
    min-height: 48px;
    border-radius: 5px;
    @media (--viewportMobile) {
      font-size: 10px;
      line-height: 12px;
      min-height: 26px;
      border-radius: 3px;
    }
  }
}

ul {
  margin: 0px;
}

.card .product .btn {
  border-radius: 10px;
  border: none;
  background: var(--matterColorLightBlue);
  min-height: 45px;
  padding: 0 28px;
  width: 100%;
  margin: 20px 0 0 0;
}

.mdstepperhorizontal {
  display: table;
  width: 100%;
  margin: 0 auto;
  /* background-color:var(--matterColorLight); */
}
.mdstepperhorizontal .mdstep {
  display: table-cell;
  position: relative;
  padding: 24px;
  @media (--viewportMobile) {
    padding: 24px 0px;
  }
}
.done {
  & .mdstepcircle {
    background-color: #ffffff !important;
  }
  & .mdsteptitle {
    color: #ffffff !important;
  }
}

.mdstepperhorizontal .mdstep:active {
  background-color: rgba(0, 0, 0, 0.04);
}
.mdstepperhorizontal .mdstep:active {
  border-radius: 15% / 75%;
}
.mdstepperhorizontal .mdstep:first-child:active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.mdstepperhorizontal .mdstep:last-child:active {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.mdstepperhorizontal .mdstep:hover .mdstepcircle {
  background-color: #757575;
}
.mdstepperhorizontal .mdstep:first-child .mdstepbarleft,
.mdstepperhorizontal .mdstep:last-child .mdstepbarright {
  display: none;
}
.mdstepperhorizontal .mdstep .mdstepcircle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  color: #8f8f8f;
  border: 2px solid #eaeaea;
  border-radius: 50%;
  text-align: center;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorLight);
  background: #0058fa;
  /* position: relative; */
}
.mdstepperhorizontal.green .mdstep.active .mdstepcircle {
  background-color: #00ae4d;
}
.mdstepperhorizontal.activeColor .mdstep.active .mdstepcircle {
  background-color: white;
  border: 0px;
  color: #0058fa;
}
.mdstepperhorizontal .mdstep.active .mdstepcircle {
  background-color: #0058fa;
}
.mdstepperhorizontal .mdstep.done .mdstepcircle:before {
  font-family: 'FontAwesome';
  font-weight: 100;
}
.mdstepperhorizontal .mdstep.done .mdstepcircle *,
.mdstepperhorizontal .mdstep.editable .mdstepcircle * {
  display: none;
}
.mdstepperhorizontal .mdstep.editable .mdstepcircle {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.mdstepperhorizontal .mdstep.editable .mdstepcircle:before {
  font-family: 'FontAwesome';
  font-weight: 100;
  content: '\f040';
}
.mdstepperhorizontal .mdstep .mdsteptitle {
  margin-top: 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}
.mdstepperhorizontal .mdstep .mdsteptitle,
.mdstepperhorizontal .mdstep .mdstepoptional {
  text-align: center;
  /* color:rgba(0,0,0,.26); */
}
.mdstepperhorizontal .mdstep.active .mdsteptitle {
  font-weight: 600;
  color: var(--matterColorLight);
}
.mdstepperhorizontal .mdstep.active.done .mdsteptitle,
.mdstepperhorizontal .mdstep.active.editable .mdsteptitle {
  font-weight: 600;
}
.mdstepperhorizontal .mdstep .mdstepoptional {
  font-size: 12px;
}
.mdstepperhorizontal .mdstep.active .mdstepoptional {
  color: rgba(0, 0, 0, 0.54);
}
/* .mdstepperhorizontal .mdstep .mdstepbarleft {
	position:absolute;
	top:36%;
  left: 53%;
	/* height:1px; */
/* border-top:1px solid #DDDDDD;
  border-top: 2px solid #35445D;
} */
.mdstepperhorizontal .mdstep .mdstepbarright {
  position: absolute;
  height: 1px;
  border-top: 2px dashed #ffffff;
  /* z-index: -1; */
  width: 95%;
  top: 36%;
  left: 62%;
  /* z-index: -1; */
  @media (--viewportMobile) {
    width: calc(100% - 12px);
    top: 29%;
    left: 79%;
  }
}

.mdstepperhorizontal .mdstep .md2stepbarright {
  position: absolute;
  height: 1px;
  border-top: 2px dashed #ffffff;
  /* z-index: -1; */
  width: 78%;
  top: 36%;
  left: 55%;
  /* z-index: -1; */
  @media (--viewportMobile) {
    width: calc(100% - 52px);
    top: 29%;
    left: 79%;
  }
}
/* .mdstepperhorizontal .mdstep .mdstepbarleft {
	left:0;
	right:50%;
	margin-right:20px;
  /* z-index: -1; 
} */

.bookingDetailTitle {
  font-size: 25px;
  color: #cacaca;
  font-weight: 500;
}

.bookingDetailListName {
  margin: 0;
  font-weight: bold;
  font-size: 21.683px;
  line-height: 27px;
  color: var(--marketplaceColorDarkest);
}
.bookingDetailDescription {
  font-size: 20px;
  color: var(--matterColorLightBlue);
  font-weight: 400;
}

.listDescription {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--marketplaceColor);
  width: 80%;
  /* margin-bottom: 15px; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bookingLocationDateTime {
  font-size: 15px;
  color: #999999;
  font-weight: 400;
  display: flex;
  column-gap: 20px;
}

.bookingDetailContainer {
  margin: 0 auto;
  width: 100%;
  border: 1px solid var(--matterColorNegative);
  border-radius: 12px 12px 0 0;
  @media (--viewportMobile) {
    /* margin-top: -186px; */
  }
  @media (--viewportLaptopDown) {
    width: 100%;
    display: block;
  }
}
.bookingDetailSection {
  /* padding: 0 0 12px 12px; */
  @media (--viewportMobile) {
    width: 100%;
  }
}
/* .bookingIconSection{
  background-color: var(--matterColorLightGrey);
  padding: 0 15px;
  width: 35%;
  @media (--viewportMobile) {
    width: 100%;
    display: none;
  }
} */

.bookingBottomIconSection {
  background-color: var(--matterColorLightGrey);
  padding: 0 15px;
  display: none;
  @media (--viewportMobile) {
    width: 100%;
    display: block;
  }
}

.bookingIcon {
  margin-right: 6px;
  & path {
    fill: var(--matterColorLightBlue);
  }
}

.stepSection {
  margin: 0 20px 0 0;
  /* padding: 0 20px; */
  width: 70%;
  @media (--viewportLaptopDown) {
    width: 100%;
    margin: 0 0 50px;
  }
  @media (--viewportMobile) {
    padding: 0 22px 0 0;
    margin: 0;
  }
  @media (--viewportTabletDown) {
    padding: 0 22px 0 0;
    margin: 0;
  }
}

.paymentType {
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
}

.paymentFormSection {
  margin: 0 20px 0 0;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  width: 65%;
  @media (--viewportLaptopDown) {
    width: 100%;
    margin: 0 0 50px;
  }
}

.paymentOptionsSection {
  padding: 0 16px;
  & img {
    margin: 0 16px 0 0;
    @media (--viewportMobile) {
      margin: 0 10px 0 0;
    }
  }
  @media (--viewportMobile) {
    margin-bottom: 60px;
  }
}

.bookListingContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  @media (--viewportMobile) {
    padding: 0;
  }
  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
}

.listingBookingDetail {
  width: 30%;
  padding: 0;
  @media (--viewportLaptopDown) {
    width: 100%;
    padding: 0 24px 79px;
  }
}

.listingPaymentDetail {
  width: 60%;
}

.BookingDetailTitleSection {
  margin-left: 20px;
}

.detailsAspectWrapper {
  position: relative;
  & img {
    border-radius: 12px 12px 0 0;
  }
}

.avatarWrapper {
  & div {
    background: #c4c4c4;
    & span {
      text-transform: uppercase;
    }
  }
}

.BookingDetailInfo {
  background-color: #ffffff;
  padding: 16px 28px 36px;
}

.timeInfoContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.imageSize {
  width: 100%;
  max-height: 139px;
  border-radius: 0px 0px 5px 5px;
  & img {
    width: 100%;
    height: auto;
    @media (--viewportMobile) {
      height: 82px;
    }
  }
  @media (--viewportMobile) {
    height: auto;
  }
}

.packageTitleSection {
  width: 100%;
  height: 35px;
  background: rgba(35, 48, 69, 0.6);
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  @media (--viewportMobile) {
    height: 17px;
  }
  & h2 {
    font-size: 9px;
    line-height: 12px;
    margin: 0 6px 0 !important;
    color: var(--matterColorLight);
    font-style: normal;
    font-weight: bold;
    @media (--viewportLarge) {
      margin: 0 10px 0 !important;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.bookingHeading {
  /* display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    /* margin-bottom: 33px; 
  } */
  @media (--viewportMobile) {
    padding: 20px 14px 0;
  }
}
.desktopPriceContainer {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-shrink: 0;
  margin: 0 48px 0 0;
  padding: 0;
}

.desktopPriceValue {
  font-weight: bold;
  font-size: 19px;
  line-height: 24px;
  color: var(--matterColorDarkBlue);

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.desktopPerUnit {
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin-left: 6px;
  }
}

.bookingTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.1px;
  color: var(--matterColorGrey);
  margin-top: 4px;
  margin-bottom: 9px;
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
  font-size: 18px;
  line-height: 22px;
}

.packageSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  & p {
    & [type='radio']:checked,
    & [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    & [type='radio']:checked + label,
    & [type='radio']:not(:checked) + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      color: var(--matterColorLightGrey);
      padding: 0 40px !important;
    }
    & [type='radio']:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      border: 3px solid var(--marketplaceColorDarker);
      border-radius: 100%;
      background: var(--matterColorLight);
    }

    & [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      border: 3px solid var(--matterColorAnti);
      border-radius: 100%;
      background: var(--matterColorLight);
    }
    & [type='radio']:checked + label:after,
    & [type='radio']:not(:checked) + label:after {
      content: '';
      width: 22px;
      height: 22px;
      transform: scale(0.54) !important;
      background: var(--marketplaceColor);
      position: absolute;
      top: 0;
      left: 0px;
      border-radius: 100%;
      transition: all 0.2s ease;
    }
    & [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    & [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
  @media (--viewportMobile) {
    padding: 0;
  }

  & label {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.1px;
    color: var(--matterColorGrey) !important;
  }
  & p {
    margin: 0;
    margin-top: 16px;
  }
}

.steps {
  display: flex;
  justify-content: center;
  background: #0058fa;
  padding: 64px 0 25px;
  @media (--viewportMobile) {
    padding: 40px 0 28px;
  }
}

.ContainerLine {
  width: 100%;
  height: 8px;
  background: var(--marketplaceColorBackground);
}

.BookingDetailInfoHead {
  display: flex;
}

.authorDetailInfo {
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  & .authorExperience {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #233045;
    margin-bottom: 4px;
  }
  & .authorLanguage {
    font-weight: normal;
    font-size: 11.034px;
    line-height: 14px;
    color: #6e6f71;
  }
}

.authorReviewInfo {
  padding: 5px 0 0 0;
  display: flex;
  align-items: center;
  margin-top: 14px;
  @media (--viewportMobile) {
    justify-content: space-between;
    padding: 0 21px 0 6px;
  }
}

.reviewSpan {
  display: flex;
  align-items: center;

  color: var(--marketplaceColorDarker);
  &:not(:last-child) {
    padding: 0 83px 0 0;
  }
  & span {
    margin-left: 9px;
    display: block;
    font-weight: normal;
    font-size: 9.93059px;
    line-height: 12px;
    color: var(--matterColorDarkBlue);
  }
  @media (--viewportMobile) {
    &:not(:last-child) {
      padding: 0 20px 0 0;
    }
    & span {
      font-size: 13px;
      line-height: 18px;
    }
  }
}

.reviewStar {
  height: 16px;
  width: 16px;
}

.iconCheckedShield {
  height: 16px;
  width: 16px;
}

.stepContentContainer {
  display: flex;
  justify-content: space-between;
  /* flex-direction: row; */
  padding: 1.5rem;
  background-color: #f5f9ff;
  @media (--viewportMobile) {
    flex-direction: column;
    padding: 0;
  }
  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 0;
  }
}

.horizontalScroll {
  width: 100%;
  /* @media (--viewportTabletDown) {
    width: 100vw;
    margin: auto;
  } */
}

.packagePrice {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  margin: 18px 0 23px 0px;
  color: #000;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 0 13px;
  }
}

.pacakageHour {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 16px;
  }

  &::after {
    content: '';
    height: 1px;
    width: 17px;
    background: var(--matterColorLight);
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    @media (--viewportLarge) {
      height: 2px;
      width: 36px;
      bottom: -8px;
    }
  }
}

.svgWrap {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 60px;
    height: 60px;
    @media (--viewportTabletDown) {
      width: 32px;
      height: 32px;
    }
  }

  @media (--viewportLarge) {
    margin-right: 16px;
  }
}

.scrollControl {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: space-between;
  column-gap: 60px;
  padding: 1rem 0.5rem;
  max-width: 100%;
  padding: 30px 30px;
  @media (max-width: 1600px) {
    column-gap: 30px;
  }
  @media (--viewportTabletDown) {
    column-gap: 20px;
    /* width: 95vw; */
    flex-direction: row;
  }
  @media (--viewportMobile) {
    column-gap: 10px;
    /* flex-direction: column; */
  }
}
.scrollContainer {
  display: flex;
  overflow: auto;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 32px;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
  @media (--viewportLaptopDown) {
    gap: 32px;
    overflow-x: scroll;
    /* margin-right: 25px; */
  }

  @media (--viewportTabletDown) {
    gap: 25px;
  }
}
.customScrollLayer {
  @media (--viewportMedium) {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}
