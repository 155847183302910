@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: unset;
}

.layoutWrapperMain {
  /* Use all available space parent element (aka root) can give and don't shrink content */
  flex-grow: 1;
  flex-shrink: 0;
  /* height: calc(100vh - 124px); */
  /* overflow: auto; */
  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
  overflow-x: unset;
  margin-bottom: 70px;
  @media (--viewportMobile) {
    margin-bottom: 0;
  }
}
