@import '../../../marketplace.css';

.cardMainWrap {
  display: flex;
  align-items: center;
  column-gap: 5px;
  text-decoration: none !important;
  cursor: pointer;
}
.reverseCardMain {
  flex-direction: row-reverse;
}
.cardImgMain {
  width: 425px;
  height: 280px;
  /* flex: 1; */
  border-radius: 50px 0;
  overflow: hidden;
}
.cardImgMain img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardImgEdu {
  width: 320px;
  height: 320px;
}
.cardImgTen {
  width: 320px;
  height: 320px;
}
.cardwrap {
  background: var(--matterColorLight);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex: 2;
  justify-content: space-between;
  column-gap: 15px;
}
.cardDetailsWrap {
  width: 100%;
}
.membershipWrap {
  display: flex;
  justify-content: space-between;
}
.subCategoryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 60%;
}
.subCategoryText {
  font-weight: 400;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background-color: #0056f5;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: capitalize;
  word-break: break-word;
  white-space: normal;
}
.cardDetailsHead {
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardDetailAddressWrap {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.cardDetailAddress {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.cardDetailAddressText {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1b1b1b;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardDetailText {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bookingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
  /* border-left: 1px solid #0b150a1a; */
  /* padding-left: 30px; */
  flex: 1;
  column-gap: 15px;

  @media (--viewportMiniDesktopDown) {
    border-left: unset;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  @media (--viewportMobile) {
    padding: 0;
  }
}
.cardDetailButton {
  background: var(--matterColorBlue);
  border-radius: 6px;
  padding: 12px 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: var(--matterColorLight);
  border: 0;
  cursor: pointer;
}
.priceWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 0 !important;
  align-items: center;
}
.priceFrom {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorDark);
  padding: 0 !important;
}
.priceProduct {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cardImagesWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.cardImgWrapper {
  width: 195px;
  height: 115px;
  border-radius: 8px;
  overflow: hidden;
}
.cardImgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.itemImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.arrowFlip {
  display: flex;
  align-items: center;
}
.arrowSvg {
  fill: transparent;
}
.trainerDetailMainWrapper {
  display: flex;
  padding: 15px;
  column-gap: 50px;
  background-color: var(--matterColorLight);
  border-radius: 5px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    column-gap: 20px;
  }
}
.featuresMainWrap {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  @media (--viewportTabletDown) {
    margin-top: 35px;
  }
}
.featuresMain {
  margin-bottom: 23px;
}
.features {
  display: flex;
  column-gap: 15px;
}
.featuresImg {
  margin-top: 5px;
}
.featuresHead {
  font-weight: 700;
  font-size: 29px;
  line-height: 32px;
  color: var(--matterColorDark);
  margin: 0;
  text-transform: capitalize;
  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 22px;
  }
}
.featuresText {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--matterColorDark);
  margin: 0;
  width: 380px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    width: 100%;
  }
}
.listingsMain {
  display: flex;
  align-items: center;
  column-gap: 32px;
}
.listingsHead {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: var(--matterColorDark);
  margin: 0;
  text-transform: capitalize;
}
.listingsText {
  font-weight: 700;
  font-size: 22.1749px;
  line-height: 32px;
  color: var(--matterColorBlue);
  margin: 0;
}
.otherListingMain {
  flex: 1;
  @media (--viewportLaptopDown) {
    min-width: 90%;
    padding: 10px;
  }
}
.otherListingTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--matterColorDark);
  margin-bottom: 12px;
}
.courseImageWrapper {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    width: 100%;
    justify-content: flex-start;
    column-gap: 10px;
  }
}
.classImage {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 130px;
  height: 95px;
  @media (max-width: 1440px) {
    width: 100px;
    height: 85px;
  }
}
.classImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cardDetailWrapper {
  padding: 10px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 0px;
  }
}
.cardDetailTitle {
  font-weight: 500;
  font-size: 16.2309px;
  line-height: 21px;
  color: var(--matterColorBlue);
  text-transform: capitalize;
  margin: 0 0 10px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }
}
.cardUsername {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 10px;
}
.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);

  text-transform: capitalize;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
  }
}
.cardPrice {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #28a74b;
}
.cardLocation {
  display: flex;
  column-gap: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  margin: 0px 0 10px;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 8px;
    }
  }
}
.currentAddress {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorDark);
}
.cardRatingsMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 10px;
  @media (min-width: 1200px) and (max-width: 1600) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
.cardRatings {
  display: flex;
  column-gap: 5px;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #ffb534;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 8px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 10px;
    }
  }
}
.cardRatingsText {
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
  color: #ffb534;
}
.cardTotalRatings {
  color: #b9b9b9;
}
.cardRatingsTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDark);
}
.reviewMainWrap {
  flex: 1;
}
.reviewRoot {
  background: none;
}
.mapImage {
  box-shadow: 0px 4px 41.76px 5.22px rgba(0, 0, 0, 0.05);
  /* margin-top: 50px; */
  height: 250px;
  @media (--viewportMobile) {
    width: 100%;
    margin: 23px 0 0;
    border-radius: 5px;
  }

  & h2 {
    display: none;
  }

  & > div {
    @media (--viewportMobile) {
      height: 100%;
      border-radius: 5px;
    }
  }

  & button {
    height: 100%;
    max-width: 100% !important;
    width: 100%;
    border-radius: 15px;
    @media (--viewportMobile) {
      /* max-height: 165px; */
    }
  }

  & button > div {
    border-radius: 15px;
  }

  & canvas {
    width: 100% !important;
    position: unset !important;
    border-radius: 15px;
    @media (--viewportMobile) {
      /* max-height: 165px; */
      border-radius: 5px;
    }
  }
}
.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 250px;
    /* max-width: unset !important; */
  }
}
.membershipButton {
  display: flex;
  /* width: 60%; */
  color: #0056f5;
  font-size: 13px;
  height: 28px;
  float: right;
  text-align: center;
  padding: 2px;
  background: var(--matterColorLight);
  border: 1px solid #070707;
  border-radius: 6px;
  align-items: center;
  padding: 0 8px;
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.categoryMembershipWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
