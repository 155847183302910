@import '../../marketplace.css';

.slider {
  background-color: var(--matterColorLight);
  /*border: 1px solid #dadada;
  box-shadow: 0px 16.7144px 63.2758px rgba(0, 0, 0, 0.1);
  border-radius: 14.6004px; */
}

.sliderWrap {
  min-width: 90%;
  padding: 12px 8px;
  /* height: 100px;
  background: red; */
}
.trainerMainWrapper {
  position: relative;
  /* padding-bottom: 35%; */
  background: var(--matterColorLight);
  border-radius: 16px;
}
.trainerSectionWrap {
  width: 100%;
  height: 250px;
}
.trainerSectionWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 8px; */
  border-radius: 50px 0;
}
.trainerDetailMain {
  /* padding: 0 20px 0; */
  /* position: absolute; */
  /* top: 160px; */
  width: 100%;
}
.trainerDetailWrap {
  background: var(--matterColorLight);
  /* box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.08); */
  /* border-radius: 0 0 16px 16px; */
  /* padding: 20px 10px; */
}
.trainerDetailTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 10px;
}
.trainerDetailDesc {
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 8px;
}
.ratingLocationWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 20px;
  /* justify-content: space-between; */
}
.ratingWrap {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}
.ratingTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}
.locationWrap {
  display: flex;
  flex-direction: column;
  column-gap: 7px;
  /* flex: 1; */
}
.locationTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}
.locationMainText {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #1b1b1b;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gallerItemsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background: var(--matterColorLight);
  padding: 10px;
  border-radius: 6px;
  /* margin-bottom: 10px; */
  min-width: 90%;
}
.gallerItems {
  /* width: 100%; */
  min-height: 150px;
  border-radius: 6px;
  overflow: hidden;

  @media (--viewportMobile) {
    /* max-height: 200px; */
  }
}
.itemImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.promotedBookingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 20px 0 20px; */
  padding: 0 10px;
  @media (--viewportTabletDown) {
    /* padding: 0; */
  }
}
.priceWrapper {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
  padding: 0 !important;
  align-items: end;
}
.priceFrom {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorDark);
  padding: 0 !important;
}
.priceProduct {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--matterColorDark);
  padding: 0 !important;
}
.cardDetailButton {
  background: var(--matterColorBlue);
  border-radius: 6px;
  padding: 12px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: var(--matterColorLight);
  border: 0;
  @media (--viewportTabletDown) {
    padding: 12px 30px;
  }
}
.messageWrap {
  background: var(--matterColorBlue);
  border-radius: 6px;
  padding: 12px 14px;
  justify-content: left;
  font-weight: 500;
  margin-left: auto;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: var(--matterColorLight);
  border: 0;
  @media (--viewportTabletDown) {
    padding: 12px 30px;
  }
}

.newScroll {
  gap: 0 !important;
}

.categoryListContainer [class*='sectionCounter'] {
  display: none;
}

.categoryListContainer [class*='left'] {
  top: 0;
  right: unset;
  left: -45px;
  display: flex;
  align-items: center;

  @media (--viewportTabletDown) {
    right: -50px;
    left: unset;
  }

  @media (--viewportLaptopDown) {
    width: unset;
    right: -30px;
    left: unset;
  }
}

.categoryListContainer [class*='right'] {
  top: 0;
  right: -90px;
  display: flex;
  align-items: center;
  height: 100%;
}

.categoryListContainer [class*='img'] {
  display: flex;
  align-items: center;
  position: revert;
  width: 32px;
  height: 32px;
}

.categoryListContainerWithoutArrow {
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}
.productListingWrap {
  background: #ffffff;
  box-shadow: 0px 16.2419px 61.4871px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  @media (--viewportMobile) {
    min-width: 60%;
  }
}
.productImgWrap {
  position: relative;
}
.productListingStartIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fafafa66;
  opacity: 0.4;
  border-radius: 5px;
  padding: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productListingDesc {
  padding: 15px 20px 30px;
}
.categoryRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
}

.categoryText {
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.15em;
  color: #a8a8a8;
}
.titleText {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin: 0 0 5px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* height: 75px; */

  @media (--viewportMobile) {
    height: unset;
  }
}
.descText {
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #a8a8a8;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
  height: 45px;
}
.productPrice {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--matterColorDark);
  padding-top: 10px;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.countryText {
  margin: 0;
  font-weight: 400;
  margin-left: 3px;
  font-size: 14px;
  line-height: 21px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
  overflow: hidden;
}

.listingHeadWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.desktopPriceNBtnWrap {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.levelButtonWrapper {
  /* display: flex; */
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 2px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  padding: 0 10px;
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.editListingTypeText {
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #000000;
}
.levelContent {
  display: flex;
  justify-content: center;
}
.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
      line-height: 23px;
      margin-left: 0px;
    }
  }
}
.levelwrapper {
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #ffb81d;
}
.buttonContent {
  display: block;
  justify-content: center;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  padding: 8px;
  background: var(--matterColorLight);
  /* border: 1px solid #d7d7d7; */
  /* border-radius: 14px; */
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.largeContent {
  display: block;
  padding: 0;
  justify-content: center;
  margin: 0;
  position: relative;
  top: -5px;
}
/* .newHomeMain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 30px 40px;
} */
.homeCardMain {
  border-radius: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  background: #fefefe;
  cursor: pointer;
  @media (--viewportTabletDown) {
    border-radius: 20px;
  }
}

/* card head */
.homeCardMain .homeCardHead {
  background: linear-gradient(180deg, #0058fa 0%, #fff 40.63%);
  box-shadow: 0px 0px 5px #0005;
  border-radius: 40px;
  padding: 30px 0px;
  margin-bottom: 60px;
  position: relative;
  @media (--viewportTabletDown) {
    padding: 25px 25px;
    border-radius: 20px;
  }
}
.homeCardHead .homeHeadImage {
  margin-bottom: -65px;
  @media (--viewportTabletDown) {
    margin-bottom: -45px;
  }
}
.homeHeadImage img {
  /* width: 100%; */
  /* width: 225px; */
  height: 240px;
  object-fit: cover;
  border-radius: 40px;
  @media (--viewportTabletDown) {
    border-radius: 20px;
    width: 100%;
  }
  @media (--viewportMobile) {
    height: 200px;
  }
}

.homeHeadDetail {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: start;
  align-items: start;
  margin: 15px;
  gap: 5px;
  @media (--viewportLaptopDown) {
    align-items: center;
  }
}
.homeHeadDetail .frontHead {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  margin: 0;
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--viewportTabletDown) {
    text-align: center;
    font-size: 26px;
    line-height: 26px;
  }
}
.homeHeadDetail .headSports {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}
.homeHeadDetail .headSports .headSGD {
  background: #373747;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.homeHeadDetail .headSports .sport1 {
  background: #7662d7;
  padding: 1px 10px;
  border-radius: 3px;
}
.homeHeadDetail .headSports .sport2 {
  background: #e66998;
  padding: 1px 10px;
  border-radius: 3px;
}
.homeHeadDetail .headRate {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  background: #373747;
  border-radius: 6px;
  padding: 5px 15px;
  text-align: center;
  @media (--viewportMobile) {
    width: 100%;
  }
}

/* card detail */
.homeCardMain .homeDetails {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 15px;
  margin: 15px;
  border-radius: 30px;
  @media (--viewportLaptopDown) {
    border-radius: 20px;
  }
}
.homeDetails .detailLocation {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  overflow: hidden;
  align-items: center;
  gap: 8px;
  color: #a8a8a8;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.homeDetails .detailDesc {
  color: #020202;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 10px;
}
.homeDetails .detailsImageGallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.homeDetails .detailsImageGallery img {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

/* button group  */
.homeCardMain .homeButtonGroup {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 10px 10px;
  margin: 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media (--viewportLaptopDown) {
    border-radius: 20px;
  }
}
.homeButtonGroup .buttonMessage {
  background: linear-gradient(180deg, #ffb81d 0%, #fe6436 100%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px;
  border: 0;
  border-radius: 30px;
}
.homeButtonGroup .buttonBook {
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px;
  border: 0;
  border-radius: 30px;
}

/* Premium Member  */
.homeCardMain .homePremium {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 10px;
  margin: 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  @media (--viewportTabletDown) {
    font-size: 18px;
  }
}
.subCategoryText {
  font-weight: 400;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background-color: #7662d7;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: capitalize;
  word-break: break-word;
  white-space: normal;
  width: max-content;
}

.ratingTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}

.subCategoryText {
  font-weight: 400;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background-color: #7662d7;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: capitalize;
  word-break: break-word;
  white-space: normal;
  width: max-content;
}
.ratingText {
  color: #000;
}
.membershipButton {
  color: gray;
}
