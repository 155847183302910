@import '../../../../marketplace.css';

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  background: #ffffff;
  border-radius: 15px 15px 0px 0px;

  @media (--viewportTabletDown) {
    margin-top: 15px;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.enquiryFeedHead {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 35px 35px 25px 35px;
  font-weight: 500;
  gap: 20px;

  @media (--viewportxldesktop) {
    padding: 15px 15px 10px 15px;
  }

  @media (--viewportTabletDown) {
    padding: 15px 22px;
    font-size: 14px;
    line-height: 18px;
  }
}

.enquiryFeedHead h4 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0b1333;
  margin-bottom: 4px;

  @media (--viewportTabletDown) {
    margin: 0;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 13px;

  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
}

.feedUserWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.feedUserName {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #aba7a7;

  @media (--viewportMobile) {
    padding-left: 21px;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: var(--marketplaceColor);
  }

  @media (--viewportTabletDown) {
    color: #aba7a7;
    padding: 0;
    font-size: 10px;
    line-height: 13px;
  }
}

.feedIconDot {
  display: flex;
  align-self: baseline;
  margin-left: auto;

  @media (--viewportTabletDown) {
    display: none;
  }
}

.feed {
  height: calc(100vh - 290px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 30px;

  @media (--viewportxldesktop) {
    height: calc(100vh - 200px);
  }

  @media (--viewportLaptopDown) {
    height: calc(100vh - 336px);
    padding-right: 15px;
  }

  @media (--viewportTabletDown) {
    height: calc(100vh - 322px);
    max-height: 375px;
    min-height: 375px;
  }
}

.feed::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.feed::-webkit-scrollbar-thumb {
  background-color: var(--matterColorAnti);
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.goBackIcon {
  display: none;

  /* background: #101c30;
  margin-left: auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg); */
  @media (--viewportLaptopDown) {
    display: block;
    display: flex;
    align-self: baseline;
    margin-left: auto;
  }

  @media (--viewportTabletDown) {
    /* display: flex;
    align-self: baseline;
    margin-left: auto; */
  }
}

.itemLink {
  color: var(--matterColorDark);
}

.messageToolsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
  padding: 12px 30px;

  @media (--viewportMobile) {
    width: auto;
    bottom: auto;
    top: auto;
    padding-top: 10px;
    padding-left: 0;
  }

  @media (--viewportxldesktop) {
    padding: 12px 15px;
  }
}

.messageToolsWrapper {
  gap: 15px;

  @media (--viewportTabletDown) {
    gap: 10px;
    margin-bottom: 70px;
  }
}

.addIcon {
  width: 50px;
  height: 50px;
  background: #0058fa;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportxldesktop) {
    width: 46px;
    height: 40px;

    & img {
      width: 12px;
      height: 12px;
    }
  }
}

.messageToolsWrapper svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}

.SendMsgKeywordWrap input {
  width: 100%;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 29px;
  height: 100%;
  padding: 10px 50px 10px 10px;

  &:focus {
    border-color: #dce5f2;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #bec8d7;

    @media (--viewportTabletDown) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (--viewportMobile) {
    width: 100%;
  }

  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 15px;
  }
}

.SendMsgKeywordWrap {
  width: 100%;
  display: flex;
  column-gap: 15px;
}

.sendButton {
  min-height: unset;
  border-radius: 28px;
  width: 36px;
  position: absolute;
  right: 10px;
  top: 6px;
  height: 36px;
  background: #0058fa;

  &:hover {
    background-color: #2e43b7;
  }

  @media (--viewportxldesktop) {
    width: 28px;
    height: 28px;
    right: 5px;
  }
}

.MsgWrap {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;

  @media (--viewportMobile) {
    width: 100%;
    height: 44px;
    border-radius: 28px;
  }

  @media (--viewportxldesktop) {
    height: 40px;
  }
}

.MsgWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;

  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.MsgWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}

.sendInputWrap {
  width: 100%;
  height: 100%;
  position: relative;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;

  @media (--viewportxldesktop) {
    margin-bottom: 15px;
  }

  @media (--viewportTabletDown) {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: #0b1333;
    height: 73px;
    padding: 0 26px;
    color: #ffffff;
    margin: 0;
    z-index: 9;
  }
}

.titleWrapper h1 {
  margin: 0;

  @media (--viewportTabletDown) {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-left: 22px;
  }
}
