@import '../../marketplace.css';
* {
  -webkit-overflow-scrolling: touch;
}
:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    animation-name: animationTitle;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }
}

.title {
  padding-left: 24px;
  padding-right: 24px;
  @apply --marketplaceH1FontStyles;
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;

  @media (--viewportMobile) {
    font-size: 22px;
  }
  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.memberships {
  display: flex;
  overflow-x: scroll;
  margin: 0px 24px;
  background-color: var(--marketplaceColorLightWhite);
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
  @media (--viewportMedium) {
    justify-content: flex-start;
    background-color: var(--marketplaceColorLightWhite);
  }
}

.memberships a {
  margin: 10px;
  display: flex;
  flex-direction: column;
  min-width: 192px;
  min-height: 242px;
  position: relative;
  @media (--viewportMedium) {
    width: 100%;
  }
}

.memberships a:hover {
  opacity: 0.8;
}

.memberships div {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.serviceTitleSectionClass {
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 24px;
  font-weight: 600;
  color: var(--marketplaceColorLightWhite);
}
