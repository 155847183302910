@import '../../marketplace.css';

.matchModelWrap {
  padding: 50px !important;
  border-radius: 10px !important;
  overflow: hidden;
  flex-basis: 1050px !important;

  @media (max-width: 991px) {
    padding: 30px !important;
  }

  @media (--viewportMobile) {
    border-radius: unset !important;
    overflow: auto;
    margin: 0px 0;
    padding: 25px !important;
  }
}
.customScrollLayer {
  @media (--viewportMedium) {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}
.matchModelWrap .questionContainer {
  display: flex;
  flex-direction: column;
}
.matchModelWrap .matchModelHead {
  display: flex;
  align-items: center;
  gap: 20px;
}
.matchModelWrap .matchModelHead svg {
  fill: none;
  cursor: pointer;
}
.matchModelWrap .questionWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.matchModelWrap .matchQuestion {
  color: #000;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 0;
}
.matchModelWrap .infoText {
  color: #000;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
}

.matchModelWrap .optionsWrapSurvey {
  display: flex;
  /* flex-direction: column; */
  gap: 10px;
  margin: 25px 0;
  max-height: calc(100vh - 240px);
  overflow: auto;
  @media (max-width: 991px) {
    flex-direction: column;
  }
}
.optionsWrapSurvey .optionsWrapper {
  flex-grow: 4;
  flex: 1;
}
.optionsWrapper .optionsLabel {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #f5f5f5;
  padding: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.optionsWrapper .optionsLabel .optionsInput {
  width: auto;
  display: none;
}

.optionsWrapper .optionsLabel .optionsImgWrap {
  border-radius: 10px;
  background: #d9d9d9;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.optionsWrapper .optionsLabel .optionsImgWrap img {
  height: 65px;
  width: 65px;
}
.optionsWrapper.checked .optionsLabel {
  background: #0058fa;
  color: #fdf9f9;
}
.optionsWrapper.checked .optionsLabel .optionsImgWrap {
  background: #fff;
}
.matchModelWrap .actionWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actionWrapper .btnBlue {
  border: 0;
  background: #0058fa;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 25px;
  border-radius: 10px;
}
.matchMain {
  max-height: calc(100vh - 160px);
  @media (--viewportMiniDesktopDown) {
    max-height: calc(100vh - 120px);
  }
  @media (max-width: 991px) {
    max-height: calc(100vh - 80px);
  }
}
.matchMain .matchHead h2 {
  font-size: 30px;
  text-align: center;
}
.matchMain .matchWrap {
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  /* height: 400px; */
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
}
.matchWrap .matchForm {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.matchForm .matchlabel {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding: 0;
}
.matchForm .matchInput {
}
.matchMain .matchFooter {
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 5px;
  margin-top: 15px;
}
.matchFooter h4 {
  width: 100%;
  text-align: start;
  margin: 0;
  color: #0058fa;
}
.matchFooter .matchFooterBtn {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 20px;
  min-height: auto;
  width: auto;
}
.dateTimeWrap {
  display: grid;
  gap: 10px;
}
.dateWrap {
  display: flex;
  gap: 10px;
}
@media (max-width: 767px) {
  .matchMain .matchWrap {
    grid-template-columns: repeat(1, 1fr);
    height: calc(100vh - 250px);
  }
}
.thankYouWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 60px;
  @media (--viewportTabletDown) {
    padding: 20px;
  }
}
.btnBlue {
  background: #0058fa;
  color: #ffffff;
  border: 0;
}
.btn {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 250px;
  @media (--viewportMiniDesktopDown) {
    width: 100%;
    padding: 10px 25px;
  }
}
