@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 35px;

  @media (--viewportTabletDown) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
}
