@import '../../marketplace.css';

.mainPage {
  @media (--viewportLaptopDown) {
    /* height: 100vh;
    overflow-x: hidden;
    overflow-y: auto; */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.pageRoot {
  display: flex;
  overflow-x: hidden;
  @media (--viewportLaptopDown) {
    padding-bottom: 80px;
  }
}

.cardContainer {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 30px; */
  padding: 5px 10px;
  flex-direction: row;
  /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr)); */
  gap: 30px;
  margin: 35px 42px;
  width: min-content;
  height: fit-content;
  overflow: hidden !important;

  @media (--viewportTabletDown) {
    /* margin: 25px 14px 14px 14px; */
    margin: 25px 3%;
    gap: 14px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}
.profilecard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 30px; */
  padding: 5px 10px;
  flex-direction: row;
  /* grid-template-columns: repeat(auto-fit, minmax(230px, 1fr)); */
  gap: 40px;
  margin: 35px 42px;
  width: min-content;
  height: fit-content;
  overflow: hidden !important;
  @media (--viewportTabletDown) {
    flex-direction: column;
  }
}
.smallCardContainer {
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.classContainer {
  display: grid;
  flex-direction: row;
  /* margin-top: 35px; */
  margin-left: 35px;
  margin-right: 35px;
  height: fit-content;
  background-color: #f7f7f7;
  /* justify-content: center; */
  border-radius: 15px;
  min-height: 365px;

  @media (--viewportMiniDesktopDown) {
    margin: 20px 0px;
    margin-top: unset;
    margin-bottom: 66px;
    justify-content: center;
  }
}

.classCardContainer {
  background-color: #f7f7f7;
  border-radius: 20px;
  width: 100%;
  @media (--viewportMobile) {
    margin-bottom: 60px;
  }

  @media (--viewportMiniDesktopDown) {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* background-color: #f2f2f2; */
  }

  @media (--viewportMobile) {
    padding: 0 1rem;
  }
  @media (max-width: 375px) {
    padding: 0 2rem;
  }
  @media (max-width: 320px) {
    padding: 0 3px;
  }
}

.homePageCardContainer:hover,
.homePageCardContainer {
  text-decoration: none;
}

.cardItem {
  margin-bottom: 0px;
}

/* .homePageCardContainerPlayerProfile:hover, */
.homePageCardContainerPlayerProfile {
  text-decoration: none;
  display: flex;
}

.cardItemPlayerProfile {
  max-width: 300px;
  min-width: 200px;
  @media (--viewportTabletDown) {
    max-width: 100%;
  }
}

.cardItem [class*='cardListingBox'] {
  width: unset;
  overflow: hidden;
}

.cardItem [class*='noImageText'] {
  margin: 0;
  padding: 0;
}

.cardItem [class*='noImageIcon'] {
  margin: 0;
  padding: 0;
  display: flex;
}

.cardItem [class*='noImageContainer'] {
  height: 100%;
}

.cardItem [class*='cardsButton'] {
  padding: 0;
  gap: 0;
  justify-content: space-around;
  margin-top: 14px;
}

.cardItem [class*='viewOfferButton'] {
  padding: 10px 15px;
}

.cardItem [class*='cardsRatingNumber'] {
  padding: 12px 0px;
}

.cardItem [class*='cardsParagraph_'] {
  height: 78px;
  width: 55%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardItem [class*='price_old'] {
  /* padding: 10px; */
  font-size: 18px;
  font-weight: 400;
  color: #898989;
  @media (--viewportMobile) {
    font-size: 14px;
  }
}

.cardItem [class*='cardTitleName'] {
  padding-top: 50px;
}

.cardItem [class*='cardsRatings'] {
  grid-column-gap: unset;
  column-gap: unset;
  justify-content: space-around;
}

.overlayMain {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 9;
}

.main {
  position: relative;
  background: #f5f9ff;
  padding-left: 305px;
  overflow: hidden;
  /* height: calc(100vh - 271px); */
  /* overflow: auto; */
  @media (--viewportTabletDown) {
    padding-left: 0;
    overflow-x: hidden;
    /* height: calc(100vh - 202px); */
  }

  @media (--viewportLaptopDown) {
    padding-left: 0;
  }
}

.mapMain {
  overflow: hidden;
  height: calc(100vh - 140px);
  @media (--viewportMobile) {
    height: 75vh;
  }
}

.openMainHeaderMain {
  height: calc(100vh - 340px);

  @media (--viewportTabletDown) {
    height: calc(100vh - 268px);
  }
}

.openModalDisableScrolling {
  overflow: hidden;
}

.disableScroll {
  overflow-y: hidden;

  @media (--viewportLaptopDown) {
    height: calc(100vh - 225px);
    /* height: calc(100vh - 282px) */
    /* display: none; */
  }

  @media (--viewportMobile) {
    height: calc(100vh - 292px);
  }
}

.disableFilterScroll {
  overflow-y: hidden;
  height: calc(100vh - 225px);
}
.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
  height: 100vh;
  overflow: auto;
}

.showMapBtn {
  background-color: black;
  border-radius: 40px;
  color: white;
  font-style: normal;
  font-weight: 400;
  border: 0;
  font-size: 16px;
  line-height: 20px;
  /* height: 48px;
  width: 150px; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: 10%;
  z-index: 9;
  margin: 0 auto;
  left: 50%;
  padding: 10px;
  gap: 10px;
  @media (--viewportLaptopDown) {
    margin: 0 auto;
    left: 40%;
    bottom: 80px;
  }
}

.showMaponOpen {
  padding: 7px 10px;
  bottom: 78px;
}

.main [class*='defaultMapLayout'] {
  width: 100%;
  height: calc(100vh - 140px);
  overflow: hidden;
}

.headerLocation {
  margin: 20px 42px;
  @media (--viewportTabletDown) {
    margin: 20px 0;
    padding: 0 40px;
  }
  @media (--viewportMobile) {
    padding: 0 8px;
  }
}

.headerLocation [class*='scrollContainer'] {
  gap: 25px;

  @media (--viewportTabletDown) {
    padding: 0 8px;
  }
}

.locationListContainer {
  width: 100%;
  /* height: 147px; */
  display: flex;
  align-items: center;
  /* left: 100px; */
  position: relative;
  padding: 0 40px;
  /* justify-content: center; */
  @media (--viewportTabletDown) {
    padding: unset;
  }
}
.activeItem svg {
  width: 26px;
  height: 26px;
  margin-right: 0;
  margin-bottom: 18px;
  fill: transparent;

  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
  }
}
.footerItem svg {
  fill: transparent;
}

.enquiryCardWrapper {
  margin: 30px;
  background: var(--matterColorLight);

  @media (--viewportTabletDown) {
    margin: 30px 0;
  }
}

.enquiryCard {
  padding: 0 40px 40px;
  display: flex;
  flex-direction: row;
  column-gap: 30px;

  @media (--viewportTabletDown) {
    align-items: center;
    justify-content: center;
    padding: 20px;
    /* margin: 0 20px; */
  }
}

.enquiryCardTitle {
  padding: 40px;
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    padding-left: 20px;
  }
}

.enquiryCardRoot {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.formatWrapper {
  flex: 1;

  /* padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px; */
  @media (--viewportMiniDesktopDown) {
    display: none;
  }
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-bottom: 1px solid #000000;
  padding-bottom: 30px;
}

.searchWrapper {
  flex: 1;
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: sticky;
  top: 150px;
  background: #ffffff;
}

.categoryWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-bottom: 1px solid #000000;
  padding-bottom: 30px;
  max-height: 300px;
}

.locationWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 30px 0 35px;
}

.formatTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
}

.categoryBody {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: auto;
}

.formatBody {
  border-color: red;
}

.filterInputBody {
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.filterInputBody:focus {
  border-color: rgba(0, 0, 0, 0.1);
}

.locationAddress {
  position: relative;
  flex-shrink: 0;
}

.rootClass {
  position: unset;
}

.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;
}

.predictionsRoot li {
  color: var(--matterColorDark) !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 10px 29px !important;

  @media (--viewportxldesktop) {
    font-size: 14px;
  }
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}

/*checkbox*/

.styledCheckbox {
  position: absolute;
  opacity: 0;
}

.labelText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.styledCheckbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styledCheckbox + label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.styledCheckbox:checked + label:before {
  background: #0058fa;
  border-radius: 3px;
}

.styledCheckbox:checked + label:after {
  content: '';
  position: absolute;
  left: 4px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  border: 0;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
    4px -8px 0 white;
  transform: rotate(45deg);
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9;
}

.footerOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.layoutTopbar {
  z-index: 99;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.slider {
  display: flex;
  flex-direction: row;
  column-gap: 65px;
}

.locationWrapperTop {
  display: flex !important;
  flex-direction: row;
  column-gap: 15px;
  z-index: 1;
  align-items: center;
  flex: none;
  cursor: pointer;
}
.locationImgWrap {
  width: 75px;
  height: 75px;
  @media (--viewportLaptopDown) {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}
.locationImgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.locationTextWrap {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  @media (--viewportLaptopDown) {
    font-size: 17px;
    line-height: 21px;
  }
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 15px;
  }
}
.selected {
  color: #ffb81d;
}
.selectedEdu {
  color: #f75959;
}
.classHeader {
  display: flex;
  width: 100%;
  /* justify-content: space-around; */
  align-items: center;
  @media (--viewportMobile) {
    flex-direction: column;
  }
}
.filterMain {
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 20px;
  @media (--viewportMobile) {
    max-width: 420px;
    margin-top: 18px;
    /* max-height: 220px; */
  }
  @media (max-width: 375px) {
    max-width: 385px;
    /* max-height: 220px; */
    margin-left: 5px;
  }
  @media (max-width: 320px) {
    max-width: 335px;
    /* max-height: 220px; */
    margin-left: 35px;
  }
}
.dropDown {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
  margin: 28px 0 0 34px;
  @media (--viewportMobile) {
    margin: 0;
    flex-direction: row;
    align-items: center;
  }
}
.dropdownContent {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 30px;
  border-radius: 5px;
}
.timeContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.timeValue {
  font-size: 16px;
  line-height: 1.75;
  color: #696c74;
}
.applyBtn {
  border: none;
  border-radius: 10px;
  height: 50px;
  width: 100px;
  color: #ffffff;
  background: #0058fa;
  font-size: 14px;
  letter-spacing: 1.5px;
  margin: 20px auto 0;
}
.filterButton {
  background: var(--matterColorLight);
  border-radius: 40px;
  border: none;
  width: 115px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  @media (--viewportMobile) {
    height: 35px;
    width: 110px;
  }
}

.filterButton img {
  margin-right: 15px;
}

.filterButton:hover {
  cursor: pointer;
}
.timeDropdown {
  position: absolute;
  top: 60px;
  /* left: auto; */
  right: 0;
  z-index: 99;
  @media (--viewportMobile) {
    left: auto;
    right: unset;
  }
}

.switchContainer {
  display: flex;
  z-index: 1;
  overflow-x: auto;
  display: grid;
  gap: 5px;
  row-gap: 20px;
  /* padding: 20px; */
  padding: 20px 40px;
  margin-bottom: 10px;
  grid-template-columns: repeat(4, 1fr);
  background: linear-gradient(180deg, rgba(168, 168, 168, 0.17) 99.99%, rgba(1, 7, 15, 0.19) 100%);
  @media (max-width: 1024px) {
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    /* margin-left: 30px; */
  }
  @media (--viewportTabletDown) {
    display: grid;
    padding: 20px;
    gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    /* margin-left: 30px; */
  }
  @media (--viewportMobile) {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 5px;
  }
}
.switch {
  display: flex;
  z-index: 1;
  align-items: center;
  column-gap: 6px;
  height: 80px;
  margin: 0 8px;
  background: #a8a8a852;
  border: 1px solid #ffffff87;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0px 0px 5px #0005;
  @media (--viewportMobile) {
    column-gap: 5px;
    margin: unset;
    /* justify-content: space-between; */
    /* width: 46vw; */
    height: 60px;
    white-space: normal;
  }
}
.switch h4 {
  font-weight: 600;
  font-size: 14px;
  width: 50%;
  text-align: center;
  line-height: 20px;
  color: #000000;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 12px;
    font-weight: 500;
  }
}
.switchimage {
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: 10% 25%;
  filter: brightness(100%);
  border-radius: 5px 0px 0px 5px;
  @media (--viewportMobile) {
    display: block;
    height: 100%;
    border-radius: 5px;
    flex-shrink: 0;
  }
}
.switchActive {
  background: #fff;
  box-shadow: 3px 5px 10px #0005;
}
.switchActive h4 {
  color: #ffb81d;
}
/* .switchActive .switchimage {
  filter: brightness(100%);
} */

/* .switch [class*='react-switch-bg'] {
  width: 37px !important;
  height: 16px !important;
  display: none;
}
.switch [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  display: none !important;
  top: -2px !important;
}
.switchUnchecked [class*='react-switch-handle'] {
  left: -1px !important;
  background: #b5b5b5 !important;
}
.switchUnchecked [class*='react-switch-bg'] {
  background: #e2e1e1 !important;
}
.switchChecked [class*='react-switch-handle'] {
  left: 16px !important;
  background: #0058fa !important;
}
.switchChecked [class*='react-switch-bg'] {
  background: rgba(0, 88, 250, 0.2) !important;
} */
.contentLoader {
  display: flex;
  justify-content: center;
}
.testScroll {
  overflow: hidden;
}
.NoListingsFound {
  text-align: center;
  padding: 20px;
  margin-bottom: 80px;
}
.whatsappLinkHome a img {
  max-width: 90px;
  height: auto;
  border-radius: 30px;
  position: fixed;
  z-index: 100;
  top: 14px;
  right: 30px;
  @media (--viewportMobile) {
    right: 0;
    top: 80px;
  }
}
.homeMainWrapper {
  position: relative;
}
.homeMainWrapper .rightBottom {
  position: fixed;
  bottom: -15px;
  right: -15px;
  /* background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%); */
  /* width: calc(100vw - 75%); */
  /* height: calc(100vh - 50%); */
  z-index: 0;
  border-radius: 40px;
  @media (--viewportLaptopDown) {
  }
}
.homeMainWrapper .rightBottom .whiteDoteIcon {
  position: fixed;
  bottom: 25px;
  right: 25px;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.homeMainWrapper .rightBottom .blueDoteIcon {
  position: fixed;
  bottom: 10px;
  right: 37%;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.homeMainWrapper .rightBottom .leftcircle {
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  position: fixed;
  bottom: -15%;
  left: 30%;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.homeMainWrapper .rightBottom .circleMiddle {
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 50%;
  z-index: 0;
  width: 250px;
  height: 250px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.homeMainWrapper .newHomeMain {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  padding: 30px 40px;
  z-index: 9;
  /* @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  } */
  @media (--viewportTabletDown) {
    /* grid-template-columns: repeat(1, 1fr); */
    padding: 20px 15px;
    margin-bottom: 115px;
    
  }
  @media (max-width:375px){
    grid-template-columns: repeat(1,1fr);
  }
}

.premiumButton {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 10px 24px;
  margin-top: 6px;
  /* width: 160px; */
  margin-left: 13px;
  display: inline;
  white-space: nowrap;
  border-radius: 6px;
}
.switch_p {
  display: flex;
  align-items: center;
  border: 1px solid gray;
  padding: 0px 10px;
  margin-top: 3px;
  margin-bottom: 12px;
  width: min-content;
  border-radius: 10px;
  margin-left: 10px;
  gap: 10px;
  @media (--viewportMobile) {
    /* border: none; */
    justify-content: space-between;
    /* width: 100%; */
    margin: 3px 15px;
    margin-top: 15px;
  }
  @media (max-width: 425px) {
    margin: 3px 25px;
    margin-top: 15px;
  }
  @media (max-width: 320px) {
    margin: 3px 35px;
    margin-top: 15px;
  }
}
.switch_p h4 {
  white-space: nowrap;
  margin: 12px 0px;
}
.switch_p [class*='react-switch-bg'] {
  width: 41px !important;
  height: 26px !important;
}
.switch_p [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  top: 3px !important;
}
.switchUnchecked_p [class*='react-switch-handle'] {
  left: 2px !important;
  background: #000 !important;
}
.switchUnchecked_p [class*='react-switch-bg'] {
  background: #fff !important;
  border: 1px solid black;
}
.switchChecked_p [class*='react-switch-handle'] {
  left: -11px !important;
  background: #ffffff !important;
}
.switchChecked_p [class*='react-switch-bg'] {
  background: #000000 !important;
  border: 1px solid #fff;
}
.switchChecked_p .switchCalss {
  border: 1px solid #000;
}
.premiumContainer {
  margin-top: 10px;
}

/*Perfect Match section*/
.perfectMatchMain {
  margin: 35px 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 40px;
  @media (--viewportMobile) {
    padding: 0 15px;
    margin: 25px 0;
  }
}
.perfectMatchMain .perfectMatchHead {
  color: #000;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  margin: 0;
}
.perfectMatchMain .perfectMatchSection {
  @media (--viewportMobile) {
    overflow: auto;
  }
}
.perfectMatchMain .perfectMatchWrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportMobile) {
    display: flex;
    align-items: stretch;
    width: 1000px;
    overflow-x: auto;
    padding: 15px 5px;
    gap: 15px;
  }
}
.perfectMatchWrap .perfectMatchCard {
  border-radius: 15px;
  background: #fffafa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.perfectMatchCard .perfectMatchImgWrap {
  position: relative;
  margin-bottom: 30px;
}
.perfectMatchCard .perfectMatchImgWrap img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}
.perfectMatchCard .perfectMatchImgWrap .perfectMatchIcon {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d9;
  position: absolute;
  bottom: -30px;
  left: 25px;
}
.perfectMatchCard .perfectMatchImgWrap .perfectMatchIconActive {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffb81d;
  position: absolute;
  bottom: -30px;
  left: 25px;
}
.perfectMatchCard .perfectMatchImgWrap .perfectMatchIcon img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.perfectMatchCard .perfectMatchImgWrap .perfectMatchIconActive img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.perfectMatchCard .perfectMatchImgWrap .perfectMatchIcon.active {
  background: #ffb81d;
}
.perfectMatchIconActive {
  background: #ffb81d;
}
.perfectMatchCard .perfectMatchDetail {
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.perfectMatchDetail .perfectMatchDetailHead {
  color: #000;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0 0 5px;
}
.perfectMatchDetail .perfectMatchDetailText {
  margin: 0;
  color: #000;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
.showClasses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin: 0px 60px -40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  height: 52px;
  padding: 0 9px;
  /* width: calc(100% - 52px); */
  @media (max-width: 991px) {
    margin: 0px 0px -40px 20px;
  }
  @media (--viewportMobile) {
    margin: 0;
  }

  & h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: 0;
  }
}
.uncheckedShowClasses [class*='react-switch-bg'] {
  background: #888888 !important;
}
.checkedShowClasses [class*='react-switch-bg'] {
  background: #0058fa !important;
}
.facilitiesCardContainer {
  margin-bottom: 0;
  @media (--viewportMobile) {
    margin-bottom: 140px;
  }
}
