@import '../../marketplace.css';

.root {
  background-color: var(--matterColorBlue);
}
.educationalRoot {
  background: #fff;
}
.tennisRoot {
  background: #4889ff;
}

.topHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
  -webkit-transition: transform 250ms ease, opacity 200ms ease;
  transform-origin: 50% 0%;
  transition: 600ms;
  align-items: center;

  @media (--viewportTabletDown) {
    height: 109px;
    transition: height ease 0.6s;
  }
}

.hidelogoTopHeader {
  height: 70px;
}

.activeProbuddyLogo {
  padding: 25px 0 0 40px;
  height: 100%;
}

.activeProbuddyLogo img {
  max-width: 200px;
}

.topHeaderActive {
  height: 212px;
  align-items: flex-start;
  @media (--viewportTabletDown) {
    height: 175px;
  }
}
.topHeaderActive .probuddyLogo {
  padding: 25px 0;
  height: 100%;
  align-items: flex-start;
}

.middleSection {
  display: flex;
  flex-direction: column;
  /* flex: 2; */
  padding: 0;
  z-index: 10;
  padding-left: 20px;

  @media (--viewportTabletDown) {
    align-items: center;
    padding: 0;
    justify-content: center;
    height: 100%;
  }
}

.middleSectionActive {
  flex-direction: column;
  white-space: nowrap;
  display: flex;
  padding-top: 10px !important;

  @media (--viewportTabletDown) {
    width: 100%;
  }

  @media (--viewportLarge) {
    padding-left: 90px;
  }
}

.topFilterContainer {
  display: flex;
  gap: 15px;
  -webkit-transition: transform 250ms ease, opacity 100ms ease, visibility 0ms 50ms;
  transform: scale(1, 1) translateY(0);
  transform-origin: 50% 0%;

  @media (--viewportLaptopDown) {
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  @media (--viewportTabletDown) {
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: 16px;
  }
}

.topFilterContainerActive {
  transform: scale(2.857142857142857, 1.375) translateY(58px);
  -webkit-transition: transform 250ms ease, opacity 100ms ease;
  transform-origin: 50% 0%;
  opacity: 0;
  pointer-events: none;
  /* visibility: hidden; */
  will-change: transform, opacity;
  height: 0;
  overflow: hidden;
}

.bottomContainer {
  transform: scale(0.35, 0.7272727272727273) translateY(-100px);
  transition: transform 250ms ease, opacity 200ms ease 100ms, visibility 0ms 200ms;
  transform-origin: 50% 0%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  will-change: transform, opacity;
  overflow: hidden;
  height: 0;
}

.bottomContainerActive {
  transition: transform 250ms ease, opacity 200ms ease;
  transform: scale(1, 1) translateY(0);
  transform-origin: 50% 0%;
  opacity: unset;
  z-index: 51;
}

.topHeaderSearchContainer {
  font-size: 20px;
  width: 440px;
  height: 48px;
  background: var(--matterColorLight);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (--viewportxldesktop) {
    width: 350px;
  }

  @media (--viewportTabletDown) {
    width: 100%;
    height: 38px;
    max-width: 308px;
    border-radius: 20.4082px;
  }
}

.topHeaderSearchContainerEdu {
  border: 1px solid #d8d7d7;
}
.topHeaderSearchContainerTen {
  border: 1px solid #4889ff;
}

.topHeaderSearchContainerActive {
  width: 688px;
  /*height: 76px;*/
  height: 0;
  /*overflow: hidden;*/
  margin-top: 30px;
}

.outSideCloseCardActive {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 688px;

  @media (--viewportTabletDown) {
    height: 53px;
    margin: 0 auto;
    max-width: 90%;
    border-radius: 25.9176px;
  }

  @media (--viewportLaptopDown) {
    margin: 0 auto;
  }
}
.whatsappRatingLink{
  background-color: #fff;
  min-height: 48px;
  font-size: 16px;
  text-align: center;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 10px;
  cursor: pointer;
}
.searchAnyActivityLink,
.searchAnyRatingLink {
  width: 160px;
  border: none;
  pointer-events: fill;
  font-size: 16px;
  text-align: center;
  flex: 1;
  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 15px;
  }
}
.searchAnyActivityLink:hover,
.searchAnyRatingLink:hover {
  cursor: pointer;
}

.searchAnyRatingLink svg path,
.searchAnyRatingLink svg circle {
  stroke: #f75959;
  fill: transparent;
}

.searchAnyWhereLink,
.searchAnyActivityLink {
  padding: 15px 20px 15px 20px;

  @media (--viewportTabletDown) {
    padding: 0;
    width: 100%;
    flex: 1;
  }
}

.searchAnyRatingLink span {
  white-space: nowrap;
}

.searchAnyRatingLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 8px 15px 20px;
  min-width: 155px;

  @media (--viewportTabletDown) {
    min-width: unset;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;

    & img {
      width: 26px;
    }
  }
}

.imgWrap {
  position: relative;
  flex: 1;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* margin: 0 20px 0 20px; */

  @media (--viewportMiniDesktopDown) {
    /* flex: unset; */
    margin: 0;
  }
}

.imgWrap a {
  z-index: 99;
  cursor: pointer;
}

.bgImgIcon {
  background: url('../../assets/HomePage/HeaderTriangles.svg') no-repeat;
  height: 85px;
  background-size: contain;
  background-position: right 0% top 0%;
  position: absolute;
  top: 0;
  width: 250px;
  right: 0;
  transition: height ease 0.6s;
}

.hideImg {
  height: 0;
}

.sellerButton {
  background: var(--matterColorLight);
  border-radius: 40px;
  /* width: 162px; */
  /* height: 48px; */
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorBlue);
  border: none;
  z-index: 999;
  position: relative;
  padding: 11px 16px;
  cursor: pointer;
  @media (--viewportxldesktop) {
    padding: 13px 20px;
  }
}

.sellerButtonEdu {
  border: 1px solid #d8d7d7;
}
.sellerButtonTen {
  border: 1px solid #4889ff;
}

.filterButton {
  background: var(--matterColorLight);
  border-radius: 40px;
  margin-left: 20px;
  border: none;
  width: 115px;
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  @media (--viewportxldesktop) {
    display: none;
  }
}

.filterButtonEdu {
  border: 1px solid #d8d7d7;
}
.filterButtonTen {
  border: 1px solid #4889ff;
}

.filterButton img {
  margin-right: 15px;
}

.filterButton:hover {
  cursor: pointer;
}

.probuddyLogo {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 376px;
  cursor: pointer;
  padding: 0;

  @media (--viewportMiniDesktopDown) {
    width: 150px;
    padding: 0 0 0 25px;
    min-width: unset;
  }
}
.probuddyLogo img {
  max-width: 200px;
  height: auto;
  @media (--viewportxldesktop) {
    max-width: 150px;
  }
}

.divider {
  height: 22.71px;
  width: 1px;
  background-color: #d8d7d7;
}

/* Tab */

.tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: transparent;
  color: var(--matterColorLight);
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  justify-content: flex-start;
  gap: 40px;

  @media (--viewportMobile) {
    font-size: 18px;
    padding-left: 0;
    padding-right: 0;
    overflow-x: scroll;
    gap: 15px;
  }

  @media (--viewportTabletDown) {
    gap: 20px;
    padding: 0px 15px 0 0px;
  }
}

.tabsEdu {
  color: #f75959;
}
.tabsTen {
  color: #fff;
}

.tabsListContainer {
  gap: 20px;
  display: flex;
  overflow-y: auto;
  /*width: 100%;*/
  padding-bottom: 10px;
  justify-content: start;

  @media (--viewportMobile) {
    justify-content: flex-start;
  }

  @media (--viewportTabletDown) {
    padding-bottom: 0;
  }

  @media (--viewportLaptopDown) {
    padding: 0;
  }
}

.activeTab {
  margin: 10px 0;
  padding: 15px 0 13.5px 0;
  color: #ffb81d;
  font-size: 18px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  border-bottom: 3px solid #ffb81d;

  @media (--viewportTabletDown) {
    margin: 0;
    font-size: 12.2664px;
    line-height: 16px;
    border-bottom: 1.36293px solid #ffb81d;
  }

  @media (--viewportLaptopDown) {
    margin: 0;
    padding: 11px 0;
    font-size: 12.2664px;
  }
}

.inactiveTab {
  font-size: 18px;
  padding: 15px 0;
  margin: 10px 0;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  position: relative;
  border-bottom: 2px solid transparent;

  @media (--viewportLaptopDown) {
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
    color: var(--matterColorLight);
    margin: 0;
    padding: 11px 0;
  }

  @media (--viewportTabletDown) {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    padding: 11px 0;
  }
}
.inactiveTab:hover {
  opacity: 1;
  color: #ffb81d;
}
.inactiveTab:hover:after {
  transform: scaleX(1);
}

.inactiveTab:after {
  content: '';
  height: 2px;
  inset-inline: 0;
  position: absolute;
  bottom: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transition: transform 0.2s ease;
  width: 100%;
  -webkit-transition: transform 0.2s ease;
  background-color: #ffb81d;
}

/* Search */
.searchLink {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 76px;
  margin-right: 0;
  min-width: 200px;

  @media (--viewportLarge) {
    padding-left: 0;
  }

  @media (--viewportLaptopDown) {
    flex-direction: column;
    gap: 20px;
    height: max-content;
  }
}

.headerSearchWithLeftPadding {
  border-radius: 50px;
  flex: 1;
  height: 100%;
  display: block;
  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }

  @media (--viewportLaptopDown) {
    background: var(--matterColorLight);
    box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
    border-radius: 15px;
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 29px;

    &:hover {
      background-color: var(--matterColorLight);
    }
  }

  @media (--viewportMobile) {
    width: 100%;
  }
}
/* padding: 0 29px; */
.headerSearchWithLeftPadding:hover {
  /* background-color: #d8d7d7; */
  flex-direction: column;
}

.headerDivider {
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  margin: 0 50px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);

  @media (--viewportTabletDown) {
    margin: 0 5%;
  }
}
.headerDividerEdu {
  height: 1px;
  background-color: #d8d7d7;
}
.headerDividerTen {
  height: 1px;
  background-color: #4889ff;
}
.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin: 20px 0 26px 0;
  color: #383838;

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
}

.filterPopUp {
  position: unset;

  @media (--viewportLaptopDown) {
    position: relative;
  }
}

.filterContainer {
  position: absolute;
  min-width: 553px;
  background-color: var(--matterColorLight);
  border-radius: 17px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: calc(50 + 1);
  margin-top: calc(72px - 53px + 3px);
  right: -140px;
  top: 55px;
  min-height: 120px;
  padding: 30px 30px 30px 30px;

  @media (--viewportLaptopDown) {
    min-width: 100%;
    position: fixed;
    width: 100%;
    top: 109px;
    height: 100vh;
    right: 0;
  }
}

.filterHeader {
  display: flex;
  align-items: center;

  @media (--viewportLaptopDown) {
    margin-bottom: 18px;
  }
}

.filterDivider {
  border: 1px solid rgba(200, 200, 200, 0.3);
  margin-bottom: 20px;
}

.filterText {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #383838;
  margin: 0 auto;
}

.maxBtn {
  height: 50px;
  width: 100%;
  border: 1px solid #fe6436;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  padding: 1px 6px;
}

.minMaxInput {
  padding: 0;
  border: none;
}
.minMaxInput::placeholder {
  font-size: 14px;
  line-height: 15px;
  color: var(--matterColorDark);
  font-weight: 400;
}

.inputWrap {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: var(--matterColorDark);
  font-weight: 400;
}
.btnLabel {
  height: min-content;
  width: max-content;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;

  @media (--viewportLaptopDown) {
    color: #383838;
  }
}

.btnValue {
  height: min-content;
  width: max-content;

  @media (--viewportLaptopDown) {
    line-height: 18px;
  }
}

.headerTabsContainer {
  position: relative;
  max-width: 603px;

  @media (--viewportTabletDown) {
    max-width: 100%;
  }

  @media (--viewportLaptopDown) {
    margin: 0 auto;
  }
}
.rootChild {
  position: relative;
  transition: 1s;
}

.overlayHeader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  /* background-color: var(--matterColorDark); */
  z-index: 1;
}

.tabPopup {
  /* position: relative; */
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  width: 343px;
  height: 76px;
  left: 597px;
  top: 107px;
  background: var(--matterColorLight);
  border-radius: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportLaptopDown) {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0;
    border-radius: 14px 14px 0px 0px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 70px;
    overflow-y: auto;
  }

  @media (--viewportTabletDown) {
    min-width: 237px;
  }
}

.tabPopup span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;

  @media (--viewportLaptopDown) {
    font-weight: 500;
    font-size: 12.4373px;
    line-height: 16px;
    color: #000000;
  }
}

.tabWrapper {
  position: relative;
  z-index: 99;

  @media (--viewportTabletDown) {
    margin-bottom: 30px;
  }
}
.tabWrapper > div {
  margin: 0 auto;
  padding: 10px 10px 0 10px;
}

.topCategoryWrapper {
  position: absolute;
  bottom: -85px;
  left: 20%;

  @media (--viewportLaptopDown) {
    /* bottom: -40px; */
    /* left: 30%; */
    z-index: 999999;
    top: 100px;
    width: 100%;
    left: 0;
    height: calc(100vh - 170px);
  }

  /* @media (--viewportMobile) {
    left: 20%;
  } */
}

.HeaderSearchComponentRoot {
  width: 100%;
}

.searchRatingCardActive {
  width: 100%;
  height: 100%;
  position: relative;
}

.searchComponent {
  font-size: 20px;
  width: 420px;
  height: 48px;
  background: var(--matterColorLight);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (--viewportTabletDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    height: 38px;
  }
}

.searchComponentActive {
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 688px;

  @media (--viewportLaptopDown) {
    margin: 0 auto;
  }
}

.filterSearchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.searchTitleText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDark);
  padding: 16px 0px 15px 29px;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    padding: 11px 5px 12px 15px;
  }
}

.searchIconLink {
  padding: 0 8px 0 20px;

  @media (--viewportMobile) {
    padding: 6px 5px;

    & img {
      width: 26px;
      height: 26px;
    }
  }
}

.filterRangeSlider {
  /*height: 20%;*/
  width: 90%;
  margin: 0 5%;
}

.filterRangeSlider [class*='smallVisibleHandle'] {
  background-image: url('../../assets/HomePage/line.svg');
  background: var(--matterColorLight);
  border: 1px solid #fe6436;
  background-position: top 48% left 47.1%;
  background-size: 44%;
  width: 22px;
  height: 22px;
}

.filterRangeSlider [class*='slimRange'] {
  background-color: #fe6436;
  height: 2px;
}

.filterRangeSlider [class*='slimTrack'] {
  background-color: #c8c8c8;
  height: 2px;
}

.filterAvgText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
  margin-bottom: 20px;

  @media (--viewportLaptopDown) {
    margin: 23px 0;
  }
}

.applyBtn {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  background-color: #fe6436;
  color: var(--matterColorLight);
  border-radius: 7px;
  border: 0;
  min-width: 146px;
  height: 44px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileTopHeader {
  background: var(--matterColorBlue);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 20px 10px 20px;
  position: relative;
}

.mobileTopHeaderEdu {
  background: #f75959;
}
.mobileTopHeaderTen {
  background: #4889ff;
}

.topHeaderLogoHide {
  padding: 10px 20px 10px 20px;
}

.activeSearchCard {
  padding: 20px 0;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.logoContainer img {
  height: 32px;
  transition: height ease 0.8s;
}
.logoHideOnScrolling {
  margin-bottom: 0;
}
.logoHideOnScrolling img {
  height: 0;
}

.hideLogo {
  display: none;
}

.mobileTopHeaderSearchContainer {
  background: var(--matterColorLight);
  border-radius: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 49px;
  width: 375px;
  cursor: pointer;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.modalSearchBar {
  top: 0;
  height: 100%;
  background: var(--matterColorLight);
  width: 100%;
  height: 95vh;
}

.tabModalSearchbar {
  top: 104px;
  @media (--viewportMobile) {
    top: 57px;
  }
}

.modalSearchBar [class*='Modal_container_'] {
  background: #f5f5f5;
  padding: 20px 14px;
  position: relative;

  @media (--viewportLaptopDown) {
    height: calc(100vh - 192px);
    min-height: unset;
    min-width: 100%;
    overflow: auto;
  }
  @media (--viewportTabletDown) {
    height: 100%;
    padding-bottom: 50px;
  }
}

.tabModalSearchbar [class*='Modal_container_'] {
  @media (--viewportLaptopDown) {
    background: var(--matterColorBlue);
    padding: 0;
    height: calc(100vh - 127px);
  }
}

.tabModalSearchbar [class*='Modal_content_'] {
  height: 100%;
}

.modalSearchBar [class*='scrollLayer'] {
  min-height: unset;
  height: 100%;
  overflow: unset;
}
.mobileSearchWrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  @media (--viewportTabletDown) {
    margin-left: 10px;
    gap: 15px;
  }
  @media (--viewportMobile) {
    margin-left: 10px;
    gap: 15px;
  }
  @media (max-width: 375px) {
    margin-left: 0;
    gap: 10px;
  }
  @media (max-width: 320px) {
    margin-left: 0;
    gap: 10px;
  }
}

.toDoText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: var(--matterColorDark);
}

.searchLinkContainer {
  display: flex;
  font-weight: 450;
  font-size: 12px;
  line-height: 13px;
  color: #8b8b8b;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.mobileTabContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: end;
  width: 100%;
  background-color: var(--matterColorBlue);
  z-index: 999999;
  margin-right: 30px;
}

.hideZindex {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: center;
  width: 100%;
  background-color: var(--matterColorBlue);
}

.closeIcon {
  padding: 11px 0;
}

.tabOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  /* background: var(--matterColorDark); */
}

.hideTabOverlay {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  background: none;
}

.rootClassName {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  position: relative;
  z-index: 9;
}
.showActivityContainer {
  position: absolute;
  background-color: var(--matterColorLight);
  border-radius: 17px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: 999;
  margin-top: calc(72px - 53px + 3px);
  min-width: 290px;
  /* min-width: 100%;
  min-height: 500px;
  height: 320px; */
  padding: 10px;
  right: 0;

  @media (--viewportTabletDown) {
    min-width: 100%;
    min-height: unset;
    height: 65vh;
    left: 0;
    padding: 20px 12px;
    margin: 12px 0 0 0;
  }

  @media (--viewportLaptopDown) {
    position: unset;
    margin-top: 15px;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
}
.becomeHostMember {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.becomeHostMemberWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  text-decoration: none !important;
}
.becomeHostMemberImg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 18px;
}
.becomeHostMemberText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorBlue);
  text-decoration: none;
}
.profileMenuRoot {
  height: auto;
  margin-left: 8px;
  z-index: 99;
}
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  /* height: 100%; */
  /* margin-left: 12px; */
  padding: 0 0 0 20px;
  /* margin: 18px 0; */
  /* flex-direction: column-reverse; */
}
.profileMenuLabel > span {
  width: fit-content;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #233045;
  /* font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: var(--marketplaceColorDarker); */
  /* height: 31px; */
  @media (max-width: 1200px) {
    & > span {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
.profileMenuLabel:active {
  border-bottom: 0;
}

.profileMenuIsOpen:hover {
  border-bottom: 0;
}

.activeUser {
  height: 62px;
  width: 62px;
  position: relative;
  margin-left: 7px;
  display: flex;
  align-items: center;
  @media (max-width: 1440px) {
    height: 52px;
    width: 52px;
  }
}
.avatar {
  height: 44px;
  width: 44px;
}
.active {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #6cef3e;
  border: 3px solid var(--matterColorLight);
  top: 65%;
  left: 0;
  position: absolute;
}
.profileMenuContent {
  min-width: 200px !important;
  padding-top: 20px;
  left: auto !important;
  right: 0;
}
.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  @media (--viewportMedium) {
    margin: 0;
  }
}
.profileSettingsLink:hover,
.yourListingsLink:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}
.profileSettingsLink:hover .menuItemBorder,
.yourListingsLink:hover .menuItemBorder {
  width: 6px;
  background-color: var(--marketplaceColor);
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH4FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 15px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);
  @media (--viewportMedium) {
    /* margin-top: 20px; */
    margin-bottom: 0;
  }
}
.logoutButton:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}
.logoutButton:hover .menuItemBorder {
  height: 100%;
  width: 6px;
  top: 0;
  background-color: var(--marketplaceColor);
}

.loginIcon {
  background: #ffffff;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d7d7;
}
.loginIcon svg {
  height: 25px;
  width: 25px;
}

.switchToEdu {
  width: 198px;
  height: 48px;
  background: #fe6436;
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding: 0 15px;
  gap: 10px;
}
.switchToTen {
  width: 198px;
  height: 48px;
  color: #ffffff;
  background: #ffb91d;
  border: 1px solid #d8d7d7;
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding: 0 15px;
  gap: 10px;
}
.switchToSport {
  width: 198px;
  height: 48px;
  color: #ffffff;
  background: #0058fa;
  border: 1px solid #d8d7d7;
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding: 0 15px;
  gap: 10px;
}
.switchToSport img,
.switchToEdu img,
.switchToTen img {
  height: 18px;
}
.switchToSport:hover,
.switchToEdu:hover,
.switchToTen:hover {
  text-decoration: none;
}
.btnLogin {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  background-color: #fff;
  color: #0058fa;
  font-weight: 400;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 150px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}
a:hover {
  border: none;
  text-decoration: none !important;
  text-underline-offset: none;
  cursor: none;
}
.playerMemberButton {
  display: flex;
  gap: 10px;
}

.whatsappIcon{
  width: 35px;
  height: 35px;
}

.mobileTopHeaderMainSearchContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
}