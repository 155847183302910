@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  /* margin-top: 30px; */

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    /* margin-top: 24px; */
  }
}

.formRowStartAndEnd {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  row-gap: 30px;
}

.field {
  /* display: flex; */
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

/* .fieldDateInput {
  flex-grow: 1;
} */

.fieldSelect {
  flex-grow: 1;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportMobile) {
    margin-left: 0;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 4px;
    left: 24px;
    background-image: url('/static/images/Icons/clockIcon.png');
    background-size: 19px 17px;
    background-position-y: 4px;
    width: 19px;
    height: 24px;

    @media (--viewportMobile) {
      bottom: 3px;
      left: 0;
    }
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 11px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 19px 17px;

    @media (--viewportMobile) {
      bottom: 3px;
      left: 0;
    }
  }

  @media (--viewportMedium) {
    &::after {
      left: 0;
      bottom: 11px;
    }
  }
}

.select {
  position: relative;
  padding-left: 34px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: #0058fa;
  fill: #0058fa;
}

.rootPreviousMonthIconWrapper {
  background-color: #f5f7fc;
  padding: 0.3rem 0.7rem;
  border-radius: 50%;
}

.rootNextMonthIconWrapper {
  background-color: #f5f7fc;
  padding: 0.3rem 0.7rem;
  border-radius: 50%;
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code. 
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.startDate {
  width: 100%;
}

.endDateHidden {
  display: none;
}

.unavailable {
  /* padding-left: 25px; */
  pointer-events: none;
  color: var(--matterColorAnti);
}

/**
CSS for hiding the end date above
**/

.bookingWrapper {
  position: relative;
  flex-grow: 1;
}

.bookingLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  color: var(--matterColorDark);
  position: absolute;
  top: -7px;
  left: 15px;
  background: var(--matterColorLight);
  z-index: 1;
  padding: 0 5px;
  text-transform: uppercase;
  width: unset !important;
}

.bookingInputWrapper {
  position: relative;

  & input {
    background: var(--matterColorLight);
    border: 1px solid #56515180;
    border-bottom-width: 1px !important;
    border-color: #0000001a !important;
    padding: 16px 20px !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(86, 81, 81, 0.5);
    padding: 16px 45px 16px 20px;

    &:hover,
    &:focus {
      border-color: var(--matterColorBlue) !important;
    }
  }
}

.option {
  &:hover,
  &:focus {
    background-color: #cfdffe !important;
    color: #0d60fa !important;
  }
}
.spinner {
  display: block;
  margin: 10px auto;
}
