@import '../../marketplace.css';

.vendorListingsMain {
  min-height: 100vh;
  padding: 10px 0 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.vendorBgImage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.vendorBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.2;
}
.vendorImageDote {
  position: absolute;
  top: 30%;
  right: -30px;
  @media (--viewportTabletDown) {
    width: 70px;
    right: -10px;
  }
}
.circle1 {
  position: absolute;
  top: 20%;
  left: -120px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  @media (--viewportTabletDown) {
    top: 10%;
    left: -50px;
    width: 100px;
    height: 100px;
  }
}
.circle2 {
  position: absolute;
  top: 60%;
  left: 60px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  @media (--viewportTabletDown) {
    left: 20px;
    width: 55px;
    height: 55px;
  }
}
.circle3 {
  position: absolute;
  top: 70%;
  left: 190px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  @media (--viewportTabletDown) {
    width: 150px;
    height: 150px;
    left: 100px;
    top: 60%;
  }
}
.circle4 {
  position: absolute;
  top: 20%;
  right: 130px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  @media (--viewportTabletDown) {
    display: none;
  }
}
.vendorListingsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  margin-top: 70px;
  z-index: 2;
}
.authorDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.authorAvatar {
  width: 180px;
  height: 180px;
  @media (--viewportTabletDown) {
    width: 140px;
    height: 140px;
  }
}
.authorName {
  font-weight: 400;
  font-size: 31px;
  line-height: 39px;
  text-align: center;
  color: var(--matterColorDark);
  margin: 25px 0 0;
  @media (--viewportTabletDown) {
    font-size: 24px;
    line-height: 34px;
  }
}
.authorCompanyName {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: var(--matterColorDark);
  opacity: 0.79;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 10px 0;
}
.vendorCatagoryWrapper {
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 0 25px;
  }
}
.VendorShowListingsWrapper {
  @media (--viewportTabletDown) {
    padding: 0 25px;
  }
}
.vendorListingsButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 60px;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.vendorListingsButton {
  border: 1px solid var(--matterColorDark);
  filter: drop-shadow(0px 4.04979px 4.04979px rgba(0, 0, 0, 0.25));
  border-radius: 40px;
  width: 685px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: var(--matterColorDark);
  padding: 18px;
  text-transform: capitalize;
  cursor: pointer;

  @media (--viewportTabletDown) {
    width: 100%;
  }

  /* &:hover {
    cursor: pointer;
    background: white;
    color: black;
  } */
}
.noVendorListings {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: var(--matterColorDark);
  padding: 18px;
  text-transform: capitalize;
}
.probuddyLogo {
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 376px;
  cursor: pointer;
  padding: 0;
  margin-top: 30px;

  @media (--viewportMiniDesktopDown) {
    /* width: 200px; */
    /* padding: 0 0 0 45px; */
    min-width: unset;
  }

  & img {
    max-width: 100%;
    height: auto;
  }
}
.headerRoot {
  width: 100%;
  height: 127px;
  display: flex;
  align-items: center;
  background-color: var(--matterColorBlue);
}
.headerRootEducation {
  background-color: #f77d7d;
}
.headerRootMobile {
  height: 60px;
}
.bgImgIconMobile {
  width: 180px !important;
}
.bgImgIcon {
  background: url('../../assets/HomePage/HeaderTriangles.svg') no-repeat;
  height: 85px;
  background-size: contain;
  background-position: right 0% top 0%;
  position: absolute;
  top: 0;
  width: 250px;
  right: 0;
  transition: height ease 0.6s;
}
.hideImg {
  height: 0;
}
.iconWrap {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  cursor: pointer;
}
.iconWrap svg {
  height: 20px;
  width: 20px;
}
.serviceCard {
  display: grid;
  padding: 30px;
  width: 80%;
  margin: 0px auto;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
  }
  @media (--viewportTabletDown) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 25px;
  }
  @media (--viewportMobile) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 25px;
  }
}
.sreviceCardWrap {
  border-radius: 15px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  background: #2e2b3c;
  box-shadow: 0px 0px 41.76088px 5.22011px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 20px;
  margin-top: 40px;
  @media (--viewportTabletDown) {
    padding: 25px;
  }
}
.serviceImage {
  margin-top: -60px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  /* padding: 0px 15px; */
}
.serviceText {
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 40px 0;
  @media (--viewportTabletDown) {
    margin: 20px 0;
    font-size: 26px;
  }
}
.btnView {
  background: linear-gradient(90deg, #0058fa, #9d7aff);
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  border: 0;
  padding: 9px 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  @media (--viewportTabletDown) {
    font-size: 20px;
    padding: 10px 35px;
  }
}
a {
  text-decoration: none;
  text-align: center;
}
.cardWrapper {
  align-items: center;
}
