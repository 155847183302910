@import '../../marketplace.css';

* {
  -webkit-overflow-scrolling: touch;
}

:root {
  --promotedListingCardSizeDesktop: 189px;
  --promotedListingCardSizeMobile: 150px;
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */
  height: 200px;
  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
    height: 229px;
  }
  object-fit: cover;
}

.popularImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popularImageHover {
  animation: ZoomIn 2.15s linear infinite;
}

@keyframes ZoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
.sliderImage {
  min-height: 300px;
}

.linkText {
  width: 100%;
}

.popularServices {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  display: flex;
  padding-left: 25px;

  @media (--viewportLarge) {
    justify-content: center;
    padding-left: unset;
    overflow: hidden;
  }
}

.promotedListingContainer {
}
.promotedListingsWrapper {
  display: flex;
  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

.popular {
  background-color: var(--matterColorLight);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  text-align: center;
  padding-bottom: 0;
  height: fit-content;
  width: var(--promotedListingCardSizeDesktop);
  position: relative;
  border-radius: 10px;
  margin: 10px 11px;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.047);
  font-family: HelveticaNeue, 'Open Sans', sans-serif;
  cursor: pointer;
  /* &:hover {
    opacity: 0.8;
  } */
  @media (--viewportMobile) {
    width: 150px;
    margin: 10px 5px;
  }
}

.popular a,
.popular span,
.popular p,
.popular div {
  font-family: HelveticaNeue, 'Open Sans', sans-serif;
}

.title {
  /* @apply --marketplaceH1FontStyles; */
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 50px 0 20px;
  text-transform: capitalize;
  @media (--viewportTabletDown) {
    margin: 25px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }
}

.titleHighlight span {
  color: var(--matterColorLightBlue) !important;
}

.mainWrapper {
  flex: 1 1 auto;
  margin: 8px 12px;

  @media (--viewportLarge) {
    height: unset;
  }
}

.avatarWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.avatarImg {
  height: 40px;
  flex: 0 0 40px;
  border-radius: 50%;
  margin-right: 10px;
  @media (--viewportMobile) {
    display: none;
  }
}

.headline {
  display: -webkit-box;
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 8px;
  max-height: 42px;
  font-size: 14px;
  overflow: hidden;
  width: 125px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  white-space: break-spaces;
  word-wrap: break-word;
  font-weight: bold;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
    margin: 0;
    min-height: 32px;
  }
  @media (--viewportMobile) {
    min-height: 38px;
  }
}

.listingLink {
  text-decoration: none !important;
  font-size: 16px !important;
}

.description {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 11px;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  font-weight: normal;
  text-align: left;
  line-height: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--matterColorDimmedText);
}

.favourite {
  margin-top: 10px;
  font-size: 12px;
  text-align: right;
  color: #30c7be;
  @media (--viewportMobile) {
    display: none;
  }
}

.favourite a {
  display: flex;
  justify-content: flex-end;
  color: #30c7be;
  font-size: 16px;
  font-weight: bold;
}

.favoriteIcon {
  & > svg:nth-child(1) {
    width: 20px;
    stroke-width: 10px;
  }
  & > svg:nth-child(2) {
    width: 9px;
    left: 18px;
    top: -4px;
  }
}

.name {
  font-weight: bold;
  font-size: 13px !important;
  line-height: 16px !important;
  color: var(--marketplaceColor);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  @media (--viewportLarge) {
  }
}

.star_Wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.rating {
  color: #e82953;
  font-size: 10px;
  font-weight: 550;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.proVerified {
  font-size: 10px;
  font-weight: 550;
  @media (--viewportLarge) {
    font-size: 14px;
  }
}
.reviewCount {
  font-size: 12px;
  margin-left: 5px;
  color: #707070;
}

.startingAt span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.startingAt {
  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 8px 0 0 0;
  padding: 5px 15px;
  font-size: 10px;
  text-align: left;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorDarkest);
}

.showAll {
  font-size: 15px;
  color: var(--marketplaceColor);
}

.subTitle span {
  font-size: 16px !important;
  color: var(--matterColorDarkBlue);
  font-weight: bold;
}

.header {
  margin: 15px 25px 25px;
}

.newheader {
  @media (--viewportMobile) {
    margin: 0;
    padding: 0;
  }
}

.subTitle {
  margin: 0px 25px 20px 25px !important;
}

.buttonWrap {
  margin: 30px 24px 0;
}

.levelContainer div {
  padding: 0px;
}

.levelContainer {
  margin-left: auto;
  @media (--viewportMobile) {
    margin: unset;
  }
}

.iconWhatsapp {
  text-align: right;
  & svg {
    width: 25px;
    height: 25px;
  }
}

.descriptionContainer {
  display: flex;
}

.PromotedListingLength {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorDimmedText);
}

.whatsappSection {
  display: flex;
  column-gap: 8px;
  margin: 11px 0 0 0;
  @media (--viewportMobile) {
    width: 100%;
  }
  & .iconWhatsapp {
    width: 21px;
    height: 21px;
  }
}

.scrollController {
  column-gap: 30px;
  overflow-x: auto;
  padding: 30px 0;
  @media (--viewportTabletDown) {
    column-gap: 20px;
    padding: 25px 0 25px 20px;
  }
}

.productsMobileWrapper {
  @media (--viewportTabletDown) {
    background: #fffdfd;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 15px;
    margin: 15px;
  }
}

.autorListingWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.productsButton {
  width: 185px;
  min-height: 46px;
  background: var(--matterColorBlue);
  border-radius: 3.31824px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  margin: 25px auto 0;
  &:hover {
    background: var(--matterColorBlue);
    box-shadow: none;
  }
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    color: var(--matterColorBlue);
    min-height: 20px;

    &:hover {
      background: var(--matterColorLight);
      box-shadow: none;
    }
  }
}