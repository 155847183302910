.floatingTopBar {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 99;
  @media (--viewportMobile) {
    gap: 0;
  }
}
.floatingTopBar .floatingWrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid transparent;
  height: 100%;
  width: 100%;
}
.floatingTopBar .floatingTop {
  display: flex;
  align-items: center;
  /* flex: 1; */
  width: 60%;
  @media (--viewportMobile) {
    flex: 3;
    width: 100%;
  }
}
.floatingTopBar .floatingLink {
  /* background: #ffffff; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  @media (--viewportTabletDown) {
    font-size: 14px;
  }
  @media (--viewportMobile) {
    font-size: 12px;
  }
}

.wrapperInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  /* @media (--viewportMobile) {
    gap: 12px;
    display: none;
  } */
}
.wrapperInput.floatingInput {
  position: fixed;
  flex-direction: column;
  background: linear-gradient(180deg, #0058fa 26.39%, rgba(45, 104, 213, 0.43) 100%);
  box-shadow: -4px 4px 11px 2px #0005;
  border-radius: 25px;
  right: 0;
  height: 170px;
  padding: 20px;
  @media (max-width: 991px) {
    height: 140px;
    padding: 15px;
    gap: 5px;
  }
  @media (--viewportMiniDesktopDown) {
    height: 150px;
    padding: 15px;
  }
  @media (--viewportMobile) {
    left: 50%;
    transform: translatex(-50%);
    bottom: 70px;
    width: 95%;
    height: 120px;
    display: none;
    /* box-shadow: 0 -2px 5px 2px #0000002b; */
  }
}
.wrapperInput.floatingInputEdu {
  background: linear-gradient(180deg, #fe575b 26.39%, rgb(254 87 91 / 43%) 100%);
}
.wrapperInputHead {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: #ffffff;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 25px;
  }
  @media (--viewportMiniDesktopDown) {
    font-size: 26px;
  }
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 30px;
  }
}
.wrapperInputWrap {
  display: flex;
  align-items: center;
  gap: 18px;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px 15px;

  @media (--viewportMobile) {
    gap: 12px;
    /* display: none; */
  }
}
.wrapperInputWrap input {
  border: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #a8a8a8;
  @media (--viewportMiniDesktopDown) {
    font-size: 16px;
  }
  @media (--viewportTabletDown) {
    font-size: 12px;
    padding: 0 10px;
    color: #000000;
    font-weight: 600;
  }
}
.wrapperInputWrap .btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 0 10px;
  width: 145px;
  height: 40px;
  border-radius: 6px;
  @media (--viewportMiniDesktopDown) {
    width: 115px;
  }
  @media (--viewportTabletDown) {
    font-size: 12px;
    width: 70%;
  }
}
.inputWrap {
  width: 260px;
  display: flex;
  flex-direction: column;
  padding: 0 0;
  @media (--viewportMiniDesktopDown) {
    width: 200px;
  }
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.inputWrap .inputLabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  padding: 0;
  @media (--viewportMiniDesktopDown) {
    font-size: 18px;
  }
  @media (--viewportTabletDown) {
    display: none;
  }
}
.quoteWrapper .inputWrapper .btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  padding: 0 24px;
  width: 185px;
  line-height: 40px;
  border-radius: 6px;
  @media (--viewportMobile) {
    padding: 10px 2px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  }
}
.quoteWrapper .inputWrapper .btnQuote:active {
  background: transparent;
  border: 1px solid #0058fa;
  color: #0058fa;
}
/* new floating bar */
.newFloatingMain {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* padding: 10px 15px; */
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  display: none;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translatex(-50%);
  bottom: 70px;
  width: 98%;
  @media (--viewportTabletDown) {
    display: none;
  }
  @media (--viewportMobile) {
    display: flex;
    gap: 6px;
  }
  @media (max-width: 320px) {
    gap: 4px;
  }
}
.newFloatingMainEdu {
  background: #fff;
}
.newFloatingMain .newFloatingImage {
  width: 70px;
  height: 50px;
}
.newFloatingMain .newFloatingImage img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.newFloatingMain .newFloatingText {
  color: black;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  @media (--viewportMobile) {
    font-size: 15px;
  }
  @media (max-width: 320px) {
    font-size: 13px;
  }
}
.newFloatingMain .newFloatingButton {
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  margin-right: 15px;
  white-space: nowrap;
  border: 0;
  background: linear-gradient(180deg, #ffb81d 0%, #fe6436 100%);
  color: #fff;
  @media (--viewportMobile) {
    padding: 5px 10px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
}
