@import '../../marketplace.css';

.classCard {
  width: 100%;
  margin-bottom: 30px;
  text-decoration: none !important;

  @media (--viewportMobile) {
    margin-bottom: 25px;
  }
}

.namedLinkClass {
  &:hover {
    text-decoration: none;
  }
}

.mainCardWrapper {
  padding: 30px 15px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  width: 100%;
  border-radius: 5px;
  padding: 20px;
  transform: translateY(0px);
  transition: all 0.2s;
  transition-duration: 300ms;
  cursor: pointer;

  @media (--viewportMiniDesktopDown) {
    border: none;
  }

  @media (--viewportTabletDown) {
    padding: unset;
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0 0px 12px 0 rgb(0 0 0 / 30%);
    -webkit-transform: translateY(-5px);
    transform: translateY(-3px);
    transition: all 0.2s;
  }
}
.mainCardSection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (--viewportMiniDesktopDown) {
    flex-direction: column;
    row-gap: 1rem;
  }
  @media (--viewportTabletDown) {
    row-gap: 15px;
  }
}

.classImage {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  flex: 1;

  @media (--viewportMobile) {
    width: 170px;
    height: 125px;
  }
}

.classImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cardDetailWrapper {
  padding: 10px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 0px;
  }
}

.cardRatingWrapper {
  padding: 10px;

  @media (--viewportMobile) {
    padding: 0px;
    width: 30%;
  }
}

.cardPriceWrapper {
  padding: 10px;

  @media (--viewportMobile) {
    padding: 0px;
    width: 30%;
  }
}

.courseImageWrapper {
  display: flex;
  justify-content: flex-start;
  column-gap: 30px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    width: 100%;
    justify-content: flex-start;
    column-gap: 10px;
  }
}

.authorInfoWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (--viewportMiniDesktopDown) {
    justify-content: space-between;
  }
}

.descriptionWrapper {
  /* width: 100%; */
  display: flex;
  padding: 5px;
  justify-content: space-evenly;
  /* border-left: 1px solid #0b150a1a; */
  padding-left: 30px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    border-left: unset;
    padding-left: 0;
  }
}

.bookingWrapper {
  align-items: center;
  padding: 9px;
  /* border-left: 1px solid #0b150a1a; */
  padding-left: 30px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    border-left: unset;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}

.cardDetailTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--matterColorBlue);
  text-transform: capitalize;
  margin: 0 0 13px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }
}

.cardDescription {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #0b150a;
  text-transform: capitalize;
  margin: 0 0 13px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
  }
}

.cardUsername {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  margin: 0px 0 13px;
  text-transform: capitalize;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
  }
}
.cardDayTimeWrap {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 10px;
  margin: 15px 0;
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (--viewportMobile) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.cardDayTimeWrap .cardDayTime {
  display: flex;
  flex-direction: column;
  /* background: rgba(0, 88, 250, 0.2); */
  /* border-radius: 20px; */
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0058fa;
  padding: 10px;
  text-transform: capitalize;
  border-right: 1px solid #d3d2d3;
}
.cardDayTimeWrap .cardDayTime:last-child {
  border-right: 0;
}
.cardDayTimeWrap .cardDayTime:nth-of-type(5n) {
  @media (--viewportMedium) and (--viewportMiniDesktopDown) {
    border-right: 0;
  }
}
.cardDayTimeWrap .cardDayTime:nth-of-type(3n) {
  @media (--viewportMobile) {
    border-right: 0;
  }
}

.cardLocation {
  display: flex;
  column-gap: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1b1b1b;
  margin: 0px 0 13px;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 8px;
    }
  }
}

.cardRatings {
  display: flex;
  column-gap: 15px;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #ffb534;
  margin: 0px 0 13px;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 8px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 10px;
    }
  }
}

.cardTotalRatings {
  color: #b9b9b9;
}

.cardDetailInfo {
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  text-transform: capitalize;

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    margin: 8px 0 0;
  }
}

.cardDetailAuthor {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-transform: capitalize;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  @media (--viewportLaptop) {
    width: 175px;
  }

  @media (--viewportTabletDown) {
    width: 150px;
  }

  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    margin: 5px 0 0;
  }
}

.cardDetailDuration {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  /* width: 177px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  @media (--viewportLaptop) {
    /* width: 198px; */
  }

  @media (--viewportTabletDown) {
    width: 150px;
  }

  @media (--viewportMobile) {
    font-size: 10px;
    line-height: 13px;
    margin: 5px 0 0;
    width: unset;
  }
}

.detailWrapper {
  display: flex;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    flex-direction: column;
    width: 100%;
    row-gap: 0.5rem;
  }
}

.cardDetailprice {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #0058fa;
  margin: 18px 0px 18px 0px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 19px;
    margin: 5px 0 0;
  }
}

.btnBookNow {
  width: 90px;
  min-height: 46px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background: var(--matterColorBlue);
  border-radius: 3px;

  @media (--viewportLaptop) {
    width: 109px;
    font-size: 16px;
    line-height: 20px;
  }

  @media (--viewportMiniDesktopDown) {
    font-size: 16px;
    line-height: 20px;
    /* width: 100%; */
  }

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 12px;
    width: 98px;
    min-height: 35px;
  }
}

.currentAddress {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .cardDescription {
    width: 190px;
  }

  .courseImageWrapper {
    column-gap: 15px;
  }

  .descriptionWrapper {
    padding-left: 15px;
  }

  .bookingWrapper {
    padding-left: 15px;
  }

  .cardDetailTitle {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.groupDate {
  margin: 0 0.75rem;
  padding: 1.5rem 0;
  font-weight: 600;
  display: flex;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border-top: 1px solid #d3d2d3;
    margin: auto;
  }
}
.groupText {
  margin: 0 1.5rem;
  color: #696c74;
  font-weight: 600;
  @media (--viewportMobile) {
    font-size: 16px;
    margin: 0 1rem;
  }
}
.noDataFound {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
