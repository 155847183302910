/* Form side */
@import '../../../marketplace.css';
.root {
  display: flex;
  flex-direction: column;
}
.root select {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

/* Example side */
.enquiryWrapper {
  display: flex;
  column-gap: 20px;
  padding: 13px;
  @media (--viewportTabletDown) {
    padding: 13px 0;
  }
}

.enquiryFormWrapper {
  flex: 1;
  /* margin: 13px 0px 13px 13px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 40px;
  background: #ffffff;
  border-radius: 20px;
  padding: 40px 100px;
  @media (--viewportMiniDesktopDown) {
    padding: 30px 20px;
  }
  @media (--viewportTabletDown) {
    padding: 30px 20px;
    overflow-x: hidden;
  }
}
.enquiryFormWrapper input,
.enquiryFormWrapper textarea,
.enquiryFormWrapper select {
  transition: border-color ease-in 0.2s;
}

.enquiryExampleWrapper {
  flex: 1;
  /* margin: 13px 13px 13px 20px; */
  border-radius: 20px;
  background: #0d60fa;
  @media (--viewportTabletDown) {
    display: none;
  }
}
.enquiryExampleWrapperEdu {
  background: #f75959;
}

.enquiryRequestWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  /* align-items: center; */
  padding: 20px 30px;
}

.enquiryRequestTitle {
  padding-top: 20px;
  color: var(--matterColorLight);
}
.mobilePostVideo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.breadCrumbWrapper {
  margin: 10px auto 0;
  width: 85%;
}
.breadcrumbArrow {
  margin-left: 10px;
  margin-right: 10px;
}
.currentListingTypeName {
  text-transform: capitalize;
}
