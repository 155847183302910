@import '../../marketplace.css';

.root {
  /* Fixed on top of everything */
  position: sticky;
  bottom: 0;
  z-index: 9999;

  /* Dimensions */
  width: 100vw;
  padding: 28px 0;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--matterColorBlue);
  color: var(--matterColorLight);
  border-top: 1px solid #ffffff80;
  /* Cover Topbar on desktop */

  @media (--viewportMobile) {
    bottom: 70px;
    border-top: none;
    flex-direction: column;
    row-gap: 20px;
    padding: 15px 0 32px;
  }
}

.message {
  @apply --marketplaceH5FontStyles;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  color: var(--matterColorLight);
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 24px;

  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 23px;
  }
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  /* font-weight: var(--fontWeightSemiBold); */
  font-weight: 450;
  font-size: 19px;
  line-height: 24px;
  color: var(--matterColorLight);

  /* Dimensions */
  padding: 12px 21px 12px 22px;
  margin: 0 24px 0 6px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 5px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
    padding: 9px 15px;
    margin: 0;
  }
}
