@import '../../../marketplace.css';

.bottomHeader {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: #0058fa;

  @media (--viewportTabletDown) {
    gap: 0;
    /* padding-left: 15px; */
    padding: 0;
  }
}
.bottomHeaderEducational {
  background-color: #f75959;
}
.bottomHeaderTennis {
  background-color: #4889ff;
}
.opacityBottomHeader .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 9;
}
.overlayMain {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 9;
}
.mobileCategoryContainer {
  display: flex;
  width: calc(100% - 500px);

  @media (--viewportLaptopDown) {
    width: 100%;
    margin: 0 auto;
  }

  @media (--viewportMobile) {
    margin: 0 auto;
  }
}
.categoryListContainer {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  left: 376px;
  position: relative;
  transition: height 600ms;

  @media (--viewportLaptopDown) {
    width: 100%;
    left: 0;
    margin-left: 25px;
  }

  @media (--viewportTabletDown) {
    left: 0;
    height: 91px;
    margin: 0;
    justify-content: center;
  }
}
.hideSubcategory {
  height: 0;
}

.categoryListContainer [class*='sectionCounter'] {
  display: none;
}

.categoryListContainer [class*='left'] {
  top: 0;
  right: unset;
  left: -45px;
  display: flex;
  align-items: center;

  @media (--viewportTabletDown) {
    right: -50px;
    left: unset;
  }

  @media (--viewportLaptopDown) {
    width: unset;
    right: -30px;
    left: unset;
  }
}

.categoryListContainer [class*='right'] {
  top: 0;
  right: -90px;
  display: flex;
  align-items: center;
  height: 100%;
}

.categoryListContainer [class*='img'] {
  display: flex;
  align-items: center;
  position: revert;
  width: 32px;
  height: 32px;
}
.categoryListContainerWithoutArrow {
  width: 100%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}
.activeItem {
  background-color: var(--matterColorBlue);
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 73px;
  width: 100%;
  cursor: pointer;

  @media (--viewportTabletDown) {
    min-width: unset;
    font-size: 11.1047px;
    line-height: 14px;
  }

  @media (--viewportLaptopDown) {
    min-width: unset;
  }
}

.activeItem svg path,
.activeItem svg rect,
.activeItem svg circle {
  stroke: #ffb81d !important;
}

.item svg path,
.item svg rect,
.item svg circle {
  stroke: #ffffff;
  fill: transparent;
}

.activeItemEdu svg path,
.activeItemEdu svg rect,
.activeItemEdu svg circle {
  stroke: #ffb81d !important;
  fill: transparent;
}
.activeItemTen svg path,
.activeItemTen svg rect,
.activeItemTen svg circle {
  stroke: #ffb81d !important;
  fill: transparent;
}

.itemEdu svg path,
.itemEdu svg rect,
.itemEdu svg circle {
  fill: transparent;
  stroke: #fff;
}
.itemTen svg path,
.itemTen svg rect,
.itemTen svg circle {
  fill: transparent;
  stroke: #fff;
}

/* .activeItemEdu {
  color: #ffb81d !important;
} */

.item svg {
  width: 26px;
  height: 26px;
  margin-right: 0;
  margin-bottom: 18px;
  fill: transparent;

  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
  }
}

.item {
  background-color: transparent;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 73px;
  width: 100%;
  cursor: pointer;
  /* margin: 0 !important; */

  @media (--viewportTabletDown) {
    min-width: unset;
    font-size: 13px;
    line-height: 14px;
  }
}

.itemEdu {
  color: #fff;
}

.item:first-child {
  margin-left: 8px;
}

/* .item:last-child {
  margin-right: 41px;
} */
