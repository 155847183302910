@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.logo {
  width: 175px;
  height: 45px;
  margin-right: 10px;

  background: url('../../assets/ProbuddylogoBlue.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  @media (--viewportMedium) {
    /* background: url('../../assets/SellerPage/ProbuddyLogo.svg'); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
