@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (--viewportMobile) {
    padding: 11px 24px 40px 24px;
  }
}

.form {
  flex-grow: 1;
}

.title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  position: relative;
  margin: 0 0 35px;
  text-transform: capitalize;
  & img {
    position: absolute;
    top: -21px;
    width: 71px !important;
    height: 26px;
    right: 36px;
  }
  @media (--viewportLarge) {
    /* margin-bottom: 38px;
    padding: 1px 0 7px 0; */
  }
}
.jobsboardHeading {
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  text-transform: capitalize;
  color: var(--matterColorDarkBlue);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 16px;
  }
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  margin: 9px 0;
  gap: 0 20px;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 113px;
  border: 1px solid #00bfe633;
  border-radius: 8px;
  @media (--viewportMobile) {
    width: 105px;
    height: 105px;
  }
  & .iconText {
    font-weight: 600;
    font-size: 13px;
    line-height: 37px;
    text-align: center;
    color: #233045;
    padding: 8px 0 0 0;
    @media (--viewportMobile) {
      font-size: 12px;
      line-height: 31px;
    }
  }
}

.selectedIcons {
  background: var(--marketplaceColorLight);
  & svg {
    & path {
      fill: var(--matterColorLight);
    }
  }
  & .iconText {
    color: var(--matterColorLight);
  }
}

.selectedText {
  display: none;
  font-weight: bold;
  font-size: 21px;
  line-height: 33px;
  color: var(--matterColorDarkBlue);
}
