@import '../../marketplace.css';

.root {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 11px 60px;
  @media (--viewportLaptopDown) {
    padding: 11px 35px;
  }
  @media (--viewportTabletDown) {
    padding: 27px 25px;
  }
  @media (--viewportMobile) {
    padding: 14px;
    margin: 15px;
  }
}

.FAQItem {
  position: relative;
  border-bottom: 1.5px solid #0000001a;

  &:last-child {
    border-bottom: 0;
  }
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

/*Question*/
.question {
  position: relative;
  background: var(--matterColorBright);
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  padding-bottom: 28px;
  padding-top: 34px;
  display: block;
  width: 100%;
  cursor: pointer;
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
}

/*Answer*/
.answers {
  width: 100%;
  /* max-width: 589px; */
  font-weight: 450;
  font-size: 16px;
  line-height: 25px;
  color: var(--matterColorParagraphText);
  margin-bottom: 27px;
  position: relative;
  display: block;
  @media (--viewportTabletDown) {
    margin-bottom: 15px;
  }
}

/*FAQ Toggle*/
.plus {
  margin-right: 44px;
  font-size: 24px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportLaptopDown) {
    margin-right: 15px;
  }
  @media (--viewportMobile) {
    margin-right: 0;
  }
  @media (--viewportTabletDown) {
    width: 24px;
    height: 24px;
  }
}

.minus {
  margin-right: 44px;
  font-size: 24px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportTabletDown) {
    width: 24px;
    height: 24px;
    margin-right: 15px;
  }
  @media (--viewportMobile) {
    margin-right: 0;
  }
}
.minus span {
  width: 8px;
  border-radius: 50%;
  height: 2px;
  background: var(--matterColorDark);
}
.FAQItemWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
