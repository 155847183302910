@import '../../marketplace.css';

.classCard {
  margin-bottom: 17px;
  display: flex;
  align-items: flex-start;
  background-color: var(--matterColorLight);
  text-decoration: none !important;
  color: var(--matterColorDark);
  cursor: pointer;
  @media (--viewportMobile) {
    height: auto;
    border-radius: 3px 8px 8px 2px;
    margin-top: 16px;
    display: none;
  }
}
.classImage {
  position: relative;
  min-width: 186px;
  height: 146px;
  object-fit: cover;
  @media (--viewportMobile) {
    min-width: 134px;
    height: 90px;
  }
}

.popularImage {
  /* padding: 4px; */
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 7px;
  @media (--viewportMobile) {
    border-radius: 2px  0 0 2px;
  }
}

.classMain {
  padding: 18px 11px 22px;
  display: flex;
  flex-grow: 1;
  background: var(--matterColorLight);
  border-bottom: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 6px;
  max-width: 900px;
  margin: 0 auto;
  @media (--viewportMobile) {
    flex-direction: column;
    padding: 4px 10px 8px;
    width: 70%;
    position: relative;
    box-shadow: 0 0 0 0;
    /* display: none; */
  }
  & .classMainHeader {
    padding: 5px 0 0 0;
    margin-bottom: 7px;
    @media (--viewportMobile) {
      margin-bottom: 0px;
      max-width: 100%;
    }
  }
  & .title {
    margin-bottom: 3px;
    overflow: hidden;
    padding: 3px 0 0 0;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: var(--marketplaceColorDarkest);
    max-width: 543px;
    & span{
      @media (--viewportMobile) {
        display: none;
      }
    }
    @media (--viewportMobile) {
      font-size: 16px;
      -webkit-line-clamp: 1;
      line-height: 20px;
      padding: 0;
    }
  }
  & .authorName {
    /* max-width: 160px; */
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: var(--marketplaceColor);
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (--viewportMobile) {
      font-size: 11px;
      line-height: 14px;
      max-width: 100%;
      color: #C4C4C4;
    }
  }
  & .address {
    font-size: 14px;
  }
  & .availability {
   
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (--viewportMobile) {
      display: block;
    }
    & .availabilityText {
      word-break: break-word;
      line-height: 14px;
      & svg{
        margin-right: 12px;
      }
      @media (--viewportMobile){
        display: flex;
        position: static;
        bottom: 0;
        flex-wrap: wrap;
        & svg{
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

.availabilityText {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    margin: 0 10px 0 0;
    padding: 6px 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    &::-webkit-scrollbar{
      display: none;
    }
  }
}

.classPrice {
  width: 86px;
  height: 34px;
  /* margin-top: 13px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorDarkBlue);
  @media (--viewportMobile) {
    margin-top: 0;
    width: 48px;
    border-radius: 4px;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: var(--matterColorDarkBlue);
  }
}

.notTimeSlots,
.classAvailability {
    color: var(--matterColorDimmedText);
    line-height: 20px;
  @media (--viewportMobile) {
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    color: #6E6F71;
  }
}

.classAvailability {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDarkBlue);
  min-width: 55px;
  width: 55px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.540006px solid var(--matterColorAnti);
  box-sizing: border-box;
  border-radius: 6px;
  margin: 0 5px 9px 0;
  white-space: pre;
  &:last-child:after {
    display: none;
  }
  @media (--viewportMobile) {
    margin: 0 5px 0 0;
    width: 60px;
    height: 24px;
  }
}


.star_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  min-width: 122px;
  margin-top: 28px;
}

.rating {
  color: #e82953;
  font-size: 10px;
  font-weight: 550;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}
.reviewCount {
  font-size: 11px;
  font-size: 15px;
  margin: 0 8px;
  color: #C4C4C4;
  @media (--viewportMobile) {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #C4C4C4;
  }
}

.classDescription{
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6E6F71;
  margin: 0;
  white-space: break-spaces;
  overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  @media (--viewportMobile) {
    font-size: 13px;
    line-height: 18px;
    padding-left: 0;
    font-weight: normal;
    -webkit-line-clamp: 4;
  }
}

.availabilityContainer{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (--viewportMobile) {
    height: 0;
    padding: 4px;
  }
}

.mobileData{
  display: none;
  @media (--viewportMobile) {
    display: block;
  }
}

.mainDiv{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 16px;
}

.contentCardWrap{
  margin: 0 8px 0 22px;
  width: 100%;
  @media (--viewportMobile) {
    margin: 0;
    display: flex;
    flex-grow: 1;
  }
}

.MobileclassCard{
  display: none;
  @media (--viewportMobile){
    display: block;
    background: var(--matterColorLight);
    box-sizing: border-box; 
    padding: 6px 11px 8px;
    margin-bottom: 9px;
    text-decoration: none !important;
    & .classMain{
      width: 100%;
      display: flex;
      padding: 0;
      flex-direction: column;
      border: 0;
    }
    & .popularImage{
      width: 137px;
      height: 90px;
      border-radius: 4px;
    }
    & .classMainHeader{
      display: block;
    }
    & .authorName{
      display: flex;
      max-width: auto;
      @media (--viewportMobile) {
        flex-direction: column;
      }
    }
    & .star_Wrapper{
      min-width: fit-content;
      margin-top: 0px;
    }
    & .mHead{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3px;
    }
  }
}

.authorProfileName{
  width: 92px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  white-space: break-spaces;
  word-wrap: break-word;
  @media (--viewportMobile) {
    width: 150px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--marketplaceColor);
  }
}

.titlePriceContainer{
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.descriptionWrapper {
  border-left: 1px solid #e5e5e5;
    /* min-width: 283px; */
    min-width: 316px;
    max-width: 316px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0 5px 22px;
    @media (--viewportMobile) {
      border-left: 0;
      border-top: 1px solid #e5e5e5;
      min-width: 100%;
      max-width: 100%;
      padding: 6px 0 0;
    }
}
.descriptionWrapper .descTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDarkest);
  margin: 0 0 3px 0;
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
  }
}
.addresswrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}
.addresswrapper .address {
  width: 100%;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.72);
}
.address svg {
  margin-right: 10px;
}
.timeRange {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: var(--matterColorDarkBlue);
}
.classMaininner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}