@import '../../marketplace.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}

.messageItem {
  margin-bottom: 35px;
  position: relative;
  /* Clearfix */
  @apply --clearfix;

  @media (--viewportxldesktop) {
    margin-bottom: 25px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;
}

.avatar {
  flex-shrink: 0;
  margin: 0px 12px 0 0;

  @media (--viewportMedium) {
    margin: 0px 12px 0 16px;
  }
}

.ownMessageContentWrapper {
  @apply --clearfix;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
}

.ownMessageContent {
  color: #ffffff;
  float: right;
  background: #0d60fa;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 15px 15px 0px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 25px;

  @media (--viewportxldesktop) {
    padding: 10px;
    border-radius: 10px 10px 0px 10px;
  }

  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 15px;
    padding: 10px;
  }
}

.messageDate,
.ownMessageDate {
  @apply --marketplaceMessageDateFontStyles;

  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
  position: absolute;
  bottom: -25px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  width: 100%;
  right: 0;

  @media (--viewportxldesktop) {
    margin: 0;
    bottom: -15px;
    font-size: 9px;
  }

  @media (--viewportTabletDown) {
    /* display: none; */
  }
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  @apply --marketplaceTxTransitionFontStyles;
  margin: 0;
}

.transitionDate {
  @apply --marketplaceMessageDateFontStyles;
  color: var(--matterColorAnti);
  margin: 7px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.showOlderButton {}

.chatBoxmessage {
  display: flex;
  justify-content: flex-start;
  padding-top: 24px;

  @media (--viewportxldesktop) {
    padding-top: 15px;
  }
}

.profileImages {
  padding-top: 10px;
}

.profileTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 0;
  margin-left: 30px;
  /* right: 0; */
  white-space: nowrap;
  bottom: -25px;
  font-size: 12px;
  line-height: 15px;
  color: #aba7a7;

  @media (--viewportxldesktop) {
    margin: 0;
    bottom: -15px;
    font-size: 9px;
    margin-left: 15px;
  }

  @media (--viewportTabletDown) {
    /* display: none; */
  }
}

.profileTitle h4 {
  padding-left: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
}

.profileTitle span {
  font-size: 12px;
  line-height: 15px;
  color: #aba7a7;
  font-weight: 400;

  @media (--viewportxldesktop) {
    margin: 0;
    font-size: 9px;
  }
}

.profileSidetext {
  position: relative;
}

.profileParagraph {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  max-width: 756px;
  background: #f3f9fa;
  padding: 25px;
  margin-left: 30px;
  background: #f1f4ff;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 3%);
  border-radius: 15px 15px 15px 0px;

  @media (--viewportxldesktop) {
    padding: 10px;
    border-radius: 10px 10px 10px 0px;
  }

  @media (--viewportxldesktop) {
    margin-left: 15px;
  }

  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 24px;
    padding: 10px;
  }
}