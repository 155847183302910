.playersMain {
  display: flex;
  align-items: center;
  gap: 10px;
}
.playersCard {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* padding-top: 5px; */
}
.playersCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* object-position: 0px 5px; */
}
.playersMore {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: black;
  margin: 0;
}
.joinedCount {
  font-size: 15px;
  text-transform: lowercase;
  align-items: flex-end;
  line-height: 28px;
  font-weight: 500;
  color: #0058fa;
  margin: 10px 0 0;
}
