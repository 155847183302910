@import '../../marketplace.css';

.sectionReviews {
  /* padding: 60px 104px 35px 104px; */
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 60px 50px;
  @media (--viewportLaptopDown) {
    padding: 60px 35px 35px 35px;
  }
  @media (--viewportTabletDown) {
    padding: 25px;
  }
  @media (--viewportMobile) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.reviewWrap {
  display: flex;
  gap: 15px;
  font-weight: 450;
  font-size: 18px;
  line-height: 18px;
  color: #0b150a;
  align-items: center;
  margin-bottom: 20px;
}

.reviewCard {
  padding-top: 20px;
  border-top: 1px solid rgba(11, 21, 10, 0.1);
  @media (--viewportMobile) {
    border-bottom: 1px solid rgba(11, 21, 10, 0.1);
    border-top: unset;
  }
}

.reviewCard:first-child,
.reviewCard:nth-child(2) {
  border-top: unset;
}

.reviewCardWapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (--viewportTabletDown) {
    margin-bottom: 15px;
  }
}

.reviewAuthorDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 25px;
}

.reviewProfileImageWrapper {
}

.avatar {
  width: 84px;
  height: 84px;
  border-radius: 10px;

  @media (--viewportTabletDown) {
    width: 68px;
    height: 68px;
  }

  & img {
    border-radius: 10px;
  }
}

.reviewAuthorInfo {
  display: flex;
  flex-direction: column;
  /* row-gap: 13px; */
}

.reviewAuthorName {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: var(--matterColorBlue);
  margin: 0px;
  @media (--viewportTabletDown) {
    font-size: 18px;
    line-height: 23px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.reviewDate {
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #0b150a;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}

.ratingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  padding: 5px;
  background: var(--matterColorBlue);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  @media (--viewportTabletDown) {
    width: 65px;
  }
}

.star {
  width: 18px;
  height: 18px;
  @media (--viewportTabletDown) {
    width: 14px;
    height: 14px;
  }
}

.ratingNumber {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--matterColorLight);
  padding-left: 6px;
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 24px;
  }
}

.reviewContent {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #0b150a;
  margin: 0px;
  /* padding-top: 20px; */
  padding-right: 60px;
  padding-bottom: 20px;
  @media (--viewportTabletDown) {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 15px 0px 20px 0px;
  }
}

.viewMoreButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewMoreButtonWrapper button {
  background-color: var(--matterColorBlue);
  width: 127px;
  min-height: 46px;
  border-radius: 3.31824px;
  padding: 13px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  border: none;

  @media (--viewportMobile) {
    background-color: #fff;
    color: var(--matterColorBlue);
    padding: 0;
    min-height: 20px;
    margin-top: 20px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorBlue);
    box-shadow: none;
    @media (--viewportTabletDown) {
      background-color: #fff;
    }
  }
}
