@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  & select {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 20px;
  color: #afafaf;
  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.seatsField {
  flex-shrink: 0;
  margin-bottom: 24px;
  position: relative;
  & input {
    border-width: 1px;
    &:hover,
    &:focus {
      border-width: 1px;
    }
  }

  & label {
    @media (--viewportMobile) {
      position: absolute;
      top: -7px;
      left: 15px;
      text-transform: uppercase;
    }
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
  @media (--viewportMobile) {
    padding: 0;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 25px;
  margin-bottom: 14px;
  @media (--viewportTabletDown) {
    margin-top: 25px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 30px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  /* @apply --marketplaceTinyFontStyles; */
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 18px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
.paymentMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 30px;
}
.paymmentWrapper {
  margin: 0;
  position: relative;
  width: unset;
}
.or {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.priceAddon {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.periodSelect {
  color: #afafaf;
  margin: 0 24px 38px 24px;
  @media (--viewportMedium) {
    margin: 0 0 38px 0;
  }
  @media (--viewportMobile) {
    margin: 0 0 12px 0;
  }
}

.priceAddonAmount {
  @apply --marketplaceListingAttributeFontStyles;
  margin: 0 0 0 10px;
  font-weight: 500;
  font-family: 'Int Circular PTT';
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorBlue);
}
.activeTab {
  font-weight: var(--fontWeightBold);
  border-bottom: 3px solid var(--marketplaceColorDark);
  color: var(--marketplaceColor);
}

.inactiveTab {
  font-weight: var(--fontWeightBold);
}

.tabs {
  display: flex;
  margin-bottom: 24px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }
}

.requestToBookWithCredits {
  padding: 0 10px;
}

.tabs li:first-child {
  margin-right: 18px;
}

.invalidDates {
  color: var(--failColor);
  margin-bottom: 39px;
}

.classBookingSlotContainer {
  margin: 24px 0 16px 0;
}

.classBookingSlot {
  & label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 18px 24px;
    border-radius: 6px;
    background-color: #f9f9f9;
    color: var(--matterColorDark);
    cursor: pointer;
    line-height: 18px;
    &:hover {
      background-color: var(--matterColorNegative);
    }
    & .classBookingSlotTime {
      font-size: 13px;
    }
    & .classBookingPrice {
      color: var(--matterColorDimmedText);
    }
    & .classBookingBtn {
      width: 90px;
      padding: 10px 20px;
      background-color: var(--marketplaceColorDarker);
      color: var(--matterColorLight);
      font-size: 13px;
      font-weight: bold;
      line-height: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.active {
      background-color: var(--successColor);
      color: var(--matterColorLight);
      & .classBookingBtn {
        display: none;
      }
      & .classBookingPrice {
        color: var(--matterColorLight);
      }
    }
  }
  & input {
    display: none;
    &:checked ~ label {
      background-color: #0058fa;
      color: var(--matterColorLight);
      & .classBookingBtn {
        display: none;
      }
      & .classBookingPrice {
        color: var(--matterColorLight);
      }
    }
  }
}

.classBookingMore {
  text-align: center;
  padding: 12px 0;
  font-size: 15px;
  font-weight: 600;
  vertical-align: center;
  background-color: #f9f9f9;
  border-radius: 12px;
  cursor: pointer;
  & .classBookingMoreIcon {
    margin-right: 10px;
  }

  @media (--viewportTabletDown) {
    margin-bottom: 20px;
  }
}

.modalContent {
  padding-top: 24px;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  @media (--viewportLarge) {
    flex-basis: 576px;
    min-height: unset;
    height: auto;
    max-height: calc(100vh - 120px);
    margin-top: 70px;
    overflow: auto;
    padding: 1.5rem;
    &::-webkit-scrollbar {
      width: 2px;
      height: 0;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b2b2b2;
    }
  }
}

.continueAndSkipContainer {
  display: flex;
  justify-content: space-between;
  & button {
    width: 200px;
  }
}

.submitButtonPanel {
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  & button {
    width: 100%;
    min-height: 53px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.3px;
    color: var(--matterColorLight);
    background: var(--matterColorBlue);
  }
}

.darkBorder {
  & input {
    border-bottom-color: var(--matterColorDarkBlue);
  }
}

.buyPackage {
  margin-top: 29px;
}
.sendCustomAmount {
  background: #ffb81d !important;
  width: 100%;
  min-height: 53px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.3px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 20px;
}
.disabled {
  cursor: not-allowed !important;
}
.addonLabel {
  text-transform: capitalize;
}
