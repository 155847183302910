@import '../../marketplace.css';

.root {
}

label {
  padding: 0;
}

.select {
  color: var(--matterColorAnti);
  border-color: var(--attentionColor);
  padding-right: 20px;

  & option {
    color: var(--matterColor);
  }

  & optgroup {
    color: var(--matterColor);
  }

  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor) !important;
  border: 2px solid var(--successColor) !important;
}

.selectNormal {
  border: 2px solid #0000001a !important;
}

.selectError {
  border: 2px solid var(--failColor);
}

.bookingWrapper {
  position: relative;
}

.bookingLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  color: var(--matterColorDark);
  position: absolute;
  top: -7px;
  left: 15px;
  background: var(--matterColorLight);
  padding: 0 5px;
  text-transform: uppercase;
  z-index: 1;
}

.bookingInputWrapper {
  position: relative;
}

.selectInputStyle {
  background: var(--matterColorLight) !important;
  border-radius: 5px !important;
  font-family: 'Int Circular PTT';
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #5c5b5c;
  padding: 16px 45px 16px 20px !important;

  @media (--viewportMobile) {
    line-height: 36px !important;
  }
}

.bookingSvgIcon {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 17px;
}

.bookingSvgIcon svg {
  fill: none;
}
