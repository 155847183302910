@import '../../marketplace.css';

.root {
  position: relative;
}

.scrollContainer {
  display: flex;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 32px;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
  }

  @media (--viewportLaptopDown) {
    gap: 32px;
    overflow-x: scroll;
    /* margin-right: 25px; */
  }

  @media (--viewportTabletDown) {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 25px;
    gap: 40px;
  }
  @media (--viewportMobile) {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0;
    gap: 40px;
  }
  @media (max-width: 375px) {
    /* margin-left: 55px; */
    gap: 40px;
  }
  @media (max-width: 320px) {
    /* margin-left: 25px; */
    gap: 40px;
  }
}

.scrollArrow {
  @media (--viewportMobile) {
    display: none;
  }
}

.sectionCounter {
  position: absolute;
  top: -38px;
  right: 90px;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  color: #6e6f71;

  @media (--viewportMobile) {
    display: none;
  }
}
