.table {
  width: 100%;
  margin: 10px;
}
.date {
  min-width: 150px;
  font-size: 16px;
}
.td {
  min-width: 60px;
  border: 1px solid #a1a1a1;
  font-size: 14px;
}
.name {
  min-width: 150px;
  font-size: 14px;
}
.availability {
  background-color: #0058fa;
}
.availbale {
  background-color: #fffdfd;
  border-radius: 5px;
  position: relative;
  height: 60px;
}
.availbale:hover {
  background-color: #0058fa;
}

.unavailbale {
  background-color: #0058fa;
  height: 60px;
  border-radius: 5px;
  @media (--viewportMobile) {
    height: 55px;
  }
}

.booked {
  background: #0058fa;
  width: 100%;
  height: 60px;
  border-radius: 5px;
  position: relative;
  @media (--viewportMobile) {
    height: 55px;
  }
}

.tooltipWrap {
  visibility: hidden;
  width: 140px;
  background-color: #0e2433;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.tooltipWrap::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltipWrap span {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.facilityNameTooltip {
  left: 0%;
  transform: translateX(0%);
  width: auto;
  padding: 8px 15px;
}

.facilityName:hover .tooltipWrap,
.booked:hover .tooltipWrap,
.availbale:hover .tooltipWrap {
  visibility: visible;
}
.facilityName:hover .tooltipWrap span a {
  color: #fff;
}

.tableTdGap {
  border: 1px solid #879199;
  padding: 1px;
}
.viewMoreButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.viewMoreButtonWrapper button {
  background: #0058fa;
  width: max-content;
  min-height: 46px;
  border-radius: 3px;
  padding: 13px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  @media (--viewportTabletDown) {
    background-color: #ffffff;
    color: #0058fa;
    padding: 0;
    min-height: 20px;
  }

  &:hover,
  &:focus {
    background-color: #0058fa;
    box-shadow: none;
    @media (--viewportTabletDown) {
      background-color: #ffffff;
    }
  }
}
.link {
  margin-left: 0px;
  color: #fff;
  &:hover {
    text-decoration: none;
  }
}
.facilityiesWrapper {
  overflow: auto;
}
.horizontalScroll {
  width: 100%;
}
.scrollControl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0px;
  padding: 1rem 0.5rem;
  max-width: 100%;
  padding: 30px 0;
  /* @media (max-width: 1600px) {
    column-gap: 30px;
  }
  @media (--viewportTabletDown) {
    column-gap: 20px;
  } */
}
.spinner {
  display: block;
  margin: 10px auto;
}
.scrollControl table {
  /* border-spacing: 5px; */
  border-collapse: collapse;
}
.scrollControl table tr th {
  border: 1px solid #a1a1a1;
  color: #879199;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
}
.scrollControl .title {
  border: 1px solid #a1a1a1;
  min-width: 150px;
  border-top: 0;
}
.facilityDateWrap .facilityName {
  margin: 0;
  position: relative;
  width: fit-content;
}
.facilityDateWrap .facilityName a {
  color: #0058fa;
  font-size: 16px;
  white-space: nowrap;
  margin: auto 10px;
  font-weight: 400;
  line-height: 26px;
  text-transform: capitalize;
  margin: 0 !important;
}
.facilityDateWrap .facilityDate {
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
}
.scrollControl .title div {
  margin-left: 10px;
  @media (--viewportMobile) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.scrollControl .title a {
  color: #0058fa;
  font-size: 16px;
  white-space: nowrap;
  margin: auto 10px;
  font-weight: 500;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: block;
  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.indicatorWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: start;
    gap: 5px;
  }
}
.indicator {
  display: flex;
  align-items: center;
  gap: 5px;
}
.indicatorIcon_available {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  border: 1px solid #000;
  background: #fffdfd;
}
.indicatorIcon_notavailable {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  /* border: 1px solid #000; */
  background: #0058fa;
}
.indicatorIcon_yourbooking {
  width: 15px;
  height: 15px;
  border-radius: 5px;
  /* border: 1px solid #000; */
  background: #949494;
}
.indicatorText {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.filterIcon {
  height: 16px;
  width: 16px;
}
