.root {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background-color: var(--matterColorNegative); */
  background: #f5f9ff;
  @media (--viewportMobile) {
    /* overflow-x: hidden; */
    max-width: 100vw;
  }
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
