@import '../../marketplace.css';

.mainCategoryFilters {
  width: 246px;
  height: 72px;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(147, 147, 147, 0.255) 0%,
    rgba(147, 147, 147, 0.075) 100%
  );
  border-radius: 4.80251px;
  border: 1.5px solid transparent;
  @media (--viewportMobile) {
    width: 100%;
    min-height: 60px;
    padding: 4px 5px;
    background: var(--matterColorLight);
    border-radius: 8px;
    & .svgEllipse {
      display: flex;
      align-items: center;
      padding-right: 4px;
      & svg {
        fill: transparent;
      }
    }
  }
  & .categoryImage {
    width: 67px;
    min-width: 67px;
    height: 100%;
    @media (--viewportMobile) {
      max-width: 51px;
      width: 100%;
      height: auto;
      & img {
        border-radius: 6px;
      }
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .categoryLabel {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    white-space: nowrap;
    color: var(--matterColorLight);
    padding: 23px 59px 23px 17px;
    @media (--viewportMobile) {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:0 4px 0 4px;
      & svg {
        width: 20px;
        heigh: auto;
        fill: transparent;
      }
    }
  }
}

.selectedMobileMainCategory {
  background: var(--marketplaceColor);
  & .categoryLabel {
    color: var(--matterColorLight);
  }
}

.selectedMainCategory {
  border: 1.5px solid var(--marketplaceColorLight);
}

.proCategoriesWrapper {
  max-width: 1175px;
  margin: 0 auto;
  padding: 38px 24px;

  & .proCategoriesMain {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    column-gap: 70px;

    @media (--viewportMobile) {
      justify-content: space-between;
      column-gap: 0;
    }
    
    & .proSelectedCategory{
         color: #101C30;
        font-weight: 700;
        font-size: 25px;
       line-height: 16px;
       cursor: pointer;
        & span{
          color: #03e4fc;
        }
        @media (--viewportMobile) {
          font-size: 20px;
        }
    }

    & .proCategory {
      color: #c4c4c4;
      font-weight: 600;
      font-size: 25px;
      line-height: 16px;
      @media (--viewportMobile) {
        font-size: 20px;
      }
    }
  }
}

.mainCategoryFiltersBox {
  background-color: #101c30;
}

.mainCategoryFiltersWrapper {
  max-width: 1175px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(246px, 1fr));
  gap: 12px 8px;
  background-color: #101c30;
  position: relative;

  padding: 35px 20px 25px 55px;
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    padding: 17px 16px 24px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -6%;
    left: 5%;
    background-color: #101c30;
    width: 20px;
    height: 11px;
    clip-path: polygon(50% 0%, 3% 76%, 100% 76%);
    @media (--viewportMobile) {
      top: -4%;
      left: 15%;
    }
  }
}

.mainAdventureWrapper {
  &:after {
    left: 19%;
    @media (--viewportMobile) {
      left: 45%;
    }
  }
}

.mainWellnessWrapper {
  &:after {
    left: 34%;
    @media (--viewportMobile) {
      left: 74%;
    }
  }
}

.advanceFilterContainer {
  position: relative;
}

.advanceFilterWrapper {
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  @media (--viewportMedium) {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 19px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 10px 24px 0;
  }
}

.subCategoryItemsWrap {
  display: flex;
  column-gap: 11px;
  padding: 26px 0 30px;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
  }
}

.mobileSubCategoryItemsWrap {
  display: flex;
  padding: 19px 24px 19px;
  column-gap: 11px;
  overflow-x: scroll;
  width: 100%;
  background: var(--matterColorLight);
}
.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  @media (--viewportTabletDown) {
    width: 100%;
    padding: 33px 15px 18px;
  }
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  max-width: 154px;
  white-space: nowrap;
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMobile) {
    color: var(--matterColorDarkBlue);
    & svg path {
      fill: var(--matterColorDarkBlue);
    }
  }
}

.selectedSubCategoryItem {
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    background: var(--marketplaceColorBackground);
  }
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 7px;
  padding-bottom: 4px;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
    max-width: 300px;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
}

.labelSellerLevels {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  white-space: nowrap;
  padding: 9px 14px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 34px;
  max-height: 34px;

  & svg {
    margin-left: 5px;
    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportMobile) {
    justify-content: space-between;
  }
}

.filterToolWrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 7px;
  padding-bottom: 4px;

  @media (--viewportMobile) {
    max-width: 100%;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #d9d9d9;
    position: relative;
  }
}
