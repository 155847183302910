.fullArea {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.activeLabel {
  z-index: 1;
  /* @media (--viewportTabletDown) {
    z-index: unset;
  } */
}

.labelContainer {
  position: relative;
  &:hover {
    /* z-index: 1; */
  }

  &:focus {
    outline: none;
  }
  @media (--viewportTabletDown) {
    position: unset;
  }
}

.infoCardContainer {
  z-index: 3;
  /* @media (--viewportTabletDown) {
    z-index: unset;
  } */
}

.labelWrapper {
  /* position: relative; */
  @media (--viewportTabletDown) {
    position: unset;
  }
}

.activelabelWrapper {
  /* z-index: 2; */
  /* @media (--viewportTabletDown) {
    z-index: unset;
  } */
}
