@import '../../../../marketplace.css';

.itemLink {
  text-decoration: none;
  color: var(--matterColorDark);
  padding: 0 0 0 10px;
  &:hover {
    text-decoration: none;
  }
}

.enquireCardRoot {
  background: var(--matterColorLight);
  box-sizing: border-box;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 15px;
  @media (--viewportTabletDown) {
    border-radius: 11.0089px;
    padding: 15px;
  }
}

.selectedEnquiriesCardRoot {
  background: #f1f4ff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  padding: 20px;
}

/* .userAvatar{
    padding-left: 12px;
} */

.pastTitle {
  color: var(--marketplaceColor);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #aba7a7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media (--viewportTabletDown) {
    font-size: 11.7428px;
    line-height: 15px;
  }
}

.pastMessage {
  font-size: 20px;
  font-weight: 800;
  padding: 0;
}

.pastGuest {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  margin-bottom: 4px;
  @media (--viewportTabletDown) {
    font-size: 11.7428px;
    line-height: 15px;
    padding-left: 15px;
  }
}

.pastDay {
  font-style: normal;
  font-weight: 400;
  color: #6e6f71;
  text-align: right;
  font-size: 11px;
  line-height: 14px;
  color: #aba7a7;
  @media (--viewportTabletDown) {
    font-size: 8.07317px;
    line-height: 10px;
  }
}
.pastName {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDarkBlue);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  height: 19px;
  -webkit-box-orient: vertical;
  @media (--viewportTabletDown) {
    font-size: 13.2106px;
    line-height: 17px;
  }
}

.pastParagraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0px;
  padding: 6px 20px 0 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 54px;
  -webkit-box-orient: vertical;
  @media (--viewportTabletDown) {
    font-size: 11.7428px;
    line-height: 18px;
    height: 40px;
  }
  @media (--viewportTabletDown) {
    padding: 6px 15px 0 15px;
  }
}
.otherUserinfoWrapper {
  display: flex;
  column-gap: 9px;
  align-items: center;
  @media (--viewportMedium) {
    column-gap: 35px;
  }
}

.avatarUser {
  height: 49px;
  width: 49px;
  background: var(--matterColorLight);
  /* border: 1px solid #E7E7E7; */
  padding: 4px;
  border: 0;
  border-radius: 13px;
  background: #101c30;
  @media (--viewportMedium) {
    height: 49px;
    width: 49px;
  }
  @media (--viewportTabletDown) {
    width: 44.04px;
    height: 44.04px;
  }
  & span {
    font-size: 24px;
  }
}

.selectAvatarUser {
  border: 0;
  border-radius: 13px;
  background: #101c30;
}

/* .otherUserinfoWrapper .userAvatar{

} */
.enquireCardRoot .otherUserinfoWrapper .userInfo {
  display: flex;
  flex-direction: column;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userName {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.enquireCardRoot .otherUserinfoWrapper .userInfo .userMessageTime {
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #9e9e9e;
}
.enquireCardRoot .listingName {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  margin: 5px 0 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  @media (--viewportTabletDown) {
    margin: 9px 0 6px;
  }
}
.enquireCardRoot .messageContent {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: var(--matterColorDark);
  margin-bottom: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @media (--viewportTabletDown) {
    margin-bottom: 20px;
  }
}
.enquireCardRoot .messageButton {
  background: #001f3f;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--matterColorLight);
  padding: 8px 25px;
  width: 100%;
  min-height: 36px;
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.authorInfo {
  width: 100%;
}
