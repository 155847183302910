@import '../../marketplace.css';

.root {
  display: inline-block;
  /* margin-left: auto; */
  margin-right: 8px;

  &:last-of-type {
    padding-right: 0;
  }
}

.icon {
  position: relative;
  font-size: 10px;
  color: var(--matterColor);
}

.iconUp {
  position: absolute;
  left: 0;
  top: -9px;
}

.iconDown {
  position: absolute;
  left: 0;
  top: -0px;
}

.menuLabel {
  /* @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #0058fa;
  border-radius: 7.18751px;
  width: 119px;
  height: 48px;
  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  color: #0058fa;
  & img {
    margin-left: 10px;
  }

  & svg {
    fill: transparent;
  }

  &:focus {
    outline: none;
    /* background-color: var(--matterColorLight); */
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: 4px;
  z-index: 999;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuHeading {
  /* @apply --marketplaceListingAttributeFontStyles; */
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 24px;
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
