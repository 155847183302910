.switchContainer {
  z-index: 1;
  overflow-x: auto;
  display: grid;
  gap: 30px;
  row-gap: 20px;
  margin-bottom: 15px;
  grid-template-columns: repeat(4, 1fr);
  overflow: visible;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportTabletDown) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportMobile) {
    grid-template-columns: repeat(2, 1fr);
    margin-left: 10px;
    gap: 15px;
  }
  @media (max-width: 375px) {
    margin-left: 0;
  }
  /* @media (max-width: 320px) {
    margin-left: 5px;
    gap: 3px;
  } */
  /* @media (max-width: 320px) {
    gap: 5px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 3px;
  } */
}
.switch {
  display: flex;
  z-index: 1;
  width: 100%;
  align-items: center;
  column-gap: 6px;
  height: 70px;
  /* margin: 0 75px; */
  /* border: 1px solid #ffffff87; */
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 0px 0px 5px #0005;
  @media (--viewportMobile) {
    display: grid;
    gap: 10px;
    width: 170px;
    height: 60px;
    margin: 0 0;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 0px;
  }
  @media (max-width: 320px) {
    width: 140px;
  }
}
.switch h4 {
  font-weight: 600;
  font-size: 14px;
  width: 50%;
  text-align: center;
  line-height: 20px;
  color: #000000;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 12px;
    text-align: end;
    font-weight: 600;
  }
  /* @media (max-width: 320px) {
    text-align: end;
  } */
}
.switchimage {
  width: 70%;
  height: 100%;
  object-fit: cover;
  /* object-position: 10% 25%;
  filter: brightness(100%); */
  border-radius: 5px 0px 0px 5px;
  @media (--viewportMobile) {
    display: block;
    width: 85px;
    height: 60px;
    /* height: 100%; */
    border-radius: 5px;
    /* flex-shrink: 0; */
  }
  @media (max-width: 320px) {
    width: 70px;
  }
}
.switchActive {
  background: #fff;
  box-shadow: 3px 5px 10px #0005;
}
.switchActive h4 {
  color: #ffb81d;
}
