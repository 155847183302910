.masterVerifiedBtn {
  background: linear-gradient(99.59deg, #d7a955 6.3%, #ffd270 66.37%, #d7a955 121.63%);
  border-radius: 6px;
  padding: 13px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  border: 0;
}

.levelTwoBtn {
  background: linear-gradient(99.59deg, #d75576 6.3%, #ff7c70 66.37%, #d75576 121.63%);
  border-radius: 6px;
  padding: 13px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  border: 0;
}

.newSellerBtn {
  background: linear-gradient(99.59deg, #a347f0 6.3%, #e285ee 51.62%, #a347f0 121.63%);
  border-radius: 6px;
  padding: 13px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  border: 0;
}
