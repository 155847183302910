@import '../../marketplace.css';

.whatYouCanExpectHead {
  margin: 0 auto;
  /* border-bottom: 1px solid #e5e5e5; */
  /* padding: 80px 0 0 0; */
  & h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--matterColorDark);
    margin: 50px 0 25px;
    text-transform: capitalize;
    @media (--viewportTabletDown) {
      margin: 25px 0px;
      text-align: center;
      font-style: normal !important;
      font-weight: 700 !important;
      font-size: 22px !important;
      line-height: 28px !important;
      letter-spacing: 0.01em;
    }
  }
  @media (--viewportTabletDown) {
    margin: 0;
  }
}

.WhatYouCanExpectItem {
  background: var(--matterColorLight);
  padding: 50px;
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  @media (--viewportTabletDown) {
    row-gap: 20px;
    padding: 14px 14px 20px;
    margin: 15px;
  }
}
