@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.textInput {
  flex-shrink: 0;
  width: 100%;
}

.expectation {
  margin-bottom: 32px;
}

.pictureHeading {
  font-weight: var(--fontWeightBold);
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButtonContainer .submitButton {
  min-width: 30%;
  width: auto;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
  & > button {
    max-width: 200px;
    min-height: 40px;
  }
}

.pictureContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (--viewportLarge) {
    max-width: 50%;
    margin: 0 auto;
  }
}

.picture img {
  width: 300px;
  padding: 15px;
}

.uploadPictureBtn {
  @apply --marketplaceButtonStylesSecondary;
  margin-top: 22px;
  padding: 0px;
}

.hiddenField {
  display: none;
}
.videoWarningText {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 12px;
  color: #b5b3b3;
}
