@import '../../marketplace.css';

.content {
  background: var(--matterColorLight);
  overflow: hidden;
}

.topBarContainer {
  width: 100%;
  padding: 0.6rem 12.5%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  background: white;

  @media (--viewportMobile) {
    padding: 0.6rem;
  }
}
.servicesSectionBrand {
  width: 100%;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  text-align: center;
  color: #000000;
  /* margin: 4rem auto 0 auto; */

  @media (--viewportMobile) {
    margin: 1rem auto 0 auto;
    font-size: 25px;
    line-height: 30px;
  }
}

.topBarContainer .probuddyLogo {
  height: 2rem;

  @media (--viewportMobile) {
    height: 1.8rem;
  }
}
.topBarContainer .probuddyLogo img {
  height: 100%;
  width: 100%;
}
.topBarContainer .exploreButton {
  padding: 0.6rem 1rem;
  max-width: 9rem;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  min-height: 2.2rem;

  @media (--viewportMobile) {
    padding: 0.5rem 1rem;
    width: 100%;
  }
}
.reverseSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0rem;
    margin: 0;
    padding: 0;
  }
}

.section {
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 24px;
  @media (max-width: 991px) {
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0rem;
  }
  @media (min-width: 1600px) {
    max-width: 1524px;
    margin: 20px auto;
  }
}
.heroDescription {
  margin-bottom: 2rem;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.4px;

  @media (max-width: 991px) {
    display: none;
  }
}
body {
  overflow-x: hidden;
}

.uniqueDesignSection {
  padding: 10px 24px;
  /* width: 560px; */
  flex: 1;
  @media (max-width: 991px) {
    padding: 0 0 20px 0;
    max-width: 100%;
  }
  & hr {
    margin: 20px 0 30px;
  }
  & h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 16px;
    color: var(--matterColorDark);
    width: 100%;
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 30px;
      width: 90%;
      margin-top: 31;
      margin-bottom: 11px;
    }
  }
}

.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: var(--matterColorDark);
  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 28px;
  }
}

.servicesSection {
  width: 100%;
  margin: 2rem 0;
  /* margin: 4rem auto 0 auto; */

  @media (--viewportMobile) {
    margin: 2rem auto 0 auto;
  }
}

.servicesTitle {
  line-height: 40px;
  font-weight: 600;
  color: black;
  margin: auto;
  max-width: 45rem;
  font-size: 34px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.4px;

  @media (--viewportMobile) {
    font-size: 25px;
    line-height: 32px;
    padding: 0px 20px;
  }
}

.servicesSubheading {
  line-height: 28px;
  max-width: 38rem;
  font-weight: 400;
  color: black;
  margin: 1rem auto 0 auto;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.4px;

  @media (--viewportMobile) {
    display: none;
  }
}

.servicesCarousel {
  margin: auto;
  width: calc(75% + 4rem);
  padding: 0 2rem;
  overflow-x: auto;

  @media (--viewportMobile) {
    padding: 0rem;
  }
}

.servicesCarouselItems {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.servicesCarouselItem {
  max-width: 20rem;
  margin: 2rem 0;
  display: flex;
  flex: 1;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 0.5rem;

  & img {
    width: 100%;
    height: 15rem;
    border-top-radius: 10px;
    object-fit: cover;

    @media (--viewportMobile) {
      height: 12rem;
    }
  }

  & div {
    font-family: 'Int Circular PTT';
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
  }

  & .carouselInfo {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  & .carouselHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .carouselHeading > h5 {
    margin: 0;
    color: #a8a8a8;
  }

  & .carouselRating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }

  & .carouselRating img {
    width: 0.8rem;
    height: 0.8rem;
  }

  & .carouselRating h5 {
    margin: 0;
    font-weight: 600;
  }

  & .carouselRating span {
    font-size: 15px;
    color: #a8a8a8;
  }

  & .carouselName {
    margin: 0.3rem 0;
    font-size: 24px;
    font-weight: 500;
  }

  & .carouselDescription {
    font-size: 16px;
    color: #a8a8a8;
    line-height: 25px;
  }
}

.slickCarouselCard {
  display: flex !important;
  width: 75% !important;
  border-radius: 10px;
  background: #f7f7f7;
  margin: auto;
}

.slickCarouselCard :global(.slick-dots li.slick-active button:before) {
  color: #0058fa !important;
  font-size: 18px !important;
}

.slickCarouselSlider {
  margin: 2rem 0;
  @media (--viewportMobile) {
    display: none !important;
  }
}

.carouselCardLeft {
  flex: 1;
  border-radius: 10px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carouselCardRight {
  flex: 1;
  padding: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  justify-content: center;
}

.cardTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;

  @media (--viewportMobile) {
    font-size: 24px;
  }
}

.cardInfo {
  display: flex;
  gap: 3rem;
}

.cardLocation {
  display: flex;
  & img {
    width: 0.8rem;
    height: 0.8rem;
  }

  & span {
    margin-left: 0.5rem;
    font-size: 16px;
    font-weight: 400;

    @media (--viewportMobile) {
      font-size: 14px;
    }
  }
}

.cardRating {
  display: flex;
  gap: 3px;
  font-size: 16px;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 14px;
  }

  & img {
    width: 0.9rem;
    height: 0.9rem;
  }

  & h5 {
    margin: 0;
    color: #ffb534;
    font-size: 16px;
  }

  & span {
    color: #8c8c8c;
  }
}

.cardDescription {
  margin-top: 0.5rem;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.companiesSection {
  width: 100%;
  margin: 2rem auto;

  @media (--viewportMobile) {
    margin: 2rem auto 0 auto;
  }
}
.topHeadContainer img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.topHeadContainer {
  height: calc(100vh);
  width: 100%;
  /* background: var(--marketplaceColorBackground); */
  display: flex;
  color: var(--matterColorLight);
  margin-top: -5px;

  @media (max-width: 991px) {
    flex-flow: column;
    margin-top: -1px;
    max-height: unset;
    height: auto;
  }
}

.topHeadSectionLeft {
  flex: 1;
  height: 100%;
  /* justify-content: center; */
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-direction: column;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);

  & h1 {
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 65px;
    color: var(--matterColorLight);
    margin-bottom: 2rem;
    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  & section & .probuddyBookingTools {
    font-weight: normal;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    color: #c4c4c4;
    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 17px;
    }
  }
  & p {
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #c4c4c4;
    padding: 28px 0 42px 0;
    width: 609px;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 13px;
      width: 100%;
      padding: 24px 0 30px 0;
    }
  }
  & button {
    background: var(--matterColorLightBlue) !important;
    border-radius: 8px;
    width: 379px;
    height: 65px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorLight);
    @media (max-width: 991px) {
      width: 155px;
      height: 40px;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.sectionContainer {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    justify-content: center;
    align-items: center;
  }
}

.topHeadSectionRight {
  flex: 1;
  background: url('https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1661504770/probuddy/static/images/BecomeVendorPage/VendorHeroImage_ziy99j.png');
  background-size: cover;
}

.topHeadSectionRightEdu {
  flex: 1;
  background: url('https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1675838459/probuddy/static/NewEducation/BecomeTutor/pexels-vanessa-garcia-6325986_mntowk.jpg');
  background-size: cover;
}

.topHeadSectionOnlineTutor {
  flex: 1;
  background: url('https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1679996549/probuddy/static/NewEducation/Online_Tutor_1_aaf8xx.jpg');
  background-size: cover;
}

.topHeaderOnlinetutor {
  width: 100%;
}

.imageContainer {
  width: 75%;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* margin-top: 3rem; */
  padding: 4rem;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 1rem;

  @media (max-width: 991px) {
    width: 90%;
    padding: 1rem;
    margin: 1rem auto;
  }

  & h3 {
    font-size: 32px;
    color: white;
    text-align: center;
    line-height: 40px;

    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.probuddyVideo {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.businessContainer {
  width: 75%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  background-size: cover;
  border-radius: 1rem;

  margin-bottom: 100px;

  @media (max-width: 991px) {
    width: 90%;
    height: auto;
    margin: 1rem auto;
  }

  & h3 {
    text-align: center;
    max-width: 25rem;
    font-size: 40px;
    line-height: 45px;
    color: white;

    @media (max-width: 991px) {
      width: 90%;
      font-size: 22px;
      line-height: 30px;
    }
  }

  & h5 {
    text-align: center;
    max-width: 35rem;
    font-size: 25px;
    line-height: 32px;
    color: white;

    @media (max-width: 991px) {
      width: 90%;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.listingContainer {
  width: 75%;
  height: 350px;
  margin: auto;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  background-size: cover;
  border-radius: 1rem;
  background-color: #f7f7f7;

  @media (max-width: 991px) {
    width: 90%;
    flex-flow: column;
    margin-top: 1rem;
    height: 250px;
  }

  & .listingContainerText {
    display: flex;
    flex-flow: column;
    margin-left: 3rem;
    justify-content: center;
    @media (max-width: 991px) {
      margin-left: 0;
      padding: 1rem;
    }
  }
  & h3 {
    width: 100%;
    font-size: 26px;
    line-height: 35px;
    font-weight: 400;
    color: #000000;

    @media (max-width: 991px) {
      width: 85%;
      font-size: 15px;
      line-height: 22px;
    }
  }

  & .footballImage {
    max-width: 40rem;
    align-self: flex-end;
    width: 40%;
    height: 100%;

    @media (max-width: 991px) {
      margin-top: -4.6rem;
      margin-right: 1rem;
    }
  }

  & .probuddyLogo {
    width: 10rem;
    height: 4rem;

    @media (max-width: 991px) {
      height: 1.5rem;
      width: 6rem;
    }
  }
  & .blackButton {
    width: max-content;
    background-color: black !important;
    color: white !important;
  }
}

.paymentContainer {
  width: 75%;
  margin: 3rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  height: 500px;
  overflow: hidden;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  background-size: cover;

  @media (--viewportMobile) {
    width: 90%;
    margin: 1rem auto;
    flex-direction: column;
    height: auto;
  }

  & .paymentInfo {
    flex: 1;
    padding: 1rem 2rem;
    color: white;
  }

  & h3 {
    font-size: 34px;
    line-height: 44px;
    font-weight: 600;
    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  & h5 {
    font-size: 16px;
    font-weight: 400;

    @media (--viewportMobile) {
      font-size: 12px;
    }
  }

  & .paymentImage {
    flex: 1.6;
  }

  & .paymentImage img {
    height: 100%;
    object-fit: cover;

    @media (--viewportMobile) {
      height: 10rem;
      margin-left: 5rem;
    }
  }
}

.sectionImages {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* @media (max-width: 991px) {
    width: 100%;
    height: 210px;
    object-fit: contain;
  } */
}

.uniqueSectionContainer {
  max-width: 1180px;
  margin: 50px auto 0;
  @media (max-width: 991px) {
    margin: 20px 24px 20px;
  }
}

.advantagesSection {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3.5rem;
  margin-bottom: 3rem;
  justify-content: center;

  @media (max-width: 991px) {
    gap: 1rem;
  }
}

.advantageItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.advantageItemIcon img {
  width: 7rem;
  height: 7rem;
}

.advantageItemHeading {
  font-size: 20px;
  font-weight: 600;
}

.advantageItemSubtitle {
  font-size: 16px;
  text-align: center;
  color: #a8a8a8;
}

.pricingHeading {
  max-width: 1154px;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 991px) {
    display: none;
  }
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: #272727;
    margin: 0;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  & .fromFreelancers {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    padding: 24px 0 33px 0;
    color: var(--matterColorDark);
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 25px;
    }
  }
  & button {
    /* background: var(--matterColorLightBlue) !important; */
    background: var(--marketplaceColor) !important;
    border-radius: 50px;
    width: 239px;
    height: 62px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorLight);
    @media (max-width: 991px) {
      width: 228px;
      height: 64px;
      line-height: 27px;
    }
  }
}

.planContainer {
  max-width: 1154px;
  margin: 48px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (--viewportTabletDown) {
    flex-direction: column;
    margin: 0;
    align-items: center;
  }
}

.planHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  & h3 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: #272727;
    margin: 0;
    @media (--viewportMobile) {
      font-size: 30px;
    }
  }
  & h4 {
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    text-transform: capitalize;
    color: var(--marketplaceColorLight);
    margin: 0;
    @media (--viewportMobile) {
      font-size: 30px;
    }
  }
  & span {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: var(--matterColorAnti);
    @media (--viewportMobile) {
      font-size: 16px;
    }
  }
}

.planHeadingWhite {
  & h3 {
    color: var(--matterColorLight);
  }
  & h4 {
    color: var(--matterColorLight);
  }
  & span {
    color: white;
  }
}

.planPriceWrapper {
  display: flex;
  align-items: center;
  gap: 6px 0;

  @media (--viewportMobile) {
    display: none;
  }

  & span {
    color: #cbbeff;
  }
}

.planSection2 {
  width: 100%;
  height: auto;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  box-shadow: 0px 30px 40px 10px rgba(0, 149, 179, 0.15);
  border-radius: 22px;
  padding: 37px 45px 27px;

  @media (--viewportTabletDown) {
    margin-top: 28px;
    flex-direction: column;
    align-items: center;
  }

  @media (--viewportMobile) {
    width: 90%;
    height: auto;
    margin-top: 0px;
    flex-direction: column;
    padding: 1rem;
  }
  & a {
    width: 389px;
    min-height: 62px;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #0095b3;
    background: #ffffff !important;
    border: 1px solid #0095b3;
    box-sizing: border-box;
    border-radius: 49px;
    margin: 21px auto 8px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (--viewportMobile) {
      width: 100%;
    }
  }
}

.proPlanMobile {
  display: none;
  @media (max-width: 640px) {
    display: inline;
    flex-flow: column;
  }
}

.proPlanDivider {
  margin: 0.8rem 0;
}

.proPlanHeading {
  width: 12rem;
  font-size: 22px;
  font-weight: 500;
  color: white;

  @media (--viewportMobile) {
    width: 15rem;
  }
}

.proPlanPricing {
  width: 12rem;
  display: flex;
  align-items: center;
  gap: 6px 0;
  margin-bottom: 1rem;
  color: white;

  @media (--viewportMobile) {
    width: 15rem;
  }

  & h4 {
    font-size: 28px !important;
  }

  & span {
    color: #cbbeff;
  }
}

.proPlanFeatures {
  color: white;
  font-size: 22px;
  margin-bottom: 0.6rem;
  @media (--viewportMobile) {
    width: 15rem;
  }
}

.planLists {
  width: 12rem;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    width: 15rem;
  }

  & h3 {
    font-weight: bold;
    font-size: 30px;
    line-height: 55px;
    text-transform: capitalize;
    color: rgba(196, 196, 196, 0.6);
    margin: 31px 0 7px;
    @media (--viewportMobile) {
      font-size: 20px;
      line-height: 30px;
      margin: 28px 0 8px;
    }
  }
  & span {
    font-weight: normal;
    font-size: 15px;
    line-height: 25px;
    text-transform: capitalize;
    color: var(--matterColorDarkBlue);
    display: flex;
    margin-bottom: 22px;
    @media (--viewportMobile) {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 17px;
    }
  }
  & svg {
    margin-right: 13px;
    min-width: 1.5rem;
    @media (--viewportMobile) {
      width: 18px;
      height: 18px;
      margin-right: 0.5rem;
      margin-top: 3px;
      min-width: 1rem;
    }
  }
}

.planListsWhite {
  & h3 {
    color: rgba(255, 255, 255, 0.5);
  }
  & span {
    color: var(--matterColorLight);
  }
}

.billedMonthly {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorAnti);
  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.whiteText {
  color: #ffffff;
}
.selectPricesection {
  padding: 44px 0 99px;
  @media (--viewportMobile) {
    padding: 26px 19px 46px;
  }
}

.sectionImagesContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 390px;
  flex: 1;
}

hr {
  margin: 60px 0 25px;
}

.subscriptionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white !important;
  color: #2860fb;
  border-radius: 8px;
  width: fit-content;
  padding: 0 22px;
  height: 65px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 20px;
  }
}

.getProLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  width: fit-content;
  padding: 0 1.5rem;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-top: 1rem;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 14px;
    line-height: 19px;
  }
}

.learnMoreLink {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: white !important;
  color: black;
  border-radius: 8px;
  width: fit-content;
  padding: 0 1.5rem;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 1rem;
  text-align: center;
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 12px;
    line-height: 16px;
  }
}
.topHeaderButtonWrap {
  display: flex;
  gap: 10px;
  & a {
    color: #fff;
  }
}
.topbarContainer {
  width: 90%;
  /* margin: 0 auto; */
  @media (max-width: 991px) {
    width: 100%;
  }
}

.primaryActionBtn {
  & > a {
    width: 12rem;
    color: white;
    border-width: 1px;
    border-style: solid;
    border-color: var(--marketplaceColor);
    background-color: #ff876b !important;
    text-decoration: none;
    border-radius: 49px;
    &:hover {
      text-decoration: none;
    }
  }
}

.becomeSellerSection {
  max-width: 1154px;
  margin: 48px auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (--viewportTabletDown) {
    flex-direction: column;
    /* margin: 0; */
    align-items: center;
  }
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}
.breadCrumbWrapper {
  margin: 2rem auto 0;
  width: calc(75% + 4rem);
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.popularExercisesSection {
  width: 85%;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.productsMobileWrapper {
  margin-top: 50px;
  @media (--viewportTabletDown) {
    background: #fffdfd;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 30px 10px;
    margin: 20px;
  }
}
.autorListingWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportTabletDown) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.companyListing {
  margin: 45px auto;
  width: 85%;
  @media (--viewportxldesktop) {
    margin: 30px auto;
  }
  @media (--viewportTabletDown) {
    margin: 15px auto;
  }
}

.categoryId {
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  @media (--viewportxldesktop) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 26px;
  }
}
.whatsappLinkVendor a img {
  max-width: 100px;
  height: auto;
  /* border-radius: 30px; */
  position: fixed;
  z-index: 10000;
  top: 10px;
  right: 50px;
  border-radius: 8px;
  @media (--viewportMobile) {
    right: 0;
    top: 120px;
  }
}
.planCardsWrapper {
  display: flex;
  width: 100%;
  padding: 0 200px;
  justify-content: space-between;
  gap: 50px;

  @media (--viewportTabletDown) {
    padding: 0 20px;
    flex-direction: column;
    gap: 30px;
  }
}
.planCard {
  background: #ffffff;
  box-shadow: 0px 22.8958px 86.677px rgb(0 0 0 / 10%);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #000000;

  @media (--viewportTabletDown) {
    gap: 15px;
  }
}

.planTitle {
  font-weight: 700;
  font-size: 34px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;

  @media (--viewportTabletDown) {
    font-size: 24px;
    line-height: 22px;
  }
}

.planPrice {
  font-weight: 700;
  font-size: 45px;
  line-height: 30px;
  text-align: center;

  @media (--viewportTabletDown) {
    font-size: 35px;
    line-height: 22px;
  }
}

.perMonth {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  opacity: 0.5;

  @media (--viewportTabletDown) {
    font-size: 13px;
    line-height: 22px;
  }
}

.includesTitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 30px;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 22px;
  }
}

.descriptionWrap {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;

  @media (--viewportTabletDown) {
    height: 100px;
  }
}

.desWrap {
  display: flex;
  gap: 20px;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  align-items: center;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}

.planDivider {
  width: 100%;
  height: 0px;
  left: 250px;
  top: 1573px;
  border: 0.5px solid #a8a8a8;
}
.popularWraper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;

  @media (--viewportTabletDown) {
    display: none;
  }
}

.popular {
  position: relative;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 55px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  color: #ffffff;
}
.popular:before {
  content: '';
  position: absolute;
  bottom: -19px;
  left: 45%;
  border: solid 10px transparent;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-top-color: #4a76ef;
  z-index: 1;
}
.partnerImg {
  width: 250px;
  height: 78px;
  object-fit: cover;
  margin: auto;
  @media (--viewportMobile) {
    width: 220px;
    height: 70px;
  }
}
.planBtn {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 15px;
  height: 50px;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.planBtn:hover {
  cursor: pointer;
}
.anchfree {
  color: #ffffff;
  line-height: 16px;
}
.anchfree:hover {
  text-decoration: none;
}
.planCard:nth-child(2) {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  color: #ffffff;
}

.planCard:nth-child(2) .buttonText {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: #ffffff;
}

.planCard:nth-child(2) .planBtn {
  background: #ffffff;
}

.planCard:nth-child(2) a {
  background: #ffffff;
}
.btnClose {
  font-size: 18px;
  line-height: 28px;
  cursor: pointer;
  text-align: center;
  background-color: #005aff;
  color: #fff;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 150px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}
.btnGroup {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (--viewportTabletDown) {
    justify-content: space-around;
    margin: 10px 0;
  }
  @media (--viewportMobile) {
    flex-direction: column;
  }
}
.btnLogin {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  background-color: #fe6436;
  color: #fff;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 150px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 20px;
  }
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 18px;
  }
}
a:hover {
  border: none;
  text-decoration: none !important;
  text-underline-offset: none;
  cursor: none;
}
