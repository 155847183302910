@import '../../marketplace.css';

.root {
  width: 100%;
  position: relative;
  @media (--viewportMobile) {
    position: sticky;
    bottom: 0;
    z-index: 9;
  }
}
