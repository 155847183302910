@import '../../marketplace.css';

.headerWrap {
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 15px;
  transition: 0.5s;
  border-bottom: 1px solid transparent;
  z-index: 9;
}
.headerBg {
  background-color: var(--matterColorLight);
  border-bottom: 1px solid #7e7e7e4a;
}
.iconGrop {
  display: flex;
  gap: 10px;
}
.iconWrap {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  background: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconWrap svg {
  height: 20px;
  width: 20px;
  fill: none;
  stroke: var(--matterColorDark);
}
.wrapperInputHead {
  font-weight: 600;
  font-size: 15px;
  color: #005df4;
  background-color: #ffffff;
  margin: 0;
  padding: 15px;
  border-radius: 12px;
}
.wrapperInputHead:active {
  color: #ffffff;
  background-color: #005df4;
  border: 2px solid #fff;
}
.wrapperInputHeadEdu {
  color: #fe575b;
}
.wrapperInputHeadEdu:active {
  color: #ffffff;
  background-color: #fe575b;
  border: 2px solid #fff;
}

.floatingTopBar {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 99;
  @media (--viewportMobile) {
    gap: 0;
  }
}

.wrapperInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  /* @media (--viewportMobile) {
    gap: 12px;
    display: none;
  } */
}
.wrapperInput.floatingInput {
  position: fixed;
  flex-direction: column;
  background: linear-gradient(180deg, #0058fa 26.39%, rgba(45, 104, 213, 0.43) 100%);
  box-shadow: -4px 4px 11px 2px #0005;
  border-radius: 25px;
  padding: 20px;
  right: 0;
  top: 10px;
  height: 75px;
  /* box-shadow: 0 -2px 5px 2px #0000002b; */
}
.wrapperInput.floatingInputEdu {
  background: linear-gradient(180deg, #fe575b 26.39%, rgb(254 87 91 / 43%) 100%);
}
