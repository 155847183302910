.shareFormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 50px;
}
.shareFormTitle {
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
.shareFormListingTitle {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
.shareFormListingWrapper {
  display: flex;
  column-gap: 15px;
  align-items: center;

  @media (--viewportMedium) {
    flex-direction: column;
  }
}
.shareFormIconWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 8px;
  background: rgb(255, 255, 255) !important;
}
.shareFormImageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportTabletDown) {
    height: 75px;
    width: 75px;
  }
}
.shareFormImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.shareFormNameIconWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.iconName {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.iconWrap {
  height: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shareFormNameIconWrapper button {
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px !important;
  border: 1px solid rgb(235, 235, 235) !important;
  border-radius: 8px;
  background: rgb(255, 255, 255) !important;
}
.shareFormNameIconWrapper svg {
  height: 25px;
  width: 25px;
}
