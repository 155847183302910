@import '../../marketplace.css';

.expectationItem {
  display: flex;
  column-gap: 45px;
  @media (min-width: 768px) and (max-width: 1199px) {
    column-gap: 25px;
  }
  @media (--viewportMobile) {
    display: block;
    margin: 0;
  }
}

.expectationItem:last-child {
  @media (--viewportMobile) {
    padding-bottom: 0;
  }
}

.imageWrap {
  height: 330px;
  flex: 1;
  @media (max-width: 1199px) {
    height: 200px;
  }
  @media (--viewportTabletDown) {
    width: 100%;
    height: 260px;
  }
  & > img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    /* object-position: top; */
    @media (--viewportMobile) {
      border-radius: 5px;
    }
  }

  & > video {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
  }
}
.expectDetailWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media (--viewportTabletDown) {
    row-gap: 15px;
  }
}
.DetailContainer {
  margin-top: 25px;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;
  color: #272727;
  /* padding: 0 49PX 0 0; */
  /* background: var(--matterColorLightGrey); */
  align-items: flex-start;
  display: flex;
  flex: 2;
  & h3 {
    margin: 0;
  }
  & pre {
    margin: 0;
  }
  @media (--viewportMobile) {
    width: 100%;
  }
}

.paragraphDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: var(--matterColorDark);
  white-space: break-spaces;
  @media (--viewportTabletDown) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    height: auto;
  }
}

.paragraphDescriptionWithBackground {
  @media (--viewportMobile) {
    background: #fcfcfc;
    color: #272727;
    padding: 24px 24px 48px;
  }
}

.DetailContainerWithBackground {
  @media (--viewportMobile) {
    width: 100%;
    padding: 0 0 19px 0;
  }
}

.paragraphButton {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.reverseSection {
  flex-direction: row-reverse;
}
