@import '../../marketplace.css';

.root {
}

.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  border: 2px solid var(--attentionColor) !important;
  padding: 10px;
}

.extraLabel {
  font-size: 14px;
  color: gray;
  line-height: 18px;
}
