@import '../../marketplace.css';

:root {
  --cardSizeDesktop: 185px;
  --cardSizeMobile: 175px;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  -webkit-box-shadow: 0px 1px 8px -1px #00000090;
  box-shadow: 0px 1px 8px -1px #00000090;
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.descForImage {
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  /* padding: 0.2rem; */
  flex: 1 1 auto;
  margin: 10px;

  @media (--viewportLarge) {
    /* padding: 0.6rem; */
    height: unset;
  }
}

.price {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  font-size: 14px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.no_bold {
  font-weight: 300;
}
.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 300;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.review {
  font-size: 12px;
}

.review_withYellow {
  font-size: 12px;
  color: #ffc400;
}
.star {
  display: inline-flex;
}

.star-container {
  vertical-align: unset;
}

.heart {
  float: right;
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.linkText {
  width: 100%;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  font-size: 10px;
  @media (--viewportMobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.avatarImg {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 10px;
  @media (--viewportLarge) {
    height: 40px;
    width: 40px;
  }
  @media (--viewportMobile) {
  }
  display: none;
}

.name {
  font-weight: bold;
  font-size: 13px !important;
  line-height: 16px !important;
  color: var(--marketplaceColor);
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
}

.levelContainer {
  margin-left: auto;
  & div {
    padding: 0px;
  }
  @media (--viewportMobile) {
    margin-left: initial;
  }
}

.listingLink {
  /* margin: 0 auto; */
  text-decoration: none !important;
  font-size: 16px !important;
}

.headline {
  display: -webkit-box;
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 8px;
  max-height: 42px;
  font-size: 14px;
  overflow: hidden;
  width: 125px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  white-space: break-spaces;
  word-wrap: break-word;
  font-weight: bold;

  @media (--viewportLarge) {
    margin-top: 5px;
    width: unset;
    margin: 0;
    min-height: 32px
  }
  @media (--viewportMobile) {
    min-height: 38px;
  }
}

.star_Wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}

.rating {
  color: #e82953;
  font-size: 10px;
  font-weight: 550;

  @media (--viewportLarge) {
    font-size: 14px;
  }
}

.reviewCount {
  font-size: 12px;
  margin-left: 5px;
  color: #707070;
}

.description {
  margin-top: 4px;
  margin-bottom: 0px;
  font-size: 11px;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  font-weight: normal;
  text-align: left;
  line-height: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--matterColorDimmedText);
}

.favourite {
  margin-top: 10px;
  font-size: 12px;
  text-align: right;
  color: #30c7be;
}

.favourite a {
  display: flex;
  justify-content: flex-end;
  color: #30c7be;
}

.popular {
  background-color: var(--matterColorLight);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 0;
  height: fit-content;
  width: var(--cardSizeDesktop);
  position: relative;
  border-radius: 10px;
  margin: 10px 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.047);
  font-family: 'HelveticaNeue', 'Open Sans', sans-serif;
  @media (--viewportMobile) {
    width: var(--cardSizeMobile);
    margin: 10px auto;
  }
  @media (min-width: 360px) and (max-width: 400px){
      width: 145px;
  }
  @media (min-width: 401px) and (max-width: 460px){
      width: 170px;
  }
}

.popular a,
.popular span,
.popular p,
.popular div {
  font-family: 'HelveticaNeue', 'Open Sans', sans-serif;
}

.popularImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: 0px;
  object-fit: cover;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popularImageHover{
  animation: ZoomIn 2.15s linear infinite;
}

@keyframes ZoomIn{
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.3);
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */
  height: 233px;
  @media (--viewportMedium) {
    height: 229px;
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
  object-fit: cover;
}

.startingAt span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  @media (--viewportLarge) {
    font-size: 16px;
  }
}

.startingAt {
  font-size: 10px;
  text-align: left;
  padding: 5px 15px;
  color: var(--matterColorLight);
  background-color: var(--marketplaceColorDarkest);
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 10px 0 0 0;
}

.whatsappSection{
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 11px 0 0 0;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.iconWhatsapp{
 width: 21px;
 height: 21px;
}