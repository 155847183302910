@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.validTokenMessage {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-top: 24px;
  margin-bottom: 24px;

  border-radius: 4px;
  background: var(--successColorLight);
  border: 1px solid var(--successColor);
  padding: 8px 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.inValidTokenMessage {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-top: 24px;
  margin-bottom: 24px;

  border-radius: 4px;
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
  padding: 8px 24px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.icon {
  margin-right: 10px;
}
.layoutTopbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  height: fit-content;
  @media (--viewportMobile) {
    height: 50px;
  }
}
.layoutFooter {
  width: 100%;
  position: relative;
}
