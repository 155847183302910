@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 5px;
  --TopbarSearchForm_mobilePredictionTop: calc(
    var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
      var(--TopbarSearchForm_bottomBorder)
  );
}

.mobileInputRoot {
  width: 100%;

  @media (--viewportLaptopDown) {
    background: #ffffff;
    box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
    border-radius: 15px;
    min-width: 346px;
    min-height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 29px;
  }
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;
  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);
  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  @apply --marketplaceH1FontStyles;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 1px 13px 13px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  border: none;
  display: flex;
  padding: 3px 15px 0 30px;
  align-self: stretch;

  @media (--viewportTabletDown) {
    padding: 0px 13px 0 24px;
  }
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-family: 'Int Circular PTT';
  font-weight: 450;
  line-height: unset;
  border: none;
  padding: 0;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportTabletDown) {
    font-size: 12.9841px;
    line-height: 16px;
  }
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(100vh - var(--TopbarSearchForm_mobilePredictionTop));
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 76px;
  /* left: 0; */
  background-color: #ffffff;
  border-radius: 17px;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  margin-top: calc(
    var(--topbarHeightDesktop) - var(--TopbarSearchForm_inputHeight) +
      var(--TopbarSearchForm_bottomBorder)
  );
  max-width: 434px;
  width: 414px;
  height: max-content;
  max-height: 460px;
  @media (--viewportxldesktop) {
    right: 0;
  }
  @media (--viewportTabletDown) {
    height: 45vh;
    top: 65px;
    margin: 0;
    width: 344px;
  }

  @media (--viewportLaptopDown) {
    position: unset;
    margin: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: ;
    box-shadow: none;
    padding: 0;
  }
}

.desktopPredictions [class*='currentLocation'],
.desktopPredictions [class*='currentLocation'] {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  position: relative;
  display: flex;
  margin: 0;
  font-weight: 400;
  align-items: center;

  @media (--viewportTabletDown) {
    font-size: 15.0435px;
    line-height: 19px;
    align-items: baseline;
  }

  @media (--viewportLaptopDown) {
    font-weight: 400;
    font-size: 15.0435px;
    line-height: 19px;
  }
}

.desktopPredictions [class*='currentLocationIcon'],
.desktopPredictions [class*='currentLocationIcon'] {
  display: none;
}

.desktopPredictions [class*='currentLocation']:before,
.desktopPredictions [class*='currentLocation']:before {
  content: '';
  width: 30px;
  height: 25px;
  background: url('../../assets/HomePage/Location.svg');
  background-repeat: no-repeat;
  top: 4px;
  position: relative;
  /*position: absolute;*/
  display: inline-block;

  @media (--viewportTabletDown) {
    width: 12px;
    height: 15px;
    background-size: cover;
    background-position: center;
    margin-right: 12px;
  }
}

.desktopPredictions [class*='predictions'] li,
.desktopPredictions [class*='predictions'] li {
  font-size: 12.8395px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 29px;

  @media (--viewportTabletDown) {
    padding: 13px 10px 15px 29px;
  }

  @media (--viewportLaptopDown) {
    padding: 13px 0;
  }
}

.desktopPredictions [class*='predictions'] li:hover [class*='predictionsWrap'],
.desktopPredictions [class*='predictions'] li:hover [class*='predictionsWrap'] {
  font-weight: 700;
  line-height: 24px;
  color: #000000;
}

.desktopPredictions [class*='predictionsWrap'],
.desktopPredictions [class*='predictionsWrap'] {
  position: relative;
  width: 100%;
  white-space: pre-line;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;

  @media (--viewportTabletDown) {
    padding-left: 24px;
  }
}

.desktopPredictions [class*='predictionsWrap']:before,
.desktopPredictions [class*='predictionsWrap']:before {
  content: '';
  width: 30px;
  height: 30px;
  background: url('../../assets/HomePage/Location.svg');
  background-repeat: no-repeat;
  top: calc(50% - 8px);
  left: 0;
  /*position: relative;*/
  position: absolute;
  display: inline-block;

  @media (--viewportTabletDown) {
    width: 12px;
    height: 15px;
    background-size: cover;
    background-position: center;
  }
}

.showActivityContainer {
  position: absolute;
  background-color: #ffffff;
  border-radius: 17px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: calc(50 + 1);
  margin-top: calc(72px - 53px + 3px);
  /* min-width: 750px; */
  min-width: 100%;
  min-height: 500px;
  height: 320px;
  padding: 30px;

  @media (--viewportTabletDown) {
    min-width: 100%;
    min-height: unset;
    height: 65vh;
    left: 0;
    padding: 20px 12px;
    margin: 12px 0 0 0;
  }

  @media (--viewportLaptopDown) {
    position: unset;
    margin-top: 15px;
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.searchQuery {
  flex: 1 1;
  /*min-width: 276px;*/
  position: relative;
  transition: border-color 0.2s ease 0s;
  cursor: pointer;
  background: #ffffff;
  border-radius: 50px;
  width: 100%;
  /*max-width: 214px;*/
  height: 50px;
  display: flex;
  border: 2px solid #0058fa;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1b;

  @media (--viewportLaptopDown) {
    transform: scale(1.03);
  }

  @media (--viewportMobile) {
    width: 100%;
    min-width: 100%;
  }

  @media (--viewportTabletDown) {
    height: 38.95px;
    margin: 0 0 10px 0;
  }

  & input {
    font-family: 'Int Circular PTT';
    height: 100%;
    font-weight: 500;
    border: none;
    font-size: 16px;
    padding: 0 0 0 0.2rem;
    line-height: 21px;
    color: #1d1d1d;
    width: calc(100% - 30px);
    margin-left: auto;

    &::placeholder {
      font-style: normal;
      color: #cecece;
    }

    @media (--viewportMobile) {
      padding: 0 0 0 10px;
    }

    @media (--viewportTabletDown) {
      font-size: 12.9841px;
      line-height: 16px;
      width: calc(100% - 16px);
    }
  }

  &:not(.active-search-query):hover {
    border-color: #0058fa;
  }

  & :global(.react-autosuggest__container) {
    height: 48px;
    padding-left: 30px;
    padding-right: 0;
    width: 100%;

    @media (--viewportTabletDown) {
      height: 100%;
      padding-left: 20px;
    }
  }
}

.singleActivityContainer {
  /* min-width: 350px; */

  @media (--viewportTabletDown) {
    min-width: 100%;
    right: 0;
    height: 35vh;
  }

  @media (--viewportLaptopDown) {
    min-width: 100%;
    position: unset;
  }
}

.activeSearchIcon {
  position: absolute;
  top: 16px;
  left: 30px;

  @media (--viewportTabletDown) {
    top: 12px;
    left: 20px;
    width: 12px;
  }
}

.searchQuery :global(.react-autosuggest__suggestions-container--open) {
  top: 50px;
  background-color: #ffffff;
  z-index: calc(50 + 1);
  min-height: 385px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  box-shadow: none;
  margin: 15px 0 0 0;

  @media (--viewportTabletDown) {
    min-height: unset;
    margin: 0;
    overflow: auto;
    height: 53vh;
    width: calc(100% - 3px);
  }
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 35%);
  background-color: #f5f5f5;
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.searchQuery :global(.react-autosuggest__suggestions-container--open)::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 35%);
}

.searchQuery :global(.react-autosuggest__suggestion),
.searchPageInputWrapper :global(.react-autosuggest__suggestion) {
  font-weight: 400;
  font-size: 16.8395px;
  line-height: 24px;
  color: #000000;
  border: 1px solid transparent;
  padding: 13px 20px;

  @media (--viewportTabletDown) {
    padding: 10px 0;
  }
}

.searchQuery :global(.react-autosuggest__suggestion):hover,
.searchPageInputWrapper :global(.react-autosuggest__suggestion):hover {
  border-radius: 8px;
  font-weight: 700;
}

.searchQuery :global(.react-autosuggest__suggestion):hover .suggetionDotWrap,
.searchPageInputWrapper :global(.react-autosuggest__suggestion):hover .suggetionDotWrap {
  font-weight: 700;
}

.searchQuery :global(.react-autosuggest__suggestions-list) {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (--viewportTabletDown) {
    grid-template-columns: 1fr;
  }
}

.singleActivityContainer .searchQuery :global(.react-autosuggest__suggestions-list) {
  display: grid;
  grid-template-columns: 1fr;
}

.singleActivityContainer .searchQuery :global(.react-autosuggest__suggestions-container--open) {
  @media (--viewportTabletDown) {
    height: 28vh;
  }
}

.suggestionContainer {
  display: flex;
  align-items: center;
}

.suggestionImg {
  border-radius: 50%;
  margin-right: 15px;
  width: '38px';
  height: '38px';
  @media (--viewportTabletDown) {
    width: 30.84px;
    height: 30.84px;
  }
}

.suggestionDetailsContainer {
  display: block;
  font-family: 'Int Circular PTT';
}

.suggestionDetailsText {
  font-style: normal;
  font-weight: 400;
  font-size: 12.5283px;
  line-height: 16px;
  color: #a8a8a8;

  @media (--viewportTabletDown) {
    font-size: 10.1668px;
    line-height: 13px;
  }
}

.suggestionTitle {
  font-style: normal;
  line-height: 21px;
  color: #000000;
  font-weight: 500;
  font-size: 16.7044px;

  @media (--viewportTabletDown) {
    font-size: 13.5557px;
    line-height: 17px;
  }
}

.ratingTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (--viewportTabletDown) {
    justify-content: space-between;
  }
}

.activityContainerRight {
  right: 0;
  min-width: 100%;
}

/* .activityHeader:after {
  position: absolute;

  height: 30px;
  width: 2px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #d8d7d7;
}
.activityHeader:before {
  position: absolute;

  height: 30px;
  width: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00000015;
} */
.activitySelectedLocation,
.activityRatingActive {
  &:before {
    background-color: transparent;
  }
}

.activitySelectedRating,
.activityRatingActive {
  &:after {
    background-color: transparent;
  }
}

.ratingHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #383838;

  /*background: #ffffff;*/
  border-radius: 0;
  border: 1.5px solid transparent;
  width: 100%;
  height: 100%;
  padding: 0 37px;
  position: relative;
  text-align: left;

  showActivityContainer @media (--viewportTabletDown) {
    font-size: 10px;
    line-height: 13px;
  }

  @media (--viewportLaptopDown) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  & > span {
    opacity: 0.8;
  }
}

.ratingHeaderButton {
  @media (--viewportLaptopDown) {
    font-weight: 500;
    font-size: 15.0435px;
    line-height: 19px;
    color: #000000;
    border: none;
    width: 100%;
  }
}

.activityRatingActive:before {
  display: none;
}

.activityRatingActive {
  box-shadow: 0px 0px 44px 1px rgb(0 0 0 / 15%);
  border-radius: 51px !important;

  &:hover {
    background-color: transparent;
  }

  @media (--viewportLaptopDown) {
    border-radius: unset;
    box-shadow: none;
    font-weight: 500;
    font-size: 15.0435px;
    line-height: 19px;
    color: #000000;
    opacity: unset;
  }
}

.ratingContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportLaptopDown) {
    max-width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

.ratingContent {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1b;
  text-transform: capitalize;

  @media (--viewportLaptopDown) {
    font-size: 15.0435px;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 100%; */
  }
}

.clearRatingButton {
  border-radius: 50%;
  border: 0;
  background-color: #0058fa;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.showRatingContainer {
  position: absolute;
  width: calc(100% - 10%);
  background-color: #ffffff;
  border-radius: 17px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  z-index: calc(50 + 1);
  margin-top: calc(72px - 53px + 3px);
  max-width: 705px;
  min-height: 120px;
  display: flex;
  align-items: center;

  @media (--viewportLaptopDown) {
    position: unset;
    width: 100%;
    border-radius: unset;
    box-shadow: none;
    margin: 0;
    min-height: unset;
    max-width: unset;
    margin-bottom: 20px;
  }
}

.showRatingTabContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;

  @media (--viewportTabletDown) {
    right: 0;
    width: 100%;
    flex-wrap: wrap;
  }

  @media (--viewportLaptopDown) {
    padding: 0;
    gap: 20px;
    margin-top: 15px;
  }
}

.showRatingContainerRight {
  right: 0;
}

.ratingButton {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1b;
  flex: 1;
  height: 50px;
  cursor: pointer;
  text-transform: capitalize;

  @media (--viewportTabletDown) {
    font-size: 10.2857px;
    line-height: 13px;
    height: 32.14px;
    border: 0.642857px solid #d9d9d9;
    border-radius: 6.42857px;
    padding: 0 15px;
  }

  @media (--viewportLaptopDown) {
    min-width: 82px;
    max-width: unset;
    display: flex;
    white-space: pre;
    align-items: center;
    padding: 8px;
    text-align: center;
    text-align: center;
    justify-content: center;
  }

  @media (--viewportMobile) {
    max-width: 85px;
  }
}

.ratingActiveButton {
  background: rgba(0, 88, 250, 0.1);
  border: 1px solid #0058fa;
  color: #0058fa;
}

.searchIcon img {
  height: 100%;
  margin-right: 0;
  cursor: pointer;
}

.searchIcon svg path,
.searchIcon svg circle {
  stroke: #f75959;
  fill: transparent;
}

.searchIcon {
  width: 46px;
  height: 46px;
  border: transparent;
  padding: 0;
  margin-right: 18px;

  @media (--viewportTabletDown) {
    width: 31.7px;
    height: 31.7px;
    margin-right: 10px;
  }
}

.clearActivityButton {
  border-radius: 50%;
  border: 0;
  background-color: #0058fa;
  cursor: pointer;
  min-width: 22px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 15px;

  @media (--viewportLaptopDown) {
    position: unset;
  }
}

.activityOptionWrapper {
}

.ratingPopup {
  width: 100%;
}

.activityOptionWrapper [class*='react-autosuggest'] {
  @media (--viewportLaptopDown) {
    height: 100%;
  }
}

.modalFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  height: 70px;
  background-color: #ffff;
  width: 100%;
  left: 0;
  padding: 0 44px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px 12px 0px 0px;
  font-weight: 500;
  font-size: 15.0435px;
  line-height: 19px;
  z-index: 999;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 105px;
    background: #0058fa;
    border-radius: 7px;
    height: 40px;
    color: #fff;
    gap: 11px;
    border: 0;
  }

  & .clearBtn {
    min-width: 105px;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 7px;
  }
}
.mobileTabWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 26px;
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  position: relative;
  height: 60px;
}
.selectedTab {
  border: 1px solid var(--matterColorBlue);
}

.selectedTab .checkMark {
  display: flex;
}
.listingTypeName {
  text-transform: capitalize;
}
.tabIcon {
  height: 20px;
  width: 20px;
}
.checkMark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 6px 0px 4px;
  background: var(--matterColorBlue);
  align-items: center;
  justify-content: center;
  display: none;
}
