@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
  margin-top: 8px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }

  @media (--viewportMobile) {
    margin-top: 20px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  margin-top: 0.5rem;

  @media (--viewportMobile) {
    margin: 1rem 0 0 0;
    text-align: center;
  }
}

.recoveryLink {
  @apply --marketplaceModalHelperLink;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperText;
}
