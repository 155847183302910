@import '../../marketplace.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
  margin-bottom: 25px;
}

.field {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.emailfield {
  margin: 0 0 24px 0;
  height: 42px;
  @media (--viewportMedium) {
    margin: 0 0 40px 0;
  }
}
.emailfield [class*='inputSuccess'] {
  border: 2px solid #ffaa00 !important;
}

.emailfield label {
  display: none;
}

.emailfield textarea {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  line-height: 15px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* .sendInputWrap textarea {
  padding: 10px 45px 5px 10px;
  height: 25px !important;
  width: 87%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  @media (--viewportMobile) {
    width: 83%;
  }
} */

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
.SendMsgKeywordWrap input {
  width: 100%;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  background: var(--matterColorLight);
  border: 1px solid #dce5f2;
  border-radius: 29px;
  height: 100%;
  padding: 10px 50px 10px 10px;
  &:focus {
    border-color: #dce5f2;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #bec8d7;

    @media (--viewportTabletDown) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  @media (--viewportMobile) {
    width: 100%;
  }

  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 15px;
  }
}
.SendMsgKeywordWrap {
  width: 100%;
  display: flex;
  column-gap: 15px;
}
.MsgWrap {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;

  @media (--viewportMobile) {
    width: 100%;
    border-radius: 28px;
  }
}

.MsgWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;

  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.MsgWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}
.sendInputWrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.sendInputWrap div > div {
  position: absolute;
  bottom: -35px;
}
.sendInputWrap [class*='textarea'] {
  box-sizing: border-box;
  height: 80px !important;
  padding-right: 35px;
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(144 144 144 / 35%);
  }
}

.messageToolsWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;

  @media (--viewportMobile) {
    width: auto;
    bottom: auto;
    top: auto;
    padding-top: 10px;
    padding-left: 0;
  }
}

.messageToolsWrapper {
  gap: 15px;

  @media (--viewportTabletDown) {
    gap: 10px;
  }
}
.messageToolsWrapper svg {
  width: 20px;
  height: 20px;
  fill: var(--matterColorLight);
}
.addIcon {
  width: 50px;
  height: 50px;
  background: var(--matterColorBlue);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportxldesktop) {
    width: 46px;
    height: 40px;

    & img {
      width: 12px;
      height: 12px;
    }
  }
}

.sendButton {
  min-height: unset !important;
  border-radius: 28px !important;
  min-width: 36px;
  width: 36px !important;
  position: absolute !important;
  right: 5px !important;
  bottom: 5px !important;
  height: 36px;
  background: var(--matterColorBlue);

  &:hover {
    background-color: #2e43b7;
  }

  @media (--viewportTabletDown) {
    width: 28px !important;
    min-width: 28px;
    height: 28px;
  }

  @media (--viewportTabletDown) {
    bottom: 5px !important;
  }
}
.svgContainer {
  background: #ffffff;
  box-shadow: 0px 19px 18px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 61px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  & svg {
    background-color: transparent;
    fill: none;
    width: 30px;
    height: 30px;
  }
}
.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;

  @media (--viewportxldesktop) {
    margin-bottom: 15px;
  }

  @media (--viewportLaptopDown) {
    top: 0;
    width: 100%;
    left: 0;
    height: 73px;
    padding: 0 26px 0 10px;
    margin: 0;
  }
}

.titleWrapper h1 {
  margin: 0;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;

  @media (--viewportLaptopDown) {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-left: 22px;
  }
}
.enquiryFeedHead {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-weight: 500;
  gap: 20px;
  padding: 35px 0;

  @media (--viewportxldesktop) {
    padding: 15px 15px 10px 15px;
  }

  @media (--viewportTabletDown) {
    padding: 15px 22px;
    font-size: 14px;
    line-height: 18px;
  }
}

.enquiryFeedHead h4 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #0b1333;
  margin-bottom: 4px;

  @media (--viewportTabletDown) {
    margin: 0;
  }
}
.avatar {
  width: 60px;
  height: 60px;
  border-radius: 13px;

  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
}
.feedUserName {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #aba7a7;

  @media (--viewportMobile) {
    padding-left: 21px;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    color: var(--marketplaceColor);
  }

  @media (--viewportTabletDown) {
    color: #aba7a7;
    padding: 0;
    font-size: 10px;
    line-height: 13px;
  }
}
.goBackIcon {
  display: none;
  @media (--viewportLaptopDown) {
    display: block;
    display: flex;
    align-self: baseline;
    margin-left: auto;
  }
}
.feedIconDot {
  display: flex;
  align-self: baseline;
  margin-left: auto;
}
.noListing {
  margin: 15px 0;
  color: red;
}
