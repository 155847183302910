@import '../../../../marketplace.css';

.checkboxContaner {
  background: var(--marketplaceColorDarker);
}

.row {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;

  @media (--viewportMobile) {
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  &:nth-child(even) {
    background: #f7f9fa;
  }

  &.clickable {
    cursor: pointer;
    background: var(--marketplaceColorDarker);
    color: var(--matterColorLight);

    & .nameContent {
      font-weight: bold;
    }
  }
}

.clientRow {
  display: flex;
  background: #e0f2f7;

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }
}

.cell {
  margin: 18px 0 18px 1.8%;
  font-size: 14px;
}

.nameContent,
.listingNameContent {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }
}

.clientName {
  width: 50%;
  text-align: left;
}

.name {
  position: ;
  width: 10%;
}

.time {
  width: 6.5%;
}

.listingName {
  width: 30%;

  @media (--viewportMobile) {
    width: 13%;
  }
}

.clientMembership {
  width: 10%;
}

.membership {
  width: 9.5%;
}

.marked {
  position: relative;
  width: 2.5%;

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--marketplaceColor);
  }

  &.not {
    &:before {
      background-color: #f2f6f8;
    }
  }
}

.weekday {
  margin-bottom: 24px;

  & .contentInner {
    background: #ffffff;
    border: 2px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 12px;
  }
}

.collapsibleWeekday {
  margin-bottom: 12px;
  border: 1px solid #ededed;
  border-radius: 12px;
  padding: 10px;
}

.weekdayRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 18px;
  margin: 0 16px;

  @media (--viewportMobile) {
    padding: 0 0 5px;
    margin: 0;
    position: relative;
  }
}

.weekdayRowWithPadding {
  padding-bottom: 15px;
}

.collapsibleRowWithPadding {
  padding: 10px 1.8% 18px 0.8%;
  width: 100%;
  display: flex;
  align-items: center;
}

.weekdayName {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: var(--matterColorAnti);
  text-transform: capitalize;
}

.collapsibleHeadRow {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 0 25px 0 0;
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  width: 100%;
  position: relative;
  color: var(--matterColorAnti);
  text-transform: capitalize;

  @media (--viewportMedium) {
    margin: 0 16px;
    padding: 0 12px;
  }
}

.bookingNumber {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  color: #0058fa;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  /* & {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;

  } */
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 20px;
  }
}

.collapsible {
  height: auto;
  overflow: hidden;
  transition: height 2s linear;

  &.hidden {
    height: 0;
  }
}

.collapseIcon {
  margin-right: 16px;
  transform: rotate(180deg);
  transition: transform 0.5s linear;

  &.collapsed {
    transform: rotate(0deg);
  }
}

.collapseIconMultiBooking {
  transform: rotate(0deg);
  fill: var(--matterColorLight);

  &.collapsed {
    transform: rotate(180deg);
  }
}

.listItem {
  /* Layout */
  /* height: 100px; */
  display: flex;
  margin-bottom: 10px;
  padding: 12px 20px;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    /* margin-bottom: 24px; */
    /* padding: 15px; */
    margin: 0 16px;
    margin-bottom: 10px;
  }

  @media (--viewportMobile) {
    flex-direction: column;
    position: relative;
    padding: 22px 12px;
  }
}

.collapsibleListItem {
  border-top: 1px solid var(--matterColorNegative);
  /* border-bottom: 0; */
}

.checkboxInput {
  display: inline;
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.checkboxField {
  position: relative;
  display: flex;
  align-items: center;

  @media (--viewportLarge) {
    margin-left: 9px;
  }

  @media (--viewportMobile) {
    /* top: auto; */
    right: 6px;
    position: absolute;
    /* margin-left: 60px; */
  }

  & > span {
    font-size: 16px;
    padding-left: 5px;
  }
}

.checkboxField label {
  background-color: var(--matterColorLight);
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  border: 1px solid #0058fa;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.checkboxField label:after {
  border: 3px solid var(--matterColorLight);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 50%;
  transform: translate(-50%, -75%) rotate(-45deg);
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 12px;

  @media (--viewportMobile) {
    border-width: 2px;
  }
}

.checkboxField input[type='checkbox'] {
  visibility: hidden;
}

.checkboxField input[type='checkbox']:checked + label {
  background-color: #0058fa;
  border-color: #0058fa;
}

.checkboxField input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.collapsible {
  height: auto;
  overflow: hidden;
  transition: height 2s linear;

  &.hidden {
    height: 0;
  }
}

.collapseIcon {
  margin-right: 16px;
  transform: rotate(180deg);
  transition: transform 0.5s linear;

  &.collapsed {
    transform: rotate(0deg);
  }

  & path {
    fill: var(--matterColorDark);
  }
}

.collapseIconMultiBooking {
  transform: rotate(0deg);
  fill: var(--matterColorLight);

  &.collapsed {
    transform: rotate(180deg);
  }
}

/* .contentInnerWrapper {
  display: flex;
  flex-direction: column;
  @media (--viewportMiniDesktopDown) {
    flex-direction: row;
    column-gap:15px;
  }
} */
