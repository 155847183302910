.jobCardWrap {
  border-radius: 10px;
  /* background-color: #fff; */
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  background-color: #f5f5f5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;

  /* @media (--viewportTabletDown) {
    height: 100%;
  } */
}
.jobCardWrap::after {
  content: '';
  position: absolute;
  top: -45px;
  right: 15px;
  width: 75px;
  height: 75px;
  background: #0058fa;
  border-radius: 15px;
  transform: rotate(135deg);
}
.jobCardWrap::before {
  content: '';
  position: absolute;
  top: -50px;
  right: 65px;
  width: 75px;
  height: 75px;
  background: #ffb81d;
  border-radius: 15px;
  transform: rotate(135deg);
}
.jobCardWrap .jobCardHead {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.jobCardHead .cardHead {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-left: 14px;
  color: #0058fa;
  margin: 10px 0 0;
}
.jobCardHead .cardLavel {
  padding: 5px 10px;
  border-radius: 5px;
  /* border: 1px solid #0058fa; */
  border: 1px solid #f3f7ff;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.jobCardHead .cardLavel .cardLavelHead {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #020202;
  font-size: 13px;
  font-weight: 400;
}
.jobCardHead .cardLavel .cardLavelText {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #0058fa;
}
.jobCardWrap .cardButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardButtons .cardBtn {
  padding: 8px 15px;
  border-radius: 10px;
  min-height: auto;
  width: auto;
}
.cardButtons .joinBtn {
  padding: 8px 15px;
  border-radius: 10px;
  min-height: auto;
  width: auto;
}
.jobCardWrap .hostedByMain {
  display: flex;
  align-items: center;
  gap: 15px;
}
.hostedByMain .hostbyImg {
  width: 30px;
  height: auto;
  object-fit: contain;
}
.hostedByMain .hostbyWrap {
  display: flex;
  flex-direction: column;
}
.hostbyWrap .hostedBy {
  color: #0f0f0f;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  text-transform: capitalize;
}
.hostbyWrap .hostedByName {
  color: #0f0f0f;
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
  margin: 0;
  text-transform: capitalize;
}
.detailDesc {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  /* color: #a8a8a8; */
  margin: 0;
  width: 100%;
  word-break: break-all;
}
.detailDescHide {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* background: #fff; */
}
.jobCardWrap .playersDetailWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  color: #0f0f0f;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
.playersDetailWrap .dote {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #a59999;
}
.playersDetailWrap .playerPrice sub {
  color: #a59999;
}
.jobCardWrap .playerTime {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.jobCardWrap .playerTime img {
  width: 30px;
  margin-top: 3px;
}
.playerTime .timewrap {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}
.timewrap .timetext {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}
.timewrap .timeLabel {
  color: #a59999;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 26px;
}
.jobCardWrap .playerLocation {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.jobCardWrap .playerLocation img {
  width: 30px;
}
.playerLocation .locationText {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}
.jobCardWrap .detailLocation {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  text-transform: capitalize;
}
.locationText .locationTextHead {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
}
.locationText .locationValue {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.jobCardWrap .playersMember {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
/* .playersMember .playersMemberWrap {
  width: 60%;
} */

.joinedPlayerCount {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin: 0;
  color: #0058fa;
}
.matchCardImg {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-top: 15px; */
}
@media (max-width: 767px) {
}
