.locationAddress {
  position: relative;
  flex-shrink: 0;
}

.rootClass {
  position: unset;
}
.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.locationAutocompleteInputIcon {
  display: none;
}
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;
}

.predictionsRoot li {
  color: var(--matterColorDark) !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 10px 29px !important;

  @media (--viewportxldesktop) {
    font-size: 14px;
  }
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}
