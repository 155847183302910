@import '../../../../marketplace.css';

.header {
}

.subHeadingWrapper {
  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.subHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #0058fa;
  margin: 30px 0 0 0;
  @media (--viewportMedium) {
    padding-bottom: 0;
  }
  @media (--viewportLaptopDown) {
    margin-top: 20px;
    font-size: 16px;
    line-height: 20px;
  }
}

.tablePlaceholder {
  padding: 20px;
  text-align: center;
}
.spinner {
  display: block;
  margin: 80px auto;
}

.heading {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  /* padding-bottom: 15px; */
  justify-content: flex-start;
  @media (--viewportMedium) {
    justify-content: space-between;
    flex-direction: row;
    gap: 0;
    /* padding-top: 20px;
    padding-bottom: 20px; */
  }
  @media (--viewportLaptopDown) {
    align-items: flex-start;
    margin: 0 20px;
  }
}

.headingSchedule {
  justify-content: center;
  margin-bottom: 2px;
}

.sendMessageButton {
  font-weight: 500;
  padding: 0 16px;
  min-height: auto;
  min-width: 150px;
  height: 41px;
  display: inline-block;
  width: auto;
}

.helpers {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  @media (--viewportMedium) {
    width: auto;
  }
}

.filterKeywordWrap {
  justify-content: left;
  align-items: center;
  margin-left: 15px;
  display: flex;
  @media (--viewportMedium) {
    margin-left: 67px;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  @media (--viewportMobile) {
    width: 100%;
    max-width: 287px;
    height: 44px;
    border-radius: 28px;
    padding: 0 8px 0 16px;
    /* margin-bottom: 18px; */
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}
.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    width: 100%;
    min-width: 96px;
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.btnapply {
  width: 85px;
  min-width: 63px;
  border-radius: 16.5601px;
  min-height: 33px;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-transform: capitalize;
  margin-left: 14px;
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    width: 64px;
    min-width: 64px;
    min-height: 28px;
    font-weight: 600;
    font-size: 10px;
    margin: 0;
    line-height: 12px;
  }
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
  transform: scaleY(-1);
}

.scheduleTable {
  background: transparent;
  overflow: unset;
  transform: scaleY(-1);
  margin-bottom: 0;
  /* padding-top: 25px; */
  /* & .head {
    border: none;
  } */
}

.scheduleTable [class*='head'] {
  border: none;
  @media (--viewportLaptopDown) {
    display: none;
  }
}
.scheduleTable [class*='weekday_'] {
  border: none;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  @media (--viewportLaptopDown) {
    margin-bottom: 25px;
  }
}
.scheduleTable [class*='weekdayName'] {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #0b1333;
  @media (--viewportLaptopDown) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0b1333;
  }
}
.scheduleTable [class*='bookingNumber'] {
  .font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: rgba(11, 19, 51, 0.5);
  @media (--viewportLaptopDown) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: right;
  }
}

.scheduleTable [class*='checkboxField'] {
  & label {
    border: 1px solid #0058fa;
    border-radius: 3px;
  }
}

.scheduleTable [class*='headContainer'] {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #95a2d6;
  white-space: nowrap;
  /* @media (--viewportMiniDesktopDown){
    font-size: 13px;
  line-height: 22px;
  } */
}

.scheduleTable [class*='contentInner'] {
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  padding: 0 15px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.scheduleTable [class*='row'] {
  background: transparent;
  border-bottom: 1px solid #dce5f2;
  margin: 0;
  &:last-child {
    border: none;
  }
  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
}

.scheduleTable [class*='table'] {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

/* .scheduleTable [class*='triggerWrap'] {
  background: #FFFFFF;
  border: 1px solid #DCE5F2;
  border-radius: 10px;
  display: flex;
  padding-left: 1.8%;
} */

.WeekDayBtns {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  & div {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    height: 40px;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear, color 0.2s linear;
    margin: 4px;
    display: flex;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  @media (--viewportMobile) {
    justify-content: center;
  }
}

.tabDivider {
  border: 1px solid #dce5f2;
  /* margin-bottom: 20px; */
}
.headingContentContainer {
  height: calc(100vh - 200px);
  overflow-y: auto;
  @media (--viewportLaptopDown) {
    padding: 0 20px;
    overflow: unset;
  }

  @media (--viewportMobile) {
    overflow: unset;
    height: auto;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3e557c;
  }
}

.myClientPagination {
  /* background-color: red; */

  @media (--viewportMobile) {
    margin-bottom: 20px;
  }
}
