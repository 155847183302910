@import '../../marketplace.css';

.btnWrapper img {
  margin-right: 16px;
}

.btnWrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.btnWrapper::-webkit-scrollbar {
  width: 4px;
  background-color: #ffffff;
}

.btnWrapper::-webkit-scrollbar-thumb {
  background-color: rgb(144 144 144 / 35%);
}

.btnWrapper hr {
  width: 90%;
  justify-content: center;
  margin: 0% 4% !important;
  margin-top: 10% !important;
  border-color: #ffffff;
}

.LeftBar {
  background-color: #ffffff;
  min-width: 273px;
  max-width: 273px;
  left: 0;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  top: 120px;
  margin-left: 32px;
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 135px);
  flex: 1;
  overflow: hidden;
  transition: 600ms;
  @media (--viewportLaptopDown) {
    top: 0;
    left: 0;
    margin: 0;
    height: 100vh;
    min-width: 314px;
    max-width: 314px;
    border-radius: 0;
    z-index: 99999999;
    border: none;
  }

  @media (--viewportLaptopDown) {
    min-width: 100%;
    max-width: 100%;
    overflow-y: auto;
    position: relative;
    height: 100%;
  }
}

.LeftBarEdu {
  border: 1px solid #d8d7d7;
}

.LeftbarTop {
  @media (--viewportxldesktop) {
    top: 124px;
    height: calc(100vh - 125px);
  }
}

.leftbarTopScrolling {
  top: 76px;
  height: calc(100vh - 78px);
}

.overlayLeft {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 9;
}

.leftBarActiveSearchBar {
  top: 240px;
  height: calc(100vh - 250px);
}

.requestButton {
  padding: 28px 26px;
  width: 100%;

  @media (--viewportxldesktop) {
    padding: 20px 26px;
  }

  @media (--viewportTabletDown) {
    line-height: 50px;
    border-radius: 7px;
    padding: 15px 27px;
  }
}

.btnWrapper {
  padding: 0 26px 26px;
  width: 100%;
  flex: 1;
  overflow: auto;

  @media (--viewportLaptopDown) {
    padding: 0 26px 55px;
  }
}
.requestButton .button {
  background-color: #0058fa;
  width: 100%;
  border-radius: 10px;
  border: none;
  color: #f3f1ef;
  line-height: 52px;
  font-size: 16px;
  font-weight: 500;
}

.educationalBtn .button {
  background-color: #f75959;
}

.tabContainer:first-child,
.tabContainer:nth-child(2) {
  background: -moz-linear-gradient(180deg, #7c61c8 28.65%, #0058fa 100%);
  background: -webkit-linear-gradient(180deg, #7c61c8 28.65%, #0058fa 100%);
  background: -o-linear-gradient(180deg, #7c61c8 28.65%, #0058fa 100%);
  background: -ms-linear-gradient(180deg, #7c61c8 28.65%, #0058fa 100%);
  background: linear-gradient(180deg, #7c61c8 28.65%, #0058fa 100%);

  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  -ms-text-fill-color: transparent;
  -o-text-fill-color: transparent;
}

.tabContainer:first-child {
  margin-top: 5px;
}

.tabContainerEdu:first-child,
.tabContainerEdu:nth-child(2) {
  background: -moz-linear-gradient(180deg, #f77a22 28.65%, #f75959 100%);
  background: -webkit-linear-gradient(180deg, #f77a22 28.65%, #f75959 100%);
  background: -o-linear-gradient(180deg, #f77a22 28.65%, #f75959 100%);
  background: -ms-linear-gradient(180deg, #f77a22 28.65%, #f75959 100%);
  background: linear-gradient(180deg, #f77a22 28.65%, #f75959 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -ms-background-clip: text;
  -o-background-clip: text;
  background-clip: text;
}

.tabContainer:last-child {
  @media (--viewportMobile) {
    padding-top: 30px;
  }
}

.tabContainer:last-child:before {
  @media (--viewportTabletDown) {
    content: '';
    position: absolute;
    background-color: #f0f0f0;
    top: 0;
    width: calc(100% + 52px);
    height: 1px;
    left: -26px;
    left: -25px;
    width: calc(100% + 15px);
  }
}

.tabContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding: 0 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1f293d;
  text-decoration: none;

  @media (--viewportxldesktop) {
    margin-top: 25px;
  }

  @media (--viewportTabletDown) {
    margin-top: 30px;
    padding: 0 13px;
  }
}

.tabContainer:hover {
  text-decoration: none;
}

.tabWrappper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
}

.createButton {
  background: #0058fa;
  border-radius: 8px;
  color: #fff;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  text-decoration: none;
}
.educationalCreateBtn {
  background: #f75959;
}
.tennisCreateBtn {
  background: #4889ff;
}

.btnImg {
  margin: 0 !important;
  /*position: absolute;*/

  border: 5px solid #2f50ff;
  border-radius: 50%;
  background-color: white;
  padding: 10px;
  margin: -25px auto 0;

  @media (--viewportTabletDown) {
    /* border: none; */
    padding: 16px;
  }
}
.educationalCreateBtn .btnImg {
  border: 5px solid #f75959;
}
.tennisCreateBtn .btnImg {
  border: 5px solid #4889ff;
}
.projectTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 30px 50px;
  cursor: pointer;

  @media (--viewportTabletDown) {
    padding: 45px 50px;
  }
}

.curve {
  position: absolute;
  top: -25px;

  @media (--viewportTabletDown) {
    top: -30px;
  }
}

/*.curve:before {*/
/*  position: absolute;*/
/*  content: '';*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  left: 0;*/
/*  background: red;*/
/*  border-radius: 25px;*/
/*}*/
/*.curve:after {*/
/*  position: absolute;*/
/*  content: '';*/
/*  right: 0;*/
/*  width: 20px;*/
/*  height: 20px;*/
/*  background: red;*/
/*  border-radius: 25px;*/
/*}*/
.footerBtn {
  width: 100%;
  padding: 16px 16px 18px;

  @media (--viewportTabletDown) {
    padding: 0 36px 100px 36px;
  }
}

.footerBtn a:hover {
  text-decoration: none;
}
.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin: 0px 26px 30px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  height: 52px;
  padding: 0 16px;
  width: calc(100% - 52px);
  @media (--viewportxldesktop) {
    margin: 0px 26px 15px;
  }

  @media (--viewportTabletDown) {
    margin: 20px 26px 0px;
  }
}
.switch h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin: 0;
}

.switchinput[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 37px;
  height: 16px;
  background: #e2e1e1;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -1px;
  width: 20px;
  height: 20px;
  background: #b5b5b5;
  border-radius: 90px;
  transition: 0.3s;
}

.switch input:checked + label:after {
  background: #0058fa;
}

.switch input:checked + label {
  background: rgba(0, 88, 250, 0.2);
}

.switch input:checked + label:after {
  left: calc(100%);
  transform: translateX(-100%);
}

.switch label:active:after {
  width: 30px;
}

.logoContainer {
  width: 100%;
}

.logoContainer img {
  height: 36px;
  margin: 15px 0 0 15px;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 20px;
}

.closeBtn img {
  height: 25px;
  width: 25px;
  margin: 0;
}

.logoutBtn {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0058fa;
  /* width: 100%; */
  margin: 20px 46px 30px;

  @media (--viewportLaptopDown) {
    margin: 0 0 50px 45px;
  }
}

.googleLink {
  width: 100%;
}
.googleLink:hover {
  text-decoration: none;
}

.calendarBtn {
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  color: var(--matterColorDark);
  height: 52px;
  margin: 20px auto 0;
  padding: 0 16px;
  width: calc(100% - 52px);
  align-items: center;
  justify-content: space-between;

  @media (--viewportxldesktop) {
    margin: 15px auto 0;
  }
  @media (--viewportMobile) {
    margin: 0 auto 20px;
  }
}

.becomeHostMember {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.becomeHostMemberWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  text-decoration: none !important;
}
.becomeHostMemberImg {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  @media (--viewportTabletDown) {
    height: 100px !important;
  }
}
.becomeHostMemberText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
}
.becomeHostWrapper {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  padding: 30px;
  height: 190px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.educationHostWrapper {
  background: linear-gradient(77.51deg, #fa5c00 -10.19%, #f14474 100%);
}
.switch [class*='react-switch-bg'] {
  width: 37px !important;
  height: 16px !important;
}
.switch [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  top: -2px !important;
}
.switchUnchecked [class*='react-switch-handle'] {
  left: -1px !important;
  background: #b5b5b5 !important;
}
.switchUnchecked [class*='react-switch-bg'] {
  background: #e2e1e1 !important;
}
.switchChecked [class*='react-switch-handle'] {
  left: -12px !important;
  background: #0058fa !important;
}
.switchChecked [class*='react-switch-bg'] {
  background: rgba(0, 88, 250, 0.2) !important;
}

.switchCheckedEdu [class*='react-switch-handle'] {
  left: -12px !important;
  background: #f75959 !important;
}
.switchCheckedEdu [class*='react-switch-bg'] {
  background: #fa250033 !important;
}
.switchToEdu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  column-gap: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 40px;
  height: 52px;
  margin: 20px auto;
  font-size: 16px;
  padding: 0px 15px;
  width: calc(100% - 52px);
  background: #fe6436;
  color: #ffffff;
}
.switchToSport {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  column-gap: 20px;
  border: 1px solid #d8d7d7;
  border-radius: 40px;
  height: 52px;
  margin: 20px auto;
  font-size: 16px;
  padding: 0px 15px;
  width: calc(100% - 52px);
  color: #ffffff;
  background: #0d60fa;
}
.LeftNavBarLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  column-gap: 20px;
  border: 1px solid #d8d7d7;
  border-radius: 40px;
  height: 52px;
  margin: 20px auto;
  font-size: 16px;
  padding: 0px 15px;
  width: calc(100% - 52px);
  color: #ffffff;
  background: #0058fa;
}
.LeftNavBarLogo img {
  height: 25px;
  margin: auto;
}
.switchToEdu:hover,
.switchToSport:hover {
  text-decoration: none;
}
.switchToSport img,
.switchToEdu img {
  height: 20px;
}
.active {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #fe565b;
  border: 3px solid #ffffff;
  margin-bottom: 6px;
}
