@import '../../../../marketplace.css';

.wrapper {
  margin-bottom: 100px;
  padding: 20px;
  overflow: auto;
}
.weekdayNameRow {
  background-color: var(--marketplaceColorLight);
  color: var(--matterColorLight);
  font-weight: bold;
  & td {
    padding: 0 10px;
  }
}
.table {
  min-width: 1328px;
  font-size: 16px;
  padding-right: 20px;
  @media (--viewportLaptop) {
    padding-right: 0;
  }

  & th {
    padding: 0 10px;
  }

  & .head {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 18px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--marketplaceColorDark);
    & > .cell {
      font-size: 15px;
      font-weight: bold;
    }
  }
  & .cell {
    /* margin-left: 1.8%; */
  }

  & .nameCol {
    width: 10%;
  }
  & .timeCol {
    width: 6.5%;
  }
  & .listingNameCol {
    /* width: 30%; */
    width: 10%;
  }
  & .membershipCol {
    /* width: 9.5%; */
    width: 10%;
  }
  & .weekMarkCol {
    text-align: center;
    /* width: 2.5%; */
    width: 5%
  }
}
