@import '../../marketplace.css';

.classCalendar {
  display: flex;
  padding: 0 30px;
  margin-bottom: 12px;
  justify-content: space-between;

  & .date {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;

    &.selected {
      font-weight: bold;
      color: var(--marketplaceColor);
    }
  }
}

.subHeader {
  margin: 12px 0;
  text-align: center;
  overflow: hidden;

  & span {
    position: relative;
    font-size: 14px;
    color: var(--matterColorGrey);

    &:after,
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: var(--matterColorNegative);
      top: 50%;
      width: 100vh;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.noListings {
  margin: 10px 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}

.spinner {
  display: block;
  margin: 10px auto;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  margin-top: 60px;
  margin-bottom: 10px;
  /* font-weight: bold; */
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorDimmedText);
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 19px;
    margin: 52px 0 16px 0;
  }
}

.listingSpan {
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 31px;
  margin-bottom: 4px;
  color: var(--matterColor);

  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 28px;
  }
}

.mobileResultHead {
  padding: 0 11px 0;
  margin: 20px 3px 0;
  display: none;

  @media (--viewportMobile) {
    display: block;
  }
}

.totalClasses {
  display: block;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--matterColorDimmedText);
  margin: 0;
}

.classesContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 20px;
}

.viewMoreButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.viewMoreButtonWrapper button {
  background: var(--matterColorBlue);
  width: 127px;
  min-height: 46px;
  border-radius: 3px;
  padding: 13px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  border: none;
  @media (--viewportTabletDown) {
    background-color: var(--matterColorLight);
    color: var(--matterColorBlue);
    padding: 0;
    min-height: 20px;
  }

  &:hover,
  &:focus {
    background-color: var(--matterColorBlue);
    box-shadow: none;
    @media (--viewportTabletDown) {
      background-color: var(--matterColorLight);
    }
  }
}
.showClasses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  /* margin: 0px 26px 30px; */
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  height: 52px;
  padding: 0 16px;
  /* width: calc(100% - 52px); */

  @media (--viewportMobile) {
    margin: 25px 0;
  }

  & h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: 0;
  }
}

.showClasses [class*='react-switch-bg'] {
  height: 20px !important;
  width: 48px !important;
  background: var(--matterColorBlue) !important;
}
.showClasses [class*='react-switch-handle'] {
  height: 18px !important;
  width: 18px !important;
}

.uncheckedShowClasses [class*='react-switch-bg'] {
  background: #888888 !important;
}

.classesHead {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 35px;

  @media (--viewportMobile) {
    flex-direction: column;
  }
}
