@import '../../marketplace.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  margin-top: 0 !important;
  @media (--viewportMobile) {
    margin-top: 10px;
  }
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.locationAddress {
  position: relative !important;
  flex-shrink: 0;
}
.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.locationInput:focus {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.locationAutocompleteInputIcon {
  display: none;
}
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;

  & li {
    color: var(--matterColorDark) !important;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 10px 29px !important;
    @media (--viewportxldesktop) {
      font-size: 14px;
    }
  }
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}
.locationWrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
  position: relative;
  flex: 1;
  @apply --marketplaceModalPasswordMargins;
  @media (--viewportTabletDown) {
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
  }
}
.locationLable {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  color: #000000;
  position: absolute;
  top: -8px;
  left: 10px;
  background: #fff;
  padding: 0 5px;
  z-index: 1;
  @media (--viewportTabletDown) {
    background: #f5f9ff;
    font-size: 12px;
    line-height: 15px;
  }
}
.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;
  width: 100%;
  margin: 0;
}
.rootClass {
  position: unset;
  width: 100%;
}
.userData label {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
/* ////////////////////////////////////// */
.card {
  text-align: center;
  width: 280px;
}
.card .title {
  font-size: 2em;
  margin: 15px;
}
.card .form {
  display: flex;
  align-items: center;
}
.form label {
  cursor: pointer;
}
.card .form .input-radio {
  box-shadow: 0px 0px 0px 1px #6d6d6d;
  font-size: 3em;
  width: 15px;
  height: 15px;
  margin-right: 7px;

  border: 4px solid #fff;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.card .form label {
  display: flex;
  margin: 10px 15px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
}
.input-radio.blueCheck:checked {
  box-shadow: 0px 0px 0px 4px #005ef4;
  background-color: #005ef4;
  width: 15px;
  height: 15px;
}
.input-radio.orgCheck:checked {
  box-shadow: 0px 0px 0px 4px #ec614f;
  background-color: #ec614f;
  width: 15px;
  height: 15px;
}
.multipal {
  background-color: #005ef46f;
}
.showCategory {
  width: 100%;
  padding: 6px;
  border: 2px solid rgb(105, 105, 105);
  border-radius: 10px;
}
.tagLable {
  padding: 0px 3px;
  background-color: #3d87ff64;
  margin: 2px;
  cursor: pointer;
}
.svgClose {
  font-size: 16px;
  font-weight: 600;
}
.userData {
  margin-top: 30px;
}
.selectCity {
  margin-bottom: 20px;
}
label {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
