@import '../../../marketplace.css';

.rootEnqiryCard {
  background: var(--matterColorLight);
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
}
.cardHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardHeadding {
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  margin: 0;
}
.eventWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.eventText {
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8e8e8e;
}
.cardEvents {
  background: rgba(13, 96, 250, 0.2);
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--matterColorBlue);
  padding: 8px 15px;
}
.cardTextWrap {
  display: flex;
  margin-top: 20px;
}
.cardText {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #576c8d;
  margin: 0;
}
.timeAddressWrap {
  display: flex;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap;
  row-gap: 15px;
  @media (--viewportTabletDown) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.timeWrap {
  display: flex;
  align-items: center;
  column-gap: 15px;
  flex: 2;
}
.timeIcon svg {
  fill: transparent;
}
.timeText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #576c8d;
}
.addressWrap {
  display: flex;
  align-items: center;
  column-gap: 15px;
  flex: 1;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}

.addressIcon svg {
  fill: transparent;
}
.addressText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #576c8d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100px;
}
.dateWrap {
  display: flex;
  align-items: center;
  column-gap: 15px;
  flex: 1;
}
.dateIcon svg {
  fill: transparent;
}
.dateText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #576c8d;
}
.authorPrisingWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
.authorWrap {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.authorText {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: var(--matterColorDark);
}
.prisingWrap {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: var(--matterColorBlue);
}
.enquiryCardBody {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex: 3;
  background: var(--matterColorBlue);
  border-radius: 20px;
  padding: 30px;
  height: max-content;

  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.enquiryCardBodyEdu {
  background: #f75959;
}
.enquiryModal [class*='container'] {
  padding: 35px;
  @media (--viewportLaptopDown) {
    padding: 25px;
    min-height: unset;
    height: unset;
    width: 95%;
    flex-grow: 0;
  }
}

.enquiryModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    /* min-height: unset; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.enquiryModal [class*='close'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    right: 10px;
  }
}
