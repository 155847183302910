.serviceCardMain {
  @media (--viewportMobile) {
    overflow: auto;
  }
}
.serviceCard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px;
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  @media (--viewportTabletDown) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 25px;
  }
  @media (--viewportMobile) {
    display: flex;
    width: 700px;
    padding: 0 25px;
  }
}
.sreviceCardWrap {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #2e2b3c;
  box-shadow: 0px 0px 41.76088px 5.22011px rgba(0, 0, 0, 0.05);
  margin-top: 30px;
  text-align: center;
  padding: 20px;
  @media (--viewportTabletDown) {
    padding: 25px;
  }
}
.serviceImage {
  margin-top: -60px;
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  padding: 0px 15px;
}
.serviceText {
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 40px 0;
  @media (--viewportTabletDown) {
    margin: 20px 0;
    font-size: 26px;
  }
}
.btnView {
  background: linear-gradient(90deg, #0058fa, #9d7aff);
  color: #fff;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  border: 0;
  padding: 9px 30px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  @media (--viewportTabletDown) {
    font-size: 20px;
    padding: 10px 35px;
  }
}
a {
  text-decoration: none;
  text-align: center;
}
