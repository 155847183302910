/* .newHomeMain {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 30px 40px;
} */
.homeCardMain {
  border-radius: 40px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  background: #fefefe;
  cursor: pointer;
  @media (--viewportTabletDown) {
    border-radius: 20px;
  }
}

/* card head */
.homeCardMain .homeCardHead {
  background: linear-gradient(180deg, #0058fa 0%, #fff 40.63%);
  border-radius: 40px;
  box-shadow: 0px 0px 5px #0005;
  padding: 30px 0px;
  margin-bottom: 60px;
  position: relative;
  @media (--viewportTabletDown) {
    padding: 25px 0px;
    border-radius: 20px;
  }
}
.homeCardMain .eduColor {
  background: linear-gradient(180deg, #fe575b 0%, #fff 40.63%);
}
.homeCardHead .homeHeadImage {
  margin-bottom: -65px;
  @media (--viewportTabletDown) {
    margin-bottom: -45px;
  }
}
.homeHeadImage img {
  /* width: 100%; */
  /* width: 225px; */
  height: 240px;
  object-fit: cover;
  border-radius: 40px;
  @media (--viewportTabletDown) {
    border-radius: 20px;
    width: 100%;
  }
  @media (--viewportMobile) {
    height: 200px;
  }
}

.homeHeadDetail {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: start;
  align-items: start;
  margin: 15px;
  gap: 5px;
  @media (--viewportLaptopDown) {
    align-items: center;
  }
}
.homeHeadDetail .frontHead {
  color: #000;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
  margin: 0;
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--viewportTabletDown) {
    text-align: center;
    font-size: 26px;
    line-height: 26px;
  }
}
.homeHeadDetail .headSports {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}
.homeHeadDetail .headSports .headSGD {
  background: #373747;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}
.homeHeadDetail .headSports .sport1 {
  background: #7662d7;
  padding: 1px 10px;
  border-radius: 3px;
}
.homeHeadDetail .headSports .sport2 {
  background: #e66998;
  padding: 1px 10px;
  border-radius: 3px;
}
.homeHeadDetail .headRate {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  background: #373747;
  border-radius: 6px;
  padding: 5px 15px;
  text-align: center;
  @media (--viewportMobile) {
    width: 100%;
  }
}

/* card detail */
.homeCardMain .homeDetails {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 15px;
  margin: 15px;
  border-radius: 30px;
  @media (--viewportLaptopDown) {
    border-radius: 20px;
  }
}
.homeDetails .detailLocation {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  overflow: hidden;
  align-items: center;
  gap: 8px;
  color: #a8a8a8;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}
.homeDetails .detailDesc {
  color: #020202;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  margin: 5px 0 10px;
}
.homeDetails .detailsImageGallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.homeDetails .detailsImageGallery img {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}

/* button group  */
.homeCardMain .homeButtonGroup {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 10px 10px;
  margin: 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  @media (--viewportLaptopDown) {
    border-radius: 20px;
  }
}
.homeButtonGroup .buttonMessage {
  background: linear-gradient(180deg, #ffb81d 0%, #fe6436 100%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px;
  border: 0;
  border-radius: 30px;
}
.homeButtonGroup .buttonBook {
  background: linear-gradient(78deg, #0058fa -10.19%, #9d7aff 100%);
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px;
  border: 0;
  border-radius: 30px;
}

/* Premium Member  */
.homeCardMain .homePremium {
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 10px;
  margin: 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  @media (--viewportTabletDown) {
    font-size: 18px;
  }
}
.subCategoryText {
  font-weight: 400;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background-color: #7662d7;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: capitalize;
  word-break: break-word;
  white-space: normal;
  width: max-content;
}

.ratingTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}
.ratingText {
  color: #000;
}
.membershipButton {
  color: gray;
}
