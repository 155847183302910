@import '../../../marketplace.css';

.pageRoot {
  display: flex;
}

.layoutWrapperTopbar {
  position: relative;
}
.overlayBody {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 1;
}

.actionBarWrapper {
  position: relative;
}

.headImageContainer {
  position: relative;
  height: 473px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportTabletDown) {
    height: 375px;
  }
  /* @media (--viewportMobile) {
    height: 257px;
  } */
}

.headImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 55, 156, 0.42); */
  height: 100%;
  width: 100%;
}

.imageContainerBackground {
  background: rgba(0, 55, 156, 0.42);
}

.headContainer {
  font-family: 'Int Circular PTT';
  font-weight: 700;
  font-size: 42px;
  line-height: 73px;
  text-align: center;
  color: var(--matterColorLight);
  width: 550px;
  @media (--viewportTabletDown) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 26px;
    width: 259px;
  }
}

.subMainContainer {
  padding: 0 0 0 40px;
  position: relative;
  background: var(--matterColorLight);
  display: flex;
  background: #f5f9ff;
  @media (--viewportLaptopDown) {
    padding: 0 0 0 30px;
  }
  @media (--viewportTabletDown) {
    padding: 0;
    flex-direction: column;
  }
}

.leftSideContainer {
  position: sticky;
  top: 100px;
  width: 20%;
  max-height: 85vh;
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: -200px;
  margin-bottom: 40px;
  @media (--viewportLaptopDown) {
    width: 30%;
    /* padding: 25px 20px 100px; */
  }
  @media (--viewportTabletDown) {
    width: 90%;
    margin: -30px auto 30px;
    position: unset;
    max-height: 100vh;
  }
  @media (--viewportMobile) {
    margin: -20px 18px 10px;
    /* padding: 25px 26px 26px; */
  }
}
.leftSideContainerTop {
  padding: 20px 0px 0;
  flex: 1;
  @media (--viewportLaptopDown) {
    /* width: 30%; */
    padding: 25px 20px 10px;
  }
  @media (--viewportMobile) {
    /* margin: -74px 18px 30px; */
    padding: 25px 26px 26px;
  }
}
.leftSideContainerBottom {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: var(--matterColorLight);
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (--viewportMobile) {
    position: sticky;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    z-index: 9;
  }
}
.leftSideContainerBottom a {
  @media (--viewportLaptopDown) {
    width: 100%;
  }
}
.authorAvatar {
  width: 140px;
  height: 140px;
  border: 2px solid var(--matterColorLightBorder);
  margin: 0 auto;
  @media (max-width: 1199px) {
    width: 100px;
    height: 100px;
  }
  @media (--viewportMobile) {
    width: 120px;
    height: 120px;
  }
}

.authorName {
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  color: var(--matterColorDark);
  margin: 15px 0 5px;
  @media (--viewportTabletDown) {
    font-size: 20px;
    line-height: 25px;
    margin: 20px 0 10px;
  }
}

.authorAddress {
  font-weight: 450;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  width: 181px;
  margin: 0 auto 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--viewportTabletDown) {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 160px;
    height: 49px;
  }
}

.messageButton {
  width: 100%;
  max-width: 320px;
  min-height: 52px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: var(--matterColorBlue);
  border: 1px solid var(--matterColorBlue);
  background: var(--matterColorLight) !important;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  @media (--viewportMobile) {
    max-width: 100%;
  }
  @media (--viewportTabletDown) {
    margin-top: 0px;
    width: 100%;
    min-height: 46px;
    max-width: 285px;
  }
}

.exploreButton {
  border: 1px solid rgba(255, 255, 255, 0.6);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;
  padding: 10px 15px;
  min-height: unset;
  color: #fff;
  background: #f63f81;
  background-image: linear-gradient(to right, #f63f81, #0058fa);
  @media (--viewportMobile) {
    max-width: 100%;
    padding: 10px 14px;
    font-size: 14px;
    column-gap: 10px;
  }
}
.yellowButton {
  background: #ffb81d;
}
.yellowButton:hover {
  background: #ffb81d;
}
.listingInfoWrapper {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  @media (--viewportMobile) {
    padding: 26px 0;
  }
}

.listingInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listItem {
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 20px;
  }
}

.listValue {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  text-align: right;
  color: rgba(0, 0, 0, 0.8);
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 20px;
  }
}

.socialContainer {
  padding: 0 0 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportTabletDown) {
    padding: 0 0 26px;
  }
}

.socialHeadding {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: var(--matterColorDark);
  margin-bottom: 10px;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 9px;
  }
}

.socialIconsWrappers {
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: space-evenly;
}

.socialIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0000004d;
  @media (--viewportTabletDown) {
    width: 32px;
    height: 32px;
    align-items: flex-start;
  }
}

.aboutHeadding {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.06em;
  color: var(--matterColorDark);
  margin-bottom: 10px;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 9px;
  }
}

.aboutText {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #00000080;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 24px;
  }
}

.rightSideContainer {
  padding: 40px 40px 50px;
  width: 80%;
  @media (--viewportLaptopDown) {
    padding: 30px;
    width: 70%;
  }
  @media (--viewportTabletDown) {
    padding: 0;
    width: 100%;
  }
}

.sectionGallery {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 25px;
  @media (--viewportTabletDown) {
    overflow: auto;
  }
  @media (--viewportMobile) {
    padding: 14px 14px 96px;
    margin: 15px;
  }
}

.sectionSocialGallery {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 25px;
  @media (--viewportTabletDown) {
    overflow: auto;
  }
  @media (--viewportMobile) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.galleryWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-auto-rows: auto;
  column-gap: 26px;
  row-gap: 26px;
  /* height: calc(100vh - 435px);
  overflow: hidden; */
  @media (--viewportLaptop) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportTabletDown) {
    column-gap: 14px;
    row-gap: 14px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMobile) {
    column-gap: 14px;
    row-gap: 96px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallerySocialWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-auto-rows: auto;
  column-gap: 26px;
  row-gap: 26px;
  /* height: calc(100vh - 435px);
  overflow: hidden; */
  @media (--viewportLaptop) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportTabletDown) {
    column-gap: 14px;
    row-gap: 14px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMobile) {
    column-gap: 14px;
    row-gap: 14px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.socialGalleryWrap {
  @media (--viewportTabletDown) {
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    max-width: 950px;
  }
}

.authorListingWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallerItems {
  width: 100%;
  height: 255px;
  @media (min-width: 768px) and (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 150px;
  }
}

.itemImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
  @media (--viewportMobile) {
    height: 150%;
    border-radius: 0px;
  }
}

.classesContainer {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px 45px;
  box-sizing: border-box;
  @media (--viewportLaptopDown) {
    padding: 0 35px;
  }
  @media (--viewportMobile) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.facilityContainer {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px 20px;
  overflow: auto;
  margin-bottom: 30px;
  @media (--viewportLaptopDown) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.facilityTop {
  margin-top: 25px;
}

.upcomingTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  margin: 50px 0px;
  @media (--viewportTabletDown) {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    margin: 25px 0;
  }
}

.ourProSectionTitle {
  margin: 50px 0px;
  @media (--viewportTabletDown) {
    margin: 0px;
  }
}

.ourProsHeading {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
  justify-content: flex-start;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    margin: 25px 0px 25px 0px;
    text-align: center;
  }
}

.productsMobileWrapper {
  @media (--viewportTabletDown) {
    background: #fffdfd;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.productsButton {
  width: 185px;
  min-height: 46px;
  background: var(--matterColorBlue);
  border-radius: 3.31824px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  margin: 25px auto 0;
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    color: var(--matterColorBlue);
    min-height: 20px;
  }
}
.productsButton:hover {
  background: var(--matterColorBlue);
  box-shadow: none;

  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    box-shadow: none;
  }
}

.sectionFAQWrapper {
  margin-top: 50px;
  @media (--viewportTabletDown) {
    margin: 0px;
  }
}

.sectionFAQTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 0 0 50px;
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    margin: 25px 0px 25px 0px;
    text-align: center;
  }
}

.reviewTitle {
  font-family: 'Int Circular PTT';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 50px 0;
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 25px 0;
  }
}

.blogTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 50px 0 50px;
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 25px 0;
  }
}

.sectionBlog {
  background: var(--matterColorLight);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 50px;
  @media (--viewportLaptopDown) {
    padding: 50px 35px;
  }
  @media (--viewportTabletDown) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}

.blogContentWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-auto-rows: auto;
  gap: 40px;
  position: relative;
  @media (--viewportLaptop) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportTabletDown) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

.blogContentWrapper :global(.slick-track) {
  display: flex;
  column-gap: 40px;
  width: 100% !important;
  @media (--viewportTabletDown) {
    flex-direction: column;
  }
}

.blogItemWrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
}
.blogItemWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.blogItemTitle {
  position: absolute;
  top: 0;
  font-weight: 500;
  font-size: 28px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 35px;
  text-transform: uppercase;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color: var(--matterColorLight);
  border-radius: 12px;

  @media (--viewportMiniDesktopDown) {
    font-size: 15px;
    line-height: 19px;
    padding: 5px;
  }
}

.sendRequestTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 50px 0;
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin: 25px 0;
  }
}

.mapImage {
  box-shadow: 0px 4px 41.76px 5.22px rgba(0, 0, 0, 0.05);
  margin-top: 50px;
  @media (--viewportMobile) {
    width: 93%;
    margin: 50px auto 0;
    border-radius: 5px;
  }
}
.mapImage h2 {
  display: none;
}
.mapImage > div {
  @media (--viewportMobile) {
    max-height: 165px;
    border-radius: 5px;
  }
}
.mapImage button {
  max-height: 371px;
  max-width: 100% !important;
  width: 100%;
  border-radius: 15px;
  @media (--viewportMobile) {
    max-height: 165px;
  }
}
.mapImage button > div {
  border-radius: 15px;
}
.mapImage canvas {
  width: 100% !important;
  position: unset !important;
  border-radius: 15px;
  @media (--viewportMobile) {
    max-height: 165px;
    border-radius: 5px;
  }
}

.blogModalContent {
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  align-items: flex-start;

  @media (--viewportLaptop) {
    flex-direction: row;
    gap: 0 40px;
  }
}

.blogModalImage img {
  height: 100%;
  width: 100%;
}

.blogsContainer {
  @apply --marketplaceModalBaseStyles;
  height: 100%;
  margin: 0;
  padding: unset;

  @media (--viewportMedium) {
    min-height: 100vh;
    flex-basis: 60%;
    min-height: auto;
    height: auto;
  }
}

.close {
  @apply --marketplaceModalCloseStyles;
  padding: 0 10px;

  @media (--viewportTabletDown) {
    position: absolute;
  }
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.galleryButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  @media (--viewportMobile) {
    margin-top: 20px;
  }
}

.galleryButton button {
  background: var(--matterColorBlue) !important;
  width: 127px;
  min-height: 46px;
  border-radius: 3.31824px;
  padding: 13px 16px 13px 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  border: none;
  @media (--viewportTabletDown) {
    padding: 10px;
    height: 20px;
  }
}

.viewAllBlogButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  @media (--viewportMobile) {
    margin-top: 20px;
  }
}
.viewAllBlogButton button {
  background-color: var(--matterColorBlue);
  width: 139px;
  min-height: 46px;
  border-radius: 3.31824px;
  padding: 13px 16px 13px 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  border: none;
  @media (--viewportTabletDown) {
    min-height: 20px;
    outline: none;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
    padding: 10px 0;
  }
}

.messageButtonText {
  @media (--viewportTabletDown) {
    font-size: 16px;
  }
}

.ourProSectionWrapper {
  position: relative;
}
.blogHeader {
  text-transform: capitalize;
  margin-top: 0;
}

.paragraphDescription {
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--matterColorDark);
  margin: 0;
  white-space: break-spaces;
  word-break: break-all;
}

.aboutUsWrapper {
  background: #fffdfd;
  box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
  border-radius: 15px;
  padding: 20px 45px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 20px;

  @media (--viewportTabletDown) {
    padding: 14px 14px 20px;
    margin: 15px;
  }
}
.aboutUsWrapperEdu {
  background: #fffdfd;
  box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
  border-radius: 15px;
  padding: 20px 45px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* margin-top: 30px; */

  @media (--viewportMobile) {
    padding: 14px 14px 20px;
  }
}
.mainWrapper {
  padding: 20px 0;
  @media (--viewportMobile) {
    padding: 14px 14px 20px;
  }
}

.reviewWrap {
  display: flex;
  gap: 15px;
  font-weight: 450;
  font-size: 18px;
  line-height: 18px;
  color: #0b150a;
  align-items: center;
}

.description {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #5c5b5c;
  white-space: pre-line;
}
.newScroll {
  /* gap: 0 !important; */
  overflow-x: auto !important;
  @media (--viewportMobile) {
    flex-direction: column;
  }
}
.categoryListContainer [class*='sectionCounter'] {
  display: none;
}

.categoryListContainer [class*='left'] {
  top: 0;
  right: unset;
  left: -45px;
  display: flex;
  align-items: center;

  @media (--viewportTabletDown) {
    right: -50px;
    left: unset;
  }

  @media (--viewportLaptopDown) {
    width: unset;
    right: -30px;
    left: unset;
  }
}

.categoryListContainer [class*='right'] {
  top: 0;
  right: -90px;
  display: flex;
  align-items: center;
  height: 100%;
}

.categoryListContainer [class*='img'] {
  display: flex;
  align-items: center;
  position: revert;
  width: 32px;
  height: 32px;
}

.categoryListContainerWithoutArrow {
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}

.categoryListContainerWithoutArrow {
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}
.breadCrumbWrapper {
  margin-bottom: 10px;
  @media (--viewportTabletDown) {
    margin: 10px 15px;
  }
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.introVideo {
  max-height: 400px;
  @media (--viewportTabletDown) {
    max-height: 100%;
  }
}
.whatsappLinkCompany a img {
  max-width: 100px;
  height: auto;
  /* border-radius: 30px; */
  position: fixed;
  z-index: 100000 !important;
  top: 0;
  right: 0;
}
.floatingTopBar {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 9999;
  @media (--viewportTabletDown) {
    display: none;
  }
}
.socialMain {
  position: relative;
  @media (--viewportMobile) {
    min-width: 200px;
    height: 200px;
  }
}
.socialMediaImg {
  width: 100%;
  height: 250px;
  filter: brightness(30%);
  border-radius: 20px;
  transition: all ease 0.5s;
  @media (--viewportTabletDown) {
    height: 205px;
  }
  @media (--viewportMobile) {
    height: 100%;
  }
}
.socialWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000066;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  border-radius: 20px;
}
.socialWrapText {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.socialmain {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease 0.5s;
}
.socialInfo {
  /* height: 250px; */
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (--viewportMobile) {
    width: 100%;
  }
}
.socialText {
  color: white;
  text-transform: capitalize;
  font-size: 35px;
  @media (--viewportTabletDown) {
    font-size: 30px;
  }
}
.socialLogo {
  width: 70px;
  height: 70px;
  @media (--viewportTabletDown) {
    width: 50px;
    height: 50px;
  }
}
.socialMain:hover .socialmain {
  display: none;
  transition: all ease 0.5s;
}
.socialMain:hover .socialMediaImg {
  filter: brightness(80%);
  border-radius: 20px;
  transition: all ease 0.5s;
}
.quickScroll {
  display: flex;
  flex-direction: column;
  background: #f3f7fd;
  overflow: auto;
  height: 200px;
  padding-right: 5px;
  @media (max-width: 1199px) {
    height: 190px;
  }
  @media (max-width: 767px) {
    height: unset;
  }
}
.quickScroll::-webkit-scrollbar {
  width: 3px;
}
.quickScroll::-webkit-scrollbar-track {
  background: transparent;
}
.quickScroll::-webkit-scrollbar-thumb {
  background: #0058fa;
  border-radius: 10px;
  padding-left: 10px;
}
.floatingWrap {
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.floatingWrap:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.floatingLink {
  margin: 0;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
