@import '../../marketplace.css';

.productsMobileWrapper {
  margin-top: 50px;
  @media (--viewportTabletDown) {
    background: #fffdfd;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 10px 15px;
    margin: 20px;
  }
}
.productPageWrapper {
  display: flex;
  width: 80%;
  margin: auto;
  padding: 20px;
  align-items: end;
  justify-content: end;
}
.autorListingWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportTabletDown) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.content {
  background: var(--matterColorLight);
  overflow: hidden;
}
.reverseSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  gap: 1.5rem;
  margin: 20px 0 30px 0;
  min-width: 100%;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 3.25rem;
    margin: 0;
    padding: 0;
  }
}

.section {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  min-width: 100%;
  margin: 0px auto 60px auto;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 3.25rem;
    margin: 0;
    padding: 0;
  }

  @media (min-width: 1600px) {
    max-width: 100%;
    margin: 0px auto 60px auto;
  }
}

body {
  overflow-x: hidden;
}

.uniqueDesignSection {
  padding: 10px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 0 20px 0;
    width: 100%;
  }
}
.uniqueDesignSection hr {
  margin: 20px 0 30px;
}
.uniqueDesignSection h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 16px;
  margin-top: -50px;
  color: var(--matterColorDark);
  width: 100%;

  @media (max-width: 991px) {
    font-size: 18px;
    line-height: 30px;
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
  }
}
.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--matterColorDark);

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.sectionImages {
  width: 100%;
  height: 450px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 20px;

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.learnLink {
  margin-top: 0.5rem;
  font-size: 16px;

  @media (max-width: 991px) {
    font-size: 14px;
    display: inline;
  }
}

.popularExercisesSection {
  width: 85%;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}

.exerciseCard {
  flex: 1;
  background-color: white;
  max-width: 22rem;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.exerciseCardImage img {
  max-width: 100%;
  max-height: 16rem;

  @media (--viewportMobile) {
    max-width: 100%;
    /* max-height: 13rem; */
  }
}

.exerciseCardInfo {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
}

.exerciseCardTitle {
  font-size: 25px;

  @media (max-width: 991px) {
    font-size: 20px;
  }
}

.exerciseCardDescription {
  font-size: 20px;
  color: #a8a8a8;

  @media (max-width: 991px) {
    font-size: 16px;
  }
}

.exerciseCardContainer {
  display: grid;
  gap: 3rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: min-content;
  margin: 2rem 0;
  margin-bottom: 4rem;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    margin: 20px;
    flex-wrap: nowrap;
  }
}

.chooseUsSection {
  width: 85%;
  margin: 2rem auto;
  padding: 4rem 6rem;
  display: flex;
  gap: 5rem;
  flex-wrap: wrap-reverse;
  background-color: #f7f7f7;
  border-radius: 20px;

  @media (--viewportMobile) {
    width: 100%;
    gap: 2rem;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 0px;
  }
}
.chooseUsSection img {
  flex: 1;
  max-width: 30rem;
  max-height: 30rem;
  object-fit: cover;
  border-radius: 20px;
  @media (--viewportMobile) {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border-radius: 10px;
  }
}

.chooseUsInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;

  @media (--viewportMobile) {
    gap: 1rem;
  }
}
.chooseUsInfo .title {
  font-size: 32px;
  line-height: 50px;
  font-weight: 600;

  @media (--viewportMobile) {
    font-size: 24px;
    line-height: 30px;
  }
}
.chooseUsInfo .description {
  font-size: 18px;

  @media (--viewportMobile) {
    font-size: 14px;
  }
}
.chooseUsInfo .cardsContainer {
  display: flex;
  gap: 2rem;

  @media (--viewportMobile) {
    margin: 0;
    gap: 1rem;
  }
}
.chooseUsInfo .cardItem {
  flex: 1;
  max-width: 15rem;
  min-width: 9rem;
  padding: 1.5rem;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  background-color: white;
  border-radius: 12px;

  @media (--viewportMobile) {
    font-size: 14px;
    padding: 1rem;
    max-width: 8rem;
    min-width: 8rem;
  }
}

.yogaClassesLocation {
  width: 85%;
  margin: auto;
  margin-top: 3rem;

  @media (--viewportMobile) {
    width: 95%;
    margin-top: 0rem;
    padding: 1rem;
  }
}
.yogaClassesLocation .locationContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3rem;
  align-items: center;
  gap: 2rem 5rem;
  @media (--viewportMiniDesktopDown) {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  @media (--viewportTabletDown) {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  @media (--viewportMobile) {
    margin-top: 1rem;
    gap: 2rem;
    justify-content: center;
  }
}
.yogaClassesLocation a:hover {
  text-decoration: none !important;
}

.yogaClassesLocation .locationItem {
  display: flex;
  gap: 30px;
  width: 12rem;
  justify-content: flex-start;
  align-items: center;

  @media (--viewportMobile) {
    gap: 0.5rem;
    width: 8rem;
  }

  & span {
    @media (--viewportMobile) {
      font-size: 14px;
    }
  }
}

.yogaClassesLocation img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 10px;

  @media (--viewportMobile) {
    width: 50px;
    height: 50px;
  }
}

.servicesSection {
  width: 100%;
  margin-left: auto;
  margin: auto;
  margin-top: 4rem;

  @media (--viewportMobile) {
    margin-top: 2rem;
  }
}

.servicesTitle {
  line-height: 40px;
  font-weight: 600;
  color: black;
  margin: auto;
  font-size: 34px;
  text-align: center;

  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.textAlignLeft {
  @media (--viewportMobile) {
    width: 90%;
    margin: auto;
    text-align: start;
  }
}

.textAlignCenter {
  text-align: center;
}

.alignItemsCenter {
  align-items: center;
}

.servicesSubheading {
  max-width: 40rem;
  margin-top: 1rem;
  font-weight: 400;
  color: black;
  margin: 1rem auto 0 auto;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  @media (--viewportMobile) {
    display: none;
  }
}

.servicesCarousel {
  margin: auto;
  width: calc(85% + 4rem);
  padding: 1rem 2rem;
  overflow-x: auto;

  @media (--viewportMobile) {
    padding: 0rem;
  }
}

.servicesCarouselItems {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.servicesCarouselItem {
  max-width: 23rem;
  margin: 2rem 0;
  display: flex;
  flex: 1;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 0.5rem;

  @media (--viewportMobile) {
    max-width: 21rem;
  }

  & img {
    width: 100%;
    height: 15rem;
    border-top-radius: 10px;
    object-fit: cover;

    @media (--viewportMobile) {
      height: 12rem;
    }
  }

  & div {
    font-family: 'Int Circular PTT';
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
  }

  & .carouselInfo {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  & .carouselHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .carouselHeading > h5 {
    margin: 0;
    color: #a8a8a8;
  }

  & .carouselRating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }

  & .carouselRating img {
    width: 0.8rem;
    height: 0.8rem;
  }

  & .carouselRating h5 {
    margin: 0;
    font-weight: 600;
  }

  & .carouselRating span {
    font-size: 15px;
    color: #a8a8a8;
  }

  & .carouselName {
    margin: 0.3rem 0;
    font-size: 22px;
    font-weight: 600;
  }

  & .carouselDescription {
    font-size: 16px;
    color: #a8a8a8;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    height: 75px;
  }
}

.mobileCarouselCard {
  display: none;

  @media (--viewportMobile) {
    display: flex !important;
    width: 100% !important;
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
    margin: 2rem auto;
    border-radius: 10px;
    background: #f7f7f7;
  }
}

.slickCarouselCard {
  display: flex !important;
  width: 85% !important;
  border-radius: 10px;
  background: #f7f7f7;
  margin: auto;
}

.slickCarouselCard :global(.slick-dots li.slick-active button:before) {
  color: #0058fa !important;
  font-size: 18px !important;
}

.slickCarouselSlider {
  margin: 2rem 0;
  @media (--viewportMobile) {
    display: none !important;
  }
}

.carouselCardLeft {
  flex: 1;
  border-radius: 10px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carouselCardRight {
  flex: 1;
  padding: 2rem;
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
  justify-content: center;

  @media (--viewportMobile) {
    padding: 1rem 0rem;
    gap: 0rem;
  }
}

.cardTitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;

  @media (--viewportMobile) {
    font-size: 24px;
  }
}

.cardInfo {
  display: flex;
  gap: 3rem;
}

.cardLocation {
  display: flex;

  & img {
    width: 0.8rem;
    height: 0.8rem;
  }

  & span {
    margin-left: 0.5rem;
    font-size: 16px;
    font-weight: 400;

    @media (--viewportMobile) {
      font-size: 14px;
    }
  }
}

.cardRating {
  display: flex;
  gap: 3px;
  font-size: 16px;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 14px;
  }

  & img {
    width: 0.9rem;
    height: 0.9rem;
  }

  & h5 {
    margin: 0;
    color: #ffb534;
    font-size: 16px;
  }

  & span {
    color: #8c8c8c;
  }
}

.cardDescription {
  margin-top: 0.5rem;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.companiesSection {
  width: 100%;
  margin: auto;
  margin-top: 4rem;

  @media (--viewportMobile) {
    margin: 3rem auto;
  }
}

.popularYogaClassesSection {
  width: 85%;
  margin: 5rem auto;

  @media (--viewportMobile) {
    width: 100%;
    margin: 2rem auto;
  }
}

.yogaClassesContainer {
  padding: 2rem 5rem;
  margin: 2rem 0;
  background-color: #f7f7f7;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  border-radius: 20px;

  @media (--viewportLaptopDown) {
    padding: 40px 50px;
  }

  @media (--viewportMobile) {
    padding: 1rem;
    border-radius: 0px;
  }
}

.divider {
  margin: 0;
  border-top: 1px solid rgba(11, 21, 10, 0.1);

  @media (--viewportMobile) {
    display: none;
  }
}

.featuresSection {
  width: 100%;
  margin: 2rem 0;
  padding-top: 3rem;
  background-color: #f7f7f7;
}

.featureItemsContainer {
  display: flex;
  padding: 4rem 0;
  gap: 1.5rem;
  width: 85%;
  margin: auto;

  @media (--viewportMobile) {
    width: 90%;
    padding: 2rem 0;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
}

.featureItem {
  flex: 1;
  max-width: 20rem;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border-radius: 20px;

  @media (--viewportMobile) {
    font-size: 14px;
    min-width: 9rem;
    gap: 0.4rem;
  }
}

.submitRequestSection {
  margin-bottom: 2rem;
}

.becomeSellerSection {
  max-width: 85%;
  position: relative;
  margin: 0 auto 100px;
  @media (max-width: 1600px) {
    max-width: 85%;
  }
  @media (--viewportMiniDesktopDown) {
    margin: 0 auto 70px;
    max-width: 90%;
  }
  @media (--viewportTabletDown) {
    margin: 0 auto 25px;
    max-width: unset;
  }
}

.becomeSellerSection [class*='becomeSellerWrap'] {
  position: unset;
}

.paramsTitle {
  text-transform: capitalize;
}

.featureIcon img {
  @media (--viewportMobile) {
    width: 1rem;
    height: 1rem;
  }
}

.featureTitle {
  font-size: 22px;
  line-height: 30px;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.featureTitle2 {
  display: block;

  @media (--viewportMobile) {
    font-size: 13px;
  }
}

.featureDescription {
  font-size: 16px;
  font-weight: 400;

  @media (--viewportMobile) {
    font-size: 10px;
    line-height: 16px;
  }
}

.reviewsSection {
  width: 95%;
  margin: auto;
  margin-bottom: 4rem;

  @media (--viewportMobile) {
    margin-bottom: 2rem;
  }
}

.reviewsCarouselContainer {
  margin-top: 2rem;
  width: 100% !important;
  display: flex !important;
  align-items: center;
  gap: 2rem;

  @media (--viewportMobile) {
    margin-top: 1rem;
    padding: 2rem;
    overflow-x: scroll;
  }
}

.reviewsCardItem {
  flex: 1;
  max-width: 30rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background-color: white;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  @media (--viewportMobile) {
    min-width: 18rem;
    padding: 1rem;
  }
}

.cardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  & .cardImage {
    width: 3.5rem;
    height: 3.5rem;
    clip-path: circle(45%);
    shape-outside: circle(45%);

    @media (--viewportMobile) {
      width: 3rem;
      height: 3rem;
    }
  }

  & .cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .cardInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (--viewportMobile) {
      gap: 0.2rem;
    }
  }

  & .cardName {
    font-size: 24px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }

  & .cardSubheading {
    font-size: 20px;
    color: #a8a8a8;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }
}

.cardContent {
  font-size: 18px;
  font-weight: 400;
  color: #a8a8a8;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.yogaClassesItem {
  margin-top: unset;
  width: 100%;
  display: flex;
  flex-flow: row;
  gap: 1rem;

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }

  @media (--viewportMobile) {
    flex-flow: column;
  }

  & .itemImage {
    width: 13rem;
    height: 8rem;

    @media (--viewportMobile) {
      width: 8rem;
      height: 7rem;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  & .itemBasicInfo {
    flex: 2;
    display: flex;
    gap: 1rem;
  }

  & .yogaName {
    margin: 8px 0;
    color: #0058fa;

    @media (--viewportMobile) {
      font-size: 15px;
      margin: 4px 0;
    }
  }

  & .itemText {
    display: flex;
    flex-direction: column;

    @media (--viewportMobile) {
      font-size: 13px;
    }

    & img {
      @media (--viewportMobile) {
        width: 0.7rem;
        height: 0.7rem;
      }
    }

    & h6 {
      margin: 0.5rem 0;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;

      @media (--viewportMobile) {
        font-size: 12px;
        margin: 4px 0;
      }
    }
  }

  & .itemLocation,
  & .yogaRatings {
    display: flex;
    gap: 0.5rem;

    @media (--viewportMobile) {
      align-items: center;
      gap: 0.3rem;
    }
  }

  & .yogaRatings {
    & h6 {
      color: #ffb534;
    }

    & span {
      color: #8c8c8c;
    }
  }

  & .itemDescription {
    flex: 1;
    font-size: 16px;
    padding: 0.3rem;
    line-height: 28px;
    font-weight: 500;

    @media (--viewportMobile) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  & .itemBookingAndTimingInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 16px;

    @media (--viewportLaptopDown) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @media (--viewportMobile) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .timingInfo {
    @media (--viewportMobile) {
      font-size: 13px;
      line-height: 13px;
      margin: 5px 0 0;
      width: unset;
    }
  }

  & .bookingPrice {
    color: #28a74b;
    font-size: 22px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }

  & .bookNowBtn {
    width: max-content;
    padding: 0rem 1rem;
    min-height: 50px;

    @media (--viewportMobile) {
      font-size: 12px;
      min-height: 35px;
    }
  }
}

.cardsContainer {
  column-count: 2;

  @media (max-width: 991px) {
    column-count: 1;
    margin: 41px 24px;
  }
}

.cardsContainer div:nth-child(2) {
  background: #d3ffdd;
}

.cardsContainer div:nth-child(3) {
  background: #ffe4fe;
}

.cardsContainer div:nth-child(4) {
  background: #ffffea;
}

.cardsContainer div:nth-child(5) {
  background: #e0f4ff;
}

.cardsContainer div:nth-child(6) {
  background: #ffe7d1;
}

.topHeadContainer {
  height: calc(70vh - 56px);
  width: 100%;
  /* background: var(--marketplaceColorBackground); */
  display: flex;
  position: relative;
  color: var(--matterColorLight);
  padding: 100px 30px;
  gap: 50px;
  @media (max-width: 991px) {
    flex-flow: column-reverse;
    margin-top: 50px;
    height: fit-content;
    padding: 30px 20px;
  }
}
/* Top Header Quotes CSS starts */
.quoteWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  @media (--viewportMobile) {
    padding: 0 15px;
  }
}
.quoteWrapper img {
  @media (--viewportMobile) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    max-width: 100px;
    height: auto;
  }
}
.floatingTopBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  display: flex;
  /* border-bottom: 1px solid #dcdcdc; */
  box-shadow: 0 2px 5px 2px #0000002b;
  height: 50px;
  gap: 15px;
  justify-content: space-between;
  @media (--viewportMobile) {
    gap: 0;
  }
}
.floatingTopBar .floatingWrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid transparent;
  height: 100%;
  width: 100%;
}
.floatingTopBar .floatingTop {
  display: flex;
  align-items: center;
  /* flex: 1; */
  width: 60%;
  @media (--viewportMobile) {
    flex: 3;
    width: 100%;
  }
}
.floatingTopBar .floatingLink {
  /* background: #ffffff; */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
  @media (--viewportTabletDown) {
    font-size: 14px;
  }
  @media (--viewportMobile) {
    font-size: 12px;
  }
}
.floatingWP {
  display: flex;
  @media (min-width: 991px) {
    position: fixed;
    bottom: 15px;
    right: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    bottom: 85px;
  }
  @media (--viewportMobile) {
    flex: 1;
  }
}
.floatingWP .floatingWPLink {
  display: flex;
}
.floatingWP .floatingWPLink .imgDesktop {
  display: none;
  width: 64px;
  height: 64px;
  @media (min-width: 992px) {
    display: flex;
  }
}
.floatingWP .floatingWPLink .imgMobile {
  display: none;
  @media (max-width: 991px) {
    display: flex;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.floatingIG {
  display: flex;
  @media (min-width: 991px) {
    position: fixed;
    bottom: 90px;
    right: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    bottom: 85px;
  }
  @media (--viewportMobile) {
    flex: 1;
  }
}
.floatingTopBar .floatingWrap.activeLink {
  background: #0058fa;
}
.floatingTopBar .floatingWrap.activeLink .floatingLink {
  color: #fff;
}
.floatingTopBar .floatingWrap:last-child {
  border: 0;
}
.quoteWrapper p {
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  position: relative;
  color: #345592;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 15px;
  }
}

.quoteWrapper .floating img {
  @media (--viewportMobile) {
    display: none;
  }
}
.quoteWrapper .inputWrapper img {
  max-width: 130px;
  height: auto;
  position: absolute;
  right: 0;
  top: 5px;
  @media (--viewportMobile) {
  }
}
.quoteWrapper .inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapperInput {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media (--viewportMobile) {
    gap: 12px;
    display: none;
  }
}
.wrapperInput.floatingInput {
  position: fixed;
  flex-direction: column;
  background: linear-gradient(180deg, #0058fa 26.39%, rgba(45, 104, 213, 0.43) 100%);
  border-radius: 25px;
  right: 0;
  height: 170px;
  padding: 25px;
  @media (max-width: 991px) {
    height: 140px;
    padding: 15px;
    gap: 5px;
  }
  @media (--viewportMiniDesktopDown) {
    height: 150px;
    padding: 15px;
  }
  @media (--viewportMobile) {
    left: 50%;
    transform: translatex(-50%);
    bottom: 70px;
    width: 95%;
    height: 120px;
    /* box-shadow: 0 -2px 5px 2px #0000002b; */
  }
}
.wrapperInputHead {
  font-weight: 700;
  font-size: 37px;
  line-height: 45px;
  color: #ffffff;
  margin: 0;
  @media (max-width: 991px) {
    font-size: 25px;
  }
  @media (--viewportMiniDesktopDown) {
    font-size: 26px;
  }
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 30px;
  }
}
.wrapperInputWrap {
  display: flex;
  align-items: center;
  gap: 22px;
  background: #ffffff;
  border-radius: 15px;
  padding: 10px 20px;

  @media (--viewportMobile) {
    gap: 12px;
    /* display: none; */
  }
}
.inputWrap {
  width: 300px;
  display: flex;
  flex-direction: column;
  padding: 0 0;
  @media (--viewportMiniDesktopDown) {
    width: 200px;
  }
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.inputWrap .inputLabel {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  padding: 0;
  @media (--viewportMiniDesktopDown) {
    font-size: 18px;
  }
  @media (--viewportTabletDown) {
    display: none;
  }
}
.wrapperInputWrap input {
  border: 0;
  padding: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #a8a8a8;
  @media (--viewportMiniDesktopDown) {
    font-size: 16px;
  }
  @media (--viewportTabletDown) {
    font-size: 12px;
    padding: 0 10px;
    color: #000000;
    font-weight: 600;
  }
}
.wrapperInputWrap .btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  padding: 0 10px;
  width: 145px;
  height: 40px;
  border-radius: 6px;
  @media (--viewportMiniDesktopDown) {
    width: 115px;
  }
  @media (--viewportTabletDown) {
    font-size: 12px;
    width: 70%;
  }
}
.floating {
  justify-content: center;
  width: 100%;
  padding: 16px;
  position: fixed;
  /* bottom: 0; */
  top: 0;
  background: #fff;
  z-index: 9;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 16.2419px 61.4871px rgba(0, 0, 0, 0.1);
  @media (--viewportMobile) {
    bottom: 70px;
    top: unset;
  }
}
.quoteWrapper .inputWrapper input[type='text'] {
  border: 1px solid rgba(0, 0, 0);
  height: 40px;
  padding: 0 25px;
  width: 420px;
  color: #000;
  font-size: 20px;
  @media (--viewportMobile) {
    padding: 0 15px;
    font-size: 14px;
    width: 100%;
  }
}
.quoteWrapper .inputWrapper .btnQuote {
  background: #0058fa;
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  padding: 0 24px;
  width: 185px;
  line-height: 40px;
  border-radius: 6px;
  @media (--viewportMobile) {
    padding: 10px 2px;
    font-size: 14px;
    line-height: 15px;
    border-radius: 6px;
  }
}
.quoteWrapper .inputWrapper .btnQuote:active {
  background: transparent;
  border: 1px solid #0058fa;
  color: #0058fa;
}

.quoteWrapper h4 {
  font-weight: 500;
  font-size: 46px;
  line-height: 45px;
  color: #000000;
  @media (--viewportMobile) {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
  }
}
.heroImage img {
  border-radius: 27px;
  position: relative;
  z-index: 1;
}
.heroImage .heroVideo {
  position: relative;
  left: 50%;
  transform: translateX(-55%);
  top: 0;
  opacity: 1;
  /* width: 100%; */
  height: 100%;
  border-radius: 27px;
  position: relative;
  z-index: 2;
  object-fit: cover;
  object-position: center;
  box-shadow: #00000066 0px 2px 4px, #0000004d 0px 7px 13px -3px, #00000033 0px -3px 0px inset;

  @media screen and (max-width: 991px) {
    width: 100%;
    transform: translateX(-50%);
  }
}
.heroImage .overlayBg {
  background: var(--matterColorShades);
  border-radius: 27.5877px;
  width: 520px;
  height: calc(100% + 60px);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-55%, -50%);
  @media (max-width: 1699px) {
    width: 450px;
  }
  @media (--viewportMobile) {
    width: 80%;
    left: 55%;
  }
}
.overlayVideoBg {
  background: var(--matterColorShades);
  border-radius: 27.5877px;
  width: 80%;
  height: calc(100% - 60px);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-55%, -50%);
  /* @media (max-width: 1699px) {
    width: 450px;
  } */
  @media (--viewportMobile) {
    width: 80%;
    left: 55%;
  }
}

.heroImage video::-webkit-media-controls-panel {
  display: flex;
  -webkit-appearance: none;
  opacity: 1;
  height: 40px;
}

.heroImage video::-webkit-media-controls-mute-button,
.heroImage video::-webkit-media-controls-volume-slider {
  display: block;
  visibility: visible;
  width: 40px;
}

.heroImage video::-webkit-media-controls-timeline,
.heroImage video::-webkit-media-controls-play-button,
.heroImage video::-webkit-media-controls-fullscreen-button,
.heroImage video::-webkit-media-controls-time-remaining-display,
.heroImage video::-webkit-media-controls-current-time-display {
  display: none;
  -webkit-appearance: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
/* Top Header Quotes CSS ends */
.heroImage {
  flex: 1.5;
  width: 100%;
  height: 100%;
  position: relative;

  @media (--viewportMobile) {
    height: 255px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.imageContainer {
  width: 85%;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 4rem;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 1rem;

  @media (max-width: 991px) {
    width: 90%;
    padding: 1rem;
    margin: 2rem auto;
  }

  & h3 {
    font-size: 32px;
    color: white;
    text-align: center;
    line-height: 45px;
    max-width: 50rem;

    @media (max-width: 991px) {
      font-size: 18px;
      text-align: center;
      line-height: 25px;
    }
  }
}

.arrowIconLeft {
  width: 2rem;
  height: 2rem;

  @media (max-width: 991px) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.uniqueSectionContainer {
  max-width: 90%;
  margin: 40px auto 0;

  @media (max-width: 991px) {
    margin: 20px 24px 20px;
  }
}

.sectionImagesContainer {
  display: flex;
  align-items: center;
  gap: 25px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
    h3 {
      margin: 24px 0;
    }
  }
}

hr {
  margin: 60px 0 25px;
}

.getProLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  width: fit-content;
  padding: 0 1.5rem;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-top: 1rem;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;

  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 14px;
    line-height: 19px;
  }
}
.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ourTopExampleTitle {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: start;
  color: var(--matterColorDarkBlue);
  margin: 35px 0;
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 28px;
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
  }
}
/* location area css start */
.locationArea {
  background-color: var(--matterColorBlue);
  margin: 5px 0px;
  padding: 5px 0;
}
.locationHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  width: 85%;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}
.positionStatic {
  position: static !important;
}
.locationTitle {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: start;
  color: #fff;
  margin: 20px 0 0;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 26px;
    width: 85%;
    margin: 15px auto 0 auto;
  }
}
.locationTextWrap {
  color: #fff;
}
.locationHeader {
  padding: 35px 0;
  background-color: transparent;
  @media (--viewportTabletDown) {
    padding: 5px 24px;
  }
}

.locationListContainer {
  /* height: 147px; */
  display: flex;
  align-items: center;
  /* left: 100px; */
  position: relative;
  width: 85%;
  margin: auto;
  /* padding: 0 40px; */
  /* justify-content: center; */
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 0;
    margin: unset;
  }
}

.locationListContainer [class*='scrollContainer'] {
  /* padding: 0 30px; */
  padding-bottom: 10px;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar {
  display: block;
  height: 3px;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar-track {
  background: transparent;
}
.locationListContainer [class*='scrollContainer']::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 50px;
}

.locationListContainer [class*='left'] {
  left: -40px;
}

.locationListContainer [class*='right'] {
  right: -50px;
}

.categoryListContainerWithoutArrow {
  width: 100%;
  margin: 0 80px;

  @media (--viewportMobile) {
    margin: 0;
  }
}

.locationWrapperTop {
  display: flex !important;
  flex-direction: row;
  column-gap: 30px;
  align-items: center;
  flex: none;
  cursor: pointer;
  @media (--viewportLaptopDown) {
    column-gap: 15px;
  }
  &:hover {
    text-decoration: none;
  }
}
.locationImgWrap {
  width: 75px;
  height: 75px;
  @media (--viewportLaptopDown) {
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }
}
.locationImgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.locationTextWrap {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;

  @media (--viewportLaptopDown) {
    font-size: 17px;
    line-height: 21px;
  }
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 15px;
  }
}
.selected {
  color: #ffb81d;
}
/* location area css end */

.productsButton {
  width: 185px;
  min-height: 46px;
  background: var(--matterColorBlue);
  border-radius: 3.31824px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  margin: 25px auto 0;
  &:hover {
    background: var(--matterColorBlue);
    box-shadow: none;
  }
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    color: var(--matterColorBlue);
    min-height: 20px;

    &:hover {
      background: var(--matterColorLight);
      box-shadow: none;
    }
  }
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}

.companyListing {
  margin: 45px auto;
  width: 85%;
  @media (--viewportxldesktop) {
    margin: 30px auto;
  }
  @media (--viewportTabletDown) {
    margin: 15px auto;
  }
}

.categoryId {
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  @media (--viewportxldesktop) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 26px;
  }
}
.breadCrumbWrapper {
  /* margin: 10px auto 0; */
  width: 85%;
  word-break: break-all;
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.shareFormModal [class*='container'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    padding: 15px;
    min-height: unset;
    width: 95%;
    height: 100%;
    flex-grow: 0;
    top: 50px;
    border-radius: 10px;
    background: rgb(247, 247, 247);
  }
}
.shareFormModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.shareFormModal [class*='close'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    left: 5px;
    background: none;
    width: 15px;
    height: 15px;
    fill: #000000;
  }
}
.descriptionWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
}
.textDescriptionLeft {
  text-align: left;
}
.listingMode {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0px 120px 35px;

  @media (--viewportMobile) {
    padding: 5px 15px;
  }
}
.bgClass {
  --matterColorShades: #ffafbd;
  --matterColorBlue: #ff385c;
}
/* Quotes Modal Design Start */

.quotesFormContainer {
  background: var(--matterColorLight);
}

.quotesForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.quotesSubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}
/* Quotes Modal Design End */
/* new floating bar */
.newFloatingMain {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* padding: 10px 15px; */
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  display: none;
  width: 100%;
  position: fixed;
  left: 50%;
  transform: translatex(-50%);
  bottom: 70px;
  width: 98%;
  @media (--viewportTabletDown) {
    display: none;
  }
  @media (--viewportMobile) {
    display: flex;
    gap: 6px;
  }
  @media (max-width: 320px) {
    gap: 4px;
  }
}
.newFloatingMain .newFloatingImage {
  width: 70px;
  height: 50px;
}
.newFloatingMain .newFloatingImage img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}
.newFloatingMain .newFloatingText {
  color: black;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 500;
  @media (--viewportMobile) {
    font-size: 15px;
  }
  @media (max-width: 320px) {
    font-size: 13px;
  }
}
.newFloatingMain .newFloatingButton {
  border-radius: 30px;
  background: linear-gradient(180deg, #ffb81d 0%, #fe6436 100%);
  color: #fff;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  white-space: nowrap;
  border: 0;
  @media (max-width: 320px) {
    font-size: 10px;
  }
}
