@import '../../marketplace.css';

.root {
}

.select {
  @apply --marketplaceInputStyles;
  /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  line-height: 18px;

  /* Borders */
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  &::placeholder {
    color: var(--matterColorAnti);
    font-size: 14px;
  }

  /* Effects */

  transition: border-color var(--transitionStyle);

  &:focus {
    border-color: var(--matterColor);
    outline: none;
  }
}

.selectSuccess {
  color: var(--matterColor) !important;
  border-color: var(--successColor);
}

.selectError {
  border-color: var(--failColor);
}
.label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  background: white;
  font-size: 14px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (min-width: 550px) and (max-width: 800px) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (--viewportMobile) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
