@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import '../../../marketplace.css';

.ListingTypeWrapper {
  display: flex;
  /* justify-content: space-evenly; */
  width: 100%;
  background: #f5f5f5;
  min-height: calc(100vh - 122px);
  padding: 13px;
  @media (--viewportTabletDown) {
    column-gap: 25px;
  }
  @media (--viewportMobile) {
    flex-direction: column;
    row-gap: 20px;
    padding: 15px;
    min-height: calc(100vh - 110px);
  }
}
.listingTypeImg {
  flex: 1;
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  @media (--viewportMobile) {
    height: 160px;
    flex: unset;
  }
}
.listingImg {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
  @media (--viewportMobile) {
    height: auto;
  }
}
.listingImgText {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 75px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)); */
  @media (--viewportTabletDown) {
    padding: 35px;
  }
}
.imgText {
  font-weight: 500;
  font-size: 59px;
  line-height: 77px;
  color: var(--matterColorLight);
  flex: 1;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    font-size: 22px;
    line-height: 30px;
  }
}
.authorDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (--viewportMobile) {
    display: none;
  }
}
.authorInfo {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.authorName {
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
  color: var(--matterColorLight);
  font-family: 'Italianno', cursive;
}
.category {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--matterColorLight);
}
.authorAddress {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorLight);
  margin: 0;
}
.selectListingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 2;
  @media (--viewportTabletDown) {
    flex: 1;
  }
  @media (--viewportMobile) {
    align-items: flex-start;
  }
}
.selectListing {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 35px;
  justify-content: center;
  @media (--viewportTabletDown) {
    width: 100%;
  }
  @media (--viewportMobile) {
    row-gap: 20px;
    /* margin-bottom: 40px; */
    justify-content: flex-start;
    align-items: center;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 514px;
  /* height: 136px; */
  padding: 38px 38px 38px 40px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  @media (--viewportTabletDown) {
    width: 400px;
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}

.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
}

.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 34px;
  height: 34px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 22px;
    height: 22px;
    border-radius: 6px 0px 4px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
}
.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}

.nextButtonWrapper {
  width: 222px;
  height: 60px;
  background: var(--matterColorBlue);
  color: var(--matterColorLight);
  border-radius: 7px;
}

.nextButton {
  width: 100%;
  height: 100%;
  border: 0;
  cursor: pointer;
}

.errorMessage {
  color: var(--failColor);
}
