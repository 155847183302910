@import '../../marketplace.css';

.topbar {
  z-index: 1;
  /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: none;
  padding: 7px 22px 5px 27px;

  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportSmall) {
    padding: 0 24px;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.02);

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--marketplaceColorBackground);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 24px 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 59px;
    left: 0;
    overflow-x: hidden;
    padding-top: 30px;
    transition: 0.5s;
  }

  @media (--viewportLaptopDown) {
    /* justify-content: flex-start; */
    /* padding: 24px 24px 0 24px; */
  }

  @media (--viewportLarge) {
    padding: 19px 0 0 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: none;
    max-width: 279px;
    width: 279px;
    height: 100vh;
    box-shadow: none;
    position: sticky;
    top: 0;
    left: 0;
    height: 100%;
    background-color: transparent;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles;
  color: var(--matterColor); */
  font-weight: bold;
  font-size: 25px;
  line-height: 31px;
  /* color: var(--matterColorLight); */
  /* Layout */
  margin-top: 25px;

  @media (--viewportMedium) {
    padding-bottom: 10px;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
    font-size: 40px;
    line-height: 50px;
    /* margin-top: 0; */
    margin-bottom: 0;
    padding-bottom: 10px;
  }
}

.headerDivider {
  margin: 0 5%;
  border: 1px solid #1b2758;
  transform: rotate(180deg);
  width: 90%;

  @media (--viewportLaptopDown) {
    border: 0;
  }
}

.helpers {
  display: flex;
}
.rightContentMain {
  /* overflow-y: auto; */
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (--viewportMobile) {
    overflow-y: unset;
  }
}
.contentWrapper {
  /* height: calc(100% - 52px); */
  overflow-y: auto;
  overflow-x: hidden;
  @media (--viewportMobile) {
    height: calc(100% - 214px);
  }
  /* background-color: #3e557c; */
  @media (--viewportLaptopDown) {
    height: calc(100% - 200px);
    height: 75vh;
  }
}
.enquiryScroll {
}

.tabs {
  padding-top: 2px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3e557c;
  }

  @media (--viewportMedium) {
    padding-top: 12px;
  }

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }
}

.tab {
  /* margin-left: 16px; */
  /* max-width: 150px; */
  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResults {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 1px;

  @media (--viewportLarge) {
    margin-top: 6px;
  }
}

.itemList {
  margin: 0;

  @media (--viewportLaptopDown) {
    padding: 15px 22px 15px 22px;
    /* height: calc(100vh - 173px);
    overflow: auto; */
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
  }
}

.transectionItemList {
  height: calc(100vh - 184px);
  overflow-y: auto;
}

.sellerPackagesItemList {
  height: calc(100vh - 195px);
  overflow-y: auto;
  @media (--viewportLaptopDown) {
    height: calc(100vh - 310px);
  }
  @media (--viewportMobile) {
    height: calc(100vh - 360px);
    height: 75vh;
  }
}
.sellerPackagesItemListScroll {
  @media (--viewportMobile) {
    /* height: calc(100vh - 190px); */
    height: auto;
  }
}
.buyerPackagesItemList {
  @media (--viewportMobile) {
    height: calc(100vh - 210px);
  }
}

.bookingItemList {
  max-height: calc(100vh - 180px);
  /* overflow-y: auto; */
  scroll-behavior: smooth;
  /* overflow: auto; */
  @media (--viewportLaptopDown) {
    max-height: calc(100vh - 335px);
    margin-bottom: 45px;
  }

  @media (--viewportMobile) {
    /* max-height: 100%;   */
    /* height: 100%; */
    max-height: calc(100vh - 380px);
  }
}

.bookingItemListAll {
  /* max-height: calc(100vh - 270px); */
  @media (--viewportMobile) {
    max-height: calc(100vh - 400px);
  }
}

.buyerBookingList {
  /* overflow-y: auto; */
  scroll-behavior: smooth;
  max-height: calc(100vh - 260px);
  overflow: auto;

  @media (--viewportLaptopDown) {
    max-height: calc(100vh - 305px);
    overflow: unset;
  }

  /* @media (--viewportMobile)
  {
    overflow-y: unset;
  }*/
}

.buyerBookingListAll {
  max-height: calc(100vh - 260px);
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  overflow: visible;
  @media (--viewportLaptopDown) {
    max-height: calc(100vh - 365px);
    /* overflow: auto; */
  }
}

.myClientItemList {
  max-height: calc(100vh - 155px);
  overflow-y: auto;

  @media (--viewportLaptopDown) {
    height: unset;
    padding: 12px 22px 0 22px;
    max-height: calc(100vh - 200px);
  }
}

.enquiryItemList {
  height: unset;

  @media (--viewportMobile) {
    padding: 0;
  }

  @media (--viewportTabletDown) {
    margin-top: 110px;
    padding: 0;
  }

  @media (--viewportLaptopDown) {
    margin-top: 66px;
  }
}

.scheduleItemList {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  display: flex;
  margin-bottom: 10px;
  /* padding-bottom: 12px; */
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;

  @media (--viewportLaptopDown) {
    flex-direction: column;
  }

  &:first-child {
    margin-top: 20px;
  }

  &:last-child {
    /* border-bottom: none; */
  }

  @media (--viewportLarge) {
    margin-bottom: 24px;
    /* padding-bottom: 15px; */
  }
}

.enquirylistItem {
  border-color: transparent;
  margin-bottom: 0;
}

.paymentProofsContainer {
  margin-top: 30px;
}

.paymentProofSsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (--viewportMobile) {
    margin: 29px 24px 0 24px;
  }
}

.paymentProofSs img {
  width: 300px;
  padding: 15px;
}

.acceptPackageRequest {
  @apply --marketplaceButtonStylesPrimary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}

.rejectPackageRequest {
  @apply --marketplaceButtonStylesSecondary;
  width: auto;
  padding: 15px 25px;
  margin-left: 20px;
}

.trigger button {
  width: auto;
  padding: 15px 25px;
  margin-left: 10px;
}

.trigger {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border-bottom: 1px solid var(--matterColorNegative); */
  /* padding: 10px; */
  cursor: pointer;
  font-size: 16px;
  color: var(--matterColorDarkBlue);

  @media (--viewportMobile) {
    flex-direction: column;
  }
}

@media (--viewportMiniDesktopDown) {
  .trigger {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.userInfoContainer {
  display: flex;
  width: 100%;
  flex: 1.5;

  @media (--viewportMiniDesktopDown) {
    flex: 1;
  }
}

.sessionContainer {
  display: flex;
  flex: 1;

  @media (--viewportMobile) {
    width: 100%;
  }
}

.packageRequestActions,
.paymentInfoContainer {
  display: flex;
  border-left: 1px solid #e0e0e0;
}

.collapsible {
  width: 100%;
  padding: 20px;

  @media (--viewportMobile) {
    padding: 15px;
  }
}

.searchWrapper {
  background: #ffffff;
  width: 420px;
  box-shadow: 0px 16px 17px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 51.43px;

  @media (--viewportMobile) {
    width: 100%;
    height: 38px;
  }
}

.searchWrapperMobile {
  background: #f5f9ff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (--viewportLaptopDown) {
    gap: 23px;
    padding: 23px;
    align-items: center;
  }

  @media (--viewportMobile) {
    align-items: flex-end;
    flex-direction: column;
    padding: 10px;
  }
}

.searchIconWrapper {
  background: #0058fa;
  border-radius: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  border: none;

  @media (--viewportLaptopDown) {
    width: 26px;
    border-radius: 4px;
    height: 26px;
  }
}

.downloadBtn {
  max-width: fit-content;
  height: 48px;
  min-height: 48px;
  padding: 1rem;
  /* height: 40px; */
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  background: #0058fa;
  line-height: 24px;
  border: none;
  border-radius: 7.18751px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;

  @media (--viewportMiniDesktopDown) {
    font-size: 12px;
    line-height: 12px;
    max-width: 205px;
    margin-left: 10px;
  }

  @media (--viewportMobile) {
    /* margin-bottom: 12px; */
    font-size: 12px;
    max-width: 175px;
    height: 40px;
    min-height: unset;
  }

  @media (--viewportLarge) {
    margin-top: 20px;
  }

  &:focus,
  &:hover {
    background: #0058fa;
  }
}

.downloadBtnIcon {
  margin-right: 8px;
  stroke: #ffffff;
}

.userAndListingName {
  display: flex;
  /* flex-direction: column; */
  /* margin-left: 30px; */
}

.trigerDivider {
  border: 1px solid #dce5f2;
  height: 40px;
  margin: auto 10px;
}

.sessions,
.usedSessions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;

  @media (--viewportMobile) {
    padding: 0;
    flex: 1;
  }
}

.triggerSectionRight {
  margin-left: auto;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  @media (--viewportMobile) {
    margin: 0;
  }
}

.triggerSectionLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  /* & .root {
    border-radius: 8px;
    width: 60px;
    height: 60px;
    margin: 20px;
  } */
}

.createdAt {
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: #aba7a7;
  padding: 10px;
  /* min-width: 175px; */
  flex: 1;

  @media (--viewportMobile) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 10px;
    line-height: 13px;
    padding: 13px 0 15px;
  }
}

.timeZoneWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
  }
}

.imgContainer [class*='root'] {
  border-radius: 8px;
  width: 60px;
  height: 60px;

  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
}

.userName {
  & a {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: #0b1333;

    @media (--viewportTabletDown) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #0b1333;
    }
  }
}

.userListing {
  /* min-width: 435px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;

  @media (--viewportTabletDown) {
    min-width: unset;
    display: flex;
    flex-wrap: wrap;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
  }
}

.userName:hover {
  color: var(--marketplaceColor);
}

.listingTitle {
  @media (--viewportMobile) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100% !important;
    line-height: normal;
  }

  @media (--viewportMiniDesktopDown) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    line-height: normal;
  }

  & a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #aba7a7;

    @media (--viewportMiniDesktopDown) {
      font-size: 10px;
      line-height: 13px;
      color: #aba7a7;
    }
  }
}

.listingTitle:hover {
  color: var(--marketplaceColor);
}

.status {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #59c264;
  margin-right: 10px;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
  }
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColorDark);
  stroke-width: 3px;
}

.salesHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
}

/* State btn */
.stateBtnsWtap {
  display: flex;
  flex-wrap: wrap;
  margin: -4px;

  & div {
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    cursor: pointer;
    height: 40px;
    padding: 0 22px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear, color 0.2s linear;
    margin: 4px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 16px 17px rgb(0 0 0 / 4%);
    border-radius: 9px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #0b1333;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.iconArrowDown {
  display: flex;
  margin-left: 15px;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: var(--marketplaceColorDark);
  border-radius: 50%;

  & svg {
    margin-left: 0;
    fill: var(--matterColorBright);
  }
}

.iconArrowDropDown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;

  & svg {
    width: 16px;
    height: 8px;
    margin-left: 0;
    fill: var(--matterColorBright);
  }
}

.payment_type,
.payment_status {
  padding: 15px 10px;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #1b1b1b;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
  }
}

/* .checkbox {
  display: flex;
  align-items: center;
  & > span {
    font-size: 16px;
  }
}*/
.checkboxInput {
  width: 25px;
}

.checkboxField {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #0058fa;
  border-radius: 8px;
  width: 135px;
  height: 44px;
  justify-content: space-evenly;

  @media (--viewportMobile) {
    /* flex-direction: row-reverse; */
  }

  & > span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #0058fa;
  }
}

.checkboxField label {
  background-color: var(--matterColorLight);
  cursor: pointer;
  height: 22px;
  position: absolute;
  width: 22px;
  /* opacity: 0.5; */
  border: 1px solid #0058fa;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  left: 10px;
}

.checkboxField label:after {
  border: 3px solid var(--matterColorLight);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 50%;
  transform: translate(-50%, -75%) rotate(-45deg);
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 12px;

  @media (--viewportMobile) {
    border-width: 2px;
  }
}

.checkboxField input[type='checkbox'] {
  visibility: hidden;
}

.checkboxField input[type='checkbox']:checked + label {
  background-color: #0058fa;
  border-color: #0058fa;
}

.checkboxField input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.selectAllWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 12px 5px 12px;
  /* border-bottom: 1px solid #dce5f2; */
  /* margin-bottom: 2px; */

  @media (--viewportTabletDown) {
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    padding: 0 15px 18px 12px;
    /* flex-direction: row-reverse; */
  }

  @media (--viewportLaptopDown) {
    margin: 0 22px 0px 22px;
  }
}

.rightSideControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;

  & button {
    background: #0058fa;
    border-radius: 7.18751px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: none;
    width: 151.84px;
    height: 44.59px;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;

    color: #ffffff;
  }

  @media (--viewportMedium) {
    width: auto;
    column-gap: 20px;
  }

  & span {
  }
}

.desktopSortPopupWrapper [class*='menuLabel'] {
  & svg {
    fill: #ffffff;
  }
}

.sendMsgDivider {
  border: 0.898439px solid #397dfa;
  height: 17.97px;
}

.bookingDivider {
  border: 1px solid #dce5f2;
  margin-bottom: 20px;
}

.mobileSortPopupWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  @media (--viewportTabletDown) {
    padding-bottom: 0;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.mobileSortPopupWrapper [class*='menuLabel'] {
  @media (--viewportMobile) {
    width: 135px;
    height: 44px;
    font-size: 16px;
    line-height: 20px;
  }
}

.sortStyle {
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;

  @media (--viewportMedium) {
    margin-right: 8px;
    margin-left: auto;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.desktopSortPopupWrapper {
  display: flex;

  /* @media (--viewportMedium) {
    display: block;
  } */
  & .menuLabel {
    color: #ffffff;
  }
}

.menuLabelReady {
  color: #ffffff;
}

.menuLabelReady [class*='menuContent'] {
  & button {
    background: transparent;
    color: black;
  }
}

/* Schedule tab */
.scheduleContent {
}

.scheduleLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes {
  display: none;
  overflow: hidden;
  width: 300px;
  text-align: left;
  line-height: 18px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: break-spaces;
  word-wrap: break-word;
  margin-left: 20px;
  padding-left: 20px;

  @media (--viewportMedium) {
    display: -webkit-box;
  }
}

.attachment img {
  width: 100%;
  padding: 15px;

  @media (--viewportMobile) {
    width: 250px;
  }
}

.attachment {
  display: flex;
  justify-content: center;
}

.bookingPlanBtn {
  @apply --marketplaceButtonStylesSecondary;
  display: inline-block;
  min-height: 30px;
  width: 150px;
  padding: 10px;
  margin-bottom: 30px;
}

.searchPackage,
.transactions {
  border: none;
  /* margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px; */
}

.searchPackageBuyer {
  border: none;
  border-bottom: 2px solid #9ba3af;
  border-radius: 0;
}

.prAmount {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  @media (--viewportMobile) {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
}

.prTime {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #a8a8a8;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
  }
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin-top: -24px;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
  }
}

.walletListingPanel {
  width: 100%;

  @media (--viewportLarge) {
    max-width: 62.5vw;
  }
}

.walletListingCards {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.LayoutSideContainer {
  display: flex;
  background: #0b1333;
  height: 100vh;

  @media (--viewportLarge) {
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
}

/* .LayoutSideContainer{
  @media (--viewportLarge) {
    max-width: 1524px;
    width: 100%;
    padding: 0;
    margin: 0 auto;
  }
  @media (max-width: 1440px){
    max-width:100%;
    padding: 0 80px;
  }
  @media (max-width: 768px){
    padding: 0;
  }
  @media (--viewportLarge) {
    display: flex;
  }
} */

.LayoutSideContainer > div:first-child {
  background: #eee;
  padding: 24px 80px;

  @media (--viewportMobile) {
    padding: 10px;
    background: var(--matterColorLight);
  }
}

.dropdownBtn {
  @apply --marketplaceButtonStylesSecondary;
  padding: 5px 0;
  min-width: 142px;
  min-height: 30px;
  color: black;
  border: 1px solid lightgrey;
}

.dropdownSelection {
  width: 260px;
  height: 45px;
  border: 1px solid gainsboro;
  padding: 0 10px;
  background: var(--matterColorLight);
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  margin-bottom: 20px;
}

.Selection {
  border-bottom-width: 0px;
}

.inboxMainHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  color: #0b1333;
  margin: 0;
  white-space: pre;

  @media (--viewportMobile) {
    line-height: 28px;
  }
}

.rightPanel {
  border: none;
  width: 100%;
  max-width: 100%;
  background-color: #f5f9ff;
  border-radius: 17px 0px 0px 17px;
  padding: 38px 35px;
  width: calc(100% - 300px);
  max-height: 100vh;

  & nav {
    justify-content: flex-end;
    /* position: absolute; */
    bottom: 15px;
    right: 38px;

    @media (--viewportLaptopDown) {
      bottom: 65px;
    }
  }

  @media (--viewportLaptopDown) {
    padding: unset;
    border-radius: 0;
  }
}

.myClientRightPanet {
}

.enquiryRightPanel {
  border: none;
  width: 100%;
  padding: 35px;
  max-width: 100%;
  padding: 38px 35px;
  border-radius: 17px 0px 0px 17px;
  background: #f5f9ff;

  @media (--viewportxldesktop) {
    padding: 17px 22px;
  }

  @media (--viewportLaptopDown) {
    border-radius: 0;
    padding: 0;
  }

  @media (--viewportTabletDown) {
    padding: 0;
  }
}

.MobileNavButton {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  /* column-gap: 95px; */
  @media (--viewportLarge) {
    gap: 28px;
  }

  & select {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (--viewportLaptopDown) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 768px) {
    margin: 16px 16px 24px;
  }
}

.bookingTitleContainer {
  /* align-items: flex-start;
  justify-content: unset; */
  justify-content: revert;
  margin: 5px;
  @media (--viewportLarge) {
    gap: 28px;
  }
}

.titleBtnWrapper {
  display: flex;
  align-items: center;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.inboxTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 13px;

  & img {
    width: 171px;
    height: 36px;
  }

  & select {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (--viewportMobile) {
    width: 100%;
    justify-content: flex-start;
    padding-left: 30px;
  }
}

.titleWrap {
  display: flex;
}

.stateDropDownWtap {
  background: var(--matterColorLight);
  width: 36%;
  padding: 8px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 7px;
  text-transform: capitalize;
  background-image: url('/static/images/Icons/downArrow.png');
  background-size: 8px 5px;
  background-position: center right;
  background-repeat: no-repeat;
  background-position-x: 96%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColorDarkest);
}

.scheduleTitleContainer {
  justify-content: space-between;
  column-gap: 29px;
  /* margin: 10px 0 50px 0; */

  & .root {
    margin: unset;
    height: auto;
  }

  @media (max-width: 769px) {
    margin: 16px 16px 24px;
    justify-content: space-between;
  }

  @media (--viewportLaptopDown) {
    flex-direction: row !important;
    align-items: center;
  }
}

.scheduleTitleContainer [class*='menuLabel'] {
  @media (--viewportLaptopDown) {
    border: 0.529412px solid #ffffff;
    border-radius: 3.80515px;
    color: #ffffff;
  }

  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 16px;
    line-height: 13px;
    width: 103px;
    height: 40px;
    margin-right: 12px;
  }
}

.scheduleTitleContainer [class*='menuLabel'] svg path {
  @media (--viewportLaptopDown) {
    stroke: #ffffff;
  }
}

.mobileStateSlider {
  display: flex;
  align-items: center;
  /* overflow: scroll; */
  width: 100%;
  height: 100px;
  background: #f5f9ff;
  margin: 0 0 2px;

  @media (--viewportLaptopDown) {
    overflow-x: scroll;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.mobileStateSliderLink {
  white-space: nowrap;
  color: #ffffff;
  text-transform: capitalize;
  padding: 6px 12px;
  margin: auto 10px;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  height: 40px;
  line-height: 13px;
  background: #ffffff;
  box-shadow: 0px 10.3282px 10.9737px rgb(0 0 0 / 4%);
  border-radius: 5.80963px;
  color: #0b1333;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transitionStyleButton);
}

.mobileStateSliderSelectedLink {
  background: #0058fa;
  box-shadow: 0px 12.2648px 11.6193px rgb(0 0 0 / 8%);
  border-radius: 5.80963px;
  color: #ffffff;
  text-decoration: none !important;
}

.modalContent {
  @media (--viewportMobile) {
    height: 100%;
  }

  & form {
    box-shadow: none;
  }
}

.contentWrap {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}

.pagination {
  padding: 15px 0;

  @media (--viewportLaptopDown) {
    margin: 0 10px;
  }
}

/* .myClientPagination {


} */

.svgContainer {
  background: #ffffff;
  box-shadow: 0px 19px 18px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 61px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  & svg {
    background-color: transparent;
    fill: none;
    width: 30px;
    height: 30px;
  }
}

.chartMenu {
  display: flex;
  width: max-content;

  @media (--viewportMobile) {
    width: 100%;
  }

  @media (--viewportLaptopDown) {
    width: 100%;
    background: #f5f9ff;
    justify-content: center;
  }
}

.chatMenuItem {
  background: #ffffff;
  box-shadow: 0px 16px 17px rgb(0 0 0 / 4%);
  border-radius: 9px;
  width: 84px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #0b1333;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 10px;
  transition: color 0.2s linear;

  &:hover {
  }

  @media (--viewportTabletDown) {
    box-shadow: 0px 9.93939px 10.5606px rgba(0, 0, 0, 0.04);
    border-radius: 5.59091px;
    width: 53.42px;
    height: 30.44px;
  }
}

.chatMenuItemActive {
  /* position: relative; */
  background: #0058fa;
  box-shadow: 0px 19px 18px rgba(0, 0, 0, 0.08);
  color: #ffffff;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 16px;
    height: 4px;
    background: var(--marketplaceColorDark);
    border-radius: 4px;
  }
}

.filterKeywordWrap {
  background: #ffffff;
  box-shadow: 0px 16px 17px rgb(0 0 0 / 4%);
  border-radius: 9px;
  display: flex;
  max-width: 420px;
  width: 100%;
  height: 51.43px;
  justify-content: space-between;
  align-items: center;

  & input {
    width: 185%;
    border: none;
  }

  @media (--viewportLaptopDown) {
    max-width: unset;
    margin: 0 20px;
    width: calc(100% - 40px);
    font-size: 11.8857px;
    line-height: 15px;
    height: 38px;
    box-shadow: 0px 11.8857px 12.6286px rgba(0, 0, 0, 0.04);
    border-radius: 6.68571px;
  }
}

.tabDivider {
  border: 1px solid #dce5f2;
  margin-bottom: 10px;

  @media (--viewportLaptopDown) {
    width: calc(100% - 44px);
    margin: 0 auto;
  }
}

.sheculeDefault {
  height: unset;
}

.mobileTitleContainer {
  background: #0b1333;
  margin: unset;
  display: flex;
  flex-direction: column;
  z-index: 9;
  height: 10vh;

  & .titleWrapBtn {
    border: none;
    margin: 23px 23px 23px 5px;
    @media (--viewportMobile) {
      margin: 20px 5px;
    }
  }

  & h1 {
    color: #ffffff;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    @media (--viewportMobile) {
      font-size: 18px;
      line-height: 20px;
    }
  }

  @media (--viewportLaptopDown) {
    /* flex-direction: row; */
    position: sticky;
    top: 0;
    align-items: center;
  }
}

.sideNavModal {
  /* top: 80px; */
  width: 310px;
  /* background: #0B1333; */
}

.sideNavModal [class*='container'] {
  background: #0b1333;
  height: 100vh;
  padding: 0;
}

.sideNavModal [class*='closeBtnWrap'] {
  visibility: hidden;
}

.contentOuter {
  padding: 0 20px;
}

.footer {
  width: 100%;
  /* height: 100%; */
  position: fixed;
  bottom: 0;
  left: 0;
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  /* top: 72px; */
}

.footer :global(.react-autosuggest__suggestions-container--open) {
  height: calc(100vh - 310px);
  position: unset;
  width: 100%;
}
.createFooterTab {
  top: 68px;
}

.activityFooter {
  top: 72px;
  /* height: calc(100vh - 72px); */
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}
.bookinhWrapAll {
  max-height: calc(100vh - 200px);
  @media (--viewportLaptopDown) {
    /* max-height: calc(100vh - 305px); */
    max-height: none;
  }
}
.deliverScroll {
  max-height: calc(100vh - 230px);

  @media (--viewportLaptopDown) {
    max-height: calc(100vh - 325px);
  }
}
.linkBtn {
  margin-right: 15px;
  display: flex;
  align-items: center;
  background-color: var(--matterColorBlue);
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  max-width: 170px;
  @media (--viewportTabletDown) {
    padding: 8px;
    max-width: 130px;
  }
}
.linkText {
  color: var(--matterColorLight);
  font-weight: 400;
  margin-left: 5px;
  text-decoration: none !important;
  font-size: 16px;
  @media (--viewportTabletDown) {
    font-size: 12px;
  }
}
.linkStart {
  height: 20px;
  width: 20px;
  display: flex;
  border-right: 1px solid var(--matterColorLight);
}
.linkStart svg {
  height: 100%;
  width: 100%;
}
.voucherWrapper {
  height: 50px;
  background: #ffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0b1333;
}
.listingPanel {
  width: 100%;
}
.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}
