@import '../../marketplace.css';

.pageRoot {
  display: flex;
  overflow-x: hidden;
  @media (--viewportLaptopDown) {
    padding-bottom: 80px;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 0; /* 3:2 Aspect Ratio */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
  background: url('../../assets/slider_bg.png');
  background-size: cover;
  background-position: center center;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }

  @media (--viewportMobile) {
    background: url('../../assets/slider_bg_mobile.png');
  }
}

.actionBar {
  position: absolute;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--marketplaceColorBackground);
  z-index: 5; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
  /* @media (--viewportMobile) {
    top: 125px;
  } */
  @media (--viewportTabletDown) {
    top: 43px;
  }
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 18px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);
  transition: var(--transitionStyleButton);
  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 18px;
  }
}
.editListingLink:hover {
  color: var(--matterColorLight);
  text-decoration: none;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  z-index: 1;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  justify-content: space-between;
  column-gap: 50px;
  align-items: flex-start;
  position: relative;
  z-index: 9;

  @media (--viewportMedium) {
    margin: 0 auto;
    margin: 0 113px 50px;
    display: flex;
  }
  @media (--viewportTabletDown) {
    display: block;
  }
  @media (max-width: 1600px) {
    margin: 0 50px 25px;
    column-gap: 30px;
  }
  @media (--viewportLaptopDown) {
    margin: 0 25px 25px;
  }
  @media (max-width: 768px) {
    margin: 0 24px 24px;
  }
  @media (--viewportMobile) {
    margin: 0 15px 15px;
  }
}

.contentBottomContainer {
  justify-content: space-between;

  @media (--viewportMedium) {
    margin: 0 auto;
    margin: 50px 113px;
    display: block;
    position: relative;
  }
  @media (max-width: 1600px) {
    margin: 40px 50px;
  }
  @media (--viewportLaptopDown) {
    margin: 25px;
  }
  @media (max-width: 768px) {
    margin: 24px;
  }
  @media (--viewportMobile) {
    margin: 0;
  }
}

.mainContent {
  /* flex-basis: 100%; */
  width: 100%;
  @media (--viewportLarge) {
    max-width: 73%;
  }
  @media (max-width: 1023px) {
    margin-right: 0;
  }
  /* @media (--viewportLaptopDown) {
    max-width: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  } */
}

.sectionHeadingWrapper {
  background-color: var(--matterColorLight);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px 50px;
  @media (min-width: 769px) and (max-width: 1023px) {
    width: 100%;
  }
  @media (--viewportMedium) {
    position: relative;
  }
  @media (--viewportTabletDown) {
    position: relative;
    padding: 30px 15px;
  }
}

.iconWrap {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  right: 40px;

  @media (--viewportTabletDown) {
    display: none;
  }
}

.companyMainContent {
  max-width: 100%;
  flex-basis: 100%;
}

.bookingPanel {
  padding: 17px 25px 21px 25px;
  display: flex;
  align-items: center;
  display: block;
  width: 100%;
  justify-content: flex-end;
  box-shadow: 0px 0px 59.9116px 7.48895px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  background: var(--matterColorLight);
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  /* margin-top: -72px; */

  /* Rendering context to the same lavel as listing image */
  /* position: relative; */

  /* Flex would give too much width by default. */
  /* width: 60px; */

  @media (--viewportMedium) {
    /* position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0; */
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  /* margin-top: 22px;
  margin-bottom: 34px; */
  @media (--viewportMedium) {
    display: flex;
  }

  @media (--viewportLarge) {
    padding: 15px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  /* margin-bottom: 33px; */
  /* margin: 19px 100px 19px 0; */
  @media (--viewportMobile) {
    margin: 0;
  }
}

.title {
  /* Font */
  /* @apply --marketplaceH1FontStyles; */

  /* Layout */
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColorDark);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 30px;
  }
  @media (--viewportMobile) {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 34px;
  }
  @media (--viewportTabletDown) {
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.accoladesTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--matterColorDark);
  margin: 50px 0 25px;
  letter-spacing: 0.01em;
  @media (--viewportTabletDown) {
    margin: 25px 0px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.01em;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.separator {
  margin: 0 6px;
}

.contactLink {
  width: 100%;
  max-width: 15rem;
  min-height: 52px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: white;
  border: 1px solid var(--matterColorBlue);
  background: #0058fa;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 14px;

  @media (--viewportMobile) {
    max-width: 160px;
    min-height: 42px !important;
  }
}

.bookFromDescription {
  display: inline;
  width: auto;
  min-width: 160px;
  min-height: 40px;
  margin: 0 12px 12px 0;
  background-color: var(--matterColorLight);
  color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
  box-shadow: 0px 10px 24px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.bookFromDescription {
  margin-right: 0;
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 17px;
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.more {
  color: var(--marketplaceColorSecond);
}

.more:hover {
  cursor: pointer;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  display: none;
  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryFormContainer {
  background: var(--matterColorLight);
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    /* max-width: unset !important; */
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.publicData {
  /* background: #223046; */
  /* padding: 20px 0; */
}

.avatarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.count {
  position: absolute;
  color: white;
  z-index: 99;
  background-color: black;
  padding: 5px 10px;
  border-radius: 15px;
  left: 2%;
  top: 520px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  @media (--viewportLarge) {
    top: 450px;
    margin-left: 15px;
  }
}

.adventure {
  padding-left: 25px;
  font-size: 20px;
  font-weight: 400;
}

.ratingContent {
  font-size: 15px;
  display: flex;
  align-items: baseline;

  & > span {
    padding-right: 12px;
    margin-right: 12px;
    border-right: 1px solid #cbd2e4;
  }
  @media (--viewportMobile) {
    padding-bottom: 16px;
    font-size: 13px;
    flex-wrap: wrap-reverse;
    border-bottom: 1px solid var(--matterColorNegative);
  }
}

.ratingRating {
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 24px;
}
.review {
  margin-top: 2px;
  margin-left: 6px;
}

.rating {
  margin-right: 5px;
  margin-top: 4px;
}

.ratingBookings {
  border-right: none !important;
  color: #9097a3;
  @media (--viewportMobile) {
    font-size: 13px;
    font-weight: bold;
  }
}

.feature {
  /* margin: 5px 10px 10px 22px; */
}

.feature_item {
  font-size: 11px;
  background-color: #757575;
  padding: 2px 10px;
  color: white;
  margin: 3px;
  border-radius: 4px;
  display: inline-block;
}

.property {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  row-gap: 40px;
  margin: 0 0 43px;
  @media (--viewportTabletDown) {
    margin: 0 0 25px;
    row-gap: 30px;
  }
}

.propertyTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #383838;

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}

.property .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: 'Int Circular PTT';
  line-height: 25px;
  margin-top: 2px;
  color: var(--matterColorDark);
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
  }
}

.propertyItem {
  flex: 0 50%;
  /* margin-bottom: 40px; */
  display: flex;
  align-items: center;
  /* color: var(--matterColorLight); */
  & svg {
    width: 30px;
    height: 30px;
    fill: transparent;
    @media (--viewportTabletDown) {
      width: 15px;
      height: 15px;
    }
  }
}

.itemIconWrapper {
  align-items: center;
}

.itinerary {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.itineraryContent {
  min-width: 0px;
  width: 100%;
  padding: 0px 20px;
  margin-top: -8px;
}

.itineraryBtn {
  border: none;
  width: 100%;
  padding: 0px;
  background: transparent;
  text-align: inherit;
  cursor: pointer;
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
}

.itineraryBtnContent {
  display: flex;
  margin-left: -4px;
  padding-left: -4px;
}

.itineraryLeftLine {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  position: relative !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  /* margin-left: 20px; */
  margin-right: 20px;
}

.itineraryRightContent {
  -webkit-flex: 1 !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 !important;
  padding-bottom: 24px !important;
}

.itineraryItemtitle {
  margin-bottom: 12px;
}

.itineraryItemtitle span {
  font-size: 16px;
  word-wrap: break-word;
}

.itineraryRightContent .content .background {
  position: relative !important;
  background-position: 50% 50% !important;
  background-repeat: no-repeat !important;
  padding-top: 66.6667%;
}

.itineraryRightContent .content .background .backContent {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
}

.itineraryRightContent .content .background .backContent img {
  height: 100% !important;
  width: 100% !important;
  position: static !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  object-fit: cover;
}

.line {
  background-color: var(--matterColorGrey) !important;
  width: 1px !important;
  position: absolute !important;
  top: 7px !important;
  bottom: 0px !important;
}

.dot {
  position: absolute !important;
  top: 7px !important;
  background: var(--marketplaceColorLightWhite) !important;
  border: 1px solid var(--matterColorGrey) !important;
  border-radius: 100% !important;
  width: 7px !important;
  height: 7px !important;
}

.backDescription {
  margin-top: 15px;
}

.backDescriptionTitle {
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionDesc {
  margin-top: 8px;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: var(--matterColorGrey) !important;
}

.backDescriptionShowMore {
  color: var(--marketplaceColorSecond) !important;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
}

.itineraryTitle h2 {
  padding: 0px 25px;
}

.slider img {
  height: 500px;
  object-fit: cover;
  /* -webkit-animation: myMove 6s infinite ease-in-out alternate;
  -moz-animation: myMove 6s infinite ease-in-out alternate;
  -o-animation: myMove 6s infinite ease-in-out alternate;
  -ms-animation: myMove 6s infinite ease-in-out alternate;
  animation: myMove 6s infinite ease-out alternate; */
  @media (--viewportLarge) {
    width: initial;
    height: 400px;
    margin: auto;
  }
}

.slider {
  margin-bottom: 50px;
  @media (--viewportLarge) {
    padding: 30px;
  }
}

@keyframes myMove {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
}
@-webkit-keyframes myMove {
  from {
    -webkit-transform: scale(1, 1);
    -webkit-transform-origin: 50% 50%;
  }
  to {
    -webkit-transform: scale(1.1, 1.1);
    -webkit-transform-origin: 50% 0%;
  }
}
@-o-keyframes myMove {
  from {
    -o-transform: scale(1, 1);
    -o-transform-origin: 50% 50%;
  }
  to {
    -o-transform: scale(1.1, 1.1);
    -o-transform-origin: 50% 0%;
  }
}
@-moz-keyframes myMove {
  from {
    -moz-transform: scale(1, 1);
    -moz-transform-origin: 50% 50%;
  }
  to {
    -moz-transform: scale(1.1, 1.1);
    -moz-transform-origin: 50% 0%;
  }
}
@-ms-keyframes myMove {
  from {
    -ms-transform: scale(1, 1);
    -ms-transform-origin: 50% 50%;
  }
  to {
    -ms-transform: scale(1.1, 1.1);
    -ms-transform-origin: 50% 0%;
  }
}

.reviewTitle {
  padding-left: 20px;
  font-weight: bold;
}

.totalReviewContainer {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.video {
  width: 100%;
}

.buttonWrap {
  margin-top: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.showAll {
  text-align: center;
  font-size: 17px;
  border: 1px solid var(--marketplaceColorBackground);
  padding: 14px 28px;
  color: var(--matterColor);
  font-weight: bold;
  border-radius: 5px;
}
.slider {
  margin-left: 35px;
  margin-right: 35px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.slider button:before {
  color: black;
  clear: both;
}

.sliderImageContainer img {
  width: 100%;
  @media (--viewportMobile) {
    height: 300px;
  }
}
.sliderImageContainer {
  margin: 0px 5px;
  width: 100%;
}

.listedBy {
  @media (--viewportMobile) {
    width: 100%;
    border-right: none !important;
  }
}

.dotSeparator {
  font-size: 24px;
  margin: 0px 10px;
}

.actionBarWrapper {
  position: relative;
}

.enquiryActionBarWrapper {
  position: relative;
  & div {
    @media (--viewportMobile) {
      top: 13px;
    }
  }
}

.sectionWrapper {
  /* margin: 0 24px 18px; */
  width: 100%;
  & h2 {
    margin: 50px 0 25px;
    @media (--viewportTabletDown) {
      margin: 25px 0;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }
  }
}

.reviewWrapper {
  & h2 {
    margin: 50px 0px 50px;
  }
}

.sectionWhatYouCanExpect {
  /* margin: 0 24px 18px; */
  & h2 {
    margin: 0;
  }
}

.sectionWrapperWithFlex {
  display: flex;
  & h2 {
    margin: 0;
  }
}

.sectionDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 45px;
  color: var(--matterColorDimmedText);
  margin-bottom: 43px;
  @media (--viewportMobile) {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 36px;
  }
}

.packagesModal {
  height: auto;

  @apply --marketplaceModalBaseStyles;

  padding: 0;
  background-color: var(--matterColorLight);
  /* margin-top: 5vh; */
  /* margin-bottom: 5vh; */
  @media (--viewportMedium) {
    flex-basis: 80%;
  }
  max-width: 80%;
  @media (--viewportMobile) {
    padding: 0;
    max-width: 100%;
  }
}

/* Inquire styles */
.inquireMainContent {
  margin-top: 105px;
  flex-basis: 50%;
}
.enquirySidePanel {
  display: block;
  margin-top: 95px;
  margin-left: 60px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  padding-top: 40px;
  padding-left: 60px;

  /* The calendar needs 312px */
  flex-basis: 312px;
  flex-shrink: 0;

  margin-left: 30px;
  margin-top: 80px;
  width: max-content;

  @media (--viewportMobile) {
    margin: 0;
    padding-left: 20px;
  }
}

.category {
  & span {
    color: var(--matterColorDarkBlue);
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 16px;
    text-align: left;
    line-height: 14px;
    font-weight: 400;
  }
  color: var(--matterColorDarkBlue);
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  font-weight: 600;
}

.subCategory {
  & span {
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    color: #c4c4c4;
    /* padding: 0 0 0 10px; */
    margin: 0 20px 0 0;
  }
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  color: var(--marketplaceColor);
  margin-top: 5px;
  margin-bottom: 0px;
  text-align: left;
}

.name {
  font-size: 26px;
  font-weight: var(--fontWeightMedium);
}
.imageSlider :global .slick-slide:not(:last-child) {
  @media (--viewportMobile) {
    padding-right: 0;
  }
}

.singleImageContainer {
  margin: 0 auto;
  height: 500px;
  max-height: 800px;
  object-fit: cover;
  @media (--viewportMobile) {
    height: 300px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cancelPolicy {
  & h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--matterColorDark);
    margin: 50px 0px 25px;
    text-transform: capitalize;
    @media (--viewportTabletDown) {
      padding: 14px 14px 20px;
      margin: 15px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }
  }

  & p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: var(--matterColorDark);
    background: var(--matterColorLight);
    padding: 14px 14px 20px;
    margin: 15px 0;
    border-radius: 15px;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);

    @media (--viewportMobile) {
      margin: 15px;
    }
  }

  @media (--viewportTabletDown) {
    font-size: 12px;
    line-height: 20px;
  }
}

.cancelPolicySection {
}

.promoListings {
  @media (--viewportTabletDown) {
    padding: 0;
    margin: 0;
  }
}
.promoListings :global(.LandingPagePromotedListings_newheader__1AytE) {
  @media (--viewportTabletDown) {
    padding: 0;
  }
}

.promoListings :global(.LandingPagePromotedListings_title__2lSmp) {
  /* & span {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;

    color: #272727;
    @media (--viewportMobile) {
      font-size: 30px;
      line-height: 38px;
    }
  } */
}

.packagesDisplay {
  & h2 {
    & span {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color: var(--matterColorDark);
      @media (--viewportTabletDown) {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
      }
    }
  }
}

.sectionShare {
  display: flex;
}

.shareBtnWrapper {
  display: flex;
}
.shareButton {
  width: 220px;
  justify-content: flex-end;
  cursor: pointer;
  @media (--viewportMobile) {
    width: 94px;
  }
}

.whatsapp {
  margin-left: 20px;
  & svg {
    width: 38px;
    height: 38px;
  }
}
.contactWrapper {
  display: flex;
  justify-content: center;
}

.paragraphButton {
  color: var(--marketplaceColor);
  cursor: pointer;
}

.paragraphDescription {
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  color: var(--matterColorDark);
  margin: 0;
  white-space: break-spaces;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 35px;
  }
}
.floatingTopBar {
  position: fixed;
  top: 75px;
  left: 0;
  z-index: 9999;
  @media (--viewportTabletDown) {
    display: none;
  }
}
.SectionHead {
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  /* margin-top: 20px; */
  max-width: 1154px;
  margin: 32px auto 13px auto;
  @media (--viewportMobile) {
    padding: 10px 12px;
  }
  @media (max-width: 768px) {
    margin: 18px auto 0;
    padding: 0 24px;
  }
  @media (--viewportMobile) {
    margin: 35px auto 0;
    padding: 0 12px;
  }
}

.companyWrapper {
  display: block;
}

.detailSectionInfoWrapper {
  flex: 1;
  padding-top: 15px;
  @media (--viewportTabletDown) {
    padding-top: 0;
  }
}

.detailSection {
  display: flex;
  align-items: flex-start;
  column-gap: 30px;
  /* @media (--viewportMobile) {
    margin-bottom: 34px;
  } */
  @media (--viewportTabletDown) {
    column-gap: 15px;
  }
  & h2 {
    margin: 0 !important;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    color: var(--matterColorDark);
    letter-spacing: 0.01em;
    @media (--viewportTabletDown) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  /* @media (--viewportMobile) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  } */
}
.detailSectionWrapper {
  display: flex;
  /* flex: 1 1;
  margin-bottom: 20px; */
  justify-content: space-between;
}

.detailSectionInfo {
  /* @media (--viewportMobile) {
    width: 76%;
    padding-left: 15px;
  } */
}

.detailSectionAuthorNameLink:hover {
  text-decoration: none;
}

.detailSectionAuthorNameLink span {
  font-size: 24px;
  line-height: 28px;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 24px;
  }
}

.detailInfo {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorParagraphText);
  margin: 10px 0 15px;
  display: block;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 16px;
    margin: 5px 0 10px;
  }
}

.reviewWrapper {
  display: flex;
  color: #0b150a;
  margin-bottom: 30px;
  @media (--viewportTabletDown) {
    margin-bottom: 10px;
  }
}

.reviewSpan {
  display: flex;
  gap: 0.3rem;
  align-items: flex-end;
  font-weight: 400;
  color: var(--marketplaceColorDarker);
  &:not(:last-child) {
    padding: 0 83px 0 0;
  }
  & span {
    margin-left: 0px;
    font-style: normal;
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0b150a;
  }
  @media (--viewportMobile) {
    &:not(:last-child) {
      padding: 0 20px 0 0;
    }
    & span {
      font-size: 9px;
      line-height: 11px;
    }
  }
}
.mainWraper {
  display: flex;
  flex-direction: column;
  padding-left: 130px;
  @media (--viewportLaptopDown) {
    padding-left: 0;
  }
}
.detailDescription {
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #0b150a;
  display: -webkit-box;
  /* -webkit-line-clamp: 5; */
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  @media (--viewportTabletDown) {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 10px 0px 15px;
  }
}

.whatsappSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  column-gap: 8px;
  @media (--viewportTabletDown) {
    display: none;
  }
  @media (--viewportMobile) {
    width: 100%;
    margin: 69px 0 42px 0;
    justify-content: space-evenly;
  }
}
.masterVerifiedBtn {
  background: linear-gradient(99.59deg, #d7a955 6.3%, #ffd270 66.37%, #d7a955 121.63%);
  border-radius: 6px;
  padding: 13px 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  border: 0;
}

.whatsappSection :global(.SectionLevel_sectionLevel__3Jy-6) {
  margin-left: 16px;
  display: flex;
  align-items: center;
}

.listPublicInfo {
  line-height: 8px;
  margin-left: 17px;
  display: flex;
  flex-direction: column;
  @media (--viewportTabletDown) {
    margin-left: 11px;
  }
}

.authorDetailsContainer {
  display: flex;
  align-items: center;
  padding: 25px 0 24px 0;
  @media (--viewportMobile) {
    padding: 27px 0 37px 0;
  }
}

.authorDetails {
  margin: 0 0 0 15px;
  display: flex;
  flex-direction: column;
  @media (--viewportMobile) {
    margin: 0 0 0 15px;
  }
}

.authorDetailPromoHead {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: var(--marketplaceColorDarker);
  margin-bottom: 5px;
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
}

.authorDetailPromo {
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColor);
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}

.authorReviewInfo {
  padding: 5px 0 0 0;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    justify-content: space-between;
    /* padding: 0 21px 0 6px; */
  }
}

.authorReviewInfoDetail {
  padding: 0 0 37px;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    padding: 0 0 45px 0;
  }
}

.descriptionFontStyle {
  color: var(--matterColorDimmedText);
  font-weight: 400;
  font-size: 20px;
}

/* .authorDisplayImage{
  height: 60px;
  width: 60px;
  border-radius: 50%;
} */

.authorDetailsLargeImageRelative {
  /* position: relative; */
  /* height: 96px; */
  /* width: 96px; */
  /* padding: 22px; */
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportMobile) {
    height: 67px;
    width: 67px;
    & div {
      height: 54px;
      width: 54px;
      min-width: 54px;
    }
  }
}

.TopHead {
  display: flex;
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
  }
}

.authorDescriptions {
  display: flex;
  column-count: 3;
  column-gap: 60px;
  background: var(--matterColorLight);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 30px 40px 30px;
  & span {
    font-size: 18px;
    color: var(--matterColorDimmedText);
    font-weight: 400;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 15px;
    row-gap: 15px;
  }
}

.authorDescriptionsContainer {
  /* border-top: 1px solid #c4c4c4; */
  margin: 0 auto;
  @media (--viewportTabletDown) {
    /* margin: 0 22px 10px; */
    /* border-top: 1px solid #c4c4c4; */
    padding: 0;
  }
}

.greyReviewSection {
  & h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--matterColorDark);
    text-align: left;
    margin: 50px 0px 50px;
    @media (--viewportTabletDown) {
      margin: 25px 0px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.01em;
    }
  }
}

.mainReviewContainer {
  margin: 0 auto;
  & h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--matterColorDark);
    text-align: left;
    margin: 50px 0px 50px;
    @media (--viewportTabletDown) {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      padding: 15px 0;
    }
  }
  @media (--viewportMobile) {
    padding: 0 25px;
  }
}

.headStar {
  width: 24px;
  height: 24px;
  margin: 0 6px 0 0;
  @media (--viewportMobile) {
    margin: 0 7px 0 0;
  }
}

.reviewStar {
  height: 20px;
  width: 20px;
  @media (--viewportTabletDown) {
    height: 14px;
    width: 14px;
  }
  & svg {
    fill: #ffb534;
  }
}

.iconCheckedShield {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  @media (--viewportTabletDown) {
    height: 14px;
    width: 14px;
  }
}

.reviewHeadSpan {
  display: flex;
  align-items: center;
  padding: 0 5px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);
}

.HeadSpan {
  font-style: normal;
  margin-left: 15px;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: var(--marketplaceColorDarker);

  @media (--viewportTabletDown) {
    font-size: 13px;
    margin-left: 12px;
    line-height: 15px;
  }
}

.HeadSpan2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-left: 25px;
  color: var(--marketplaceColorDarker);

  @media (--viewportMobile) {
    font-size: 13px;
    margin-left: 13px;
    line-height: 16px;
  }
}

.actionBarWrapperContainer {
  background: #f5f9ff;
  @media (--viewportMobile) {
    max-width: 100%;
    margin: 0;
  }
}

.bookingPanelContainer {
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: sticky;
  top: 150px;
  scroll-behavior: smooth;
  @media (min-width: 769px) and (max-width: 1023px) {
    width: 0;
  }
  @media (--viewportTabletDown) {
    width: 0;
    z-index: 9999;
  }
}

.imageSlider {
  /* max-width: 1154px;
  margin: 0 auto; */
  @media (max-width: 768px) {
    padding: 0 12px;
  }
  @media (--viewportMobile) {
    padding: 0;
  }
}

.iconMasterVerified {
  width: 152px;
  height: 45px;
}

.avatarLargeImage {
  /* min-width: 90px;
  height: 90px; */
  border-radius: 8px;
  overflow: hidden;
}

.listingFooter :global(.Footer_content__3LKvi) {
  max-width: 1154px;
  padding: 0;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.desktopData {
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
}

.mobileTopbar {
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;
  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 20px 10px rgba(0, 0, 0, 0.02);

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  padding: 7px 22px 5px 27px;
  @media (--viewportLarge) {
    display: none;
  }

  @media (--viewportSmall) {
    padding: 0 24px;
  }
}

.smallImageCollection {
  display: flex;
  column-gap: 12px;
  @media (--viewportMedium) {
    column-gap: 16px;
  }
}
.landScapeImage {
  width: 100%;
  height: 254px;
  object-fit: cover;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    height: 332px;
    margin-bottom: 21px;
  }
}
.smallImage {
  width: 48.5%;
  height: 234px;
  object-fit: cover;
  @media (--viewportMedium) {
    height: 331px;
  }
}

.mobileChatSection {
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 9;
}

.whatsappInboxSection {
  max-width: 344px;
  height: 171px;
  background: linear-gradient(
    90deg,
    var(--matterColorLight) -26.02%,
    VAR(--matterColorLight) 129.31%
  );
  box-shadow: 0px -5px 40px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  display: flex;
  padding: 32px 27px 29px;
  gap: 0 38px;
  & a {
    text-decoration: none !important;
  }

  & .iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 12px 0;

    & span {
      font-weight: bold;
      font-size: 19px;
      line-height: 11px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #233045;
    }
  }
  & .iconImageWrapper {
    width: 81px;
    height: 81px;
    & img {
      width: 100%;
      height: auto;
    }
  }
}

.mobileChatWrapper {
  width: 98px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 0 11px;
  background: var(--matterColorLight);
  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  box-shadow: 0px 8px 26px rgba(33, 108, 123, 0.15);
  border-radius: 33px;
  & span {
    font-weight: bold;
    font-size: 14px;
    line-height: 12px;
    color: var(--matterColorDarkBlue);
  }
}

.avatar {
  height: 30px;
  width: 30px;
}

.activeUser {
  height: 52px;
  width: 34px;
  position: relative;
  margin-left: 7px;
  display: flex;
  align-items: center;
}

.active {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #6cef3e;
  border: 3px solid var(--matterColorLight);
  top: 65%;
  right: 0;
  position: absolute;
}

.close {
  position: absolute;
  top: 4px;
  right: 10px;
  z-index: calc(100 + 1);
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  width: auto;
  margin: 0;
  border: 0;
}

.closeBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.closeIcon {
  @apply --marketplaceModalCloseIcon;
  margin-left: 0;
  padding: 0;
}

.svgWrapper {
  display: flex;
  align-items: center;
  column-gap: 19px;
}

.svgWrapper svg {
  width: 25px;
}

.currentListingAddress {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  text-decoration-line: underline;
  color: var(--matterColorDark);
}

.timeInfoWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.timesInfoTitle {
  width: 100%;
  font-weight: normal;
  font-size: 13.5px;
  line-height: 17px;
  color: var(--matterColorDark);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.goBackArrow {
  width: 18px;
  height: 18px;
  margin: 0 11px 0 0;
  @media (--viewportMobile) {
    margin: 0 7px 0 0;
  }
}

.backToSearch {
  max-width: 100%;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: var(--matterColorLight);
  padding: 3px 10px;
  background: var(--marketplaceColorBackground);
  border: 1.4px solid #324259;
  box-sizing: border-box;
  border-radius: 30px;
  margin-right: 9px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 22px;
    height: 23px;
  }

  @media (--viewportMedium) {
    padding: 0 15px;
    font-size: 15px;
    line-height: 22px;
    margin-right: 24px;
    white-space: nowrap;

    & svg {
      width: 33px;
      height: 32px;
    }
  }

  @media (--viewportMobile) {
    & span {
      display: none;
    }
  }
  &:hover {
    color: var(--matterColorDarkBlue);
    background: var(--matterColorLight);
    & svg {
      & path {
        stroke: var(--matterColorDarkBlue);
      }
    }
  }
}

.overlayBody {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0000004d;
  z-index: 1;
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 17px 45px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  & button {
    height: 46px;
    min-height: 46px;
    width: 118px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    background: var(--matterColorBlue) !important;
    border-radius: 7px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  @media (--viewportMobile) {
    padding: 0;
    margin-right: 0;
  }
}
.packageText {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0058fa;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportTabletDown) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: var(--matterColorBlue);
  }
}

.perUnit {
  /* Font */
  color: var(--matterColorParagraphText);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  @media (--viewportMobile) {
    min-width: 66px;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}
.sectionGallery {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 25px;
  margin: 40px 0px;
  @media (max-width: 1600px) {
    margin: 25px 0px;
  }
  @media (--viewportTabletDown) {
    margin: 24px 0;
  }
  @media (--viewportMobile) {
    padding: 14px 14px 20px;
    margin: 15px 0;
  }
}
.galleryWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-auto-rows: auto;
  column-gap: 26px;
  row-gap: 26px;
  /* height: calc(100vh - 435px);
  overflow: hidden; */
  @media (--viewportLaptop) {
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: repeat(4, 1fr); */
  }

  @media (--viewportTabletDown) {
    column-gap: 14px;
    row-gap: 14px;
    grid-template-columns: repeat(2, 1fr);
  }
}
.gallerItems {
  width: 100%;
  height: 100%;
}
.itemImg {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.galleryButton {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
  @media (--viewportMobile) {
    margin-top: 20px;
  }
}

.galleryButton button {
  background: var(--matterColorBlue) !important;
  width: 127px;
  min-height: 46px;
  border-radius: 3.31824px;
  padding: 13px 16px 13px 17px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  border: none;
  @media (--viewportTabletDown) {
    background: var(--matterColorLight) !important;
    color: var(--matterColorBlue);
    padding: 0;
    min-height: 20px;
  }
}
.facilityContainer {
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 40px 20px;
  overflow: auto;
  @media (--viewportLaptopDown) {
    padding: 20px 10px;
  }
}

.facilityTop {
  margin-top: 25px;
}

.goLiveWrapper {
  z-index: 5;
  display: flex;
  position: absolute;
  justify-content: center;
  top: 232px;
  width: 100%;

  @media (--viewportMobile) {
    top: 122px;
  }
}
.goLiveBtn {
  width: 300px;
  min-height: 80px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.3px;
  color: #ffffff;
  background: #3b59fa;
  border: none;
  cursor: pointer;
}
.breadcrumbWrap {
  margin: 10px 0;
}
.breadcrumbWrap ul {
  display: flex;
}
.breadcrumbWrap a {
  margin: 0 10px;
  text-transform: capitalize;
}
.namedLinkWrap {
  margin: 0 !important;
}

.breadCrumbWrapper {
  align-items: center;
  @media (--viewportMedium) {
    margin: 50px 113px;
    display: flex;
    position: relative;
  }
  @media (max-width: 1600px) {
    margin: 25px 50px;
  }
  @media (--viewportLaptopDown) {
    margin: 25px 25px;
  }
  @media (max-width: 768px) {
    margin: 24px 24px;
  }
  @media (--viewportMobile) {
    margin: 15px 15px;
  }
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.shareFormModal [class*='container'] {
  @media (--viewportLaptopDown) {
    position: absolute !important;
    padding: 15px !important;
    min-height: unset !important;
    width: 95%;
    height: 100% !important;
    flex-grow: 0 !important;
    top: 50px;
    border-radius: 10px !important;
    background: rgb(247, 247, 247) !important;
  }
}
.shareFormModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4) !important;
    flex-grow: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
.shareFormModal [class*='Modal_close_'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    background: none;
    fill: #000;
    right: 0;
    padding: 15px;
  }
}
.shareContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
    max-height: 100vh;
    overflow: auto;
    padding: 1rem 4rem;
  }
}
.topbarRoot {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
}
.topbarRootHide {
  display: none;
}
.listingIcons {
  height: 30px;
  width: 30px;
  @media (--viewportMobile) {
    height: 20px;
    width: 20px;
  }
}
