@import '../../marketplace.css';

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;

  @media (--viewportMobile) {
    margin-bottom: 80px;
  }
}
.paginationLink {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  fill: #223047;
  background: #fff;
  border: 0.5px solid #dce5f2;
  border-radius: 6px;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  &.current {
    background: #0b1333;
    border-radius: 6px;
    color: #fff;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 22px;
  }
}
