@import '../../marketplace.css';

.cardContainer {
  display: flex;
  flex-flow: column;

  gap: 30px;
  margin: 35px 42px;
  height: fit-content;

  @media (--viewportTabletDown) {
    /* margin: 25px 14px 14px 14px; */
    margin: 3%;
    gap: 14px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

.main {
  position: relative;
  background: #f5f9ff;
  padding-left: 305px;

  /* height: calc(100vh - 271px); */
  /* overflow: auto; */
  @media (--viewportTabletDown) {
    padding-left: 0;
    margin-top: 50px;
    /* height: calc(100vh - 202px); */
  }

  @media (--viewportLaptopDown) {
    padding-left: 0;
  }
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}

.main [class*='defaultMapLayout'] {
  width: 100%;
  height: calc(100vh - 198px);
  overflow: hidden;
}

.mediumCategoriesContainer {
  /* width: 100%; */
  /* display: flex; */
  display: grid;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  /* align-items: center;
  justify-content: center; */
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1280px) {
    width: 100%;
    /* grid-template-areas: 'Category1 Category3'; */
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }
}
.mediumCategoriesContainerEdu {
  @media (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.mediumCategoriesContainerTen {
  @media (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.mediumCategoriesItemTitle {
  flex: 1;
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 30px;
  position: absolute;
  top: 75%;
  /* right: 20px; */
  width: 100%;
  text-align: center;
  text-shadow: 4px 3px 10px #0005;
  @media (--viewportMobile) {
    font-size: 16px;
    padding: 0.2rem;
    position: absolute;
    height: 100%;
    width: 100%;
    /* background-color: #796f914f; */
    top: 0 !important;
    left: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transform: unset !important;
    border-radius: 10px;
  }
}

/* .mediumCategoriesItem:nth-of-type(2) .mediumCategoriesItemTitle {
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  top: unset;
  text-align: center;
}
.mediumCategoriesItem:nth-of-type(3) .mediumCategoriesItemTitle {
  top: 50px;
  left: 20px;
  transform: unset;
  bottom: unset;
  text-align: left;
}
.mediumCategoriesItem:nth-child(4) .mediumCategoriesItemTitle {
  top: 120px;
  left: 20px;
  transform: unset;
  bottom: unset;
  text-align: right;
} */
.mediumCategoriesItem {
  /* max-height: 30rem; */
  /* min-width: 19rem; */
  /* max-width: 20rem; */
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 15px;
  background-color: white;
  position: relative;
  height: 270px;

  @media (max-width: 1280px) {
    /* max-width: 13rem; */
    gap: 0.2rem;
  }

  @media (--viewportMobile) {
    /* min-height: 5rem; */
    /* height: 120px; */
    /* min-width: 5rem; */
  }
}

.mediumCategoriesItem:hover {
  text-decoration: none;
}

/* .mediumCategoriesItem:first-child {
  @media (max-width: 1280px) {
    grid-area: Category1;
  }
} */

/* .mediumCategoriesItem:nth-child(2) {
  @media (max-width: 1280px) {
    grid-area: Category3;
    flex-direction: column;
  }
} */

/* .mediumCategoriesItem:nth-child(3) {
  @media (max-width: 1280px) {
    grid-area: Category2;
  }
} */

.mediumCategoriesItemImage {
  flex: 1;

  /* @media (--viewportLarge) {
    height: 290px;
    width: 390px;
  } */
  @media (--viewportTabletDown) {
    height: 100%;
  }
}

.mediumCategoriesItemImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

  @media (max-width: 1280px) {
    height: 100%;
    /* max-height: 10rem; */
  }
}
.categoriesItem {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  gap: 0.5rem;
  /* padding: 0.5rem; */
  border-radius: 15px;
  background-color: white;
  position: relative;
}

.categoriesItem:hover {
  text-decoration: none;
}

.categoriesItemImage {
  position: relative;
  width: 100%;
  height: 100%;
}
.categoriesItemImage img {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 1;
}
.layoutFooter {
  position: sticky;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.categoriesItemTitle {
  text-decoration: none;
  color: #000000;
  font-size: 15px;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 10px);
  border-radius: 0 0 10px 10px;
  background: #fff;
  text-align: center;
  z-index: 9;
  text-transform: capitalize;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.mainGallaryWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 19px;
}
.mainGallaryWrapper .boxWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 19px;
}
.mainGallaryWrapper .boxWrapReverse {
  display: flex;
  justify-content: space-between !important;
  flex-direction: column-reverse;
  gap: 19px;
}
.mainGallaryWrapper .sportsWrap,
.mainGallaryWrapper .boxLargeWrap {
  display: flex;
  justify-content: space-between;
  gap: 19px;
  flex-direction: column;
}

.mainGallaryWrapper .boxWrap .categoriesItem {
  width: 100%;
  height: 200px;
}
.mainGallaryWrapper .boxWrap .categoriesItem:last-child {
  height: 325px;
}
@media screen and (max-width: 767px) {
  .mainGallaryWrapper .boxWrapReverse {
    flex-direction: column !important;
  }
  .mainGallaryWrapper .boxWrapReverse:last-child {
    display: none;
  }
}
.boxWrapAlign {
  display: flex;
  width: 100%;
  gap: 15px;
  justify-content: space-between;
  height: 158px;
}

.mobileItemWrapperMain {
  display: flex;
  gap: 9px;
  flex-direction: row;
  justify-content: space-between;
}
.mobileItemWrapperMainReverse {
  flex-direction: row-reverse;
}
.mobileColumnItem {
  position: relative;
  height: 222px;
  width: 55%;
}
.mobileGalleryWrapper {
  display: grid;
  gap: 15px;
}

.mobileColumnItem:first-child {
  min-width: 40%;
  max-width: 40%;
}

.boxWrapMobile {
  height: 600px;
  display: flex;
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
}

.boxWrapHeader {
  /* height: 180px; */
  display: flex;
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
  justify-content: space-between;
}
.headerItem {
  position: relative;
  border-radius: 5px;
  display: flex;
  @media (--viewportMiniDesktopDown) {
    height: 280px;
  }
  @media (--viewportTabletDown) {
    height: 180px;
  }
}

.boxWrapItem {
  display: grid;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  gap: 10px;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
}
.categoryItemWrap {
  display: flex;
  grid-gap: 9px;
  gap: 15px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 20px;

  @media (--viewportMobile) {
    justify-content: center;
  }
}
.categoryItem {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  /* padding: 0.5rem; */
  border-radius: 15px;
  background-color: white;
  position: relative;

  @media (--viewportMobile) {
    height: 160px;
    width: 160px;
  }
}
