@import '../../marketplace.css';

.root {
  /* Font */
  @apply --marketplaceH6FontStyles;
  /* color: var(--matterColorLight) !important;
  background-color: var(--failColor);
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 4px;
  padding: 2px 7px; */
  color: #000000 !important;
  background-color: #FFB81D;
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  border-radius: 50%;
  padding: 3px;
  width: 18px !important;
  height: 18px;
  margin-left: 13px;

  @media (--viewportMedium) {
    /* margin-top: 0;
    margin-bottom: 0;

    top: -10px;
    position: relative; */
    color: #000000 !important;
    background-color: #FFB81D;
    /* margin-top: 0; */
    /* margin-bottom: 0; */
    border-radius: 50%;
    padding: 3px;
    width: 18px !important;
    height: 18px;
    margin-left: 13px;
  }
}