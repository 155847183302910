@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
    overflow: auto;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: var(--matterColorLight);
    margin-top: 0;
    margin-bottom: 0;
  }
  & label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* padding: 10px; */
  }
}
.modalContainer :global(.FieldDateAndTimeInput_fieldSelect__1ksd9) {
  margin: 0;
}

.modalContainer :global(.FieldDateAndTimeInput_fieldSelect__1ksd9::after) {
  left: 0;
}

.modalContainer :global(.FieldDateAndTimeInput_field__1hzWK) {
  /* width: calc(50% - 12px); */
}
.modalContainer :global(.FieldDateAndTimeInput_startDate__S29pM) {
  width: 100%;
}

.modalContainer :global(.FieldDateInput_mobileMargins__3WhP8) {
  @media (--viewportMobile) {
    margin: 0;
  }
}

.modalHeading {
  margin-top: 30px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.bookingContainer {
  border-radius: 15px;
  overflow: hidden;
  min-width: 360px;
  box-shadow: 0px 0px 59.9116px 7.48895px rgba(0, 0, 0, 0.05);
}

.bottomBookingWrapper {
  padding: 20px 20px 25px;
  @media (--viewportMobile) {
    padding: 20px 0 0;
  }
}

.bookingPanelContainer {
  display: flex;
  align-items: center;
  display: block;
  width: 100%;
  justify-content: flex-end;
  border-radius: 15px;
  background: var(--matterColorLight);
}

.TopHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 33px;
  color: var(--matterColorLight);
  margin: 0;
  background: var(--matterColorBlue);
  text-align: center;
  padding: 30px 0px;
}

.powerByProbuddyWrapper .powerBySubHeading .boldText {
  font-style: normal;
  font-weight: bold;
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  text-align: center;
  @media (--viewportLarge) {
    margin-top: -2px;
    /* margin-bottom: 33px; */
  }
}
.desktopPriceContainer {
}

.desktopPriceValue {
  /* Font */
  margin: 0 0 5px 0;
  font-weight: bold;
  font-size: 18px;
  line-height: 39px;
  color: var(--matterColorBlue);
}

.desktopPerUnit {
  /* Font */
  /* @apply --marketplaceH5FontStyles; */
  font-weight: normal;
  font-size: 17.5px;
  line-height: 21px;
  color: #383838;
  margin-left: 6px;
}

.bookingTitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  color: rgba(56, 56, 56, 0.91);
  margin: 0 0 40px 0;

  & span span {
    color: var(--matterColorBlue);
  }
}
.bookingTitleMembership {
  margin: 0 0 20px 0;
}

.bookingSubtitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 26px;
  color: rgba(56, 56, 56, 0.91);
  margin: 0 0 40px 0;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Mobile phones introduce bottom-bar, which takes 102px vertical space.
     In addition to that we have fixed button that takes 84px vertical space.
  */
  margin: 0 0 94px 0;

  @media (--viewportMedium) {
    margin: 0;
  }

  @media (--viewportMobile) {
    margin: 0 24px 154px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  background-color: var(--matterColorLight);
  & p {
    & [type='radio']:checked,
    & [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    & [type='radio']:checked + label {
      color: var(--matterColorBlue) !important;
    }
    & [type='radio']:checked + label,
    & [type='radio']:not(:checked) + label {
      position: relative;
      cursor: pointer;
      display: inline-block;
      color: var(--matterColorLightGrey);
      padding: 0 30px !important;
    }
    & [type='radio']:checked + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      background: var(--matterColorBlue);
      border-radius: 2px;
    }

    & [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0px;
      width: 22px;
      height: 22px;
      border: 5px solid var(--matterColorAnti);
      /* border-radius: 100%; */
      background: var(--matterColorLight);
    }
    & [type='radio']:checked + label:after,
    & [type='radio']:not(:checked) + label:after {
      /* content: ''; */
      width: 22px;
      height: 22px;
      transform: scale(0.54) !important;
      background: var(--marketplaceColorBackground);
      position: absolute;
      top: 0;
      left: 0px;
      border-radius: 100%;
      transition: all 0.2s ease;
    }
    & [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    & [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
  @media (--viewportMobile) {
    padding: 0;
  }

  & label {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.1px;
    color: var(--matterColorGrey) !important;
  }
  & p {
    margin: 0;
    margin-top: 16px;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 0 22px 14px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }
  & button {
    height: 59px;
    min-height: 59px;
    width: 200px;
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
    background: #0058fa !important;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
  @media (--viewportMobile) {
    padding: 0;
    margin-right: 0;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: #0058fa;

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButton2StylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  @media (--viewportMobile) {
    min-width: 66px;
  }
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.stickyContainer {
  position: sticky;
  top: 120px;
  /* display: none; */
  @media (--viewportMobile) {
    position: relative;
    top: 0;
  }
  @media (--viewportLarge) {
    display: block;
  }
}

.openBookingFormLiftUp {
  padding: 0 22px 80px;
}

.openBookingPanelForm {
  display: none;
}
