@import '../../marketplace.css';

.root {
  position: relative;
  width: 100%;
  padding: 24px 24px 85px 24px;
  background-color: var(--matterColorBlue);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0;
    border-top: none;
  }
  @media (--viewportMobile) {
    padding: 30px 23px 15px 20px;
  }
}

.educationRoot {
  background-color: #f77d7d;
}
.tennisRoot {
  background-color: #4889ff;
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  max-width: 1175px;
  margin: 0 auto;
  padding: 0 24px;
  @media (--viewportMobile) {
    padding: 0;
  }
  @media (--viewportMedium) {
    padding-top: 50px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1524px;
    padding: 0;
  }
  @media (max-width: 1440px) {
    max-width: 100%;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  text-align: center;
  &:first-child {
    margin-right: 50px;
  }

  & svg path {
    fill: var(--matterColorLight);
  }
  @media (--viewportMobile) {
    transform: scale(0.78);
    & svg {
      width: 8px;
      height: 15px;
    }
    &:first-child {
      margin-right: 0px;
    }
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 220px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    /* order: 1; */
    margin-right: 12px;
  }
  @media (--viewportMobile) {
    flex: 1;
    text-align: center;
    margin: 0;
  }
  @media (--viewportLarge) {
    flex-basis: 320px;
    margin-right: 12px;
  }
  @media (--viewportLargeWithPaddings) {
    flex-basis: 310px;
    margin-right: 150px;
    margin-bottom: 0px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 20px;
  @media (--viewportMobile) {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 35px;
  @media (--viewportMobile) {
    object-position: center;
    width: 255px;
    height: 60px;
  }
}

.organizationInfo {
}

.organizationDescription {
  /* @apply --marketplaceTinyFontStyles; */
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  margin: 10px 0px 0;
  /* identical to box height, or 240% */
  color: var(--matterColorLight);
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    font-weight: 400;
    text-transform: capitalize;
    padding-top: 13px 0px 20px;
  }
}

.organizationCopyright {
  /* @apply --marketplaceTinyFontStyles;*/
  margin-top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: var(--matterColorLight);
  @media (--viewportMobile) {
    margin-top: 30px;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
  }
}

.copyrightLink {
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);

  @media (--viewportMobile) {
    display: none;
  }
}
.copyrightLink:hover {
  color: var(--matterColorLight);
  text-decoration: none;
}
.copyrightLink > * {
  display: block;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 150px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (--viewportMobile) {
    flex-basis: 50%;
    margin: 0;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }

  @media (--viewportMobile) {
    margin-bottom: 8px;
    min-height: 0px;
  }
}

.link {
  font-style: normal;
  font-family: 'Int Circular PTT';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorLight);
  padding-bottom: 20px;
  display: flex;
  &:hover {
    color: #ffb81d;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin: 0px;
    padding: 0px;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 120px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (--viewportMobile) {
    flex-basis: 40%;
    margin: 0;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
  @media (--viewportMobile) {
    flex-basis: 50%;
    margin: 89px 0 0 0;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  /* flex-shrink: 1; */
  order: 5;
  flex-basis: 170px;
  margin-right: 24px;
  margin-bottom: 24px;
  /* margin-top: 10px; */

  & svg {
    width: 32px;
    height: 32px;
  }

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
  @media (--viewportMobile) {
    flex-basis: auto;
    margin: 0;
  }
}

.legalMatters {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 12px;
  /* margin-top: 38px; */

  @media (--viewportMobile) {
    margin-top: 22px;
    margin-bottom: 0px;
  }
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}

.legalLink {
  /* Font */
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Int Circular PTT';
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  display: flex;
  &:hover {
    color: #ffb81d;
    text-decoration: none;
  }
  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 8px;
    white-space: nowrap;
  }
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.privacy,
.terms,
.contact {
  /* Font */
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  display: flex;
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    padding-bottom: 0px;
    white-space: nowrap;
  }
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Layout */
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  @media (--viewportMobile) {
    flex-direction: column;
    row-gap: 26px;
    margin-bottom: 0px;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  font-family: 'Int Circular PTT';
  color: var(--matterColorLight);
  transition: var(--transitionStyleButton);
  /* Tell parent component how much space this one prefers */
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLaptop) {
    flex-basis: 185px;
  }
  @media (--viewportMobile) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  }

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  & > * {
    display: block;
  }
}

.tosAndPrivacyMobile {
  display: flex;
  @media (--viewportLaptop) {
    flex-basis: 172px;
  }
  @media (--viewportMobile) {
    justify-content: space-between;
    border-top: 1px solid #fff8f84d;
    border-bottom: 1px solid #fff8f84d;
    line-height: 26px;
    padding: 10px 0px;
  }
}

.contactPrivacyWrapper {
  display: flex;
}

.privacy {
  margin-right: 24px;
}

.linkWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (--viewportMobile) {
    margin-bottom: 12px;
  }
}

.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
}

.socialLinks {
  @media (--viewportMobile) {
    display: flex;
    column-gap: 12px;
  }
}
