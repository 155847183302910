@import '../../marketplace.css';

.root {
  position: sticky;
  top: 70px;
  left: 0;
  background: var(--matterColorLight);
  z-index: 99;
}

.advanceFilterContainer {
  position: relative;
}

.advanceFilterWrapper {
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  height: 55px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 19px;
  }

  @media (--viewportTabletDown) {
    flex-direction: column;
    padding: 10px 24px 0;
  }
}

.subCategoryItemsWrap {
  display: flex;
  column-gap: 11px;
  padding: 26px 0 30px;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    overflow-x: scroll;
    max-width: 100%;
    overflow-y: hidden;
  }
}

.mobileSubCategoryItemsWrap {
  display: flex;
  padding: 19px 24px 19px;
  column-gap: 11px;
  overflow-x: scroll;
  width: 100%;
  background: var(--matterColorLight);
}
.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  @media (--viewportTabletDown) {
    overflow-x: scroll;
    width: 100%;
    padding: 33px 15px 18px;
  }
}

.subCategoryItem {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-transform: capitalize;
  color: #6e6f71;
  background: #f7f7f7;
  border-radius: 40px;
  padding: 8px 22px;
  white-space: nowrap;
  max-height: 34px;

  max-width: 154px;
  white-space: nowrap;
}

.subCategoryMoreItems {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 19px 10px;
  max-height: 34px;
  @media (--viewportMobile) {
    width: 88px;
  }

  & svg {
    margin-left: 4px;
    & path {
      fill: var(--marketplaceColor);
    }
  }

  @media (--viewportMobile) {
    color: var(--matterColorDarkBlue);
    & svg path {
      fill: var(--matterColorDarkBlue);
    }
  }
}

.selectedSubCategoryItem {
  color: var(--matterColorLight);
  background: var(--marketplaceColor);
  @media (--viewportMobile) {
    background: var(--marketplaceColorBackground);
  }
}

.rightSideFilters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 7px;
  padding-bottom: 4px;

  @media screen and (max-width: 400px) {
    overflow-x: scroll;
    max-width: 300px;
    padding-bottom: 15px;
    justify-content: flex-start;
  }
}

.labelSellerLevels {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: var(--marketplaceColor);
  display: flex;
  align-items: center;
  column-gap: 6px;
  white-space: nowrap;
  padding: 9px 14px;
  background: var(--matterColorLight);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 34px;
  max-height: 34px;

  & svg {
    margin-left: 5px;
    & path {
      fill: var(--matterColorDark);
    }
  }

  @media (--viewportMobile) {
    justify-content: space-between;
  }
}
