@import '../../marketplace.css';

.root {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.addStripeAccountModal {
  width: 600px;
  background: #ffffff;
  padding: 65px;
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
    padding: 40px;
  }
}
.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}
.modalTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.5px;
  margin: 0;
}
.payoutModalInfoNote {
  display: inline-table;
  padding: 10px;
  font-weight: normal;
  font-size: 16px;
  height: 18px;
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}

.noteFont {
  font-weight: bold;
  font-size: 16px;
  height: 18px;
}
.btnWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  & button {
    width: max-content;
    padding: 0 10px;
    min-height: 45px;
    border: none;
    border-radius: 5px;
    color: #ffffff;
  }

  @media (--viewportMobile) {
    flex-direction: column;
    gap: 20px;
  }
}
.cancelButton {
  background: #ffb81d;
}
.submitButton {
  background: #0058fa;
}
