@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --Topbar_logoHeight: 26px;
}

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.mobileModalRoot {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: sticky;
  top: 0;
}

.container {
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  /* box-shadow: var(--boxShadow); */
  padding: 7px 22px 5px 27px;

  @media (--viewportLarge) {
    display: none;
  }
  /* @media (--viewportSmall) {
    padding: 0 24px;
  } */
}

.otherContainer {
  /* Size */
  width: 100%;
  height: 86px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: #243144;

  /* shadows */
  box-shadow: 0 0 0 0;

  @media (--viewportLarge) {
    display: none;
  }

  & svg {
    fill: var(--matterColorLight);
    & path {
      stroke: white;
    }
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px;
}

.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.searchMenu {
  padding: 12px 24px;
}

.menu,
.searchMenu {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
  @media screen and (--viewportMobile) {
    width: auto;
    padding: 12px;
  }
}

.menu {
  /* padding: container5px; */
  position: relative;
  padding: 0;
}

/* .menuIcon,
.searchMenuIcon {
  margin: 9px 0;
} */

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: -8px;
  left: 20px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  padding: 0;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  @apply --marketplaceModalHighlightEmail;
}

.helperLink {
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}

.activeUser {
  height: 46px;
  width: 46px;
  position: relative;
  @media (--viewportMobile) {
    margin: 0 24px 0;
  }
}
.avatar {
  height: 46px;
  width: 46px;
  position: relative;
}
.active {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: #84db9b;
  border: 3px solid var(--matterColorLight);
  top: 74%;
  left: -2px;
  position: absolute;
}

.searchLink {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 17px;
  & svg {
    fill: var(--matterColorLight);
    & g {
      stroke: var(--matterColorLight);
    }
  }

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  /* padding-left: 24px; */
  /* background: var(--matterColorLightGrey); */
  background: #f8f8f8;
  border-radius: 50px;
  width: 220px;
  height: 50px;
  display: flex;
  /* background: rgba(255,255,255,0.1); */
  /* flex-direction: row-reverse;
  margin-left: 24px; */
  & svg {
    & g {
      stroke: var(--matterColorDark);
    }
  }
  @media (--viewportMobile) {
    /* width: 210px; */
    width: 100%;
  }
}

.topRight {
  display: flex;
  align-items: center;
}

.backArrow svg {
  fill: transparent;
}

.mobileCloseBtn {
  display: none;
}

.mobileMenuModalContainer {
  padding: 0;
  position: relative;
  width: 100%;
}

.darkContainer {
  background: var(--marketplaceColorBackground);
  & div {
    & svg {
      fill: #fff;
      & path {
        stroke: #fff;
      }
    }
  }
}

.mobileLogo {
  width: 159px;
  height: 38px;
  object-fit: cover;
}

.filterKeywordWrap {
  width: 100%;
  justify-content: left;
  align-items: center;
  display: flex;
  position: relative;
  @media (--viewportMedium) {
    margin-left: 77px;
  }
}

.filterWrap {
  width: 375px;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 8px;
  @media (--viewportMobile) {
    width: 100%;
    height: 44px;
    background: #f5f5f5;
    border-radius: 21px;
    padding: 0 8px 0 16px;
  }
}
.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;
  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
}
.filterKeywordWrap input {
  width: 214px;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);
  @media (--viewportMobile) {
    &::placeholder {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.btnapply {
  position: absolute;
  top: 4px;
  right: 16px;
  padding: 5px;
  & svg {
    width: 100%;
    height: 21px;
  }
}

.keywordMobileTopbarContainer {
  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
  /* Size */
  width: 100%;
  /* height: var(--topbarHeight); */
  height: auto;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 12px;

  /* fill */
  background-color: var(--matterColorLight);

  /* box-shadow: var(--boxShadow); */
  padding: 22px 25px;
  border: 1px solid #d9d9d9;
  @media (--viewportLarge) {
    display: none;
  }
}

.goBackIcon {
  & svg {
    fill: var(--matterColorLight);
  }
}

.topbarMenu {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  position: relative;
  padding: 0 0 0 5px;
  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
  @media screen and (--viewportMobile) {
    width: auto;
  }
}

.goBackArrow {
  fill: transparent;
}
