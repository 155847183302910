@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.input {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.editListingTypeWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 30px;
}
.editListingTypeWrap .editListingLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
}
.editListingCardWrap {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (--viewportTabletDown) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 136px; */
  padding: 25px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}
.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
}
.editListingTypeText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}