@import '../../marketplace.css';

.content {
  background: #f5f5f5;
  overflow: hidden;
}

.topBarContainer {
  width: 100%;
  padding: 0.6rem 12.5%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  background: white;

  @media (--viewportMobile) {
    padding: 0.6rem;
  }
}
.topBarContainer .probuddyLogo {
  height: 2rem;

  @media (--viewportMobile) {
    height: 1.8rem;
  }
}
.topBarContainer .probuddyLogo img {
  height: 100%;
  width: 100%;
}

.topBarContainer .exploreButton {
  padding: 0.6rem 1rem;
  max-width: 9rem;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  min-height: 2.2rem;
}

.heroDescription {
  margin-bottom: 2rem;
  font-weight: 450;
  font-size: 18px;
  line-height: 33px;

  @media (--viewportTabletDown) {
    font-size: 10px;
    line-height: 15px;
    margin-bottom: 10px;
  }
}

body {
  overflow-x: hidden;
}

.servicesSection {
  width: 100%;
  margin-top: 3rem;
  /* margin: 4rem auto 0 auto; */

  @media (--viewportMobile) {
    margin: 2rem auto 0 auto;
  }
}

.servicesSectionBrand {
  width: 100%;
  margin-top: 1rem;
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  text-align: center;
  color: #000000;
  /* margin: 4rem auto 0 auto; */

  @media (--viewportMobile) {
    margin: 1rem auto 0 auto;
    font-size: 25px;
    line-height: 30px;
  }
}

.servicesSectionBrand img {
  width: 100%;
}

.servicesTitle {
  color: black;
  margin: auto;
  max-width: 45rem;
  font-weight: 700;
  font-size: 38px;
  line-height: 54px;
  text-align: center;

  @media (--viewportMobile) {
    font-size: 25px;
    line-height: 30px;
  }
}

.servicesSubheading {
  line-height: 26px;
  max-width: 38rem;
  margin-top: 1rem;
  font-weight: 400;
  color: black;
  margin: 1rem auto 20px auto;
  font-size: 18px;
  text-align: center;

  @media (--viewportMobile) {
    margin-bottom: 20px;
    padding: 0 20px;
  }
}

.topHeadContainer {
  /* height: calc(100vh - 56px); */
  width: 100%;
  /* background: var(--marketplaceColorBackground); */
  display: flex;
  color: var(--matterColorLight);
  margin-top: -5px;
  min-height: 460px;
  position: relative;
  /* background: url('https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1672810528/probuddy/becomeMemberPage/heroImage_becomemember_dyfflk.svg'); */

  @media (max-width: 991px) {
    flex-flow: column;
    margin-top: -1px;
    min-height: 360px;
  }
}

.topHeadContainer img {
  width: 100%;
}

.heroTitle {
  font-weight: 500;
  font-size: 45px;
  line-height: 69px;

  @media (--viewportTabletDown) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}

.sectionContainer {
  width: 48%;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 60px 0 0;
  position: absolute;

  @media (--viewportMobile) {
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    padding: 10px 0 0px;
  }
}

.probuddyVideo {
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.businessContainer {
  width: 75%;
  height: 600px;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: 3rem;
  background: url('https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664793262/probuddy/static/images/BecomeMemberPage/sportsImg_bzvwcp.png');
  background-size: cover;
  border-radius: 1rem;

  @media (max-width: 991px) {
    width: 90%;
    height: 300px;
    margin: 1rem auto;
  }
}
.subscriptionLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white !important;
  color: #2860fb;
  border-radius: 8px;
  width: 340px;
  padding: 0 22px;
  height: 65px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;
  @media (max-width: 991px) {
    width: 100%;
    height: 44px;
    font-size: 12px;
    line-height: 14px;
  }
}

.topbarContainer {
  width: 90%;
  /* margin: 0 auto; */
  @media (max-width: 991px) {
    width: 100%;
  }
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}

.successAlert a {
  box-shadow: none !important;
  border: none !important;
  outline: none !important;
}
.breadCrumbWrapper {
  margin: 20px auto 0;
  width: calc(75% + 4rem);
  padding: 0 20px;
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}

.planCardsWrapper {
  display: flex;
  width: 100%;
  padding: 0 200px;
  justify-content: space-between;
  gap: 50px;

  @media (--viewportTabletDown) {
    padding: 0 20px;
    flex-direction: column;
    gap: 30px;
  }
}

.planCard {
  background: #ffffff;
  box-shadow: 0px 22.8958px 86.677px rgb(0 0 0 / 10%);
  border-radius: 20px;
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #000000;

  @media (--viewportTabletDown) {
    gap: 15px;
  }
}

.anchfree {
  color: #ffffff;
  line-height: 16px;
}
.anchfree:hover {
  text-decoration: none;
}

.planCard:nth-child(2) {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  color: #ffffff;
}

.planCard:nth-child(2) .buttonText {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  color: #ffffff;
}

.planCard:nth-child(2) .planBtn {
  background: #ffffff;
}

.planCard:nth-child(2) a {
  background: #ffffff;
}

.planTitle {
  font-weight: 700;
  font-size: 34px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.05em;

  @media (--viewportTabletDown) {
    font-size: 24px;
    line-height: 22px;
  }
}

.planPrice {
  font-weight: 700;
  font-size: 45px;
  line-height: 30px;
  text-align: center;

  @media (--viewportTabletDown) {
    font-size: 35px;
    line-height: 22px;
  }
}

.perMonth {
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  opacity: 0.5;

  @media (--viewportTabletDown) {
    font-size: 13px;
    line-height: 22px;
  }
}

.includesTitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 30px;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 22px;
  }
}
.desWrap {
  display: flex;
  gap: 20px;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  align-items: center;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}

.planDivider {
  width: 100%;
  height: 0px;
  left: 250px;
  top: 1573px;
  border: 0.5px solid #a8a8a8;
}
.partnersTitle {
  font-weight: 450;
  font-size: 18px;
  line-height: 30px;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}

.partnersWrapper {
  display: flex;
  justify-content: space-evenly;
  font-weight: 500;
  text-align: center;
}

.partnerTitle {
  font-size: 14px;
  line-height: 18px;

  @media (--viewportTabletDown) {
    font-size: 10px;
    line-height: 13px;
  }
}

.partnerDes {
  font-size: 10px;
  line-height: 13px;
}
.partnerImageWrap {
  background: #ffffff;
  box-shadow: 0px 0px 42px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  width: 62px;
}

.partnerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.planBtn {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 15px;
  height: 50px;
  border: none;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.planBtn:hover {
  cursor: pointer;
}

.anch {
  color: #ffffff;
}
.anc:hover {
  text-decoration: none;
}

.descriptionWrap {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;

  @media (--viewportTabletDown) {
    height: 100px;
  }
}
.partnerImg {
  width: 250px;
  height: 78px;
  object-fit: cover;
  margin: auto;
  @media (--viewportMobile) {
    width: 220px;
    height: 70px;
  }
}
.tabsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 50px 150px;
  @media (--viewportTabletDown) {
    padding: 20px;
  }
}
.getTabs {
  /* padding: 25px; */
  width: 100%;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #000000;
  text-align: center;

  @media (--viewportTabletDown) {
    font-size: 13px;
    line-height: 18px;
  }
}

.selectedTab {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  /* border-bottom: 2px solid linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%); */
}
.borderBottom {
  height: 2px;
  background: #00000029;
  margin-top: 20px;
}
.selectedBorderBottom {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
}
.imageWrapper {
  padding: 0px 150px;
  @media (--viewportTabletDown) {
    padding: 0 20px;
  }
}

.imageWrapper img {
  width: 100%;
}

.descriptionWrap svg {
  fill: transparent;
}

.planCard:nth-child(2) .descriptionWrap svg path,
.planCard:nth-child(2) .descriptionWrap svg rect,
.planCard:nth-child(2) .descriptionWrap svg circle {
  stroke: #ffffff;
}

.questionWrap {
  height: 72px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px;
  transition: 300ms;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 24px;
    height: fit-content;
    padding: 10px;
  }
}

.questionWrapOpen {
  height: 150px;
}

.questionWraper {
  padding: 30px 150px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @media (--viewportTabletDown) {
    padding: 25px 20px 0;
    margin-bottom: 100px;
  }
}

.questionRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.questionRow img {
  height: 15px;
  width: 15px;
}

.ans {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.4);

  @media (--viewportTabletDown) {
    font-size: 11px;
    line-height: 21px;
  }
}
.popularWraper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 30px 0;

  @media (--viewportTabletDown) {
    display: none;
  }
}

.popular {
  position: relative;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 55px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  color: #ffffff;
}
.popular:before {
  content: '';
  position: absolute;
  bottom: -19px;
  left: 45%;
  border: solid 10px transparent;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-top-color: #4a76ef;
  z-index: 1;
}
.layoutTopbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;

  @media (--viewportTabletDown) {
    position: unset;
  }
}
.topSection {
  margin-top: 50px;
}
