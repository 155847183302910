@import '../../marketplace.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 77px;
  padding: 0 calc((100% - 1056px) / 2) 82px 59px;
}

.arrowIcon {
  fill: transparent;
  cursor: pointer;
}

.disableArrow {
  cursor: not-allowed;
  & path {
    stroke: grey;
  }
}

.dotWrapper {
  display: flex;
  align-items: center;
  gap: 0 66px;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 28px;
  background: #d9d9d9;
}

.selectedDot {
  background: #ee3e22;
}
