@import '../../marketplace.css';

.header {
  /* margin-bottom: 24px; */
}

.headerTitle {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: bold;
  color: #253045;
  margin-bottom: 20px;
}

.iconWrap {
  display: flex;
  align-items: center;
  margin-right: 8px;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #f5f7fc;
  border-radius: 8px;
}
.headerTitleText {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #0058fa;
}
.headerDate {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #0058fa;
}

.headerMain {
  display: flex;
  flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 70px;
}
.gross {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #0058fa;
  flex-wrap: wrap;
  @media (--viewportMobile) {
    font-size: 36px;
    line-height: 36px;
  }
}
.paymentNum {
  margin-left: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  margin-top: 8px;
  color: #0058fa;
}

.chartSection {
  /* padding: 40px;
  border-radius: 8px; */
}

.chartMenu {
  display: flex;
  margin-bottom: 24px;
  width: 360px;
  border-bottom: 1px solid #e3e7f0;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.chatMenuItem {
  margin-right: 32px;
  height: 40px;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s linear;
  &:hover {
  }
}
.chatMenuItemActive {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    width: 16px;
    height: 4px;
    background: var(--marketplaceColorDark);
    border-radius: 4px;
  }
}

.periodGross {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 20px 0;
  flex-wrap: wrap;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #0058fa;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.grossHeader {
  /* margin-bottom: 7px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #253045; */
}

.valueDivider {
  border: 0.898439px solid #397dfa;
  height: 17.97px;
  margin: 10px;
}

.periodDateWrap > span {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0058fa;
}

.chartWrap {
  overflow: auto;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  /* margin: 20px; */
  padding: 50px;
  @media (--viewportMobile) {
    padding: 20px;
  }
}

.dateIcon {
  margin: 0 10px;
}
