@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}
.submitButtonContainer .submitButton {
  min-width: 30%;
  width: auto;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.addonWrap {
  display: flex;
}
.addonText {
  & textarea {
    padding: 5px 0;
  }
}
.addonText,
.addonPrice {
  flex-shrink: 1;
  width: 50%;
}

.mutateBtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-bottom: 24px;
  }
  & > button {
    max-width: 200px;
    min-height: 40px;
  }
}
.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
}
.selectedActivity .editListingSelectCardText {
  color: var(--matterColorLight) !important;
  background-color: var(--matterColorBlue);
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 136px; */
  padding: 25px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}
.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}
.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
}
.editListingTypeText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.editListingTypeWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 30px;
}
.editListingTypeWrap .editListingLabel {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  /* color: #000000; */
}
.editListingCardWrap {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (--viewportTabletDown) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.priceText {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  margin-bottom: 30px;
}
