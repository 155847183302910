@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  row-gap: 14px;
}
/* Cutom Buttons Start */
.btnRed {
  color: #f00;
  background: #ffffff;
  border: 2px solid #f00;
}
.btnRed:active {
  color: #fff;
  background: #f00;
  border: 2px solid #f00;
}
.btnBlue {
  background: #0058fa;
  color: #ffffff;
  border: 0;
}
.btnBlue:active {
  background: #fff;
  color: #0058fa;
  border: 0;
  border: 2px solid #0058fa;
}
/* Quotes Modal Design Start */
.customModal {
  padding: 0px !important;
  border-radius: 15px !important;
  overflow: hidden;
  @media (--viewportMobile) {
    border-radius: unset !important;
    overflow: auto;
    margin: 20px 0;
  }
}
.customScrollLayer {
  @media (--viewportMedium) {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}
/* .customModalContent div {
  width: 100%;
} */
.customModalContent {
  flex-grow: 1;
  display: flex;
  width: 100%;
}

.quotesFormContainer {
  background: var(--matterColorLight);
}

.quotesForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.quotesSubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}
/* Quotes Modal Design End */

.actionWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 24px 0 0;
}
.actionWrapper button,
.bannerText button,
.leaveActionWrap button {
  border-radius: 6px;
  padding: 8px 14px;
  font-weight: 500;
  /* font-size: 16px; */
  font-size: 18px;
  line-height: 16px;
  line-height: normal;
  text-align: center;
  cursor: pointer;
}
.btnContinue {
  background: #0058fa;
  color: #ffffff;
  border: 0;
}
.disabledContinue {
  background: #858585;
  color: #ffffff;
}
.btnBack {
  border: 1px solid #0058fa;
  color: #0058fa;
  background: transparent;
}
.btnBack:active {
  background: #0058fa;
  color: #fff;
  border: 0;
  border: 1px solid #0058fa;
}
.questionWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
}
.questionWrapper h2 {
  font-size: 30px;
}
.requiredWrap,
.optionalWrap {
  margin: 10px 0;
  background: #0058fa0a;
  padding: 0 10px 10px;
  border-radius: 5px;
}
.requiredWrap code,
.optionalWrap code {
  font-size: 12px;
}

.optionsWrap {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.optionsWrapSurvey {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.optionsWrap input[type='text'] {
  line-height: 40px;
  padding: 0 16px;
}
.optionsWrap textarea {
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: vertical;
}

.optionsWrapSurvey input[type='radio'],
.optionsWrapSurvey input[type='checkbox'] {
  width: 16px;
  height: 16px;
  min-width: 16px;
  accent-color: #0058fa;
}

.optionsWrapSurvey .optionsWrapper label {
  padding: 0;
  font-size: 18px;
  line-height: 35px;
  width: 100%;
}

.optionsWrapSurvey .optionsWrapper .otherOptionText {
  display: flex;
  gap: 10px;
  align-items: center;
}
.optionsWrapSurvey .optionsWrapper:hover {
  background: #f9f9fa;
}
.optionsWrapSurvey .optionsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 16px;
  gap: 8px;
  border-top: 1px solid #e6e7ec;
  border-left: 1px solid #e6e7ec;
  border-right: 1px solid #e6e7ec;
}
.optionsWrapSurvey .optionsWrapper:first-child {
  border-radius: 4px 4px 0 0;
}

.optionsWrapSurvey .optionsWrapper:last-child {
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid #e6e7ec;
}
.quotesWrapper {
  width: 100%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (--viewportMobile) {
    padding: 60px 20px;
  }
}

.thankYouWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 60px;
}

.errorText {
  color: #0058fa;
}

.banner {
  width: 100%;
  margin-bottom: 20px;
}
.banner .bannerText {
  padding: 10px 25px;
  display: flex;
  gap: 1.2rem;
  flex-direction: column;
  align-items: center;
}
.banner .bannerText h3 {
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0;
  @media (--viewportMobile) {
    font-size: 24px;
    font-weight: 500;
  }
}
.banner .bannerText p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  text-align: justify;
  margin: 0 0;
  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.banner .bannerText button {
  font-size: 18px;
  line-height: 20px;
  padding: 10px 20px;

  @media (--viewportMobile) {
    font-size: 16px;
  }
}
.banner .bannerImg {
  width: inherit;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  @media (--viewportMobile) {
    border-radius: unset;
    height: 35%;
    object-fit: cover;
    object-position: left;
  }
}

.progressBarMain {
  width: 100%;
  height: 7px;
  border-radius: 10px;
  background: #e3e3e3;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.progressBarMain .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #0058fa;
  width: 25%;
  transition: 0.3s ease;
}

.leaveWrap {
  width: 100%;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media (--viewportMobile) {
    padding: 40px 20px;
    gap: 2rem;
  }
}
.leaveWrap .leaveActionWrap {
  display: flex;
  justify-content: space-between;
  gap: 4rem;
}
.leaveWrap .leaveActionWrap button {
  font-size: 16px;
  line-height: 14px;
}
.selectCity {
  font-size: 14px;
  padding: 2px;
  margin: 0px;
}
.btn {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  border: 0;
  padding: 10px 0;
  /* border-radius: 5px; */
  border-radius: 2.5px;
  width: 250px;
  @media (--viewportMiniDesktopDown) {
    width: 100%;
    padding: 10px 25px;
  }
}
.errorClass {
  color: #f00;
  font-size: small;
  position: relative;
  bottom: 10px;
}
