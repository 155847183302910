@import '../../marketplace.css';

.cardListingBox {
  max-width: 282px;
  width: 100%;
  background: var(--matterColorLightContainer);
  box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.cardImageWrapper {
  display: flex;
  column-gap: 2px;
}

.namedLinkCard {
  &:hover {
    text-decoration: none;
  }
}

.cardMiniImage {
  height: 77px;
  width: 100%;

  @media (--viewportMobile) {
    height: 80px;
  }

  & img {
    height: 100%;
    object-fit: cover;
  }

  &:first-child img {
    border-radius: 15px 0px 0px 0px;
  }

  &:last-child img {
    border-radius: 0px 15px 0px 0px;
  }
}

.authorProfileImage {
  width: 71px;
  height: 71px;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 2px solid var(--matterColorLightBorder);
  border-radius: 50px;
  position: absolute;
  @media (--viewportMobile) {
    width: 73px;
    height: 73px;
  }
}

.cardTitleText {
  padding: 0px 15px 22px 15px;
}

.cardTitleText .cardTitleName {
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: var(--matterColorDark);
  margin: 0px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardsParagraph {
  padding: 20px 35px;
  height: 78px;
}
.cardsParagraphSpan {
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #a8a8a8;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cardLocation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  justify-content: flex-start;

  & svg {
    width: 8px;
    height: 10px;
  }
}

.cardLocation .cardAddress {
  font-weight: 400;
  font-size: 12.5283px;
  line-height: 16px;
  color: #a8a8a8;
  padding-left: 6px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.cardsRatings {
  display: flex;
  column-gap: 36px;
  justify-content: center;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.cardsRatingNumber {
  padding: 15px 0px;
}

.cardsRatingPluse {
  font-weight: 500;
  font-size: 16.7044px;
  line-height: 21px;
  color: var(--matterColorDark);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 17px;
    line-height: 22px;
  }
}

.cardsRatingTitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #a8a8a8;
  @media (--viewportMobile) {
    line-height: 17px;
  }
}

.cardsButton {
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 22px;
  padding-right: 7px;
}

.price {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #28a74b;
  margin: 0;
}

.viewOfferButton {
  cursor: pointer;
  padding: 10px 25px;
  background-color: var(--matterColorBlue);
  border-radius: 6px;
  border: 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: var(--matterColorLight);
  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 18px;
  }
}
.horizontalCardContainer {
  display: flex;
  width: 100%;
  background: var(--matterColorLight);
  box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
  border-radius: 15px;
  height: -moz-fit-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  @media (--viewportTabletDown) {
    gap: 15px;
    padding: 11px;
  }
}

.cardImage {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  @media (--viewportTabletDown) {
    padding: 0;
    flex: 1;
    border-radius: 5px;
    overflow: hidden;
  }
}
.infoWrapper {
  display: block;
  flex: 1;
  @media (--viewportTabletDown) {
    padding: 0;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
.profileWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--viewportTabletDown) {
    align-items: start;
    gap: 12px;
  }
}

.locationText {
  flex-direction: column;
  font-size: 10px;
  line-height: 13px;
}
.responsiveImage {
  max-width: 119px;
  max-height: 155px;
  height: 100%;
  width: 100%;
  @media (--viewportTabletDown) {
    max-width: 100%;
    max-height: unset;
  }
}
.horizontalAuthorProfileImage {
  @media (--viewportTabletDown) {
    width: 30px !important;
    height: 30px !important;
  }
}
.horizontalTopWrapper {
  flex: 1;
  /* display: flex;
  flex-direction: column; */
}
.horizontalCardTitleName {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0;
}
.horizontalCardAddress {
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  color: #a8a8a8;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: 5px;
}
.horizontalCardsRatings {
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  @media (--viewportTabletDown) {
    margin: 0 9px;
  }
}
.horizontalCardsRatingNumber {
  padding: 15px 0px;
}
.horizontalCardsRatingPluse {
  font-weight: 500;
  font-size: 14.1921px;
  line-height: 18px;
  color: var(--matterColorDark);
  margin: 0;
  /* @media (--viewportMobile) {
    font-size: 17px;
    line-height: 22px;
  } */
}
.horizontalCardsRatingTitle {
  color: #a8a8a8;
  font-weight: 450;
  font-size: 10.644px;
  line-height: 13px;
  /* @media (--viewportMobile) {
    line-height: 17px;
  } */
}
.horizontalCardsParagraph {
  /* padding: 20px 35px;
  height: 78px; */
  @media (--viewportTabletDown) {
    margin: 0 9px;
  }
}
.horizontalCardsParagraphSpan {
  font-style: normal;
  font-weight: 450;
  font-size: 10px;
  line-height: 16px;
  /* text-align: center; */
  color: #a8a8a8;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.horizontalCardsButton {
  padding-top: 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--viewportTabletDown) {
    margin: 0 9px;
    gap: 15px;
  }
}

.horizontalPrice {
  text-align: center;
  color: #28a74b;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
}
.horizontalViewOfferButton {
  cursor: pointer;
  background-color: #0058fa;
  border-radius: 6px;
  border: 0;
  text-align: center;
  color: #ffffff;
  width: 95px;
  height: 30px;
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  @media (--viewportTabletDown) {
    border-radius: 3.31824px;
  }
}

.cardContainer {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  overflow: auto;
}

.trainerCardContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  background: var(--matterColorLight);
  box-shadow: 0px 0px 41.7609px 5.22011px rgb(0 0 0 / 5%);
  border-radius: 15px;
  height: -moz-fit-content;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.trainerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.trainerProfileWrapper {
  display: flex;
  column-gap: 10px;
}

.socialIconsWrappers {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}

.socialIcon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0000004d;
  @media (--viewportTabletDown) {
    width: 32px;
    height: 32px;
    align-items: flex-start;
  }
}

.slider {
  background-color: var(--matterColorLight);
  /*border: 1px solid #dadada;
  box-shadow: 0px 16.7144px 63.2758px rgba(0, 0, 0, 0.1);
  border-radius: 14.6004px; */
}

.sliderWrap {
  min-width: 90%;
  padding: 12px 8px;
  /* height: 100px;
  background: red; */
}
.trainerMainWrapper {
  position: relative;
  /* padding-bottom: 35%; */
  background: var(--matterColorLight);
  border-radius: 16px;

  @media (--viewportTabletDown) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }
}
.trainerSectionWrap {
  width: 100%;
  /* height: 250px; */
  height: 200px;
}
.trainerSectionWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  @media (--viewportLaptopDown) {
    border-radius: 50px 0 50px 0;
  }
}
.trainerSectionWrapedu {
  height: 280px;
}
.trainerDetailMain {
  /* padding: 0 20px 0; */
  /* position: absolute; */
  /* top: 160px; */
  width: 100%;
}
.trainerDetailWrap {
  background: var(--matterColorLight);
  /* box-shadow: 0px 4px 75px rgba(0, 0, 0, 0.08); */
  /* border-radius: 0 0 16px 16px; */
  padding: 8px 10px 0px;
}
.trainerDetailHead {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffb81d;
  text-align: left;
  margin-bottom: 5px;
}
.trainerDetailTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 10px;
}
.trainerDetailDesc {
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 10px;
}
.trainerUserDetail {
  display: flex;
  flex-direction: column;
}
.trainerText {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #111b2c;
}
.subCategoryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.subCategoryText {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  background-color: #0056f5;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: capitalize;
  word-break: break-word;
  white-space: normal;
}
.trainerProfileImage {
  width: 35px;
  height: 35px;
}
.trainerProfileImage img {
  width: auto;
  height: auto;
  border-radius: 50%;
}
.trainerWrapBtnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.trainerWrapBtn {
  background: #0058fa;
  border-radius: 6px;
  padding: 0px 15px;
  border: 0;
}
.trainerBtnText {
  font-weight: 500;
  font-size: 10px;
  /* line-height: 0; */
  text-align: center;
  color: var(--matterColorLight);
}

.ratingLocationWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 10px;
  /* justify-content: space-between; */
}
.ratingWrap {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
}
.ratingLabel {
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.ratingTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}
.ratingText {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #ffb534;
}
.locationWrap {
  display: flex;
  flex-direction: column;
  column-gap: 7px;
  /* flex: 1; */
}
.locationLabel {
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.03em;
  color: #8c8c8c;
}
.locationTextWrap {
  display: flex;
  align-items: center;
  column-gap: 7px;
  /* margin-top: 10px; */
}
.locationMainText {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #1b1b1b;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.gallerItemsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  background: var(--matterColorLight);
  padding: 10px;
  border-radius: 6px;
  /* margin-bottom: 10px; */
  min-width: 100%;
  /* height: fit-content; */
}
.gallerItems {
  /* width: 100%; */
  min-height: 165px;
  border-radius: 6px;
  overflow: hidden;
  @media (--viewportMobile) {
    height: 100%;
  }
}
.itemImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.galleryDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.questionWrapper {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 5px;
}
.answerWrapper {
  font-weight: 400;
  font-size: 8px;
  line-height: 16px;
  color: #8c8c8c;
  margin-bottom: 10px;
}

.qADescriptionWrapper {
  display: flex;
  flex-direction: column;
  /* row-gap: 5px; */
}

.aboutUsTitle {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: var(--matterColorDark);
  margin: 7px 0;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 15px;
}

.questionAnswerWrapper {
  display: flex;
  flex-direction: column;
}

.bookButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.bookButton {
  width: 100%;
  background: var(--matterColorBlue);
  color: var(--matterColorLight);
  padding: 14px 0;
  border-radius: 7px;
  border: 0;
  margin: 13px;
}
.cardMainWrap {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.cardMain {
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
}
.cardImgMain {
  width: 425px;
  height: 280px;
  flex: 1;
  border-radius: 15px;
  overflow: hidden;
}
.cardImgMain img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardwrap {
  background: var(--matterColorLight);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex: 2;
  justify-content: space-between;
  column-gap: 15px;
}
.cardDetailsWrap {
  width: 100%;
}
.cardDetailsHead {
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  color: var(--matterColorDark);
}
.cardDetailAddressWrap {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.cardDetailAddress {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.cardDetailAddressText {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1b1b1b;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cardDetailText {
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
  color: #0b150a;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.bookingWrapper {
  display: flex;
  justify-content: space-between;
}
.promotedBookingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 20px 0 20px; */
  padding: 15px 15px;
  @media (--viewportMobile) {
    flex-direction: column-reverse;
    row-gap: 10px;
  }
}
.slick-dots {
  background: red;
}
.priceWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  padding: 0 !important;
  align-items: center;
  @media (--viewportMobile) {
    width: 100%;
    justify-content: space-between;
  }
}
.priceFrom {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--matterColorDark);
  padding: 0 !important;
}
.priceProduct {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: var(--matterColorDark);
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (--viewportMobile) {
    font-size: 18px;
    line-height: 28px;
  }
}
.cardDetailButton {
  background: var(--matterColorBlue);
  border-radius: 6px;
  padding: 12px 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: var(--matterColorLight);
  border: 0;
  @media (--viewportMobile) {
    width: 100%;
  }
}
.cardImagesWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
.cardImgWrapper {
  width: 195px;
  height: 115px;
  border-radius: 8px;
  overflow: hidden;
}
.cardImgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.classCard:hover {
  text-decoration: none;
}

.newScroll {
  gap: 0 !important;
  overflow-x: auto !important;
}

.categoryListContainer [class*='sectionCounter'] {
  display: none;
}

.categoryListContainer [class*='left'] {
  top: 0;
  right: unset;
  left: -45px;
  display: flex;
  align-items: center;

  @media (--viewportTabletDown) {
    right: -50px;
    left: unset;
  }

  @media (--viewportLaptopDown) {
    width: unset;
    right: -30px;
    left: unset;
  }
}

.categoryListContainer [class*='right'] {
  top: 0;
  right: -90px;
  display: flex;
  align-items: center;
  height: 100%;
}

.categoryListContainer [class*='img'] {
  display: flex;
  align-items: center;
  position: revert;
  width: 32px;
  height: 32px;
}

.categoryListContainerWithoutArrow {
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}

.categoryListContainerWithoutArrow {
  width: 80%;

  @media (--viewportLaptopDown) {
    width: 100%;
  }
}

.uniqueFeatureText {
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  color: var(--matterColorDark);
}

.svgBuilding {
  fill: transparent !important;
  @media (--viewportTabletDown) {
    min-width: 20px;
    margin-top: 7px;
  }
}
.trainerDetailMainWrapper {
  display: flex;
  padding: 15px;
  column-gap: 50px;
  background-color: var(--matterColorLight);
  border-radius: 5px;
  @media (max-width: 1440px) {
    column-gap: 30px;
  }
}
.featuresMainWrap {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  @media (--viewportTabletDown) {
    margin-top: 20px;
  }
}
.featuresWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.featuresMain {
  margin-bottom: 23px;
}
.featuresImgWrapper {
  padding: 10px;
  min-width: 90%;
}
.featuresImgWrap {
  width: 100%;
  height: 200px;
}
.featuresImgWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.features {
  display: flex;
  /* align-items: center; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    align-items: center;
  }
}
.featuresImg {
  margin-top: 3px;
}
.featuresHead {
  font-weight: 700;
  font-size: 20.17px;
  line-height: 32px;
  color: var(--matterColorDark);
  margin: 0;
  text-transform: capitalize;
}
.featuresText {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--matterColorDark);
  margin: 28px 0 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* width: 380px; */
  /* @media (min-width: 1300px) and (max-width: 1440px) {
    width: 170px;
  } */
}
.listingsMain {
  display: flex;
  align-items: center;
  column-gap: 32px;
  @media (--viewportTabletDown) {
    column-gap: 39px;
    margin-bottom: 25px;
  }
}
.listingsHead {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: var(--matterColorDark);
  margin: 0;
  text-transform: capitalize;
}
.listingsText {
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: var(--matterColorBlue);
  margin: 0;
}
.textDesign {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
  margin: 0;
  width: 380px;
}
.otherListingMain {
  flex: 1;
  @media (--viewportLaptopDown) {
    min-width: 90%;
    padding: 10px;
  }
}
.otherListingTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: var(--matterColorDark);
  margin-bottom: 12px;
}
.courseImageWrapper {
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    width: 100%;
    justify-content: flex-start;
    column-gap: 10px;
  }
}
.classImage {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 130px;
  height: 95px;
  @media (max-width: 1440px) {
    width: 100px;
    height: 85px;
  }
}

.classImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cardDetailWrapper {
  padding: 10px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 0px;
  }
}

.cardRatingWrapper {
  padding: 10px;

  @media (--viewportMobile) {
    padding: 0px;
    width: 30%;
  }
}

.cardPriceWrapper {
  padding: 10px;

  @media (--viewportMobile) {
    padding: 0px;
    width: 30%;
  }
}
.cardDetailTitle {
  font-weight: 500;
  font-size: 16.2309px;
  line-height: 21px;
  color: var(--matterColorBlue);
  text-transform: capitalize;
  margin: 0 0 10px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 18px;
    margin: 0;
  }
}
.cardUsername {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 10px;
}
.userName {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  text-transform: capitalize;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
  }
}
.cardPrice {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #28a74b;
}
.cardLocation {
  display: flex;
  column-gap: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  margin: 0px 0 10px;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 8px;
    }
  }
}
.currentAddress {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1b1b1b;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .cardDescription {
    width: 190px;
  }

  .courseImageWrapper {
    column-gap: 15px;
  }

  .descriptionWrapper {
    padding-left: 15px;
  }

  .bookingWrapper {
    padding-left: 15px;
  }

  .cardDetailTitle {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.cardRatingsMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 10px;
  @media (min-width: 1200px) and (max-width: 1600) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
.cardRatings {
  display: flex;
  column-gap: 5px;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 23px;
  color: #ffb534;
  text-transform: capitalize;
  align-items: center;

  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 8px;
    margin: 8px 0 0;
    column-gap: 5px;

    & svg {
      width: 10px;
    }
  }
}
.cardRatingsTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: var(--matterColorDark);
}
.cardRatingsText {
  font-weight: 500;
  font-size: 14px;
  line-height: 9px;
  color: #ffb534;
}
.cardTotalRatings {
  color: #b9b9b9;
}
.detailWrapper {
  display: flex;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    flex-direction: column;
    width: 100%;
    row-gap: 0.5rem;
  }
}
.descriptionWrapper {
  /* width: 100%; */
  display: flex;
  padding: 5px;
  justify-content: space-evenly;
  /* border-left: 1px solid #0b150a1a; */
  padding-left: 30px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    border-left: unset;
    padding-left: 0;
  }
}
.cardDescription {
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  margin: 0 0 13px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (--viewportMiniDesktopDown) {
    width: 100%;
  }

  @media (--viewportMobile) {
    margin: 0;
    font-size: 12px;
    line-height: 20px;
  }
}
.bookingWrapper {
  align-items: center;
  padding: 9px;
  /* border-left: 1px solid #0b150a1a; */
  padding-left: 30px;
  flex: 1;

  @media (--viewportMiniDesktopDown) {
    border-left: unset;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media (--viewportMobile) {
    padding: 0;
  }
}
.cardDetailDuration {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: var(--matterColorDark);
  text-transform: capitalize;
  width: 177px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;

  @media (--viewportLaptop) {
    width: 175px;
  }

  @media (--viewportTabletDown) {
    width: 150px;
  }

  @media (--viewportMobile) {
    font-size: 10px;
    line-height: 13px;
    margin: 5px 0 0;
    width: unset;
  }
}
.cardDetailprice {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #28a74b;
  margin: 18px 0px 18px 0px;

  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 19px;
    margin: 5px 0 0;
  }
}
.reviewMainWrap {
  flex: 1;
}

.reviewRoot {
  background: none;
}

.arrowFlip {
  display: flex;
  align-items: center;
}

.arrowSvg {
  fill: transparent;
}
.aboutLocationWrapper {
  display: none;
  /* display: flex; */
  align-items: flex-start;
  column-gap: 15px;
}
.mapImage {
  box-shadow: 0px 4px 41.76px 5.22px rgba(0, 0, 0, 0.05);
  /* margin-top: 50px; */
  height: 250px;
  @media (--viewportMobile) {
    height: 145px;
    width: 100%;
    border-radius: 5px;
  }

  & h2 {
    display: none;
  }

  & > div {
    @media (--viewportMobile) {
      height: 100%;
      border-radius: 5px;
    }
  }

  & button {
    height: 100%;
    max-width: 100% !important;
    width: 100%;
    border-radius: 15px;
    @media (--viewportMobile) {
      /* max-height: 165px; */
    }
  }

  & button > div {
    border-radius: 15px;
  }

  & canvas {
    width: 100% !important;
    position: unset !important;
    border-radius: 15px;
    @media (--viewportMobile) {
      /* max-height: 165px; */
      border-radius: 5px;
    }
  }
}
