@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LocationAutocompleteInput_inputHeight: 50px;
  --LocationAutocompleteInput_sidePadding: 24px;
  --LocationAutocompleteInput_sidePaddingDesktop: 36px;
}

.root {
  position: initial;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  /*border-bottom: 2px solid var(--marketplaceColor);*/
  background-color: var(--matterColorLight);
}

.iconSvg {
  margin: auto;

  @media (--viewportTabletDown) {
    height: 12px;
  }
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border: none;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: var(--LocationAutocompleteInput_inputHeight);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 30px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left var(--LocationAutocompleteInput_sidePaddingDesktop);
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  @apply --marketplaceBodyFontStyles;

  margin: 0;
  padding: 0 0 14px 0;

  & li {
    color: var(--matterColorLight);
    transition: var(--transitionStyleButton);
    margin: 0;
    /* Remove the double white space */

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 9px var(--LocationAutocompleteInput_sidePadding);

    @media (--viewportMedium) {
      color: var(--matterColorLightTransparent);
      padding: 10px var(--LocationAutocompleteInput_sidePaddingDesktop);
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      border-left: 6px solid var(--matterColorLight);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: var(--matterColorLight);
    }
  }
}

.currentLocation {
  @apply --marketplaceH4FontStyles;
}

.currentLocationIcon {
  margin-right: 10px;
}

.predictionsWrap {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.newLocationPredictions {
  max-height: 360px;
  overflow-y: auto;
  margin-right: 15px;

  @media (--viewportTabletDown) {
    height: 33vh;
    max-height: unset;
  }

  @media (--viewportLaptopDown) {
    margin: 0;
    padding: 0;
  }
}

.newLocationPredictions::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 35%);
  background-color: #f5f5f5;
}

.newLocationPredictions::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.newLocationPredictions::-webkit-scrollbar-thumb {
  background-color: rgb(0 0 0 / 35%);
}

.homepageHeaderLocationSearch {
  background: #ffffff;
  border-radius: 50px;
  width: calc(100% - 52px);
  height: 48px;
  display: flex;
  border: 2px solid #0058fa;

  margin: 26px 26px 11px 26px;

  @media (--viewportLarge) {
    /* padding-left: 34px; */
  }

  @media (--viewportTabletDown) {
    height: 38.95px;
    width: calc(100% - 24px);
    margin: 20px 12px 11px 12px;
  }

  @media (--viewportLaptopDown) {
    margin: 20px 0 15px 0;
    width: 100%;
    transform: scale(1.03);
  }
}

.searchLocationTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #383838;
  background: transparent;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  /*border: 1.5px solid #f1f1f1;*/
  flex-direction: column;
  padding-left: 34px;
  justify-content: center;
  position: relative;

  @media (--viewportTabletDown) {
    padding: 0 0 0 20px;
  }

  @media (--viewportLaptopDown) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    align-items: center;
  }
}

.searchLocationTitleActive {
  box-shadow: 0px 0px 44px 1px rgba(0, 0, 0, 0.15);
  transform: scale(1.02);

  @media (--viewportLaptopDown) {
    box-shadow: none;
    transform: unset;

    & span {
      font-weight: 500;
      font-size: 15.0435px;
      line-height: 19px;
      color: #1b1b1b;
      opacity: unset;
    }
  }
}

.searchLocationHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #383838;
  opacity: 0.8;
}

.searchLocationContentContainer {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; */
  max-width: 65%;

  @media (--viewportLaptopDown) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }
}

.searchLocationContent {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1b1b1b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;

  @media (--viewportLaptopDown) {
    font-size: 15.0435px;
    line-height: 19px;
  }
}

.clearLocationButton {
  border-radius: 50%;
  border: 0;
  background-color: #0058fa;
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 15px;

  @media (--viewportLaptopDown) {
    position: unset;
  }
}

.predictionsLocationSvg {
  display: none;
}

.mobilesearchWrapper {}

.locationContainer {
  @media (--viewportLaptopDown) {
    width: 100%;
  }
}