@import './marketplace.css';

.alertWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 400;
}
.alertSvg {
  margin-right: 10px;
  display: flex;
}
.alertSvg svg {
  height: 16px;
  width: 16px;
}
