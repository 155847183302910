@import '../../marketplace.css';

.robotImageContainer {
  max-width: 550px;
  & img {
    height: 62px;
    width: 62px;
    margin: 0 12px 12px 0;
    @media (--viewportMobile) {
      margin: 0 10px 10px 0;
    }
  }
}

.addAvatarStection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: 40px;
  @media (--viewportMobile) {
    display: block;
  }
}
.mainContainer {
  display: flex;
  gap: 10px;
  margin: 35px 0 100px;
  @media (--viewportMobile) {
    display: block;
    margin-bottom: 0;
  }
}

.leftContainer {
  width: 55%;
  margin-right: 50px;
  margin-left: 20px;
  @media (--viewportMobile) {
    display: none;
  }
  @media (--viewportTabletDown) {
    display: none;
  }
}
.leftContainer img {
  width: 150%;
  height: 95%;
}

.rightContainer {
  max-width: 730px;
  width: 100%;
  margin-left: 70px;
  @media (--viewportMobile) {
    margin-left: 0;
    padding: 22px;
  }
}
.heading {
}

.heading h2 span {
  font-weight: bold;
  font-size: 40px;
  line-height: 63px;
  text-transform: capitalize;
  color: var(--marketplaceColorDarkest);
  margin: 0;
  @media (--viewportMobile) {
    font-size: 28px;
    line-height: 35px;
  }
}

.heading span {
  font-weight: normal;
  font-size: 19px;
  line-height: 15px;
  color: #6e6f71;
  margin: 16px 0 49px;
  @media (--viewportMobile) {
    font-size: 15px;
    line-height: 19px;
    margin: 8px 0 44px;
  }
}

.addAvatarTitle {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: var(--marketplaceColorDarkest);
  margin-bottom: 19px;
  @media (--viewportMobile) {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 24px;
  }
}
.imgeWrap {
  display: flex;
  gap: 30px;
  align-items: center;
  @media (--viewportMobile) {
    display: block;
  }
}
.chooseSection {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportMobile) {
    min-height: 130px;
  }
}
.chooseSectionImage [type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}
.chooseSectionImage {
  /* background-color: black; */
  padding: 20px 50px;
  /* @media (--viewportMobile) {
    min-height: 240px;
  } */
}

.chooseSection span {
  padding: 20px 0 42px;
  font-weight: normal;
  font-size: 23px;
  line-height: 29px;
  color: #fff;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 0 39px;
  }
}

.chooseSection [type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}

.chooseSection [type='file'] + label {
  width: 255px;
  height: 69px;
  background: var(--matterColorLight);
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 9px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1rem;
  outline: none;
  padding: 1rem 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  & span {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    text-transform: capitalize;
    color: var(--marketplaceColorDarkest);
  }
  @media (--viewportMobile) {
    width: 100%;
    margin-bottom: 0;
  }
}

.chooseLeftSection [type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
  display: none;
}

.chooseLeftSection [type='file'] + label {
  width: 300px;
  height: 300px;
  border: 2px dashed var(--marketplaceColor);
  background: #f3fdff;
  transition: ease-in-out 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMobile) {
    margin: 0 auto;
    width: 200px;
    height: 200px;
  }
}

.spinner {
  width: 100%;
  margin-top: 20%;
  height: 28px;
  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}

.error {
  color: var(--failColor);
  margin: 28px 0 0;
  font-size: 20px;
}

.avatar {
  width: 100%;
  height: 100%;
  & img {
    border-radius: 10%;
  }
}

.removedBorder {
  border: none !important;
}

.nextButton {
  width: 255px;
  height: 69px;
  background: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);
  box-sizing: border-box;
  border-radius: 9px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: var(--matterColorLight);
  margin: 0 0 6px;
  @media (--viewportMobile) {
    width: 100%;
    margin: 40px 0 8px;
  }
}
.nextButton:hover {
  background: var(--marketplaceColor);
}

.nextButton:disabled {
  background: rgba(0, 149, 179, 0.36);
  border: 1px solid rgba(0, 149, 179, 0.6);
  @media (--viewportMobile) {
    background: rgba(0, 149, 179, 0.35);
    border: 1px solid #dddddd;
  }
}

.phoneNumberContainer {
  padding: 0 0px;
  @media (--viewportMedium) {
    padding: 30px 0 0 0px;
  }
  & label {
    font-weight: bold;
    font-size: 33px;
    line-height: 38px;
    text-transform: capitalize;
    color: #6e6f71;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    letter-spacing: 0;
    padding-bottom: 2px;
    @media (--viewportMobile) {
      font-size: 24px;
      text-align: center;
    }
  }

  & .contactNumberInfo {
    font-size: 14px;
    display: flex;
    text-align: center;
    /* color: grey; */
    line-height: 18px;
  }
  & .PhoneInputWrapper {
    display: flex;
    column-gap: 20px;
    align-items: flex-start;
    @media (--viewportMobile) {
      width: 100%;
      justify-content: center;
    }
    & .leftInput {
      width: 30%;
    }
    & .rightInput {
      width: 50%;
    }
  }
}

.countryCode {
  display: block;
  margin: 0;
  padding: 5px 0;
  border: none;
  border-bottom: 2px solid var(--marketplaceColor);
  border-radius: 0;
  font-size: 16px;
  line-height: 24px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor) !important;
}

.inputError {
  border-bottom-color: var(--failColor) !important;
}

.phoneNumber {
  display: block;
  margin: 0;
  padding: 5px 0;
  border: none;
  border-bottom: 2px solid var(--marketplaceColor);
  border-radius: 0;
  font-size: 16px;
  line-height: 24px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.error {
  color: var(--failColor) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  padding: 8px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
    white-space: nowrap;
  }
}
