@import '../../marketplace.css';

.root {
  position: relative;
  display: inline-block;
  border-right: 2px solid var(--matterColorNegative);
  @media (--viewportMobile) {
    position: unset;
  }
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 6px 14px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  border: none;
  background: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: var(--marketplaceColorDarker);
  min-width: 120px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
  @media (--viewportMobile) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 0 14px;
  }
}

.labelSelected {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 25px;
  color: var(--marketplaceColorLight);
  border: none;
  background: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
  @media (--viewportMobile) {
  }
}
