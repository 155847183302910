@import '../../marketplace.css';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  @media (--viewportTabletDown) {
    row-gap: 25px;
  }
}
.bookingWrapper {
  position: relative;
}
.seatsField [class*='textarea'] {
  width: 97%;
  @media (--viewportTabletDown) {
    width: 94%;
  }
}
.requestBtnWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.requestBtn {
  background: #0058fa;
  border-radius: 7px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #eff5ff;
  padding: 15px 35px;
  border: 0;
}

.locationAddress,
.building {
  flex-shrink: 0;
}

.rootClass {
  position: unset;
}

.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.locationAutocompleteInputIcon {
  display: none;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);
  @media (--viewportMedium) {
    height: 40px;
  }
}
.locationAutocompleteInput:hover,
.locationAutocompleteInput:focus {
  border-bottom-color: var(--matterColorDark);
  outline: none;
}
/* Safari bugfix: without this Safari will print placeholder to a wrong place */
.locationAutocompleteInput::-webkit-input-placeholder {
  line-height: normal;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;
  @media (--viewportMedium) {
    top: 50px;
  }
}
.predictionsRoot li {
  color: var(--matterColorDark) !important;
  display: flex;
  align-items: center;
  column-gap: 15px;
  padding: 10px 29px !important;
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}

.validLocation {
  border-color: var(--successColor);
}

.label {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  background: white;
  font-size: 14px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (min-width: 550px) and (max-width: 800px) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  @media (--viewportMobile) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0px;
    position: absolute;
    margin: -0.5rem 0.5rem;
    padding: 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.bookingCurrencyWrapper {
  position: relative;
}
.bookingCurrencyWrapper label {
  background: var(--matterColorLight);
  position: absolute;
  top: -10px;
  left: 10px;
  padding: 0 5px;
}
.bookingCurrencyWrapper input:focus {
  border-color: var(--matterColorBlue);
}

.addressNewWrapper {
  position: relative;
}

.addressNewWrapper input:focus {
  border-color: var(--matterColorBlue);
}

.editListingTypeWrap {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 30px;
}
.editListingTypeWrap .editListingLable {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.08em;
  color: #000000;
}
.editListingCardWrap {
  display: flex;
  align-items: center;
  column-gap: 25px;
  row-gap: 25px;
  flex-wrap: wrap;
  @media (--viewportTabletDown) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}
.buttonMain {
  position: relative;
  @media (--viewportMobile) {
    width: 100%;
  }
}

.buttonMain .checkMark {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--matterColorBlue);
  border-radius: 14px 0px 7px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: none;
  @media (--viewportTabletDown) {
    width: 18px;
    height: 18px;
    border-radius: 6px 0px 4px;
  }
}
.buttonMain.selected .checkMark {
  display: flex;
}

.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}

.buttonMain.selected .checkMark {
  display: flex;
}

.buttonMain.selected .buttonWrapper {
  border: 1px solid var(--matterColorBlue);
}
.buttonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 136px; */
  padding: 25px;
  background: var(--matterColorLight);
  border: 1px solid #d7d7d7;
  border-radius: 14px;
  align-items: center;
  /* padding: 0 50px; */
  column-gap: 15px;
  @media (--viewportTabletDown) {
    /* width: 400px; */
  }
  @media (--viewportMobile) {
    width: 100%;
    height: 100%;
    padding: 19px 26px;
  }
}

.svgWrapper {
  @media (--viewportMobile) {
    & svg {
      width: 22px;
      height: 22px;
    }
  }
}
.editListingTypeText {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}

.productsButton {
  width: 185px;
  min-height: 46px;
  background: var(--matterColorBlue);
  border-radius: 3.31824px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  margin: 25px auto 0;
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    color: var(--matterColorBlue);
    min-height: 20px;
  }
}
.productsButton:hover {
  background: var(--matterColorBlue);
  box-shadow: none;
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    box-shadow: none;
  }
}
