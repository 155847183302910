@import '../../../../marketplace.css';

.enquiriesRoot {
  width: 100%;
  display: flex;

  @media (--viewportMobile) {
    display: block;
    overflow: auto;
  }
}

.cardItems {
  padding: 0 35px 0 0;
  display: flex;
  flex-direction: column;
  gap: 35px;
  height: calc(100vh - 215px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
  }

  @media (--viewportxldesktop) {
    gap: 15px;
    /* margin-top: 85px; */
    padding: 0 15px 0 0;
    height: calc(100vh - 235px);
  }

  @media (--viewportLaptopDown) {
    height: calc(100vh - 270px);
  }

  @media (--viewportTabletDown) {
    margin-top: 0;
    height: calc(100vh - 250px);
    padding: 0 22px 15px 22px;
  }
}

.leftSide {
  width: calc(521px - 35px);

  @media (--viewportxldesktop) {
    width: 50%;
    height: 100%;
  }
}

.mobileSenderList {
  width: 100%;
  display: none;
}

.showMobileSenderList {
  display: block;
}

.rightSide {
  flex: 1;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 15px;

  @media (--viewportxldesktop) {
    width: 50%;
  }
}

.mobileChatBoxInfo {
  width: 100%;
  position: relative;
  display: none;
  /* padding: 0 24px; */
}

.showMobileChatBoxInfo {
  display: block;
  overflow: hidden;
}

.headSection {
  padding-bottom: 5px;

  @media (--viewportxldesktop) {
    padding-bottom: 15px;
  }

  @media (--viewportTabletDown) {
    padding: 15px 22px;
  }
}

.chatBoxmessage {
  display: flex;
  justify-content: flex-start;
  padding-top: 24px;
}

.msgHeadingWrapper {
  display: flex;
  align-items: center;

  & .masged {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #0058fa;
  }
}

.inboxSearchWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 29px;
  align-items: center;
  padding-bottom: 31px;
}

.filterKeywordWrap {
  margin-right: 5px;
}

.filterSvg {
  padding-right: 30px;
}

.filterWrap {
  width: 100%;
  height: 46px;
  padding: 0 6px 0 16px;
  background: var(--matterColorLight);
  border-radius: 28px;
  border: 1px solid #d6d6d6;
  background: #f8f8f8;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (--viewportMobile) {
    width: 100%;
    max-width: 287px;
    height: 44px;
    border-radius: 28px;
    padding: 0 8px 0 16px;
    /* margin-bottom: 18px; */
  }
}

.filterWrap label {
  padding: 0 10px;
  margin: 0 20px 0 0;

  @media (--viewportMobile) {
    margin: 0 6px 0 0;
  }
}

.filterWrap svg {
  width: 21px;
  height: 21px;
  transform: scaleX(-1);
  stroke: #c9c9c9;
}

.filterKeywordWrap input {
  width: 100%;
  border-bottom: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: var(--matterColorDimmedText);

  @media (--viewportMobile) {
    width: 100%;

    &::placeholder {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.filterKeywordWrap input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 12px;
  color: #aaaaaa;
}

.titleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;

  @media (--viewportxldesktop) {
    margin-bottom: 15px;
  }

  @media (--viewportLaptopDown) {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: #0b1333;
    height: 73px;
    padding: 0 26px 0 10px;
    color: #ffffff;
    margin: 0;
  }
}

.titleWrapper h1 {
  margin: 0;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;

  @media (--viewportLaptopDown) {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-left: 22px;
  }
}

.svgContainer {
  background: #ffffff;
  box-shadow: 0px 19px 18px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 61px;
  height: 61px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  & svg {
    background-color: transparent;
    fill: none;
    width: 30px;
    height: 30px;
  }
}
