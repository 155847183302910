@import '../BlogPage.css';

.blogMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  /* width: 70%; */
  width: 85%;
  margin: 0 auto;
}
.blogMain img {
  /* min-width: 90%; */
  max-width: 100%;
}
.blogHead {
  font-weight: 800;
  font-size: 50px;
  line-height: 62px;
  /* color: #131414; */
  color: #303030;
  text-align: center;
  margin: 20px 0;
}
.blogText {
  font-weight: 400;
  /* font-size: 16px; */
  line-height: 18px;
  text-align: center;
  color: #323434;
  margin: 0 0 25px;
}
.blogWrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 30px;
}
.blogTextWrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1;
}
.blogDetail {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.blogDetailHead {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #0058fa;
  margin: 0;
}
.blogDetailText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin: 0;
}

.blogImageWrap {
  flex: 1;
}
.blogImageWrap img {
  width: 100%;
  object-fit: contain;
  margin: 10px auto;
}
.summaryWrap {
  display: flex;
  align-items: center;
  gap: 15px;
  /* margin-top: 30px; */
  padding: 10px 15px;
  border-radius: 15px;
  grid-column: 1 / 3;
  /* background-color: whitesmoke; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}
.FullWidthText {
  display: flex;
  gap: 15px;
  width: 100%;
  margin: 0;
  text-align: justify;
  /* justify-content: center; */
  grid-column: 1 / 3;
  @media (--viewportTabletDown) {
    padding: 15px 0;
  }
}
.paragraphText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  margin: 0;
}
.summaryNote {
  background: #0058fa;
  border-radius: 7px;
  padding: 9px 20px;
  min-width: 250px;
  text-align: center;
  margin: auto;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  color: #eff5ff;
}
.summaryText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000;
  margin: 0;
}
.summaryText span {
  font-weight: 700;
  color: #0058fa;
}
.subscribeWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-top: 55px;
}
.subscribeText {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}
.subscribeButtton {
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 20px;
  border: 0;
  padding: 9px 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  color: #fcfcfc;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .blogMain {
    width: 80%;
  }
  .blogWrap {
    display: block;
  }
  .blogMain img {
    max-width: 100%;
    height: auto;
  }
  .blogHead {
    font-size: 38px;
    line-height: 44px;
  }
}
@media (max-width: 991px) {
  .blogHead {
    font-size: 34px;
    line-height: 44px;
  }
  .blogWrap {
    grid-template-columns: repeat(1, 1fr);
  }
  .rememberWrap {
    flex-direction: column;
  }
  .subscribeWrap {
    flex-direction: column;
    gap: 15px;
  }
  .blogDetailText {
    font-size: 14px;
  }
  .blogDetailHead {
    font-size: 18px;
  }
  .blogDetailText {
    font-size: 14px;
  }
  .rememberButton {
    font-size: 24px;
    padding: 9px 30px;
  }
  .rememberText {
    font-size: 14px;
    text-align: justify;
  }
  .subscribeWrap {
    margin: 25px 0;
  }
  .subscribeText {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .blogMain {
    width: 100%;
    padding: 25px 15px;
  }
  .blogWrap {
    display: block;
  }
  .subscribeButtton {
    font-size: none;
  }
}
