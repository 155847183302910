.root {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    cursor: pointer;
    & > .img {
      position: absolute;
      top: -49px;
        right: 0;
      width: 30px;
      height: 110px;
      background-image: url('../../assets/HomePage/RightArrow.svg');
      background-size: contain;
      background-repeat: no-repeat;

    }
    @media (--viewportMobile) {
      display: none;
    }
  }
  
  .left {
    /* left: -15px; */
    & > .img {
      /* transform: translateY(-50%) rotate(180deg); */
      transform: scaleX(-1);
      top: -49px;
        left: -40px;
        right: unset;
    }
  }
  
  .right {
    right: -18px;
    height: 20px;
  }
  
  .disable{
    opacity: 0.4;
  }
