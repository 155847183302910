@import '../../marketplace.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--attentionColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.locationAddress,
.building {
  flex-shrink: 0;
  margin-bottom: 24px;
  width: 100%;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.submitButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.submitButtonContainer .submitButton {
  min-width: 30%;
  width: auto;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
.locationWrapper {
  display: flex;
  align-items: center;
  column-gap: 30px;
  position: relative;
  flex: 1;
  @media (--viewportTabletDown) {
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
  }
}

.locationAddress {
  position: relative !important;
  flex-shrink: 0;
}

.rootClass {
  position: unset;
  width: 100%;
}

.locationInput {
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.locationInput:focus {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--matterColorLight);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  border-radius: 17px;

  & li {
    color: var(--matterColorDark) !important;
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 10px 29px !important;
    @media (--viewportxldesktop) {
      font-size: 14px;
    }
  }
}

.predictionsRoot [class*='predictionsLocationSvg'] {
  display: block;
  min-width: 14px;
}

.locationLable {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  color: #000000;
  position: absolute;
  top: -8px;
  left: 10px;
  background: #fff;
  padding: 0 5px;
  z-index: 1;
  @media (--viewportTabletDown) {
    background: #f5f9ff;
    font-size: 12px;
    line-height: 15px;
  }
}
