@import '../../marketplace.css';

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}
.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}
.createFooterTab {
  top: 20px;
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}
.shareFormModal [class*='container'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    padding: 15px;
    min-height: unset;
    width: 95%;
    height: 100%;
    flex-grow: 0;
    top: 50px;
    border-radius: 10px;
    background: rgb(247, 247, 247);
  }
}
.shareFormModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.shareFormModal [class*='close'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    left: 5px;
    background: none;
    width: 15px;
    height: 15px;
    fill: #000000;
  }
}
.cardContainer {
  display: flex;
  flex-flow: column;
  height: fit-content;
  margin-left: 340px;

  @media (--viewportLaptopDown) {
    /* margin: 25px 14px 14px 14px; */
    margin: 55px 3%;
  }
}
.cardWrap {
  width: 90%;
  background: #fff;
  margin: 20px 40px 0;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: #000000;
  letter-spacing: 0.2px;

  @media (--viewportLaptopDown) {
    margin: 20px;
    padding: 20px;
  }
}

.breadCrumbWrapper {
  margin-top: 10px;
  padding: 0 24px;
  word-break: break-all;
  @media (--viewportTabletDown) {
    margin: 0;
    padding: 0 14px;
  }
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}
.logoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.logoWrap {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (--viewportTabletDown) {
    height: 40px;
  }
}
.logoWrap img {
  max-width: 260px;
  @media (--viewportTabletDown) {
    max-width: 150px;
  }
}
.blog {
  font-size: 50px;
  margin-top: 10px;
  line-height: 50px;
  @media (--viewportTabletDown) {
    font-size: 30px;
    margin-top: 5px;
    line-height: 35px;
  }
}
.suppotingText {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  text-align: center;
  @media (--viewportTabletDown) {
    font-size: 14px;
    line-height: 18px;
  }
}
.cardWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 40px;
  cursor: pointer;
  border-radius: 10px;
  color: #000000;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 0;
  }
}
.cardEducation {
  width: 35%;
  padding: 25px 50px;
  @media (--viewportTabletDown) {
    width: 100%;
    padding: 10px 0;
  }
}
.card img {
  height: 108px;
  object-fit: cover;
  width: 100%;
  border-radius: 30px 0;
}
.cardTitle {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.card:hover {
  transform: scale(1.02);
  box-shadow: var(--boxShadowListingCard);
  text-decoration: none;
}

.blogModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4) !important;
    flex-grow: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
/* .blogModal [class*='Modal_close_'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    background: none;
    fill: #000;
    right: 0;
    padding: 15px;
  }
} */
.blogModalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  overflow: auto;

  @media (--viewportMedium) {
    flex-basis: 75%;
    min-height: auto;
    height: auto;
    max-height: 100vh;
    overflow: auto;
    padding: 2rem 4rem;
  }
}

.blogModalContainer img {
  width: 100%;
}

.blogModalContainer::-webkit-scrollbar {
  /* display: none !important; */
  width: 8px !important;
  color: var(--matterColorLight) !important ;
}

.blogModalContainer::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
}
