.filterMain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px 20px;
  box-shadow: 0px 0px 5px 5px #9ec0ff40;
  border-radius: 5px;
}
.filterMain .filterHead {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
  color: #0058fa;
}
.filterMain .filterWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-bottom: 25px;
  @media (--viewportMobile) {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 15px;
  }
  @media (max-width: 320px) {
    margin-left: 2px;
  }
}
.ageSwitch {
  display: flex;
  align-items: center;
  gap: 15px;
  /* @media (--viewportMobile) {
    align-items: ;
  } */
}
.ageSwitchHead {
  color: #000;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  @media (--viewportMobile) {
    font-size: 16px;
    line-height: 26ffpx;
  }
}
.ageSwitch [class*='react-switch-bg'] {
  width: 37px !important;
  height: 16px !important;
  /* display: none; */
}
.ageSwitch [class*='react-switch-handle'] {
  width: 20px !important;
  height: 20px !important;
  /* display: none !important; */
  top: -2px !important;
}
.switchUnchecked [class*='react-switch-handle'] {
  left: -1px !important;
  background: #b5b5b5 !important;
}
.switchUnchecked [class*='react-switch-bg'] {
  background: #e2e1e1 !important;
}
.switchChecked [class*='react-switch-handle'] {
  left: -13px !important;
  background: #0058fa !important;
}
.switchChecked [class*='react-switch-bg'] {
  background: rgba(0, 88, 250, 0.2) !important;
}
