.myActivityMain {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.myActivityMain .myActivityWrap {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dce5f2;
  border-radius: 10px;
  padding: 12px 20px;
}
.myActivityWrap .myActivityDetail {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  width: 20%;
}
.myActivityWrap .myActivityDetail:nth-of-type(1),
.myActivityWrap .myActivityDetail:nth-of-type(3) {
  width: 25%;
}
.myActivityWrap .myActivityDetail:nth-of-type(2) {
  width: 10%;
}
.myActivityWrap .myActivityDetail:nth-of-type(5) {
  align-items: flex-end;
  justify-content: end;
}
.myActivityDetail .myActivityHead {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin: 0;
  color: #0058fa;
}
.myActivityDetail .myActivitySubHead {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin: 0;
  color: #0058fa;
}
.myActivityDetail .myActivityText {
  color: #0f0f0f;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}
.myActivityDetail .myActivityAccepted {
  color: #31d296;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}
.myActivityDetail .myActivityDeclined {
  color: #ff0000;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin: 0;
}
.myActivityWrap .myActivityButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  gap: 10px;
}
.myActivityAcceptBtn {
  border-radius: 6px;
  /* width: 104px; */
  background: #0058fa;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 15px;
  border: 0;
}
.myActivityDeclineBtn {
  border-radius: 6px;
  /* width: 104px; */
  background: #ffb81d;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 10px 15px;
  border: 0;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .myActivitySection {
    overflow: auto;
  }
  .myActivityMain {
    width: 1000px;
  }
}
@media (max-width: 767px) {
  .myActivityMain .myActivityWrap {
    flex-direction: column;
    align-items: flex-start;
  }
  .myActivityWrap .myActivityDetail {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .myActivityWrap .myActivityDetail:nth-of-type(1),
  .myActivityWrap .myActivityDetail:nth-of-type(2),
  .myActivityWrap .myActivityDetail:nth-of-type(3) {
    width: 100%;
  }
  .myActivityWrap .myActivityDetail:nth-of-type(5) {
    justify-content: center;
    margin-top: 15px;
  }
}
