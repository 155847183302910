.outerDateSelection {
    display: flex;
    margin-left: 10px;
    align-items: center;
    gap: 4px;
    margin-bottom: 20px;
    background-color: #EBEBEB;
    width: fit-content;
    padding: 8px;
    border-radius: 20px;

    @media (--viewportMobile) {
        flex-direction: column;
        width: 100%;
        margin: 10px 0;
    }
}

.searchIcon {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    background-color: #FFBD1D;
    border-radius: 20px;
    padding-right: 10px;
    cursor: pointer;
}

.outerDateSelectionWrapper {
    display: flex;

    @media (--viewportMobile) {
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }
}
.outerDate{
    @media (--viewportMobile) {
        width: 100%;
    }
   
}