@import '../../marketplace.css';

.productsMobileWrapper {
  margin-top: 50px;
  @media (--viewportTabletDown) {
    background: #fffdfd;
    box-shadow: 0px 0px 41.7609px 5.22011px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 30px 10px;
    margin: 0;
  }
}
.autorListingWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @media (--viewportMiniDesktopDown) {
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 768px) and (max-width: 992px) {
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportTabletDown) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
  @media (--viewportMobile) {
    justify-items: center;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
  }
}
.content {
  background: var(--matterColorLight);
  overflow: hidden;
}
.section {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  /* margin: 0px auto 60px auto; */

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 0.2rem;
    margin: 0;
    padding: 0;
  }

  @media (min-width: 1600px) {
    max-width: 100%;
    /* margin: 0px auto 60px auto; */
  }
}

body {
  overflow-x: hidden;
}

.uniqueDesignSection {
  padding: 10px 0;
  width: 100%;

  @media (max-width: 991px) {
    padding: 0 0 20px 0;
    width: 100%;
  }

  & hr {
    margin: 20px 0 30px;
  }

  & h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 16px;
    color: var(--matterColorDark);
    width: 100%;

    @media (max-width: 991px) {
      font-size: 21px;
      line-height: 30px;
      width: 100%;
      margin-top: 31;
      margin-bottom: 11px;
    }
  }
}

.topSpan {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: var(--matterColorDark);
  width: 60%;
  text-align: center;

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 22px;
  }
}

.popularExercisesSection {
  width: 85%;
  margin: auto;
  @media (--viewportTabletDown) {
    width: 100%;
  }
}

.chooseUsSection {
  width: 85%;
  margin: 2rem auto;
  padding: 4rem 6rem;
  display: flex;
  gap: 5rem;
  flex-wrap: wrap-reverse;
  background-color: #f7f7f7;
  border-radius: 20px;

  @media (--viewportMobile) {
    width: 100%;
    gap: 2rem;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 0px;
  }

  & img {
    flex: 1;
    max-width: 30rem;
    max-height: 30rem;
    object-fit: cover;
    border-radius: 20px;
    @media (--viewportMobile) {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.chooseUsInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;

  @media (--viewportMobile) {
    gap: 1rem;
  }

  & .title {
    font-size: 32px;
    line-height: 50px;
    font-weight: 600;

    @media (--viewportMobile) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  & .description {
    font-size: 18px;

    @media (--viewportMobile) {
      font-size: 14px;
    }
  }

  & .cardsContainer {
    display: flex;
    gap: 2rem;

    @media (--viewportMobile) {
      margin: 0;
      gap: 1rem;
    }
  }

  & .cardItem {
    flex: 1;
    max-width: 15rem;
    min-width: 9rem;
    padding: 1.5rem;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    background-color: white;
    border-radius: 12px;

    @media (--viewportMobile) {
      font-size: 14px;
      padding: 1rem;
      max-width: 8rem;
      min-width: 8rem;
    }
  }
}

.yogaClassesLocation {
  width: 85%;
  margin: auto;
  margin-top: 3rem;

  @media (--viewportMobile) {
    width: 95%;
    margin-top: 0rem;
    padding: 1rem;
  }

  & .locationContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 3rem;
    align-items: center;
    gap: 2rem 5rem;

    @media (--viewportMobile) {
      margin-top: 1rem;
      gap: 2rem;
      justify-content: center;
    }
  }

  & .locationItem {
    display: flex;
    gap: 30px;
    width: 12rem;
    justify-content: flex-start;
    align-items: center;

    @media (--viewportMobile) {
      gap: 0.5rem;
      width: 8rem;
    }

    & span {
      @media (--viewportMobile) {
        font-size: 14px;
      }
    }
  }

  & img {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 10px;

    @media (--viewportMobile) {
      width: 50px;
      height: 50px;
    }
  }
}

.servicesSection {
  width: 100%;
  margin-left: auto;
  margin: auto;
  margin-top: 4rem;

  @media (--viewportMobile) {
    margin-top: 2rem;
  }
}

.servicesTitle {
  line-height: 40px;
  font-weight: 600;
  color: black;
  margin: auto;
  font-size: 34px;
  text-align: center;

  @media (--viewportMobile) {
    font-size: 25px;
  }
}

.textAlignLeft {
  @media (--viewportMobile) {
    width: 90%;
    margin: auto;
    text-align: start;
  }
}

.textAlignCenter {
  text-align: center;
}

.alignItemsCenter {
  align-items: center;
}

.servicesSubheading {
  max-width: 40rem;
  margin-top: 1rem;
  font-weight: 400;
  color: black;
  margin: 1rem auto 0 auto;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  @media (--viewportMobile) {
    display: none;
  }
}

.servicesCarousel {
  margin: auto;
  width: calc(85% + 4rem);
  padding: 1rem 2rem;
  overflow-x: auto;

  @media (--viewportMobile) {
    padding: 0rem;
  }
}

.servicesCarouselItems {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;

  @media (--viewportMobile) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}

.servicesCarouselItem {
  max-width: 23rem;
  margin: 2rem 0;
  display: flex;
  flex: 1;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  gap: 0.5rem;

  @media (--viewportMobile) {
    max-width: 21rem;
  }

  & img {
    width: 100%;
    height: 15rem;
    border-top-radius: 10px;
    object-fit: cover;

    @media (--viewportMobile) {
      height: 12rem;
    }
  }

  & div {
    font-family: 'Int Circular PTT';
    font-size: 17px;
    font-weight: 400;
    line-height: 28px;
  }

  & .carouselInfo {
    padding: 0.5rem 1rem 1rem 1rem;
  }

  & .carouselHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .carouselHeading > h5 {
    margin: 0;
    color: #a8a8a8;
  }

  & .carouselRating {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
  }

  & .carouselRating img {
    width: 0.8rem;
    height: 0.8rem;
  }

  & .carouselRating h5 {
    margin: 0;
    font-weight: 600;
  }

  & .carouselRating span {
    font-size: 15px;
    color: #a8a8a8;
  }

  & .carouselName {
    margin: 0.3rem 0;
    font-size: 22px;
    font-weight: 600;
  }

  & .carouselDescription {
    font-size: 16px;
    color: #a8a8a8;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 25px;
    height: 75px;
  }
}

.cardInfo {
  display: flex;
  gap: 3rem;
}

.featuresSection {
  width: 100%;
  margin: 2rem 0;
  padding-top: 3rem;
  background-color: #f7f7f7;
}

.featureItemsContainer {
  display: flex;
  padding: 4rem 0;
  gap: 1.5rem;
  width: 85%;
  margin: auto;

  @media (--viewportMobile) {
    width: 90%;
    padding: 2rem 0;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
}

.featureItem {
  flex: 1;
  max-width: 20rem;
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  border-radius: 20px;

  @media (--viewportMobile) {
    font-size: 14px;
    min-width: 9rem;
    gap: 0.4rem;
  }
}

.submitRequestSection {
  margin-bottom: 2rem;
}

.becomeSellerSection {
  max-width: 85%;
  position: relative;
  margin: 0 auto 100px;
  @media (max-width: 1600px) {
    max-width: 85%;
  }
  @media (--viewportMiniDesktopDown) {
    margin: 0 auto 70px;
    max-width: 90%;
  }
  @media (--viewportTabletDown) {
    margin: 0 auto 25px;
    max-width: unset;
  }
}

.becomeSellerSection [class*='becomeSellerWrap'] {
  position: unset;
}

.paramsTitle {
  text-transform: capitalize;
}

.featureIcon img {
  @media (--viewportMobile) {
    width: 1rem;
    height: 1rem;
  }
}

.featureTitle {
  font-size: 22px;
  line-height: 30px;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.featureTitle2 {
  display: block;

  @media (--viewportMobile) {
    font-size: 13px;
  }
}

.featureDescription {
  font-size: 16px;
  font-weight: 400;

  @media (--viewportMobile) {
    font-size: 10px;
    line-height: 16px;
  }
}

.reviewsSection {
  width: 95%;
  margin: auto;
  margin-bottom: 4rem;

  @media (--viewportMobile) {
    margin-bottom: 2rem;
  }
}

.reviewsCarouselContainer {
  margin-top: 2rem;
  width: 100% !important;
  display: flex !important;
  align-items: center;
  gap: 2rem;

  @media (--viewportMobile) {
    margin-top: 1rem;
    padding: 2rem;
    overflow-x: scroll;
  }
}

.reviewsCardItem {
  flex: 1;
  max-width: 30rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background-color: white;
  box-shadow: 0px 22.8958px 86.677px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

  @media (--viewportMobile) {
    min-width: 18rem;
    padding: 1rem;
  }
}

.cardHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  & .cardImage {
    width: 3.5rem;
    height: 3.5rem;
    clip-path: circle(45%);
    shape-outside: circle(45%);

    @media (--viewportMobile) {
      width: 3rem;
      height: 3rem;
    }
  }

  & .cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .cardInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (--viewportMobile) {
      gap: 0.2rem;
    }
  }

  & .cardName {
    font-size: 24px;

    @media (--viewportMobile) {
      font-size: 18px;
    }
  }

  & .cardSubheading {
    font-size: 20px;
    color: #a8a8a8;

    @media (--viewportMobile) {
      font-size: 16px;
    }
  }
}

.cardContent {
  font-size: 18px;
  font-weight: 400;
  color: #a8a8a8;

  @media (--viewportMobile) {
    font-size: 14px;
    line-height: 20px;
  }
}

.cardsContainer {
  column-count: 2;

  @media (max-width: 991px) {
    column-count: 1;
    margin: 41px 24px;
  }
}

.cardsContainer div:nth-child(2) {
  background: #d3ffdd;
}

.cardsContainer div:nth-child(3) {
  background: #ffe4fe;
}

.cardsContainer div:nth-child(4) {
  background: #ffffea;
}

.cardsContainer div:nth-child(5) {
  background: #e0f4ff;
}

.cardsContainer div:nth-child(6) {
  background: #ffe7d1;
}

.topHeadContainer {
  height: calc(100vh - 56px);
  width: 100%;
  /* background: var(--marketplaceColorBackground); */
  display: flex;
  position: relative;
  color: var(--matterColorLight);
  margin-top: -5px;

  @media (max-width: 991px) {
    flex-flow: column;
    margin-top: -1px;
    height: fit-content;
  }
}

.sectionContainer {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (--viewportMobile) {
    justify-content: center;
    align-items: center;
  }
}

.heroImage {
  width: 100%;
  height: 100%;

  @media (--viewportMobile) {
    height: 255px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imageContainer {
  width: 85%;
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 4rem;
  background: linear-gradient(77.51deg, #0058fa -10.19%, #9d7aff 100%);
  border-radius: 1rem;

  @media (max-width: 991px) {
    width: 90%;
    padding: 1rem;
    margin: 2rem auto;
  }

  & h3 {
    font-size: 32px;
    color: white;
    text-align: center;
    line-height: 45px;
    max-width: 50rem;

    @media (max-width: 991px) {
      font-size: 18px;
      text-align: center;
      line-height: 25px;
    }
  }
}

.arrowIconLeft {
  width: 2rem;
  height: 2rem;

  @media (max-width: 991px) {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.uniqueSectionContainer {
  max-width: 85%;
  margin: 35px auto 0;

  @media (max-width: 991px) {
    margin: 20px 24px 20px;
  }
}

hr {
  margin: 60px 0 25px;
}

.getProLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  width: fit-content;
  padding: 0 1.5rem;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin-top: 1rem;
  text-align: center;
  text-transform: capitalize;
  text-decoration: none !important;

  @media (max-width: 991px) {
    width: auto;
    height: 44px;
    font-size: 14px;
    line-height: 19px;
  }
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ourTopExampleTitle {
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: start;
  color: var(--matterColorDarkBlue);
  margin: 35px 0;
  @media (--viewportMobile) {
    font-size: 20px;
    line-height: 28px;
    width: 85%;
    margin: auto;
  }
}

.productsButton {
  width: 185px;
  min-height: 46px;
  background: var(--matterColorBlue);
  border-radius: 3.31824px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--matterColorLight);
  margin: 25px auto 0;
  &:hover {
    background: var(--matterColorBlue);
    box-shadow: none;
  }
  @media (--viewportTabletDown) {
    background: var(--matterColorLight);
    color: var(--matterColorBlue);
    min-height: 20px;

    &:hover {
      background: var(--matterColorLight);
      box-shadow: none;
    }
  }
}

.layoutFooter {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999;
}

.activeleftbarFooter {
  z-index: unset;

  @media (--viewportMobile) {
    z-index: 9999999;
  }
}

.createFooterTab {
  top: 20px;
}

.modalLeftBar [class*='close__'] {
  display: none;
}

.modalLeftBar [class*='scrollLayer'] {
  width: 100%;
}

.modalLeftBar [class*='container_'] {
  background-color: transparent;
  width: 100%;
  padding: unset;
  height: unset;
  min-height: unset;
}
.sectionHeading {
  display: flex;
  justify-content: center;
}
.sectionDescription {
  display: flex;
  justify-content: center;
  width: 100%;
}
.breadCrumbWrapper {
  margin: 10px auto 0;
  width: 85%;
}
.breadcrumbArrow {
  margin-left: 6px;
  margin-right: 6px;
  font-size: 12px;
  color: #8c8c8c;
}
.breadCrumbWrapper a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px !important;
  line-height: 15px;
  color: #8c8c8c;
}

.breadCrumbWrapper a.currentListingTypeName:hover {
  text-decoration: underline;
}
.shareFormModal [class*='container'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    padding: 15px;
    min-height: unset;
    width: 95%;
    height: 100%;
    flex-grow: 0;
    top: 50px;
    border-radius: 10px;
    background: rgb(247, 247, 247);
  }
}
.shareFormModal [class*='scrollLayer'] {
  @media (--viewportLaptopDown) {
    background-color: rgba(0, 0, 0, 0.4);
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.shareFormModal [class*='close'] {
  @media (--viewportLaptopDown) {
    position: absolute;
    left: 5px;
    background: none;
    width: 15px;
    height: 15px;
    fill: #000000;
  }
}
