@import '../../marketplace.css';

.btnWrap {
  display: flex;
  justify-content: flex-end;
  margin-left: 16px;
  min-height: unset !important;
  gap: 30px;
  @media (--viewportMobile) {
    justify-content: center;
    margin-left: 0;
    width: 100%;
    border-left: none;
    gap: 15px;
  }
}

.btnWrap button:first-child {
  /* margin-left: 23px; */
  justify-content: center;
  align-items: center;
  display: flex;
  @media (--viewportMobile) {
    margin-left: 0;
  }
}

.button {
  /* background: #59c264 !important; */
  min-height: unset;
  border-radius: 6px;
  width: 104px;
  height: 44px;
  background: #0058fa;
  & span {
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    @media (--viewportTabletDown) {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media (--viewportMobile) {
    width: 100%;
    font-size: 10px;
    line-height: 16px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.stateDelivered {
  color: #0058fa;
  font-size: 15px;
}

.buttonReschedule {
  border-radius: 6px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  min-height: unset;
  width: 104px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #0058fa;
  color: #0058fa;
  @media (--viewportMobile) {
    width: 100%;
    height: 44px;
    font-size: 13px;
    line-height: 16px;
  }
  &:last-child {
    margin-right: 0;
  }
}

.otherReshedule {
  & span {
    color: #589de2;
  }
}

.cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 42px;
  width: 109px;
  background: #ffb81d;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  width: 104px;
  height: 44px;
  border-color: var(--matterColorNegative) !important;
  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    @media (--viewportTabletDown) {
      font-size: 14px;
      line-height: 15px;
    }
  }
  @media (--viewportMobile) {
    width: 100%;
    font-size: 10px;
    line-height: 16px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:focus {
    background-color: red !important;
  }
  &:global(.Button_secondaryButtonRoot__1RXd1) {
    padding: 0;
  }
}
.modalContent {
  @media (--viewportMobile) {
    height: 100%;
  }
}
.contentWrap {
  @media (--viewportMobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }
}
.confirmButtons {
  display: flex;
  padding: 0 30px;
  justify-content: space-evenly;
  @media (--viewportMobile) {
    margin-top: 15px;
  }
}

.confirmButtons [class*='primaryButton'] {
  background: #0058fa;
}

.confirmButton {
  width: 120px;
  min-height: 45px;
  &:last-child {
    margin-right: 0;
  }
}

.checkboxInput {
  width: auto;
  @media (--viewportMedium) {
    width: 50px;
  }
}

.checkboxField {
  position: relative;
  display: flex;
  align-items: center;
  @media (--viewportMobile) {
    top: 31px;
    right: 20px;
    position: absolute;
  }
  & > span {
    font-size: 16px;
    padding-left: 5px;
  }
}

.isReadyToCompleteCheckboxField {
  @media (--viewportMobile) {
    right: 11px;
  }
}

.checkboxField label {
  background-color: var(--matterColorLight);
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  border: 1px solid #0058fa;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

.checkboxField label:after {
  border: 3px solid var(--matterColorLight);
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 50%;
  transform: translate(-50%, -75%) rotate(-45deg);
  opacity: 0;
  position: absolute;
  top: 50%;
  width: 12px;
  @media (--viewportMobile) {
    border-width: 2px;
  }
}

.checkboxField input[type='checkbox'] {
  visibility: hidden;
}

.checkboxField input[type='checkbox']:checked + label {
  background-color: #0058fa;
  border-color: #0058fa;
}

.checkboxField input[type='checkbox']:checked + label:after {
  opacity: 1;
}

.bokkingMain {
  display: flex;
  align-items: center;
  width: 100%;
  @media (--viewportMiniDesktopDown) {
    flex-direction: column;
    row-gap: 15px;
  }
}
.bookingDetailsMain {
  display: flex;
  align-items: center;
  flex: 1;
  @media (--viewportMiniDesktopDown) {
    flex-direction: column;
    width: 100%;
    row-gap: 13px;
  }
}
.userBookingDetails {
  display: flex;
  align-items: center;
  border-right: 1px solid #dce5f2;
  padding-right: 40px;
  margin-right: 30px;
  width: 100%;
  @media (--viewportMiniDesktopDown) {
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}
.userDetail {
  display: flex;
  align-items: center;
  column-gap: 25px;
  @media (--viewportTabletDown) {
    column-gap: 13px;
  }
}
/* .userDetail [class*='avatarImage'] {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
  }
} */

.avatarUser {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  @media (--viewportTabletDown) {
    width: 40px;
    height: 40px;
  }
}

.bookingDateDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.clientBookListingWrapper {
}

.userDisplayNameWrapper span {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #0b1333;
  margin-bottom: 5px;
  @media (--viewportTabletDown) {
    font-size: 16px;
    line-height: 18px;
  }
}
.listingNameWrapper {
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #0b1333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (--viewportTabletDown) {
    font-size: 13px;
    line-height: 13px;
  }
}
.linkClasses {
  &:hover {
    text-decoration: none;
  }
}
